import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useFirestoreConnect } from 'react-redux-firebase';
import _ from 'lodash';
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Progress,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";

import Select from "react-select";

import { newGroupSchema } from '../../variables/schema';
import readXlsxFile from 'read-excel-file'
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { courseTypes } from 'variables/constants';

function CourseForm(props) {

  const [course, setCourse] = useState(props.course || null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModified, setIsModified] = useState(false);
  const [onSaving, setOnSaving] = useState(false);
  const [courseId, setCourseId] = useState(props.course?.id || null);
  const [alert, setAlert] = useState(null);

  const firebase = useFirebase();
  const firestore = useFirestore();


  useEffect(() => {
    if (props.course?.id) {
      setCourseId(props.course.id);
    } else {
      setCourseId(firestore.collection(`curriculumVersions/${props.version.version}/courses`).doc().id);
    }
    return () => {
      // cleanup
    }
  }, []);

  useEffect(() => {
    const currentItem = _.cloneDeep(props.course);
    const modified = !_.isEqual(currentItem, course);
    console.log(`currentItem`, currentItem);
    console.log(`currentItem`, course);
    console.log("modified: ", modified);
    setIsModified(modified);
    return () => {
      // cleanup
    };
  }, [course]);


  
  useEffect(() => {
    checkValidity();
    return () => {
      // cleanup
    }
  }, [course])

  const checkValidity = () => {
    const { title, courseType, lessons } = course;

    console.log('title = ', title);
    console.log('courseType = ', courseType);
    console.log('lessons', lessons);

    if (!title || title.length < 3) {
      setErrorMessage('코스 이름을 3글자 이상 입력해주세요.');
      return false;
    }

    if (courseType === undefined) {
      setErrorMessage('코스 타입을 설정해주세요.');
      return false;
    }

    if (isNaN(lessons) || lessons==0) {
      setErrorMessage('차시를 입력해주세요(숫자).');
      return false;
    }

    setErrorMessage(null); // no error
    return true;
  }

  const warningWithConfirmAndCancelMessage = () => setAlert(
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title={<div>삭제하면 복구할 수 없습니다.<br />계속 진행하시겠습니까?</div>}
      onConfirm={() => {
        onDelete();
        setAlert(null);
      }}
      onCancel={() => setAlert(null)}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      confirmBtnText="확인"
      cancelBtnText="취소"
      showCancel
    >
      지우면 완전히 삭제됩니다. 지우시겠습니까?
        </ReactBSAlert>
  )

  const onDelete = async () => {
    const courseRef = firestore.collection(`curriculumVersions/${props.version.version}/courses`).doc(courseId);
    await courseRef.delete();
    props.afterDelete && props.afterDelete();
  }

  const onSave = async () => {
    console.log('press save');

    if (!checkValidity()) {
      // setErrorMessage('유효하지 않는 값입니다.');
      return;
    }

    setOnSaving(true);

    const timestamp = {
      updatedAt: firestore.FieldValue.serverTimestamp()
    }

    if (!props.course?.id) {
      timestamp['createdAt'] = firestore.FieldValue.serverTimestamp();
    }

    try {
      const courseRef = firestore.collection(`curriculumVersions/${props.version.version}/courses`).doc(courseId);
      await courseRef.set({
        ...course,
        ...timestamp,
      });
    } catch (error) {
      console.error('Failed to save : ', error);
    }
    setOnSaving(false);
    props.afterSave && props.afterSave(() => props.onCancel());
  }

  const onCancel = () => {
    console.log('onCancel!');
    if (isModified) {
      props.closeConfirm(()=>props.onCancel());
    } else {
      props.onCancel();
    }
  }

  const onChange = (e, name, typeFn) => {
    const value = e.target.value;
    setCourse({ ...course, [name]: typeFn ? typeFn(value) : value });
  }


  return (
    <div className='content'>
      {alert}
      <Row>
        <Col>
          <h5 className='text-info'>코스 정보</h5>
        </Col>
      </Row>
      {/* title */}
      <Row>
        <Col>
          <div className="text-right"><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span> 필수 입력 사항입니다</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="align-items-center justify-course-between">
            <Col><label>제목(학년 또는 과목이름)</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={course.title || ''}
              placeholder="1학년 혹은 축구2반"
              onChange={e => onChange(e, 'title')} />
          </FormGroup>
        </Col>
      </Row>
      {/* description */}
      <Row>
        {/* courseType */}
        <Col>
          <Row className="align-items-center justify-course-between">
            <Col><label>코스 타입</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Select
              className="react-select"
              classNamePrefix="react-select"
              value={{ value: course.courseType, label: course.courseType }}
              onChange={item => {
                if (item.value !== course.courseType) {
                  setCourse({
                    ...course,
                    courseType: item.value
                  })
                }
              }}
              options={[
                {
                  value: "",
                  label: "코스 타입 설정",
                  isDisabled: true
                },
                ...courseTypes.map(item => ({ value: item, label: item }))
              ]}
              placeholder="코스 타입 설정"
            />
          </FormGroup>
        </Col>
        {/* lessons */}
        <Col>
          <Row className="align-items-center justify-course-between">
            <Col><label>총 차시</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Input
              value={course.lessons || ''}
              placeholder="총 차시"
              type="number"
              onChange={e => onChange(e, 'lessons', (txt) => Number(txt))} />
          </FormGroup>
        </Col>
      </Row>
      {
        errorMessage
          ? <div>
            <p className="text-danger">{errorMessage}</p>
          </div>

          : null
      }
      <Row>
        <Col>
          <Row className="align-items-center justify-course-between">
            <Col><label>설명</label></Col>
          </Row>
          <FormGroup>
            <Input
              value={course.courseDescription || ''}
              placeholder="본 코스에 대한 설명"
              onChange={e => onChange(e, 'courseDescription')} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        {
          props.course?.id
            ? <Col>
              <Button color="danger" onClick={warningWithConfirmAndCancelMessage}>삭제</Button>
            </Col>
            : null
        }
        <Col className='text-right'>
          <Button color="primary" disabled={onSaving || Boolean(errorMessage) ||!isModified} onClick={onSave}>저장</Button>
          <Button color="warning" onClick={onCancel}>취소</Button>
        </Col>
      </Row>


    </div>
  )
}


CourseForm.propTypes = {
  course: PropTypes.object,
  version: PropTypes.object.isRequired,
  afterSave: PropTypes.func,
  onCancel: PropTypes.func,
};

export default CourseForm

