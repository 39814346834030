import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useFirestoreConnect } from 'react-redux-firebase';
import _ from 'lodash';
import arrayMove from 'array-move';
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Progress,
  Row,
  Col,
  Button,
  Spinner,
  UncontrolledTooltip
} from "reactstrap";

import Select from "react-select";

import { newGroupSchema } from '../../variables/schema';
import readXlsxFile from 'read-excel-file'
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { asyncForEach } from 'helpers/async';
import { contentTypes } from 'variables/constants';
import { sensorTypes } from 'variables/constants';
import { optionUITypes } from 'variables/constants';
import { optionDataTypes } from 'variables/constants';
import { getSelectValues } from 'helpers/input';
import { sensorTypeStrings } from 'variables/constants';

function ContentForm(props) {

  const [content, setContent] = useState(props.content || null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [onSaving, setOnSaving] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [alert, setAlert] = useState(null);

  const firebase = useFirebase();
  const firestore = useFirestore();

  //민호 - content.id를 삭제를 하는 이유는?
  useEffect(() => {
    if (props.content) {
      const _content = _.clone(props.content);
      delete _content.id;
      setContent(_content);
    }
    return () => {
      // cleanup
    }
  }, [props.content]);

  useEffect(() => {
    checkValidity();
    return () => {
      // cleanup
    };
  }, [content]);

  const checkValidity = () => {
    const{contentId, title, contentDescription, contentType, isMeasurable, playerNumber, sensors, sensorType, metValue} = content;
    //민호 - 순서와 상관없이 attribute 이름이 같으면 저장됨.
    console.log('checkValidity => ',content);
 

    if (isNaN(contentId) || contentId==0) {
      setErrorMessage('콘텐츠 ID를 입력해주세요(숫자)');
      return false;
    }
    if (!title || title.length < 3) {
      setErrorMessage('제목을 3글자 이상 입력해주세요.');
      return false;
    }
    console.log(title.length);
    if (!contentDescription || contentDescription.length < 3) {
      setErrorMessage('설명을 3글자 이상 입력해주세요.');
      return false;
    }

    if (contentType === undefined) {
      setErrorMessage('콘텐츠 타입을 설정해주세요.');
      return false;
    }
    // if (isMeasurable === undefined) {
    //   setErrorMessage('체력 측정 가능 여부를 설정해주세요.');
    //   return false;
    // }

    if (isNaN(playerNumber) || playerNumber==0) {
      setErrorMessage('플레이어 숫자를 입력해주세요(숫자).');
      return false;

    }
    // if (!sensors || sensors.length<1) {
    //   setErrorMessage('사용하는 센서를 선택해주세요.');
    //   return false;
    // }
    if (sensorType === undefined) {
      setErrorMessage('센서타입을 선택해주세요.');
      return false;
    }
    // if (isNaN(metValue) || metValue==0) {
    //   setErrorMessage('초당 소비 칼로리(숫자)를 입력해주세요.');
    //   return false;
    // }
    
    setErrorMessage(null); // no error
    return true;
  }

  useEffect(() => {
    const currentItem = _.cloneDeep(props.content);
    console.log(`currentItem => `, currentItem);
    console.log(`content => `, content);
    if (currentItem == null&&Object.keys(content).length === 0 ) {
      return
    }
    // if (currentItem?.id) {
    //   delete currentItem.id;
    // }
    const modified = !_.isEqual(currentItem, content);
    console.log("modified: ", modified);
    setIsModified(modified);
    return () => {
      // cleanup
    };
  }, [content]);

  const onSave = async () => {
    console.log('press save');

    setOnSaving(true);

    const timestamp = {
      updatedAt: firestore.FieldValue.serverTimestamp()
    }

    if (!props.content?.id) {
      timestamp['createdAt'] = firestore.FieldValue.serverTimestamp();
    }

    try {
      const contentRef = firestore.collection(`contentVersions/${props.version.version}/contents`).doc(String(content.contentId));
      if (props.content?.id) {
        await contentRef.update({
          ...content,
          ...timestamp,
        });
        props.afterSave && props.afterSave(()=>props.onCancel());
      } else {
        // TODO: 새로만들기 전에 있는 지 확인하기
        const docSnapshot = await contentRef.get();
        const write = async () => {
          await contentRef.set({
            ...content,
            ...timestamp,
          });
          setOnSaving(false);
          console.log('props: ', props.afterSave);
          props.afterSave && props.afterSave(() => props.onCancel());
        }

        if (docSnapshot.exists) {
          // show error
          warningWithConfirmAndCancelMessage(write);
        } else {
          write();
        }

      }
    } catch (error) {
      console.error('Failed to save : ', error);
    }
  }

  const warningWithConfirmAndCancelMessage = (overWriteFn) => setAlert(
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title="이미 존재하는 콘텐츠 ID입니다. 덮어쓰시겠습니까?"
      onConfirm={() => {
        setAlert(null);
        overWriteFn();
        props.afterSave && props.afterSave();
      }}
      onCancel={() => {
        setAlert(null);
        setOnSaving(false);
      }}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      confirmBtnText="네, 덮어씁니다"
      cancelBtnText="아니요, 취소합니다"
      showCancel
    >
      이미 존재하는 콘텐츠 ID입니다. 덮어쓰시겠습니까?
        </ReactBSAlert>
  )

  const onCancel = () => {
    console.log('onCancel!');
    if (isModified) {
      props.closeConfirm(() => props.onCancel());
    } else {
      props.onCancel();
    }
  }

  const deleteWarningWithConfirmAndCancelMessage = () => setAlert(
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title={<div>삭제하면 복구할 수 없습니다.<br />계속 진행하시겠습니까?</div>}
      onConfirm={() => {
        onDelete();
        setAlert(null);
      }}
      onCancel={() => setAlert(null)}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      confirmBtnText="확인"
      cancelBtnText="취소"
       showCancel
    >
      지우면 완전히 삭제됩니다. 지우시겠습니까?
        </ReactBSAlert>
  )

  const onDelete = async () => {
    const contentRef = firestore.collection(`contentVersions/${props.version.version}/contents`).doc(String(content.contentId));
    await contentRef.delete();
    props.afterDelete && props.afterDelete();
  }

  const onChange = (e, name, typeFn) => {
    const value = e.target.value;
    setContent({ ...content, [name]: typeFn ? typeFn(value) : value });
  }

  const addOption = (index = null) => {
    const { options = [] } = content;
    if (!index) {
      setContent({
        ...content,
        options: [
          ...options,
          {
            id: '',
            title: '',
            description: '',
            uiType: '',
            defaultValues: [],
            items: [],
          }
        ]
      });
    } else {
      const newOptions = _.clone(options);
      newOptions.splice(index + 1, 0, {
        id: '',
        title: '',
        description: '',
        uiType: '',
        defaultValues: [],
        items: [],
      })
      setContent({
        ...content,
        options: newOptions
      });
    }
  }

  const deleteOption = (idx) => {
    const newOptions = content.options.filter((item, i) => i !== idx);
    setContent({ ...content, options: newOptions });
  }

  const moveUp = (index) => {
    if (index === 0) {
      return;
    }
    const newOptions = arrayMove(content.options, index, --index);
    setContent({ ...content, options: newOptions });
  }

  const moveDown = (index) => {
    if (index === content.options.length - 1) {
      return;
    }
    const newOptions = arrayMove(content.options, index, ++index);
    setContent({ ...content, options: newOptions });
  }

  return (
    <div className='content'>
      {alert}
      <Row>
        <Col>
          <h5 className='text-info'>콘텐츠 기본 설정</h5>
        </Col>
        <Col>
          <div className="text-right"><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span> 필수 입력 사항입니다</div>
        </Col>
      </Row>

      {/* title */}
      <Row>
        {/* contentId */}
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>콘텐츠 ID</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={content.contentId}
              disabled={props.content?.id}
              placeholder="콘텐츠 아이디"
              readOnly={Boolean(props.content?.contentid)}
              type="number"
              onChange={e => onChange(e, 'contentId', txt => Number(txt))} />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <Row className="align-items-center justify-content-between">
            <Col><label>제목</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={content.title}
              placeholder="콘텐츠 제목"
              onChange={e => onChange(e, 'title')} />
          </FormGroup>
        </Col>
      </Row>
      {/* description */}
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>설명</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={content.contentDescription}
              placeholder="콘텐츠 설명"
              onChange={e => onChange(e, 'contentDescription')} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        {/* contentType */}
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>콘텐츠 타입</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span>
            </Col>
          </Row>
          <FormGroup>
            <Select
              className="react-select"
              classNamePrefix="react-select"
              value={{ value: content.contentType, label: content.contentType }}
              onChange={item => {
                if (item.value !== content.contentType) {
                  setContent({
                    ...content,
                    contentType: item.value
                  })
                }
              }}
              options={[
                {
                  value: "",
                  label: "콘텐츠 타입 설정",
                  isDisabled: true
                },
                ...contentTypes.map(item => ({ value: item, label: item }))
              ]}
              placeholder="콘텐츠 타입 설정"
            />
          </FormGroup>
        </Col>
        {/* isMeasurable */}
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>체력 측정 가능 여부</label>
            </Col>
          </Row>
          <FormGroup>
            <Col>
            <Label>
              <Input
                defaultChecked={Boolean(content.isMeasurable)}
                type="radio"
                name = 'radioGroup'
                onChange={e => setContent({ ...content, isMeasurable: e.target.checked ? 1 : 0 })}
              />
              <span className="form-check-sign" />
              측정가능
            </Label>
            </Col>
            <Col>
            <Label >
              <Input
                defaultChecked={Boolean(content.isMeasurable)}
                type="radio"
                name = 'radioGroup'
                onChange={e => setContent({ ...content, isMeasurable: e.target.checked ? 0 : 1 })}
              />
              <span className="form-check-sign" />
              측정불가능
            </Label>
            </Col>
          </FormGroup>
        </Col>
        {/* playerNumber */}
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>플레이어 숫자</label> <span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={content.playerNumber}
              placeholder="플레이어 숫자"
              type="number"
              onChange={e => onChange(e, 'playerNumber', (txt) => Number(txt))} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        {/* sensors */}
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>사용하는 센서</label>
            </Col>
          </Row>

          <FormGroup check>
            {
              sensorTypes.map((sensor, index) => {
                const sensors = content.sensors || [];
                return <Label key={index} check style={{ paddingRight: 20 }}>
                  <Input
                    defaultChecked={Boolean(sensors.includes(sensor))}
                    type="checkbox"
                    onChange={e => setContent({
                      ...content,
                      sensors: e.target.checked
                        ? _.union(sensors, [sensor])
                        : sensors.filter(s => s.id !== sensor.id)
                    })}
                  />
                  <span className="form-check-sign" />
                  {sensor}
                </Label>
              })
            }
          </FormGroup>
        </Col>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>센서 타입</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="sensorType"
              value={{
                value: content?.sensorType || '',
                label: sensorTypeStrings[content?.sensorType || 0],
              }}
              onChange={({ value, label }) => {
                setContent({ ...content, sensorType: Number(value) });
              }}
              options={[
                {
                  value: "",
                  label: "센서타입 선택",
                  isDisabled: true
                },
                ...sensorTypeStrings.map((item, idx) => ({ value: idx, label: item }))
              ]}
              placeholder="센서타입 설정하기"
            />
          </FormGroup>
        </Col>
        {/* metValue */}
        <Col xs={4}>
          <Row className="align-items-center justify-content-between">
            <Col><label>초당 소비 칼로리 Cal/sec</label>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={content.metValue}
              placeholder="초당 소비 칼로리(cal/s)"
              type="number"
              onChange={e => onChange(e, 'metValue', (txt) => Number(txt))} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <hr style={{ borderTop: '2px solid lightgray' }} />
        </Col>
      </Row>
      {
        content.contentType === 'TT'
          ? <span className='text-info'>타이머 트레이닝은 여기서 옵션설정하지 않고, 개별 커리큘럼에서 가능합니다.</span>
          : <Row className="align-items-center">
            <Col xs={{ size: 'auto' }} style={{ paddingTop: 12 }}>
              <h5 className='text-info'>콘텐츠 옵션 설정</h5>
            </Col>
            <Col>
              <Button className="btn-icon btn-round" size="sm" color="primary" onClick={() => addOption(null)}>
                <i className="fa fa-plus" />
              </Button>
            </Col>
          </Row>
      }
      {
        content.contentType !== 'TT' && content?.options && content.options.map((option, idx) => {
          return <React.Fragment key={idx}>
            <Row>
              <Col>
                <Row>
                  {/* option id */}
                  <Col>
                    <Row className="align-items-center justify-content-between">
                      <Col><label>옵션 ID</label></Col>
                    </Row>
                    <FormGroup>
                      <Input
                        value={option.id}
                        placeholder="옵션 ID"
                        onChange={event => {
                          const newOptions = content.options.map((item, index) => idx === index ? { ...item, id: event.target.value } : item);
                          setContent({ ...content, options: newOptions });
                        }} />
                    </FormGroup>
                  </Col>
                  {/* option ui type */}
                  <Col>
                    <Row className="align-items-center justify-content-between">
                      <Col><label>옵션 UI타입</label></Col>
                    </Row>
                    <FormGroup>
                      <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        value={{ value: option.uiType, label: option.uiType }}
                        onChange={selectedItem => {
                          if (selectedItem.value !== option.uiType) {
                            const newOptions = content.options.map((item, index) => idx === index ? { ...item, uiType: selectedItem.value } : item);
                            setContent({ ...content, options: newOptions });
                          }
                        }}
                        options={[
                          {
                            value: "",
                            label: "UI 타입 설정",
                            isDisabled: true
                          },
                          ...optionUITypes.map((item, index) => ({ value: item, label: item }))
                        ]}
                        placeholder="UI 타입 설정"
                      />
                    </FormGroup>
                  </Col>
                  {/* option title */}
                  <Col>
                    <Row className="align-items-center justify-content-between">
                      <Col><label>옵션 제목</label></Col>
                    </Row>
                    <FormGroup>
                      <Input
                        value={option.title}
                        placeholder="옵션 제목"
                        onChange={event => {
                          const newOptions = content.options.map((item, index) => idx === index ? { ...item, title: event.target.value } : item);
                          setContent({ ...content, options: newOptions });
                        }} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  {/* option description */}
                  <Col>
                    <Row className="align-items-center justify-content-between">
                      <Col><label>옵션 설명</label></Col>
                    </Row>
                    <FormGroup>
                      <Input
                        value={option.description}
                        placeholder="옵션 설명"
                        onChange={event => {
                          const newOptions = content.options.map((item, index) => idx === index ? { ...item, description: event.target.value } : item);
                          setContent({ ...content, options: newOptions });
                        }} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  {/* option items  */}
                  <Col>
                    <Row className="align-items-center justify-content-between">
                      <Col><label>옵션 선택지</label></Col>
                    </Row>
                    <FormGroup>
                      <Input
                        value={option.items?.join(', ')}
                        placeholder="옵션 선택지"
                        onChange={event => {
                          const newOptions = content.options.map((item, index) =>
                            idx === index
                              ? {
                                ...item,
                                items: event.target.value.split(',').map(item => item.trim())
                              }
                              : item);
                          setContent({ ...content, options: newOptions });
                        }} />
                    </FormGroup>
                  </Col>
                  {/* option defaultValues */}
                  <Col>
                    <Row className="align-items-center justify-content-between">
                      <Col><label>옵션 기본값</label></Col>
                    </Row>
                    <FormGroup>
                      <Input
                        type="select"
                        name="selectMulti"
                        id="defaultValues"
                        value={option?.defaultValues}
                        multiple
                        onChange={(e) => {
                          const selected = getSelectValues(e);
                          const defaultValues = selected.map(i => Number(i.value));
                          console.log(defaultValues);
                          if (!_.isEqual(_.sortBy(defaultValues), _.sortBy(option.defaultValues))) {
                            const newOptions = content.options.map((item, index) => idx === index ? { ...item, defaultValues } : item);
                            setContent({ ...content, options: newOptions });
                          }
                        }}
                      >
                        {
                          option.items.map((item, index) =>
                            <option value={index} key={index}>
                              [index] {item}
                            </option>
                          )
                        }
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="2" className='text-center text-vertical-center'>
                <Button className="btn-icon btn-round" size="sm" color="primary" onClick={() => addOption(idx)}>
                  <i className="fa fa-plus" />
                </Button>
                <Button className="btn-round btn-icon" size="sm" color="danger" onClick={() => deleteOption(idx)}>
                  <i className="fa fa-minus" />
                </Button>
                <br />
                <Button className="btn-icon" size="sm" color="info" onClick={() => moveUp(idx)}>
                  <i className="fa fa-arrow-up" />
                </Button>
                <Button className="btn-icon" size="sm" color="info" onClick={() => moveDown(idx)}>
                  <i className="fa fa-arrow-down" />
                </Button>
              </Col>

            </Row>
            <Row>
              <Col>
                <hr style={{
                  borderTop: '1px dashed lightgray'
                }} />
              </Col>
            </Row>
          </React.Fragment>
        })
      }
      <Row>
      </Row>

      {
        errorMessage
          ? <div>
            <p className="text-danger">{errorMessage}</p>
          </div>

          : null
      }
      <Row>
        {
          //content.id를 체크하는 이유
          props.content?.id
            ? <Col>
              <Button color="danger" onClick={deleteWarningWithConfirmAndCancelMessage}>삭제</Button>
            </Col>
            : null
        }

        <Col className='text-right'>
          <Button color="primary" disabled={onSaving || !Boolean( content?.contentId && content?.title && content?.contentDescription && content?.contentType && content?.playerNumber && content?.sensors && content?.metValue) || !isModified} onClick={onSave}>저장</Button>
          <Button color="warning" onClick={onCancel}>취소</Button>
        </Col>
      </Row>


    </div>
  )
}


ContentForm.propTypes = {
  content: PropTypes.object,
  version: PropTypes.object,
  afterSave: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ContentForm

