import React, { useState, useEffect } from 'react'
import { useFirestoreConnect, useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  CardSubtitle,
  CardLink,
  CardText,
  ListGroup,
  ListGroupItem,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Badge,
  ModalHeader,
  Modal,
  ModalBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import ContentForm from 'views/forms/ContentForm';
import TimerTrainingCodesForm from 'views/forms/TimerTrainingCodesForm';
import ConfirmableForm from 'views/forms/ConfirmableForm';


function ContentVersion({ location }) {
  const { version: _version } = location?.state;
  const [version, setVersion] = useState(_version);

  const versionId = version?.version;

  const firestore = useFirestore();
  useFirestoreConnect(() => [
    {
      collection: `contentVersions/${versionId}/contents`,
      orderBy: [
        ['contentId']
      ]
    }
  ]);

  const contents = useSelector(state => state.firestore.ordered[`contentVersions/${versionId}/contents`]);
  const [selectedContent, setSelectedContent] = useState(null);
  const [filteredContents, setFilteredContents] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [settingOpen, setSettingOpen] = useState(false)
  const [timerTrainingCodeFormOpen, setTimerTrainingCodeFormOpen] = useState(false);

  const toggleSetting = () => setSettingOpen(prevState => !prevState);
  const toggleTimerTrainingCodeForm = () => setTimerTrainingCodeFormOpen(prevState => !prevState);

  useEffect(() => {
    console.log('contents: ', contents);
    setFilteredContents(contents);
    return () => {
      // cleanup
    }
  }, [contents]);


  return (
    <div className='content'>
      <Breadcrumb className='sticky-top'>
        <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
        <BreadcrumbItem><a href="./content-versions">콘텐츠 버전 목록</a></BreadcrumbItem>
        <BreadcrumbItem active>{version?.version}</BreadcrumbItem>
      </Breadcrumb>

      <div className='d-inline-flex justify-content-center align-items-baseline'>
        <h3 className='text-primary'>{version?.version}</h3>
        <Dropdown isOpen={settingOpen} toggle={toggleSetting} direction={'right'}>
          <DropdownToggle
            tag="div"
            data-toggle="dropdown"
            aria-expanded={settingOpen}
          >
            <i className="nc-icon nc-settings-gear-65 h5 ml-3"></i>
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem header>기타설정</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={toggleTimerTrainingCodeForm}>타이머 트레이닝 코드 설정</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>

      <Row>
        <Col>
          <Row className="align-items-center justify-content-between" style={{ width: '24rem' }}>
            <Col><h6>콘텐츠 목록 ({contents?.length || 0})</h6></Col>
            <Col xs={2}></Col>
            <Col>
              <Button color='primary' size='sm'
                onClick={() => setSelectedContent({})}
              >새로 추가하기</Button></Col>
          </Row>
          <InputGroup style={{ width: '20rem' }}>
            <Input type="text" placeholder="콘텐츠 이름으로 검색" onChange={(e) => {
              console.log(e.target.value);
              setFilteredContents(contents.filter(content => content.title.indexOf(e.target.value) !== -1));
            }} />
            <InputGroupAddon addonType="append">
              <InputGroupText><i className="nc-icon nc-zoom-split"></i></InputGroupText>
            </InputGroupAddon>
          </InputGroup>

          <Row>
            {
              filteredContents && filteredContents.map(content => <Col key={content.contentId} xs={12} sm={6} md={6} lg={6} xl={4}><Card style={{ width: '20rem', height: '8rem' }}>
                <CardBody onClick={() => setSelectedContent(content)}>
                  <CardTitle>
                    <div style={{ flex: 1, justifyContent: 'space-between', alignItems: 'space-between' }}>
                      <Row className="align-items-center justify-content-between">
                        <Col>
                          <Badge color='danger'>{content.contentId}</Badge> <span style={{ marginLeft: '10px' }}>{content.title}</span>
                        </Col>
                        <Col xs={3} className='justify-contents-flex-end'>
                          <Badge color='primary'>{content.contentType}</Badge>
                        </Col>
                      </Row>
                    </div>
                  </CardTitle>
                  <CardSubtitle>{content.contentDescription}</CardSubtitle>
                  <CardText></CardText>
                  {/* <NavLink
                  to={{ pathname: `./subgroup`, state: { group, subgroup: content } }}
                >반 홈으로</NavLink> */}
                </CardBody>
              </Card>
              </Col>)
            }
          </Row>
        </Col>
        <Modal
          isOpen={Boolean(selectedContent)}
          size={'lg'}
        >
          <ModalHeader toggle={() => setSelectedContent(null)}>콘텐츠 내용 변경</ModalHeader>
          <ModalBody>
            <ConfirmableForm>
              <ContentForm
                version={version}
                content={selectedContent}
                onCancel={() => setSelectedContent(null)}
                afterSave={() => setSelectedContent(null)}
                afterDelete={() => setSelectedContent(null)}
              />
            </ConfirmableForm>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={timerTrainingCodeFormOpen}
          size={'lg'}
        >
          <ModalHeader toggle={toggleTimerTrainingCodeForm}>타이머 트레이닝 코드 세팅</ModalHeader>
          <ModalBody>
            <TimerTrainingCodesForm
              version={version}
              toggle={toggleTimerTrainingCodeForm}
              afterSave={(version) => {
                setVersion(version);
              }}
            />
          </ModalBody>
        </Modal>
      </Row>
    </div>
  )
}

export default ContentVersion
