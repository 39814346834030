import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.jsx";
import AdminLayout from "layouts/Admin.jsx";
import StudentLayout from "layouts/Student.jsx";
import ParentsLayout from "layouts/Parents.jsx";
import TeacherLayout from "layouts/Teacher.jsx";
import GroupAdminLayout from "layouts/GroupAdmin.jsx";
import ChannelAdminLayout from "layouts/ChannelAdmin.jsx";
import { useFirebase } from "react-redux-firebase";

import LoadingPage from "views/LoadingPage";
import { getDefaultRouteByRole } from "layouts/DefaultRoutes";
import { setCustomClaimsInLocalStorage } from "helpers/localStorage";
import { useDispatch } from "react-redux";
import { storeInitUser } from "actions";
import { getCustomClaimsFromLocalStorage } from "helpers/localStorage";

const hist = createBrowserHistory();

const RouteProvider = () => {
  const firebase = useFirebase();
  const dispatch = useDispatch();

  const onChangeUser = async (authUser) => {
    if (!authUser) {
      hist.push("/auth/login");
    } else {
      const idTokenResult = await authUser.getIdTokenResult();
      console.log("idTokenResult = ", idTokenResult);
      const { channelId, groupId, memberId, subgroupIds } = idTokenResult.claims;
      const channel =
        channelId &&
        (await firebase.firestore().doc(`channels/${channelId}`).get()).data();
        const group =
        groupId &&
        (
          await firebase
            .firestore()
            .doc(`channels/${channelId}/groups/${groupId}`)
            .get()
        ).data();
        const member =
        memberId &&
        (
          await firebase
            .firestore()
            .doc(`channels/${channelId}/groups/${groupId}/members/${memberId}`)
            .get()
        ).data();
        const subgroup =
        groupId &&
        (
          await firebase
            .firestore()
            .doc(`channels/${channelId}/groups/${groupId}/subgroups/${subgroupIds}`)
            .get()
        ).data();

      setCustomClaimsInLocalStorage(
        idTokenResult.claims,
        { ...channel, id: channelId },
        { ...group, id: groupId },
        { ...subgroup, id: subgroupIds },
        { ...member, id: memberId }
      );
      // save to redux: global state
      dispatch(
        storeInitUser({
          channel: { ...channel, id: channelId },
          group: { ...group, id: groupId },
          subgrouop:{ ...subgroup, id: subgroupIds },
          member:{ ...member, id: memberId }
        })
      );
      if (hist.location.pathname === "/") {
        hist.push(getDefaultRouteByRole(idTokenResult.claims.role), {
          channel,
          group,
          subgroup,
          member
        });
      }
    }
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(onChangeUser);
    return () => {
      // cleanup
    };
  }, []);
  return (
    <Router history={hist}>
      <Switch>
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route
          path="/student"
          render={(props) => <StudentLayout {...props} />}
        />
        <Route
          path="/parents"
          render={(props) => <ParentsLayout {...props} />}
        />
        <Route
          path="/teacher"
          render={(props) => <TeacherLayout {...props} />}
        />
        <Route
          path="/group-admin"
          render={(props) => <GroupAdminLayout {...props} />}
        />
        <Route
          path="/channel-admin"
          render={(props) => <ChannelAdminLayout {...props} />}
        />
        <Route path="/loading" render={LoadingPage} />
        {/* <Redirect to="/loading" /> */}
      </Switch>
    </Router>
  );
};

export default RouteProvider;
