import React from 'react'

import {
  Container,
  Row,
  Col,
  Spinner,
} from 'reactstrap';


export default function LoadingPage() {

  return (
    <Container className='mt-5 msp'>
      <Row className='h-100 row align-items-center'>
        <Col className='text-center mx-auto justify-content-center'>
          <Spinner color="primary" className='mr-3' />
          <div className='text-muted h5 mt-3'>Loading...</div>
        </Col>
      </Row>
    </Container>
  )
}
