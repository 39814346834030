/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import i18n from 'i18next'
import { withTranslation } from 'react-i18next';

// reactstrap components
import {
  Collapse,   
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container
} from "reactstrap";

class AuthNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
  
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };
  render() {
    return (
      <Navbar
        className={classnames("navbar-absolute fixed-top", this.state.color)}
        expand="lg"
      >
        <Container>
          <div className="navbar-wrapper">

            <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
              {this.props.i18n.t('모두의 스포츠')}
            </NavbarBrand>
          </div>

          {/* <span>언어 변환 드랍박스</span> */}
          <Collapse
            isOpen={this.state.collapseOpen}
            className="justify-content-end"
            navbar
          >
            <Nav navbar>
              {/* <NavItem>
                <NavLink to="/" className="nav-link">
                  <i className="nc-icon nc-layout-11" />
                  대시보드
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/register" className="nav-link">
                  <i className="nc-icon nc-book-bookmark" />
                  회원가입
                </NavLink>
              </NavItem> */}
              {/* <NavItem>
                <NavLink to="/auth/login" className="nav-link">
                  <i className="nc-icon nc-tap-01" />
                  로그인
                </NavLink>
              </NavItem> */}
              {/* <NavItem>
                <NavLink to="/admin/user-profile" className="nav-link">
                  <i className="nc-icon nc-satisfied" />
                  User
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/lock-screen" className="nav-link">
                  <i className="nc-icon nc-key-25" />
                  Lock
                </NavLink>
              </NavItem> */}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default withTranslation()(AuthNavbar);
