import React from 'react'
import PropTypes from 'prop-types'
import _, { isBoolean } from 'lodash';

import Board from 'views/components/Board.jsx'
import { useFirestoreConnect, useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useTable } from 'react-table';
import { NavLink } from "react-router-dom";
import { genLicenseKey } from 'helpers/licenseKey';
import Select from "react-select";
import { activeStatusBooleanString } from 'variables/constants';

import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Jumbotron,
  Input,
  FormGroup,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Badge
} from "reactstrap";
import { useRef, useEffect, useState } from 'react';
import moment from 'moment';
import LicenseForm from 'views/forms/LicenseForm';
import { asyncForEach } from 'helpers/async';
import GroupPicker from 'views/components/GroupPicker';
import { useCallback } from 'react';

const MAX_NUMBER_OF_BULK_LICENSES = 100;

function AllLicenses({ location }) {

  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [modal, setModal] = useState(false);
  const [bulkLicenses, setBulkLicenses] = useState(null);
  const [whereOptions, setWhereOptions] = useState(null);
  const toggle = () => setModal(!modal);
  const [groupsDB, setGroupsDB] = useState(null);
  const [isModified, setIsModified] = useState(false);
  const [alert, setAlert] = useState(null);

  const firestore = useFirestore();

  useFirestoreConnect([
    {
      collection: 'channels',
    },
    {
      collectionGroup: 'groups',
      storeAs: 'groups'
    },
  ]);

  const channels = useSelector(state => state.firestore.ordered.channels);
  console.log("channels", channels)

  const watchLicenses = () => {
    const ref = firestore.collection('v2Licenses').orderBy('createdAt', 'desc');
    return ref.onSnapshot((async querySnapshot => {
      var licenses = [];

      await asyncForEach(querySnapshot.docs, async (doc) => {
        try {
          // console.log('path = ', doc.ref.path);
          // console.log('parent collection = ', doc.ref.parent.parent.path);
          // console.log('parent parent collection = ', doc.ref.parent.parent.parent.parent.path);

          const license = doc.data();
          const groupRef = firestore.doc(`channels/${license.channelId}/groups/${license.groupId}`);
          const channelRef = firestore.doc(`channels/${license.channelId}`);

          licenses.push({ id: doc.id, ...doc.data(), groupRef, channelRef });
          // console.log('licenses = ', licenses);
        } catch (e) {
          console.error('failed to get licenses: ', e);
        }
      });
      setItems(licenses);
    }));
  }

  useEffect(() => {
    const unsubscribe = watchLicenses();
    return () => {
      unsubscribe();
    }
  }, [])

  const getGroups = async (channelId) => {
    console.log(`channels/${channelId}/groups`);
    try {
      const ref = firestore.collection(`channels/${channelId}/groups`);
      const colSnap = await ref.get();
      return colSnap.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    } catch (e) {
      return Promise.reject('failed to get groups: ', e);
    }
  }

  const groups = useSelector(state => state.firestore.ordered['groups']);
  console.log(`groups`, groups);

  if (groupsDB === null) {
    setGroupsDB(groups)
  }
  console.log(`groupsDB`, groupsDB);

  const selectChannel = async (channelId) => {
    if (channelId === null) {
      setGroupsDB(groups);
      console.log(`groupsDB`, groupsDB);
      return
    }
    setGroupsDB(await getGroups(channelId));
    console.log(`groupsDB`, groupsDB);
  }

  const boardEl = useRef(null);

  const data = items
    ? items.map((item) => ({
      ...item,
      actions: (
        <div className="actions-left">
          <Button
            onClick={async () => {
              console.log('clicked');
              const { groupRef, channelRef } = item;

              console.log('groupRef = ', groupRef);

              try {
                const groupSnapshot = await groupRef.get();
                const group = { id: groupRef.id, ...groupSnapshot.data() };

                const channelSnapshot = await channelRef.get();
                const channel = { id: channelRef.id, ...channelSnapshot.data() };

                const clonedItem = _.cloneDeep(item);
                delete clonedItem.groupRef;
                delete clonedItem.channelRef;

                setSelectedItem({ ...clonedItem, group, channel });
                boardEl.current.edit(clonedItem);
              } catch (e) {
                console.error('failed to setSelectedItem: ', e);
              }
            }}
            color="warning"
            size="sm"
            className="btn-icon btn-link edit"
          >
            <i className="fa fa-edit" style={{ fontSize: '1.5em' }} />
          </Button>{" "}
          {/* use this button to remove the data row */}
          <Button
            onClick={() => {
              boardEl.current.delete(() => {
                firestore.doc(`v2Licenses/${item?.id}`).delete();
              });
            }}
            color="danger"
            size="sm"
            className="btn-icon btn-link remove"
          >
            <i className="fa fa-times" style={{ fontSize: '1.5em' }} />
          </Button>{" "}
        </div>
      )
    }))
    : [];
  // console.log('data', data);

  const onSelected = (selected) => {
    const updatedData = {
      channel: _.pick(selected?.channel, ['id', 'title']),
    };
    console.log('updatedData = ', updatedData);
    console.log('bulkLicenses = ', bulkLicenses);
    setBulkLicenses({
      ...bulkLicenses,
      ...updatedData
    });
  };

  const onChangeInputValue = (t) => {
    const v = t.target.value;
    console.log('v = ', v);
    const newBulkLicenses = { ...bulkLicenses, numberOfLicenses: Number(v) };
    console.log('newBulkLicenses = ', newBulkLicenses);
    setBulkLicenses(newBulkLicenses);
    console.log('bulkLicenses = ', bulkLicenses);
  }

  console.log(`bulkLicenses`, bulkLicenses)

  const showLicenseHelper = () => setModal(true);

  const generateBulkLicenses = async () => {
    const batch = firestore.batch();
    const { numberOfLicenses, channel } = bulkLicenses;
    if (!numberOfLicenses) {
      alert(`라이센스 생성 개수를 입력해주세요 최소 1개, 최대 ${MAX_NUMBER_OF_BULK_LICENSES}개`);
      return;
    };
    if (numberOfLicenses > MAX_NUMBER_OF_BULK_LICENSES + 1) {
      alert(`한번에 생성할 수 있는 라이센스는 최대 ${MAX_NUMBER_OF_BULK_LICENSES}개입니다. ${MAX_NUMBER_OF_BULK_LICENSES}개 이하로 설정해주세요.`)
      return;
    }
    if (!channel) {
      alert('라이센스를 부여할 총판을 선택해주세요.');
      return;
    }
    _.range(numberOfLicenses).forEach(i => {
      const ref = firestore.collection('v2Licenses').doc();
      batch.set(ref, {
        licenseKey: genLicenseKey(),
        channelId: channel.id,
        channel,
        createdAt: firestore.FieldValue.serverTimestamp(),
        updatedAt: firestore.FieldValue.serverTimestamp(),
      })
    });
    await batch.commit();
    setAlert('생성되었습니다.');
    toggle();
  }

  const controlStyles = {
    borderRadius: '1px solid black',
    padding: '5px',
    color: 'white',
  };

  useEffect(() => {
    const modified = !_.isEmpty(bulkLicenses);
    console.log(`@@@modified`, modified);
    setIsModified(modified)
  }, [bulkLicenses])

  const closeConfirm = (callback) => {
    confirm(<div>작성 중인 내용이 사라집니다.<br />계속 진행하시겠습니까?</div>, () => setModal(false), false, true);
  }

  const confirm = (message = "확실합니까?", callbackFn, isSuccess, isWarning) => {
    basicAlert(message,
      () => {
        callbackFn();
        hideAlert();
      },
      () => hideAlert(),
      isSuccess,
      isWarning,
    )
  }
  // alerts
  const basicAlert = (msg, onConfirm, onCancel, isSuccess, isWarning) => {
    setAlert(<ReactBSAlert
      warning={isWarning}
      success={isSuccess}
      style={{ display: "block", marginTop: "-100px" }}
      title={msg || "Here's a message!"}
      onConfirm={() => onConfirm ? onConfirm() : hideAlert()}
      onCancel={() => onCancel ? onCancel() : hideAlert()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      confirmBtnText="확인"
      cancelBtnText="취소"
      showCancel={!isSuccess}
    />)
  }

  const onCancel = () => {
    if (isModified) {
      console.log(`작동된다!!!!!`);
      closeConfirm();
    } else {
      toggle();
    }
    setItems(null);
  }

  const hideAlert = () => {
    setAlert(null);
  }

  return (
    <div className="content">
      {alert}
      <Breadcrumb className='sticky-top'>
        <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
        <BreadcrumbItem active>라이센스 목록</BreadcrumbItem>
      </Breadcrumb>
      <Jumbotron>
        <h1 className="display-5"><strong>라이센스 목록</strong></h1>
        <p className="lead">전체 라이센스 정보에 접근할 수 있는 페이지입니다.</p>
        <hr className="my-2" />
        <p><strong>⚠️NOTE</strong> 라이센스의 상태(활성화/비활성화) 변경은 가맹점 및 클래스의 상태를 변경하게 됩니다.</p>
        <p className="lead">
          <Button color="primary"
            onClick={showLicenseHelper}
          >가맹점 비지정 라이센스 발급</Button>
        </p>
      </Jumbotron>
      <Board
        modalTitle="라이센스 입력폼"
        data={data}
        ref={boardEl}
        // hideAddNewButton
        columns={[
          {
            Header: "라이센스 키",
            width: 200,
            accessor: 'licenseKey',
            filterMethod: (filter, row) => {
              console.log('filter = ', filter);
              const title = row[filter.id];
              console.log('row title = ', title);
              return title && title.indexOf(filter.value.toUpperCase()) != -1 || title.indexOf(filter.value.toLowerCase()) != -1;
            }
          },
          {
            id: 'active',
            Header: "상태",
            width: 100,
            accessor: d => d.active === true ? 
              <Badge color={'primary'}>{activeStatusBooleanString[0]}</Badge> 
              : <Badge color={'danger'}>{activeStatusBooleanString[1]}</Badge>,
            filterMethod: (filter, row) => {
              if (filter.value === "all") {
                return true;
              } 
              if (activeStatusBooleanString[filter?.value] === row[filter.id].props.children) {
                return true;
              }
              return false;
            },
            Filter: ({ filter, onChange }) =>
              <select
                onChange={event => {
                  console.log('event.target.value = ', event.target.value);
                  return onChange(event.target.value)
                }}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
              >
                <option value="all">전체</option>
                {
                  activeStatusBooleanString.map((status, idx) => <option key={idx} value={idx}>{status}</option>)
                }
              </select>
          },
          {
            id: 'channel',
            Header: "총판",
            width: 120,
            accessor: "channel.title",
            filterMethod: (filter, row) => {
              if (filter?.value === "all") {
                return true;
              }
              if (filter?.value === row[filter.id]) {
                return true;
              }
              return false;
            },
            Filter: ({ filter, onChange }) =>
              <select
                onChange={event => {
                  onChange(event.target.value)
                  // var index = event.target.options.selectedIndex;
                  // console.log('여기서 ID', event.target.options[index].getAttribute('id'));
                  if (event.target.value == 'all') {
                    selectChannel(null);
                  } else {
                    console.log(`ID 뽑으라!!!`, event.target.options[event.target.options.selectedIndex].getAttribute('id'));
                    selectChannel(event.target.options[event.target.options.selectedIndex].getAttribute('id'));
                  }
                }
                }
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
              >
                <option value="all">총판 전체</option>
                {
                  channels?.map(channel => <option key={channel.id} id={channel.id} value={channel.title}>{channel.title}</option>)
                }
              </select>
          },
          {
            id: 'group',
            Header: "가맹점",
            width: 120,
            accessor: "group.title",
            filterMethod: (filter, row) => {
              if (filter.value === "all") {
                return true;
              }
              if (filter?.value === row[filter.id]) {
                return true;
              }
              return false;
            },
            Filter: ({ filter, onChange }) =>
              <select
                // disabled={selectedChannel? false: true}
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
              >
                <option value="all" id="all">가맹점 전체</option>
                {
                  groupsDB?.map(group => <option key={group.id} value={group.title}>{group.title}</option>)
                }
              </select>
          },
          {
            accessor: 'description',
            Header: '설명',
            width: 150,
            filterMethod: (filter, row) => {
              console.log('filter = ', filter);
              const title = row[filter.id];
              console.log('row title = ', title);
              return title && title.indexOf(filter.value) != -1;
            }
          },
          {
            Header: "설치버전",
            width: 90,
            accessor: 'distributedClientVersion',
          },
          {
            Header: "최신버전",
            width: 90,
            accessor: 'clientVersion',
          },
          {
            id: 'endAt',
            Header: "만료 일시",
            accessor: d => d.endAt ? moment(d.endAt.toDate()).format('YYYY-MM-DD HH:mm') : '',
            filterMethod: (filter, row) => {
              console.log('filter = ', filter);
              const title = row[filter.id];
              console.log('row title = ', title);
              return title && title.indexOf(filter.value) != -1;
            }
          },
          {
            Header: "명령",
            accessor: "actions",
            sortable: false,
            filterable: false
          }
        ]}
      >
        {
          <LicenseForm
            channel={selectedItem?.channel}
            group={selectedItem?.group}
          />
        }
      </Board>
      <Modal isOpen={modal}>
        <Container>
          <ModalHeader>라이센스 대량 발급</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <div className="text-right"><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span> 필수 입력 사항입니다</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <GroupPicker
                  mode={1}
                  viewMode={['channel']}
                  cbSelected={(selected) => onSelected(selected)}
                />
              </Col>
            </Row>
            <Row>
              <Col><label>생성 라이센스 개수</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Input type='number' min={1} max={100} onChange={onChangeInputValue} />
                </FormGroup>
              </Col>
            </Row>
            <Col className='text-right'>
              <Button color="primary" disabled={!Boolean(bulkLicenses?.channel) || !Boolean(bulkLicenses?.numberOfLicenses)} onClick={generateBulkLicenses}>발급</Button>{' '}
              <Button color="warning" onClick={toggle}>취소</Button>
            </Col>
          </ModalBody>
        </Container>
      </Modal>
    </div >
  )
}

AllLicenses.propTypes = {
}

export default AllLicenses

