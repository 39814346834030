import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import Board from "views/components/Board.jsx";
import { useFirestoreConnect, useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useTable } from "react-table";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { useRef, useEffect } from "react";
import moment from "moment-with-locales-es6";

import SparkChart from "components/Charts/SparkChart";
import PhysicalTestForm from "views/forms/PhysicalTestForm";
import RadarChart from "components/Charts/RadarChart";

moment.locale("ko");

function PhysicalTests(props) {
  const { channel, group, subgroup, member } = props.state;

  // console.log('group = ', group);
  // console.log('subgroup = ', subgroup);
  // console.log('member = ', member);

  const firestore = useFirestore();
  const { t, i18n } = useTranslation();
  useFirestoreConnect([
    {
      collection: `channels/${channel.id}/groups/${group.id}/members/${member.id}/physicalTests`,
      orderBy: [["createdAt", "desc"]],
      storeAs: "physicalTests",
    },
  ]);

  const items = useSelector(
    (state) => state.firestore.ordered["physicalTests"]
  );
  const boardEl = useRef(null);

  const data =
    items &&
    items.map((item) => ({
      ...item,
      actions: (
        // we've added some custom button actions
        <div className="actions-left">
          {/* use this button to add a like kind of action */}
          {/* <Button
          onClick={() => {
            console.log('clicked');
            console.log(item);
            console.log(boardEl);
          }}
          color="info"
          size="sm"
          className="btn-icon btn-link like"
        >
          <i className="fa fa-heart" />
        </Button>{" "} */}
          {/* use this button to add a edit kind of action */}
          <Button
            onClick={() => {
              console.log("clicked");
              boardEl.current.edit(item);
            }}
            color="warning"
            size="sm"
            className="btn-icon btn-link edit"
          >
            <i className="fa fa-edit" style={{ fontSize: "1.5em" }} />
          </Button>{" "}
          {/* use this button to remove the data row */}
          <Button
            onClick={() => {
              console.log("clicked");
              boardEl.current.delete(() => {
                firestore
                  .doc(
                    `channels/${channel.id}/groups/${group.id}/members/${member.id}/physicalTests/${item.id}`
                  )
                  .delete();
              });
            }}
            color="danger"
            size="sm"
            className="btn-icon btn-link remove"
          >
            <i className="fa fa-times" style={{ fontSize: "1.5em" }} />
          </Button>{" "}
        </div>
      ),
    }));
  // console.log('data', data);

  return (
    <div style={{ marginTop: "10px" }} className="content">
      {items ? (
        <Row>
          <Col>
            <Row>
              <Col md={4}>
                <SparkChart
                  dataset={items}
                  title={t("근력")}
                  fieldPath={"ms"}
                />
              </Col>

              <Col md={4}>
                <SparkChart
                  dataset={items}
                  title={t("근지구력")}
                  fieldPath={"me"}
                />
              </Col>

              <Col md={4}>
                <SparkChart
                  dataset={items}
                  title={t("심폐지구력")}
                  fieldPath={"ce"}
                />
              </Col>

              <Col md={4}>
                <SparkChart
                  dataset={items}
                  title={t("민첩성")}
                  fieldPath={"ag"}
                />
              </Col>

              <Col md={4}>
                <SparkChart
                  dataset={items}
                  title={t("순발력")}
                  fieldPath={"po"}
                />
              </Col>

              <Col md={4}>
                <SparkChart
                  dataset={items}
                  title={t("협응성")}
                  fieldPath={"co"}
                />
              </Col>
              <Col md={4}>
                <SparkChart
                  dataset={items}
                  title={t("평형성")}
                  fieldPath={"ba"}
                />
              </Col>
            </Row>
          </Col>
          {/* <Col xs={7}>
              <RadarChart dataset={items} title={t('체력프로파일')} />
            </Col> */}
        </Row>
      ) : null}
      <Board
        // title={`${member?.name}님의 측정 목록`}
        modalTitle={t("새 측정 추가")}
        data={data}
        ref={boardEl}
        columns={[
          {
            id: "createdAt",
            Header: t("측정일시"),
            accessor: (d) =>
              d.createdAt
                ? moment(d.createdAt.toDate()).format("YYYY-MM-DD HH:mm")
                : "",
          },
          {
            id: "ms",
            Header: t("근력"),
            accessor: (d) =>
              d?.ms ? parseFloat(d.ms.toFixed(2)) : t("결과치 없음"),
          },
          {
            id: "me",
            Header: t("근지구력"),
            accessor: (d) => (d?.me ? parseFloat(d.me.toFixed(2)) : 0),
          },
          {
            id: "ce",
            Header: t("심폐지구력"),
            accessor: (d) =>
              d?.ce ? parseFloat(d.ce.toFixed(2)) : t("결과치 없음"),
          },
          {
            id: "ag",
            Header: t("민첩성"),
            accessor: (d) =>
              d?.ag ? parseFloat(d.ag.toFixed(2)) : t("결과치 없음"),
          },
          {
            id: "po",
            Header: t("순발력"),
            accessor: (d) =>
              d?.po ? parseFloat(d.po.toFixed(2)) : t("결과치 없음"),
          },
          {
            id: "co",
            Header: t("협응성"),
            accessor: (d) =>
              d?.co ? parseFloat(d.co.toFixed(2)) : t("결과치 없음"),
          },
          {
            id: "ba",
            Header: t("평형성"),
            accessor: (d) =>
              d?.ba ? parseFloat(d.ba.toFixed(2)) : t("결과치 없음"),
          },
          {
            id: "updatedAt",
            Header: t("수정일시"),
            accessor: (d) =>
              d.updatedAt
                ? moment(d.updatedAt.toDate()).format("YYYY-MM-DD HH:mm")
                : "",
          },
          {
            Header: t("명령"),
            accessor: "actions",
            sortable: false,
            filterable: false,
          },
        ]}
      >
        <PhysicalTestForm
          channel={channel}
          group={group}
          subgroup={subgroup}
          member={member}
        />
      </Board>
    </div>
  );
}

PhysicalTests.propTypes = {};

export default PhysicalTests;
