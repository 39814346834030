import React, { useState } from "react";
import PropTypes from "prop-types";

import ReactTable from "react-table";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import ReactBSAlert from "react-bootstrap-sweetalert";
import { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";

const ConfirmableForm = ({ children }, ref) => {
  const [modal, setModal] = useState(false);
  const [alert, setAlert] = useState(null);
  const { t } = useTranslation();

  const afterSave = (callbackFn) => {
    confirm(
      t("저장되었습니다!"),
      () => {
        callbackFn();
        hideAlert();
      },
      true
    );
  };

  const toggle = () => setModal(!modal);
  //가맹점 수정 폼에서 취소는 여기와 연결
  const closeConfirm = (callbackFn) => {
    console.log("check cancel after modified");
    confirm(
      <div>
        {t("작성 중인 내용이 사라집니다.")}
        <br />
        {t("계속 진행하시겠습니까?")}
      </div>,
      callbackFn,
      null,
      true
    );
  };

  const confirm = (
    message = t("확실합니까?"),
    callbackFn,
    isSuccess,
    isWarning
  ) => {
    basicAlert(
      message,
      () => {
        callbackFn();
        hideAlert();
      },
      () => hideAlert(),
      isSuccess,
      isWarning
    );
  };

  // alerts
  const basicAlert = (msg, onConfirm, onCancel, isSuccess, isWarning) => {
    setAlert(
      <ReactBSAlert
        warning={isWarning}
        success={isSuccess}
        style={{ display: "block", marginTop: "-100px" }}
        title={msg || "Here's a message!"}
        onConfirm={() => (onConfirm ? onConfirm() : hideAlert())}
        onCancel={() => (onCancel ? onCancel() : hideAlert())}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="확인"
        cancelBtnText="취소"
        showCancel={!isSuccess}
      />
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };

  return (
    <>
      {alert}
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { closeConfirm, toggle, afterSave })
      )}
    </>
  );
};

ConfirmableForm.propTypes = {};

export default ConfirmableForm;
