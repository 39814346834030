import React, { useState, useEffect } from 'react'

import { useFirestoreConnect } from 'react-redux-firebase';
import _ from 'lodash';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Progress,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import classnames from 'classnames';

import { newGroupSchema } from '../../variables/schema';
import readXlsxFile from 'read-excel-file'
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { asyncForEach } from 'helpers/async';
import GroupCategoryPicker from 'views/components/GroupCategoryPicker';
import GroupPicker from 'views/components/GroupPicker';
import { activeStatus } from 'variables/constants';

const CURRENT_FILE_VERSION = 2;

function AddGroupForm(props) {
  const [channel, setChannel] = useState(props.channel || null);
  const [file, setFile] = useState(null);
  const [groupInfo, setGroupInfo] = useState(null);
  const [subgroups, setSubgroups] = useState(null);
  const [members, setMembers] = useState(null);
  const [admins, setAdmins] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isUploaded, setInUploaded] = useState(false);
  const [onSaving, setOnSaving] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [isModified, setIsModified] = useState(false);
  
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }


  // check if use '클래스정보' tab in the excel sheet 
  let useSubgroupInfo = false;

  const firebase = useFirebase();
  const firestore = useFirestore();

  useEffect(()=>{
    console.log(`@@@작동`);
    const modified = !_.isEqual(props.currentItem, groupInfo);
    setIsModified(modified);
  },[groupInfo])

  useEffect(() => {
    const grouped = _.groupBy(members, 'subgroupTitle');

    const keys = Object.keys(grouped);
    const items = keys.map(key => ({ title: key, members: grouped[key] }));

    console.log('subgroups = ', items);
    setSubgroups(items);

    return () => {
      // cleanup
    }
  }, [members]);

  const handleFileChange = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (/['xls'|'xlsx']$/.test(file.name)) {
      console.log('합당한 파일임');
    } else {
      console.log('합당한 파일이 아님');
      setErrorMessage('엑셀 파일이 아닙니다. 파일을 점검해주세요.');
      return;
    }
    console.log('file = ', file);

    setFile(file);
    // check version
    try {
      const { rows, error } = await readXlsxFile(file, {
        schema: newGroupSchema[0],
        sheet: '버전정보'
      });
      console.log('rows ', rows);
      console.log('error = ', error);
      if (error) {
        setErrorMessage('버전 정보를 가져오는데 문제가 생겼습니다. 엑셀 파일을 점검해주세요.', error);
        return;
      }
      if (rows.length > 0 && rows[0]?.version !== CURRENT_FILE_VERSION) {
        setErrorMessage(`파일 버전이 일치하지 않습니다. 필요한 버전: ${CURRENT_FILE_VERSION}, 제공된 버전: ${rows[0]?.version}`);
        return;
      }
      setInUploaded(true);
      // `rows` is an array of rows
      // each row being an array of cells.
    } catch (error) {
      console.error(error);
      setErrorMessage('파일에 문제가 있습니다');
      return;
    }

    // group info
    try {
      const { rows, error } = await readXlsxFile(file, {
        schema: newGroupSchema[1],
        sheet: '가맹점정보'
      })
      console.log('rows ', rows);
      console.log('error = ', error);
      if (error) {
        setErrorMessage('가맹점 정보를 가져오는데 문제가 생겼습니다. 엑셀 파일을 점검해주세요.');
        return;
      }
      if (rows.length === 0) {
        setErrorMessage('가맹점 정보가 제대로 입력되지 않았습니다.');
        return;
      }

      const { title, channel } = rows[0];
      if (title && channel) {
        setGroupInfo({ title, channel });
      } else {
        setErrorMessage('가맹점 필수 입력 정보가 누락되었습니다.');
        return;
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('가맹점정보에 문제가 있습니다.');
      return;
    }

    // subgroup info
    try {
      const { rows, error } = await readXlsxFile(file, {
        schema: newGroupSchema[2],
        sheet: '클래스정보'
      })
      console.log('rows ', rows);
      console.log('error = ', error);
      if (error) {
        setErrorMessage('클래스 정보를 가져오는데 문제가 생겼습니다. 엑셀 파일을 점검해주세요.');
        return;
      }

      let studentNum = 1;
      const items = rows.map(row => {
        const { title, grade, classNum, numOfStudents, age } = row;
        if (title && grade && classNum && numOfStudents) {
          return {
            title,
            title1: /\d+/.test(grade) ? `${grade}학년` : grade,
            title2: /\d+/.test(classNum) ? `${classNum}반` : classNum,
            numOfStudents,
            members: _.range(numOfStudents).map(idx => ({
              studentNum: studentNum++,
              subgroupTitle: title,
              gradeNum: grade,
              classNum: classNum,
              classMemberNum: idx + 1,
              name: `회원${idx + 1}`,
              age,
            }))
          };
        } else {
          throw Error('클래스 필수 입력 정보가 누락되었습니다.');
        }
      })
      console.log('subgroups items = ', items);
      if (items.length > 0) {
        useSubgroupInfo = true;
        setSubgroups(items);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('클래스 정보에 문제가 있습니다.');
      return;
    }

    // if we use '클래스정보' in excel file, let us ignore processing '멤버정보'
    if (useSubgroupInfo === false) {
      // members
      try {
        const { rows, error } = await readXlsxFile(file, {
          schema: newGroupSchema[3],
          sheet: '멤버정보'
        })
        console.log('rows ', rows);
        console.log('error = ', error);
        if (error) {
          setErrorMessage('멤버 정보를 가져오는데 문제가 생겼습니다. 엑셀 파일을 점검해주세요.');
          return;
        }
        if (rows.length === 0) {
          setErrorMessage('멤버 정보가 하나도 입력되지 않았습니다.');
          return;
        }

        setMembers(rows);
      } catch (error) {
        console.error(error);
        setErrorMessage('멤버정보에 문제가 있습니다.');
        return;
      }
    }
    // admins
    try {
      const { rows, error } = await readXlsxFile(file, {
        schema: newGroupSchema[4],
        sheet: '관리자'
      })
      console.log('rows ', rows);
      console.log('error = ', error);
      if (error) {
        setErrorMessage('관리자 정보를 가져오는데 문제가 생겼습니다. 엑셀 파일을 점검해주세요.', error);
        return;
      }
      if (rows.length === 0) {
        setErrorMessage('관리자가 하나도 입력되지 않았습니다.');
        return;
      }

      setAdmins(rows);
    } catch (error) {
      console.error(error);
      setErrorMessage('관리자 정보에 문제가 있습니다.');
      return;
    }
  };

  const onSave = async () => {
    console.log('press save');

    setOnSaving(true);

    const timestamp = {
      updatedAt: firestore.FieldValue.serverTimestamp()
    }

    // let active = props.group?.active;
    let status = props.group?.status? props.group?.status: activeStatus.normal;

    if (!props.currentItem?.id) {
      timestamp['createdAt'] = firestore.FieldValue.serverTimestamp();
    }

    try {
      const groupRef = firestore.collection(`channels/${channel.id}/groups`).doc();
      await groupRef.set({
        // active,
        status,
        ...groupInfo,
        ...timestamp,
        nMembers: 0,
        nLicenses: 0,
        nSubgroups: 0,
      });

      asyncForEach(subgroups, async (subgroup, idx) => {
        const subgroupRef = groupRef.collection('subgroups').doc();
        const batch = firestore.batch();
        const memberIds = subgroup.members.map(member => {
          const memberRef = groupRef.collection('members').doc();
          batch.set(memberRef, {
            ...member,
            subgroupIds: [subgroupRef.id],
            subgroups: [{
              classMemberNum: member.classMemberNum,
              subgroupId: subgroupRef.id,
              subgroupTitle: member.subgroupTitle,
            }],
            ...timestamp
          });
          return memberRef.id;
        });
        batch.set(subgroupRef, {
          ...subgroup,
          members: memberIds,
          ...timestamp,
        })
        await batch.commit();
      })
    } catch (error) {
      console.error('Failed to save : ', error);
    }
    setOnSaving(false);
    props.toggle();
  }

  const onCancel = () => {
    console.log('onCancel!');
    if (isModified) {
      props.closeConfirm(() => props.toggle());
    } else {
      props.toggle();
    }
    
  }

  const onChange = (e, name, typeFn) => {
    const value = e.target.value;
    setGroupInfo({ ...groupInfo, [name]: typeFn ? typeFn(value) : value });
  }

  const onGroupCategorySelected = (groupCategory) => {
    if (groupCategory) {
      setGroupInfo({
        ...groupInfo,
        groupCategory
      })
    }
  }


  return (
    <div className='content'>
      <Row>
        <Col>
          <div className="text-right"><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span> 필수 입력 사항입니다</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <GroupPicker
            channel={channel}
            cbSelected={({ channel }) => setChannel(channel)}
            viewMode={['channel']}
          />
        </Col>
      </Row>
      {/* 가맹점 명칭 */}
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>가맹점(학교, 학원) 명칭</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Input
              value={groupInfo?.title}
              placeholder="가맹점(학교명,학원명)의 이름을 입력해주세요"
              onChange={e => onChange(e, 'title')} />
          </FormGroup>
        </Col>
      </Row>
      <GroupCategoryPicker
        channel={channel}
        cbSelected={onGroupCategorySelected}
      />
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>가맹점 담당자 이름</label></Col>
          </Row>
          <FormGroup>
            <Input
              value={groupInfo?.director || ''}
              placeholder="가맹점 담당자 이름을 입력해주세요"
              onChange={e => onChange(e, 'director')} />
          </FormGroup>
        </Col>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>가맹점 담당 연락처</label></Col>
          </Row>
          <FormGroup>
            <Input
              value={groupInfo?.directorPhone}
              placeholder="000-0000-0000"
              onChange={e => onChange(e, 'directorPhone')} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className='text-right'>
          <Button color="primary" disabled={onSaving || !isUploaded && (!Boolean(groupInfo?.title) || !Boolean(groupInfo?.groupCategory?.id))} onClick={onSave}>저장</Button>
          <Button color="warning" onClick={onCancel}>취소</Button>
        </Col>
      </Row>


    </div>
  )
}

export default AddGroupForm
