import React, { useRef, useState } from "react";
import { useFirestore, useFirebase } from "react-redux-firebase";
import { NavLink } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  ModalFooter,
} from "reactstrap";
import MeasurementsReport from "./MeasurementsReport";
import PhysicalTestsReport from "./PhysicalTestsReport";
import ReactToPrint from "react-to-print";
import PrintablePhysicalTestsReport from "./PrintablePhysicalTestsReport";
import { useTranslation } from "react-i18next";

function ReportMember(props) {
  const {
    channel,
    group,
    subgroup,
    member,
    measurements,
    physicalTests,
    byMember,
    ...rest
  } = props.location?.state;

  const { t } = useTranslation();

  // console.log('group = ', group);
  // console.log('subgroup = ', subgroup);
  // console.log('member = ', member);
  // console.log('measurements = ', measurements);

  const componentRef = useRef();

  const firestore = useFirestore();
  const firebase = useFirebase();
  const functions = firebase.app().functions("asia-northeast1");

  //to use Modal
  const [isShowing, setIsShowing] = useState(false);
  const [forwardedEmail, setForwardedEmail] = useState("");

  const saveReportAndShare = async () => {
    try {
      console.log("in savereportandshare");
      const batch = firestore.batch();
      const ref = firestore.collection("reports").doc();
      const reportData = {
        channel: { channel },
        createdAt: firestore.FieldValue.serverTimestamp(),
        group: { group },
        // measurements: {measurements},
        member: { member },
        // physicalTests: {physicalTests},
        subgroup: { subgroup },
      };
      const shareData = {
        receiver: forwardedEmail,
        reportName: member.name,
      };
      console.log("forwardedEmail = ", forwardedEmail);
      console.log("reportData = ", reportData);
      console.log("shareData = ", shareData);
      batch.set(ref, reportData);

      const shareRef = ref.collection("shares").doc();
      batch.set(shareRef, shareData);

      await batch.commit();
    } catch (e) {
      console.error("<NOTE> failed to createSendUserEmail: ", e);
    }
  };

  const saveReport = async () => {
    saveReportAndShare();
    printDocument();
    setIsShowing(!isShowing);
  };
  const printDocument = async () => {
    const input = document.getElementById("divToPrint");
    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF();
    pdf.addImage(imgData, "JPEG", 0, 0);
    // pdf.output('dataurlnewwindow');
    // pdf.save(a)
    try {
      const uploadingInfo = await firebase.uploadFile(
        "userContents",
        pdf.output("blob"),
        undefined,
        { name: (file) => `${+new Date()}${file.name}` }
      );
      const url = await uploadingInfo.uploadTaskSnapshot.ref.getDownloadURL();
      // todo: save to
    } catch (e) {
      console.error("Failed to upload file: ", e);
      // setErrorMessage('Failed to upload file');
      return;
    }
  };

  const toggle = () => {
    setIsShowing(!isShowing);
  };

  return (
    <div className="content">
      {byMember ? (
        <Breadcrumb className="sticky-top">
          <BreadcrumbItem>
            <a href="/">Home</a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <NavLink
              to={{ pathname: `./franchises`, state: { channel, group } }}
            >
              {t("가맹점 목록")}
            </NavLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <NavLink
              to={{ pathname: `./franchise`, state: { channel, group } }}
            >
              {group?.title}
            </NavLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <NavLink
              to={{
                pathname: `./subgroup`,
                state: { channel, group, subgroup },
              }}
            >
              {subgroup?.title}
            </NavLink>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            {member?.name}
            {t("님 결과리포트")}
          </BreadcrumbItem>
        </Breadcrumb>
      ) : (
        <Breadcrumb className="sticky-top">
          <BreadcrumbItem>
            <a href="/">Home</a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <a href="./report-home">{t("리포트 홈")}</a>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            {member?.name}
            {t("님 결과리포트")}
          </BreadcrumbItem>
        </Breadcrumb>
      )}
      <div class="d-flex justify-content-center">
        <Row>
          {/* <div onClick={toggle} style={{ cursor: "pointer" }}> <i style={{ fontSize: 30 }} class="fa fa-envelope" />공유하기</div> */}
          <Col>
            <Modal isOpen={isShowing}>
              <ModalHeader toggle={toggle}>체력 측정 레포트 전송</ModalHeader>
              <ModalBody>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="nc-icon nc-single-02" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="이메일 주소"
                    type="text"
                    onChange={(e) => setForwardedEmail(e.target.value)}
                  />
                </InputGroup>
              </ModalBody>
              <ModalFooter>
                <div class="col text-center">
                  <Button onClick={() => saveReport()}>Submit</Button>
                </div>
              </ModalFooter>
            </Modal>
            
            <ReactToPrint
              trigger={() => (
                <div style={{ cursor: "pointer" }}>
                  <i style={{ fontSize: 30 }} className="fa fa-print" />{" "}
                  {t("출력하기")}
                </div>
              )}
              content={() => componentRef.current}
              pageStyle={`@page { 
          size: 21cm 29.7cm;
          margin: 0mm 0mm 0mm 0mm;
        } @media print { 
          .chartjs-size-monitor { display: none !important; }
          body { 
            -webkit-print-color-adjust: exact; padding: 10px !important; 
          }
        } `}
            />
          </Col>
        </Row>
      </div>
      {/* <MeasurementsReport channel={channel} group={group} subgroup={subgroup} member={member} /> */}
      <PrintablePhysicalTestsReport
        ref={componentRef}
        channel={channel}
        group={group}
        subgroup={subgroup}
        member={member}
        measurements={measurements}
        physicalTests={physicalTests}
        {...rest}
        
      />
    </div>
  );
}

export default ReportMember;
