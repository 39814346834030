import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useFirestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import _ from "lodash";
import ReactBSAlert from "react-bootstrap-sweetalert";

import ConfirmableForm from "../forms/ConfirmableForm";
import Switch from "react-bootstrap-switch";
import { useTranslation } from "react-i18next";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Progress,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

import readXlsxFile from "read-excel-file";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { asyncForEach } from "helpers/async";
import ImageUpload from "components/CustomUpload/ImageUpload";
import { activeStatusString } from "variables/constants";
import GroupCategoryPicker from "views/components/GroupCategoryPicker";
import GroupPicker from "views/components/GroupPicker";
import { roles } from "variables/constants";
import { Modal } from "bootstrap";

function GroupForm(props) {
  const { t } = useTranslation();
  console.log("props = ", props);
  const profile = useSelector((state) => state.firebase.profile);

  const [group, setGroup] = useState(props.currentItem || {});
  const [channel, setChannel] = useState(
    props.currentItem?.groupCategory?.channel || props.channel
  );
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModified, setIsModified] = useState(false);

  const [onSaving, setOnSaving] = useState(false);
  const [alert, setAlert] = useState(null);
  const [file, setFile] = useState(null);

  const firebase = useFirebase();
  const firestore = useFirestore();

  useEffect(() => {
    // console.log('check group before deep copy =>', group);
    // if (props.group) {
    //   const _group = _.clone(props.group);
    //   delete _group.id;
    //   setGroup(_group);
    // }
    // console.log('check group after deep copy =>', group);
    return () => {
      // cleanup
    };
  }, [props.group]);

  useEffect(() => {
    const currentItem = _.cloneDeep(props.currentItem);
    console.log("checking curruntItem when open new form");
    console.log(`currentItem`, currentItem);
    console.log(`group ->`, group);
    //ContentVersionForm.jsx의 같은 역할을 하는 코드에서 object.keys는 동작하나 여기서는 안 됨. group이 null로 들어와있음.
    //contentVersionForm의 currentItem(contentVersion)처럼 [] 형태로 데이터가 들어오는 게 아니니 null 값을 체크해줘서 초기 폼을 열었을 때 return 시켜줘야 됨
    if (currentItem == null && Object.keys(group).length === 0) {
      return;
    }

    if (currentItem?.id) {
      delete currentItem.id;
    }

    console.log(
      "check the modified before isEqual =>",
      !_.isEqual(currentItem, group)
    );
    const modified = !_.isEqual(currentItem, group); // || file != null;
    console.log("check the modified after isEqual =>", modified);
    setIsModified(modified);
    return () => {
      // cleanup
    };
  }, [group, file]);

  const onGroupCategorySelected = (groupCategory) => {
    console.log("groupCategory = ", groupCategory);
    if (groupCategory) {
      setGroup({
        ...group,
        groupCategory,
      });
    }
  };

  const onSave = async () => {
    console.log("press save");

    setOnSaving(true);

    const timestamp = {
      updatedAt: firestore.FieldValue.serverTimestamp(),
    };

    let counts = _.pick(group, ["nMembers", "nLicenses", "nSubgroups"]);

    if (!props.currentItem?.id) {
      timestamp["createdAt"] = firestore.FieldValue.serverTimestamp();
      counts = {
        nMembers: 0,
        nLicenses: 0,
        nSubgroups: 0,
      };
    }

    let subLogo = group?.subLogo || null;
    if (file) {
      try {
        const uploadingInfo = await firebase.uploadFile(
          "userContents",
          file,
          undefined,
          { name: (file) => `${+new Date()}${file.name}` }
        );
        const url = await uploadingInfo.uploadTaskSnapshot.ref.getDownloadURL();
        subLogo = url;
      } catch (e) {
        console.error("Failed to upload file: ", e);
        setErrorMessage("Failed to upload file");
        return;
      }
    }

    if (subLogo !== props.currentItem?.subLogo && props.currentItem?.subLogo) {
      // delete the file from storage
      const uriToDelete = _.findLast(
        new URL(props.currentItem?.subLogo).pathname.split("/")
      );
      try {
        const path = decodeURIComponent(uriToDelete);
        const ret = await firebase.deleteFile(path);
        console.log("deleted ", ret);
      } catch (e) {
        console.error("failed to delete file: ", e);
      }
    }

    try {
      let groupRef = firestore.collection(`channels/${channel.id}/groups`);
      if (props.currentItem?.id) {
        groupRef = groupRef.doc(props.currentItem.id);
      } else {
        groupRef = groupRef.doc();
      }
      await groupRef.set({
        ...group,
        ...timestamp,
        subLogo,
        ...counts,
      });
    } catch (error) {
      console.error("Failed to save : ", error);
    }
    props.afterSave(() => props.onCancel());
    props?.toggle && props.toggle();
    // props?.onCancel();
    setOnSaving(false);
  };

  const onDelete = async () => {
    const groupRef = firestore
      .collection(`channels/${channel.id}/groups`)
      .doc(props.group.id);
    await groupRef.delete();
    props.afterDelete && props.afterDelete();
  };

  const onCancel = () => {
    console.log("onCancel!", props.toggle);
    console.log("check is Modified!", isModified);
    if (isModified) {
      props.closeConfirm(() => {
        props?.toggle && props.toggle();
        props.onCancel();
      });
    } else {
      console.log("cancel modal");
      props?.toggle && props.toggle();
    }
  };

  const warningWithConfirmAndCancelMessage = () =>
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={
          <div>
            삭제하면 복구할 수 없습니다.
            <br />
            계속 진행하시겠습니까?
          </div>
        }
        onConfirm={() => {
          onDelete();
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="확인"
        cancelBtnText="취소"
        showCancel
      >
        지우면 완전히 삭제됩니다. 지우시겠습니까?
      </ReactBSAlert>
    );

  const onChange = (e, name, typeFn) => {
    const value = e.target.value;
    setGroup({ ...group, [name]: typeFn ? typeFn(value) : value });
  };

  const handleFileLoaded = (file) => {
    setErrorMessage(null);
    console.log(file);
    if (!file) {
      setFile(null);
      setGroup({ ...group, subLogo: null });
      return;
    }
    if (file.size > 5 * 1024 * 1024) {
      setErrorMessage(
        "파일 사이즈가 너무 큽니다. 5MB이하로 줄여서 올려주세요."
      );
      setFile(null);
      return;
    }
    const ext = _.last(file.name.split("."));
    console.log("ext = ", ext);
    if (!["jpg", "jpeg", "png"].includes(ext.toLowerCase())) {
      setErrorMessage(
        "파일 형식을 확인해주세요. 허용가능한 파일 형식: jpg, png"
      );
      setFile(null);
      return;
    }
    setFile(file);
  };

  const addClassroom = () => {
    const { classrooms = [] } = group;
    setGroup({
      ...group,
      classrooms: [
        ...classrooms,
        {
          id: "",
          title: "",
        },
      ],
    });
  };
  //2021_3_30 피커 변경했을 때 change로 group useEffect 실행시키기
  return (
    <div className="content">
      {alert}
      {
        // 최고 관리자이고, props.group이 없는 신규 생성인 경우에만
        isLoaded(profile) &&
        !isEmpty(profile) &&
        profile.role === roles.admin &&
        !props.group ? (
          <>
            <Row>
              <Col>
                <div className="text-right">
                  <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                    *
                  </span>{" "}
                  필수 입력 사항입니다
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <GroupPicker
                  channel={channel}
                  cbSelected={({ channel }) => setChannel(channel)}
                  viewMode={["channel"]}
                />
              </Col>
            </Row>
          </>
        ) : null
      }

      {/* title */}
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>가맹점 이름</label>
              <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                *
              </span>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={group?.title || ""}
              placeholder="학교나 학원지점의 이름, 예)인솔초등학교"
              onChange={(e) => onChange(e, "title")}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <GroupCategoryPicker
            channel={channel}
            groupCategory={group?.groupCategory}
            cbSelected={onGroupCategorySelected}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>상태</label>
              <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                *
              </span>
            </Col>
          </Row>
          <FormGroup row>
            {activeStatusString.map((status, index) => (
              <FormGroup key={index} check>
                <Label check>
                  <Input
                    type="radio"
                    name="status"
                    defaultChecked={index + 1 === group?.status}
                    value={index + 1}
                    onClick={(e) => onChange(e, "status", (t) => Number(t))}
                  />{" "}
                  {t(status)}
                </Label>
              </FormGroup>
            ))}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>가맹점 로고</label>
            </Col>
          </Row>
          <FormGroup>
            <ImageUpload
              onFileLoaded={handleFileLoaded}
              imageUrl={group?.subLogo}
            />
            {/* <Input
              value={item?.subLogo}
              placeholder="총판에 대한 설명을 간결하게 기술해주세요"
              onChange={e => onChange(e, 'description')} /> */}
          </FormGroup>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col xs={{ size: "auto" }} style={{ paddingTop: 12 }}>
          <label>시설(교육장) 추가</label>
        </Col>
        <Col>
          <Button
            className="btn-icon btn-round"
            size="sm"
            color="primary"
            onClick={addClassroom}
          >
            <i className="fa fa-plus" />
          </Button>
        </Col>
      </Row>
      {group?.classrooms?.map((classroom, idx) => (
        <Row key={idx}>
          <Col className="mb-2">
            <Row>
              <Col>
                <Row>
                  <Col>
                    <label>교실 구분 번호</label>
                  </Col>
                  <Col>
                    <Input
                      value={classroom?.id}
                      onChange={(e) => {
                        const newClassrooms = _.clone(group?.classrooms);
                        const currentValue = newClassrooms[idx];
                        newClassrooms[idx] = {
                          ...currentValue,
                          id: e.target.value,
                        };

                        setGroup({ ...group, classrooms: newClassrooms });
                      }}
                    />
                  </Col>
                  <Col>
                    <label>교실 이름</label>
                  </Col>
                  <Col>
                    <Input
                      value={classroom?.title}
                      onChange={(e) => {
                        const newClassrooms = _.clone(group?.classrooms);
                        const currentValue = newClassrooms[idx];
                        newClassrooms[idx] = {
                          ...currentValue,
                          title: e.target.value,
                        };

                        setGroup({ ...group, classrooms: newClassrooms });
                      }}
                    />
                  </Col>
                  <Col>
                    <Button
                      close
                      onClick={() => {
                        const newClassrooms = group?.classrooms.filter(
                          (room) => room.id !== classroom.id
                        );
                        setGroup({ ...group, classrooms: newClassrooms });
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      ))}
      {errorMessage ? (
        <div>
          <p className="text-danger">{errorMessage}</p>
        </div>
      ) : null}
      <Row>
        {/* {
          props.group?.id
            ? <Col>
              <Button color="danger" onClick={warningWithConfirmAndCancelMessage}>삭제</Button>
            </Col>
            : null
        } */}
        <Col className="text-right">
          <Button
            color="primary"
            disabled={
              onSaving ||
              !Boolean(
                group?.title &&
                  group.title.length > 0 &&
                  group?.status &&
                  group?.groupCategory?.channel
              ) ||
              !isModified
            }
            onClick={onSave}
          >
            저장
          </Button>
          <Button color="warning" onClick={onCancel}>
            취소
          </Button>
        </Col>
      </Row>
    </div>
  );
}

GroupForm.propTypes = {
  group: PropTypes.object,
  afterSave: PropTypes.func,
  afterDelete: PropTypes.func,
  onCancel: PropTypes.func,
};

export default GroupForm;
