import React, { useState } from "react";
import PropTypes from "prop-types";
import _, { isUndefined } from "lodash";

import Board from "views/components/Board.jsx";
import { useFirestoreConnect, useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useTable } from "react-table";
import { NavLink } from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ReactTable from "react-table";
import Charts from "views/Charts.jsx";
import { Line, Bar } from "react-chartjs-2";
import { activeStatusString } from "variables/constants";
import { useTranslation } from "react-i18next";

//for charts
import { chartExample9, chartExample10 } from "variables/charts.jsx";

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  CardTitle,
  CardText,
  Table,
  Row,
  Col,
  Badge,
  Jumbotron,
} from "reactstrap";
import { useRef, useEffect } from "react";
import moment from "moment";
import ChannelForm from "views/forms/ChannelForm";

function Channels(props) {
  const firestore = useFirestore();
  useFirestoreConnect([
    { collection: "channels" },
    { collection: "contentVersions" },
  ]);
  const { t, i18n } = useTranslation();
  const items = useSelector((state) => state.firestore.ordered.channels);
  const contentVersions = useSelector(
    (state) => state.firestore.ordered.contentVersions
  );
  const boardEl = useRef(null);
  const [alert, setAlert] = useState(null);

  const numeral = require("numeral");

  const data =
    items &&
    items.map((item) => ({
      ...item,
      actions: (
        // we've added some custom button actions
        <div className="actions-left">
          <Button
            onClick={() => {
              console.log("clicked");
              boardEl.current.edit(item);
            }}
            color="warning"
            size="sm"
            className="btn-icon btn-link edit"
          >
            <i className="fa fa-edit" style={{ fontSize: "1.5em" }} />
          </Button>{" "}
          {/* use this button to remove the data row */}
          <Button
            onClick={() => {
              console.log("clicked");
              warningWithConfirmAndCancelMessage(item);
            }}
            color="danger"
            size="sm"
            className="btn-icon btn-link remove"
          >
            <i className="fa fa-times" style={{ fontSize: "1.5em" }} />
          </Button>{" "}
        </div>
      ),
    }));

  const warningWithConfirmAndCancelMessage = (item) =>
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={
          <div>
            삭제하면 복구할 수 없습니다.
            <br />
            계속 진행하시겠습니까?
          </div>
        }
        onConfirm={() => {
          firestore.doc(`channels/${item.id}`).delete();
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="확인"
        cancelBtnText="취소"
        showCancel
      >
        지우면 완전히 삭제됩니다. 지우시겠습니까?
      </ReactBSAlert>
    );

  const yesterday = moment().subtract(1, "days").format("YYYY.MM.DD");
  const current = moment().format("YY.MM.DD HH:MM");
  const thisMonth = moment().format("MM");
  const lasstMonth = moment().format("MM");

  return (
    <div className="content">
      {alert}
      <Breadcrumb className="sticky-top">
        <BreadcrumbItem>
          <a href="/">Home</a>
        </BreadcrumbItem>
        <BreadcrumbItem active>총판 목록</BreadcrumbItem>
      </Breadcrumb>
      <Jumbotron>
        <h1 className="display-5">
          <strong>총판 목록</strong>
        </h1>
        <p className="lead">
          이곳에서 <strong>{items?.length || 0}개</strong> 총판과 관련 정보를
          확인할 수 있습니다.
        </p>
        <hr className="my-2" />
        <p>
          <strong>⚠️NOTE</strong> 총판에서의 상태(정상/만료/차단) 변경은 총판
          관리자 계정의 상태를 변경하게 됩니다.
        </p>
      </Jumbotron>
      <Board
        modalTitle="총판 입력폼"
        data={data}
        ref={boardEl}
        columns={[
          {
            accessor: "title",
            Header: "총판명",
            filterMethod: (filter, row) => {
              console.log("filter = ", filter);
              const title = row[filter.id];
              console.log("row title = ", title);
              return title && title.indexOf(filter.value) != -1;
            },
          },
          {
            width: 140,
            id: "status",
            Header: "상태",
            accessor: (d) => {
              let _color = "primary";
              if (d.status === 2) {
                _color = "warning";
              }
              if (d.status === 3) {
                _color = "danger";
              }
              return (
                <Badge color={_color}>
                  {activeStatusString[d.status - 1 || 0]}
                </Badge>
              );
            },
            filterMethod: (filter, row) => {
              if (filter.value === "all") {
                return true;
              }
              if (
                activeStatusString[filter?.value] ===
                row[filter.id].props.children
              ) {
                return true;
              }
              return false;
            },
            Filter: ({ filter, onChange }) => (
              <select
                onChange={(event) => {
                  console.log("event.target.value = ", event.target.value);
                  return onChange(event.target.value);
                }}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
              >
                <option value="all">전체</option>
                {activeStatusString.map((status, idx) => (
                  <option key={idx} value={idx}>
                    {t(status)}
                  </option>
                ))}
              </select>
            ),
          },
          {
            Header: "설명",
            accessor: "description",
            filterMethod: (filter, row) => {
              console.log("filter = ", filter);
              const title = row[filter.id];
              console.log("row title = ", title);
              return title && title.indexOf(filter.value) != -1;
            },
          },
          {
            Header: "콘텐츠 버전",
            accessor: "contentVersion",
            filterMethod: (filter, row) => {
              console.log("filter = ", filter);
              const title = row[filter.id];
              console.log("row title = ", title);
              return title && title.indexOf(filter.value) != -1;
            },
          },
          {
            Header: "커리큘럼 버전",
            accessor: "curriculumVersion",
            filterMethod: (filter, row) => {
              console.log("filter = ", filter);
              const title = row[filter.id];
              console.log("row title = ", title);
              return title && title.indexOf(filter.value) != -1;
            },
          },
          {
            width: 90,
            Header: "대리점수",
            accessor: "nGroupCategories",
          },
          {
            width: 90,
            Header: "가맹점수",
            accessor: "nGroups",
          },
          {
            width: 90,
            Header: "회원수",
            accessor: "nMembers",
          },
          {
            Header: "명령",
            accessor: "actions",
            sortable: false,
            filterable: false,
          },
        ]}
      >
        <ChannelForm />
      </Board>
    </div>
  );
}

Channels.propTypes = {};

export default Channels;
