import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash';

import Board from 'views/components/Board.jsx'
import { useFirestoreConnect, useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useTable } from 'react-table';
import { NavLink } from "react-router-dom";

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import { useRef, useEffect } from 'react';
import moment from 'moment';
import BodyMeasurementForm from 'views/forms/BodyMeasurementForm';
import SparkChart from 'components/Charts/SparkChart';
import { useTranslation } from "react-i18next";

function CourseDetails(props) {
  // const { channel, group, subgroup, member } = props.location?.state;
  console.log('group = ', props.state);  
  const { t, i18n } = useTranslation();

  const { channel, group, subgroup, member } = props.state;

  const firestore = useFirestore();
  useFirestoreConnect([
    {
      collection: `channels/${channel.id}/groups/${group.id}/members/${member.id}/pastCoursesList`,
      orderBy: [
        ['createdAt', 'desc']
      ],
      storeAs: 'pastCoursesList'
    }
  ]);

  const items = useSelector(state => state.firestore.ordered['pastCoursesList']);
  const boardEl = useRef(null);

  const data = items && items.map((item) => ({
    ...item,
    // actions 부분 논의 필요 및 추후 수정
    actions: (
      <div className="actions-left">
        <Button
          onClick={() => {
            console.log('clicked');
            boardEl.current.edit(item);
          }}
          color="warning"
          size="sm"
          className="btn-icon btn-link edit"
        >
          <i className="fa fa-edit" style={{ fontSize: '1.5em' }} />
        </Button>{" "}
        <Button
          onClick={() => {
            console.log('clicked');
            boardEl.current.delete(() => {
              firestore.doc(`channels/${channel.id}/groups/${group.id}/members/${member.id}/pastCoursesList/${item.id}`).delete();
            });
          }}
          color="danger"
          size="sm"
          className="btn-icon btn-link remove"
        >
          <i className="fa fa-times" style={{ fontSize: '1.5em' }} />
        </Button>{" "}
      </div>
    )
  }))
  // console.log('data', data);

  return (
    <div className="content">
      <Board
        // title={`${member?.name}님의 수강 목록`}
        modalTitle={t("새 측정 추가") }
        data={data}
        ref={boardEl}
        columns={[
          {
            id: 'title',
            Header: "수업명",
            accessor: d => {
              const group = _.clone(d);
              const channel = group.groupCategory?.channel;
              delete group.actions;
              return <NavLink
                // to={{ pathname: `./franchise`, state: { channel, group } }} 이 부분 수정
              >{d.title}</NavLink>
            },
            sortMethod: (a, b) => {
              const x = a.props.children;
              const y = b.props.children;
              if (x === y) {
                return x > y ? 1 : -1;
              }
              return x > y ? 1 : -1;
            },
            filterMethod: (filter, row) => {
              console.log('filter = ', filter);
              const title = row[filter.id].props.children;
              console.log('row title = ', title);
              return title && title.indexOf(filter.value) != -1;
            }
          },
          {
            id: 'startAt',
            Header: "수강시작일자",
            accessor: d => d.startAt ? moment(d.startAt.toDate()).format('YYYY-MM-DD HH:mm') : ''
          },
          {
            id: 'endAt',
            Header: "수강만료일자",
            accessor: d => d.endAt ? moment(d.endAt.toDate()).format('YYYY-MM-DD HH:mm') : ''
          },
          {
            Header: "명령",
            accessor: "actions",
            sortable: false,
            filterable: false
          }
        ]}
      >
        <BodyMeasurementForm
          channel={channel}
          group={group}
          subgroup={subgroup}
          member={member}
        />
      </Board>
    </div>
  )
}

export default CourseDetails;

