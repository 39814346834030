import React from 'react'
import _ from 'lodash';
import {
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Button
} from 'reactstrap';

import Board from 'views/components/Board.jsx'
import { useFirestoreConnect, useFirestore, useFirebase } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { NavLink } from "react-router-dom";

import { useRef, useEffect, useState } from 'react';
import moment from 'moment';
import { rolesString } from 'variables/constants';
import { getCustomClaimsFromLocalStorage } from 'helpers/localStorage';
import { roles } from 'variables/constants';
import ReportForm from 'views/forms/ReportForm';
import { useTranslation } from "react-i18next";

function ReportHome({ history }) {

  const firestore = useFirestore();
  const firebase = useFirebase();

  const customClaims = getCustomClaimsFromLocalStorage();
  const { role, channelId, groupId } = customClaims;
  const { t, i18n } = useTranslation();

  let whereOptions = null;
  switch (role) {
    case roles.admin:
      whereOptions = null;
      break;
    case roles.channelAdmin:
      whereOptions = ['channel.id', '==', channelId];
      break;
    case roles.groupAdmin:
      whereOptions = [
        ['channel.id', '==', channelId],
        ['group.id', '==', groupId],
      ];
      break;
    default:
    //do nothing
  }
  console.log('whereOptions = ', whereOptions);


  useFirestoreConnect([
    {
      collection: 'reports',
      where: whereOptions
    }
  ]);

  const items = _.orderBy(useSelector(state => state.firestore.ordered.reports), 'createdAt', 'desc');
  console.log('items = ', items);
  const boardEl = useRef(null);
  const [alert, setAlert] = useState(null);

  const data = items && items.map((item) => ({
    ...item,
    actions: (
      // we've added some custom button actions
      <div className="actions-left">
        {/* use this button to add a edit kind of action */}
        {/* <Button
          onClick={() => {
            console.log('clicked');
            boardEl.current.edit(item);
          }}
          color="warning"
          size="sm"
          className="btn-icon btn-link edit"
        >
          <i className="fa fa-edit" style={{ fontSize: '1.5em' }} />
        </Button>{" "} */}
        {/* use this button to remove the data row */}
        <Button
          onClick={() => {
            console.log('clicked');
            warningWithConfirmAndCancelMessage(item);
          }}
          color="danger"
          size="sm"
          className="btn-icon btn-link remove"
        >
          <i className="fa fa-times" style={{ fontSize: '1.5em' }} />
        </Button>{" "}
      </div>
    )
  }))

  const warningWithConfirmAndCancelMessage = (item) => setAlert(
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title={<div>{t('삭제하면 복구할 수 없습니다.')}<br />{t('계속 진행하시겠습니까?')}</div>}
      onConfirm={() => {
        firestore.doc(`reports/${item.id}`).delete();
        setAlert(null);
      }}
      onCancel={() => setAlert(null)}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      confirmBtnText={t("확인")}
      cancelBtnText={t("취소")}
      showCancel
    >
      {t('지우면 완전히 삭제됩니다. 지우시겠습니까?')}
        </ReactBSAlert>
  )


  return (
    <div className='content'>
      {alert}
      <Breadcrumb className='sticky-top'>
        <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
        <BreadcrumbItem active>{t('회원별 리포트')}</BreadcrumbItem>
      </Breadcrumb>
      <Jumbotron>
        <h1 className="display-5"><strong>{t('회원별 리포트 조회')}</strong></h1>
        <p className="lead">{t('이곳에서 등록된 전체')} <strong>{t('회원의 레포트')}</strong>{t('를 확인할 수 있습니다.')}</p>
        <hr className="my-2" />
        <p><strong>⚠️NOTE</strong> {t('이전 리포트 버튼이 활성화되어 있는 경우, 이전 리포트를 확인할 수 있습니다.')}</p>
      </Jumbotron>
      <Board
        data={data}
        ref={boardEl}
        hideAddNewButton={true}
        columns={[
          {
            id: 'target',
            Header: t('회원명'),
            accessor: d => {
              const report = _.clone(d);
              delete report.actions;
              return <NavLink
                to={{ pathname: `./report-member`, state: { ...report } }}
              >{d.member.name}</NavLink>
            },
            sortMethod: (a, b) => {
              const x = a.props.children;
              const y = b.props.children;
              if (x === y) {
                return x > y ? 1 : -1;
              }
              return x > y ? 1 : -1;
            },
            filterMethod: (filter, row) => {
              console.log('filter = ', filter);
              const title = row[filter.id].props.children;
              console.log('row title = ', title);
              return title && title.indexOf(filter.value) != -1;
            }
          },
          {
            Header: t('총판명'),
            accessor: "channel.title",
            filterMethod: (filter, row) => {
              console.log('filter = ', filter);
              const title = row[filter.id];
              console.log('row title = ', title);
              return title && title.indexOf(filter.value) != -1;
            }
          },
          {
            Header: t('가맹점명'),
            accessor: "group.title",
            filterMethod: (filter, row) => {
              console.log('filter = ', filter);
              const title = row[filter.id];
              console.log('row title = ', title);
              return title && title.indexOf(filter.value) != -1;
            }
          },
          {
            Header: t("클래스"),
            accessor: "member.subgroupTitle",
          },
          {
            id: 'createdAt',
            Header: t("등록일시"),
            accessor: d => d.createdAt ? moment(d.createdAt.toDate()).format('YYYY-MM-DD HH:mm') : ''
          },
          {
            Header: t("명령"),
            accessor: "actions",
            sortable: false,
            filterable: false
          }
        ]}
      >
        <ReportForm />
      </Board>
    </div>
  )
}

export default ReportHome
