import { getCustomClaimsFromLocalStorage } from "helpers/localStorage";

const initState = {
  hello: "world",
  user: getCustomClaimsFromLocalStorage(),
};

export default function (state = initState, action) {
  switch (action.type) {
    case "INIT_USER": {
      console.log("INIT_USER:", action.payload);
      return { ...state, user: action.payload };
    }
    default:
      return { ...state, a: 123 };
  }
}
