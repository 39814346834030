import React, { useRef, useState, useEffect } from "react";
import {
  useFirestoreConnect,
  useFirestore,
  isLoaded,
  isEmpty,
  useFirebase,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import Board from "views/components/Board.jsx";
import moment from "moment";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  CardSubtitle,
  CardLink,
  CardText,
  ListGroup,
  ListGroupItem,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ModalHeader,
  Modal,
  ModalBody,
  Alert,
  Jumbotron,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  Spinner,
} from "reactstrap";
import SubgroupForm from "views/forms/SubgroupForm";
import { getCustomClaimsFromLocalStorage } from "helpers/localStorage";
import { roles } from "variables/constants";
import ConfirmableForm from "views/forms/ConfirmableForm";
import { activeStatusString } from "variables/constants";
import TimeTable from "components/TimeTable/TimeTable";
import AddSubgroupsAndMembers from "./AddSubgroupsAndMembers";
import { normalDateTime } from "helpers/datetime";

function Group({ location, history }) {
  const firebase = useFirebase();
  const [alert, setAlert] = useState(null);
  const [items, setItems] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [onCopying, setOnCopying] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { t, i18n } = useTranslation();

  console.log("items = ", items);
  const profile = useSelector((state) => state.firebase.profile);
  var { group, channel } = useSelector((state) => state.auth.user);
  console.log("group = ", group);
  console.log("chaneel = ", channel);
  console.log("profile =", profile);
  // let group, channel;
  // 1) location <--- navigation params
  console.log("location?.state?.group", location?.state?.group);
  if (location?.state?.group) {
    console.log("before 1st group", group);
    if (group?.id === undefined) {
      group = location.state.group;
      channel = location.state.channel
        ? location.state.channel
        : group?.groupCategory?.channel;

      console.log("1st group", group);
    }
  }

  // if (!group) {
  //   // 2) profile <--- react-redux-firebase, profile (global state.firebase.profile)
  //   if (isLoaded(profile) && !isEmpty(profile)) {
  //     group = profile.group;
  //     channel = profile.channel
  //       ? profile.channel
  //       : group?.groupCategory?.channel;

  //       console.log("2nd group", profile.role);
  //   } else {
  //     // 3) localstorage
  //     const customClaims = getCustomClaimsFromLocalStorage();
  //     const {
  //       groupId,
  //       channelId,
  //       group: _group,
  //       channel: _channel,
  //     } = customClaims;
  //     group = { id: groupId, ..._group };
  //     channel = { id: channelId, ..._channel };
  //     console.log("3rd group", group);
  //   }
  // }

  const groupId = group?.id;

  const firestore = useFirestore();
  useFirestoreConnect(() => [
    {
      collection: `channels/${channel.id}/groups/${groupId}/subgroups`,
      orderBy: [["title"]],
      storeAs: "subgroups",
    },
    {
      collection: `channels/${channel.id}/groups/${groupId}/members`,
      orderBy: [["studentNum"]],
      storeAs: "allGroupMembers",
    },
  ]);

  const subgroups = useSelector(
    (state) => state.firestore.ordered[`subgroups`]
  );
  const members = useSelector(
    (state) => state.firestore.ordered[`allGroupMembers`]
  );
  const [foundMembers, setFoundMembers] = useState(null);
  const [filteredSubgroups, setFilteredSubgroups] = useState(null);
  const [isSearchingMember, setIsSearchingMember] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [toastMessage, setToastMessage] = useState(null);
  const [settingOpen, setSettingOpen] = useState(false);

  useEffect(() => {
    // console.log('subgroups: ', subgroups);
    setFilteredSubgroups(subgroups);
    return () => {
      // cleanup
    };
  }, [subgroups]);

  const toggleSetting = () => setSettingOpen(!settingOpen);
  const toggleModal = () => setModalOpen(!modalOpen);

  const boardEl = useRef(null);

  //Alert 하나 넣어보고 작동되는지 확인하기

  const data = subgroups
    ? subgroups.map((subgroup) => ({
        ...subgroup,
        actions: (
          <div className="actions-left">
            <Button
              onClick={() => {
                inputAlert({
                  channelId: channel.id,
                  groupId: group.id,
                  subgroupId: subgroup.id,
                  title: subgroup.title,
                });
              }}
              color="warning"
              size="sm"
              className="btn-icon btn-link"
            >
              <i className="fa fa-copy" style={{ fontSize: "1.5em" }} />
            </Button>{" "}
            <Button
              onClick={() => {
                console.log("clicked");
                setSelectedItem(subgroup);
              }}
              color="warning"
              size="sm"
              className="btn-icon btn-link edit"
            >
              <i className="fa fa-edit" style={{ fontSize: "1.5em" }} />
            </Button>{" "}
            {/* use this button to remove the data row */}
            <Button
              onClick={() => {
                console.log("clicked");
                boardEl.current.delete(async () => {
                  console.log("item: ", subgroup);
                  try {
                    await firestore
                      .doc(
                        `channels/${channel.id}/groups/${group.id}/subgroups/${subgroup.id}`
                      )
                      .delete();
                  } catch (error) {
                    console.error("failed to delete content version: ", error);
                  }
                });
              }}
              color="danger"
              size="sm"
              className="btn-icon btn-link remove"
            >
              <i className="fa fa-times" style={{ fontSize: "1.5em" }} />
            </Button>{" "}
          </div>
        ),
      }))
    : [];

  const inputAlert = (src) => {
    setAlert(
      <ReactBSAlert
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title={`[${src.title}]\n${t("복사하시겠습니까")}`}
        confirmBtnText={<span>{onCopying ? t("복사중") : t("복사하기")}</span>}
        cancelBtnText={t("취소하기")}
        onConfirm={(e) => {
          setOnCopying(true);
          inputConfirmAlert(e, src);
        }}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        disabled={onCopying}
      />
    );
  };

  const inputConfirmAlert = async (e, src) => {
    setOnCopying(true);
    try {
      const { data } = await copyClass(src);
      console.log("httpscallble data = ", data);
      if (data.result === "success") {
        copySuccess();
      } else {
        copyFailure(data.message);
      }
    } catch (e) {
      console.error("failed to httpscall: ", e);
    } finally {
      setOnCopying(false);
    }
  };

  const copySuccess = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        onConfirm={() => hideAlert()}
        confirmBtnBsStyle="info"
        title={t("복사완료")}
      />
    );
  };

  const copyFailure = (message) => {
    setAlert(
      <ReactBSAlert
        error
        style={{ display: "block", marginTop: "-100px" }}
        onConfirm={() => hideAlert()}
        confirmBtnBsStyle="info"
        title={t("복사실패")}
      >
        {message}
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const copyClass = async (src, newTitle) => {
    const { channelId, groupId, subgroupId } = src;
    console.log(
      `channelId = ${channelId}, groupId = ${groupId}, subgroupId: ${subgroupId}`
    );

    const functions = firebase.app().functions("asia-northeast1");
    console.log("functions = ", functions);
    const copySubgroup = functions.httpsCallable("copySubgroup");
    try {
      return await copySubgroup({ src, newTitle });
    } catch (e) {
      console.error("failed to httpsCallable: ", e);
    }
  };

  return (
    <div className="content">
      {alert}
      <Alert
        isOpen={Boolean(toastMessage)}
        toggle={() => setToastMessage(null)}
        color="info"
      >
        {toastMessage}
      </Alert>
      <Breadcrumb className="sticky-top">
        <BreadcrumbItem>
          <a href="/">Home</a>
        </BreadcrumbItem>
        {/* {
          profile.role === roles.admin
            ? <BreadcrumbItem><a href="./channels">총판 목록</a></BreadcrumbItem>
            : null
        }
        {
          profile.role >= roles.channelAdmin
            ? <BreadcrumbItem>
              <NavLink to={{ pathname: `./groups`, state: { channel } }}>
                {channel.title}
              </NavLink>
            </BreadcrumbItem>
            : null
        } */}
        {profile.role != 4 ? (
          <BreadcrumbItem>
            <NavLink
              to={{ pathname: `./franchises`, state: { channel, group } }}
            >
              {t("가맹점 목록")}
            </NavLink>
          </BreadcrumbItem>
        ) : (
          ""
        )}
        {profile.role != 4 ? (
          <BreadcrumbItem active>{group?.title}</BreadcrumbItem>
        ) : (
          ""
        )}
      </Breadcrumb>
      <Jumbotron>
        <div className="float-right">
          {
            /* {[roles.admin].includes(profile.role) ? (
            <i
              className="nc-icon nc-key-25 h5 ml-3"
              onClick={() =>
                history.push({
                  pathname: "./licenses",
                  state: { channel, group },
                })
              }
            />
          ) : null} */
            // (
            //   <i
            //     className="nc-icon nc-key-25 h5 ml-3"
            //     onClick={() =>
            //       history.push({
            //         pathname: "./licenses",
            //         state: { channel, group },
            //       })
            //     }
            //   />
            // )
          }
        </div>
        <h1 className="display-3">
          <strong>{group?.title}</strong>
        </h1>
        <p className="lead">
          {t("이곳에서")} {t("클래스 목록과 회원을 검색할 수 있습니다.")}
        </p>
        <hr className="my-2" />
        <p>
          <strong>⚠️NOTE</strong>{" "}
          {t(
            "각 클래스의 차시와 체력장 수행을 해야 결과 리포트를 볼 수 있습니다."
          )}
          <br />
          {/* <Button color="primary" onClick={toggleModal}>
            엑셀파일로 반/학생 생성하기
          </Button> */}
        </p>
      </Jumbotron>
      <Card>
        <CardBody>
          <TimeTable
            classrooms={group?.classrooms}
            className="m-1 mb-5"
            events={subgroups
              ?.reduce(
                (acc, cur) => [
                  ...acc,
                  ...(cur.schedules?.map((schedule) => ({
                    ...schedule,
                    subgroupId: cur.id,
                    groupId: group.id,
                    channelId: channel
                      ? channel.id
                      : group.groupCategory.channel.id,
                  })) || []),
                ],
                []
              )
              ?.map((event, idx) => {
                return {
                  ...event,
                  start: event.start.toDate(),
                  end: event.end.toDate(),
                };
              })}
            onChangeEvent={(event) => {
              console.log("editedEvents");
            }}
          />
        </CardBody>
      </Card>
      <Row>
        {/* <Row> */}
        <Col>
          <h6>{t("회원 검색")}</h6>
          <InputGroup style={{ width: "20rem" }}>
            <Input
              type="text"
              placeholder={t("회원 이름으로 검색")}
              onChange={async (e) => {
                const name = e.target.value;
                if (name.length < 2) {
                  setFoundMembers(null);
                  return;
                }
                console.log("members = ", members);
                const found = members.filter(
                  (member) => member.name?.indexOf(name) !== -1
                );
                setFoundMembers(found);
              }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i className="nc-icon nc-zoom-split"></i>
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
          <Card style={{ width: "20rem" }}>
            <ListGroup flush>
              {foundMembers &&
                foundMembers.map((member) => (
                  <ListGroupItem
                    key={member.id}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      history.push({
                        pathname: "./member",
                        state: {
                          channel,
                          group,
                          subgroup: member.subgroups[0],
                          member,
                        },
                      })
                    }
                  >
                    <div>
                      <i className="nc-icon nc-single-02"></i> {member.name} (
                      {member.subgroupTitle})
                    </div>
                    <div className="text-right text-muted">
                      {t("홈으로 가기")}
                    </div>
                  </ListGroupItem>
                ))}
            </ListGroup>
          </Card>
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <Button color='primary' size='m' onClick={() => setSelectedItem({})}>새로 추가하기</Button>
        </Col>
      </Row> */}
      <Board
        title={t("클래스 목록")}
        data={data}
        ref={boardEl}
        columns={[
          {
            id: "title",
            Header: t("클래스명"),
            accessor: (d) => {
              // console.log('d', d);
              const subgroup = _.clone(d);
              delete subgroup.actions;
              return (
                <NavLink
                  to={{
                    pathname: `./subgroup`,
                    state: { channel, group, subgroup },
                  }}
                >
                  {d.title}
                </NavLink>
              );
            },
            sortMethod: (a, b) => {
              const x = a.props.children;
              const y = b.props.children;
              if (x === y) {
                return x > y ? 1 : -1;
              }
              return x > y ? 1 : -1;
            },
            filterMethod: (filter, row) => {
              console.log("filter = ", filter);
              const title = row[filter.id].props.children;
              console.log("row title = ", title);
              return title && title.indexOf(filter.value) != -1;
            },
          },
          {
            id: "status",
            Header: t("상태"),
            accessor: (d) => {
              const channel = _.clone(d);
              delete channel.actions;
              let _color = "primary";
              if (d.status === 2) {
                _color = "warning";
              }
              if (d.status === 3) {
                _color = "danger";
              }
              return (
                <Badge color={_color}>
                  {t(activeStatusString[d.status - 1 || 0])}
                </Badge>
              );
            },
          },
          {
            Header: t("코스명"),
            accessor: "course.title",
          },
          {
            Header: t("회원수"),
            id: "members",
            accessor: (d) => (d?.members?.length ? d.members.length : 0),
          },
          // {
          //   id: "completedContents",
          //   Header: "완료율",
          //   accessor: (d) =>
          //     d.completedContents
          //       ? (d.completedContents / 100).toFixed(0) + "%"
          //       : 0 + "%",
          // },
          {
            id: "startAt",
            Header: t("시작일"),
            accessor: (d) =>
              d.startAt ? moment(d?.startAt.toDate()).format("YYYY-MM-DD") : "",
          },
          {
            id: "endAt",
            Header: t("종료일"),
            accessor: (d) =>
              d.endAt ? moment(d?.endAt.toDate()).format("YYYY-MM-DD") : "",
          },
          {
            id: "createdAt",
            Header: t("생성일"),
            accessor: (d) => normalDateTime(d.createdAt),
          },
          {
            id: "updatedAt",
            Header: t("수정일"),
            accessor: (d) => normalDateTime(d.updatedAt),
          },
          {
            Header: t("명령"),
            accessor: "actions",
            sortable: false,
            filterable: false,
          },
        ]}
      >
        <SubgroupForm
          channel={channel}
          group={group}
          subgroup={selectedItem}
          onCancel={() => setSelectedItem(null)}
        />
      </Board>
      <Modal isOpen={Boolean(selectedItem)} size={"lg"}>
        <ModalHeader>{t("클래스 정보")}</ModalHeader>
        <ModalBody>
          <ConfirmableForm>
            <SubgroupForm
              channel={channel}
              group={group}
              subgroup={selectedItem}
              onCancel={() => setSelectedItem(null)}
            />
          </ConfirmableForm>
        </ModalBody>
      </Modal>

      <Modal isOpen={Boolean(modalOpen)} size={"lg"} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {t("엑셀 파일로 클래스와 학생을 한번에 추가합니다.")}
        </ModalHeader>
        <ModalBody>
          <ConfirmableForm>
            <AddSubgroupsAndMembers
              channel={channel}
              group={group}
              closeModal={toggleModal}
            />
          </ConfirmableForm>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Group;
