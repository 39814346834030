import React from 'react'
import {
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Button
} from 'reactstrap';
function ReportSubgroup() {
  return (
    <div className="content">
      <Breadcrumb className='sticky-top'>
        <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
        <BreadcrumbItem><a href="./report-home">리포트 홈</a></BreadcrumbItem>
        <BreadcrumbItem active>반별 리포트</BreadcrumbItem>
      </Breadcrumb>
    </div>
  )
}

export default ReportSubgroup
