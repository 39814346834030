import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _, { isUndefined } from 'lodash';

import Board from 'views/components/Board.jsx'
import { useFirestoreConnect, useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useTable } from 'react-table';
import { NavLink } from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ReactTable from "react-table";

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  CardTitle,
  CardText,
  Table,
  Row,
  Col,
  Badge,
  Jumbotron,
} from "reactstrap";
import { useRef, useEffect } from 'react';
import moment from 'moment';
import { normalDateTime } from 'helpers/datetime';
import QuestionForm from './QuestionForm';

function Questions(props) {
  const firestore = useFirestore();
  useFirestoreConnect([
    { collection: 'questionBank' }
  ]);

  const items = useSelector(state => state.firestore.ordered.questionBank);
  console.log(`items`, items);

  const boardEl = useRef(null);
  const [alert, setAlert] = useState(null);

  const numeral = require('numeral');


  const data = items && items.map((item) => (
    {
    ...item,
    actions: (
      // we've added some custom button actions
      <div className="actions-left">
        <Button
          onClick={() => {
            console.log('clicked');
            boardEl.current.edit(item);
          }}
          color="warning"
          size="sm"
          className="btn-icon btn-link edit"
        >
          <i className="fa fa-edit" style={{ fontSize: '1.5em' }} />
        </Button>{" "}
        {/* use this button to remove the data row */}
        <Button
          onClick={() => {
            console.log('clicked');
            warningWithConfirmAndCancelMessage(item);
          }}
          color="danger"
          size="sm"
          className="btn-icon btn-link remove"
        >
          <i className="fa fa-times" style={{ fontSize: '1.5em' }} />
        </Button>{" "}
      </div>
    )
  }))


  const warningWithConfirmAndCancelMessage = (item) => setAlert(
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title={<div>삭제하면 복구할 수 없습니다.<br />계속 진행하시겠습니까?</div>}
      onConfirm={() => {
        firestore.doc(`questionBank/${item.id}`).delete();
        setAlert(null);
      }}
      onCancel={() => setAlert(null)}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      confirmBtnText="확인"
      cancelBtnText="취소"
      showCancel
    >
      지우면 완전히 삭제됩니다. 지우시겠습니까?
        </ReactBSAlert>
  )

  const yesterday = moment().subtract(1, 'days').format("YYYY.MM.DD");
  const current = moment().format("YY.MM.DD HH:MM");
  const thisMonth = moment().format("MM");
  const lasstMonth = moment().format("MM");


  return (
    <div className="content">
      {alert}
      <Breadcrumb className='sticky-top'>
        <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
        <BreadcrumbItem active>문제은행</BreadcrumbItem>
      </Breadcrumb>
      <Jumbotron>
        <h1 className="display-5"><strong>문제은행</strong></h1>
        <p className="lead">이곳에서 O/X 문제를 확인할 수 있습니다.</p>
        <hr className="my-2" />
        <p><strong>⚠️NOTE</strong> 총판/그룹의 선택여부에 따라 전체/총판/그룹에서 사용하는 문제를 생성할 수 있습니다.</p>
      </Jumbotron>
      <Board
        modalTitle="문제은행 입력폼"
        data={data}
        ref={boardEl}
        columns={[
          {
            id:'channelTitle',
            Header: '총판',
            accessor: d=>d.channelId === "default" || null ? '총판 전체' : d.channelTitle
          },
          {
            id:'groupTitle',
            Header: '가맹점',
            accessor: d=>d.groupId === "default" ? '가맹점 전체' : d.groupTitle
          },
          {
            accessor: 'title',
            Header: "주제",
          },
          {
            accessor: 'subTitle',
            Header: "소제목"
          },
          {
            accessor: 'language',
            Header: "언어"
          },
          {
            id: 'nQuestions',
            accessor: d => d.questions.length,
            Header: "문항수"
          },
          {
            id: 'createdAt',
            Header: "등록일시",
            accessor: d => normalDateTime(d.createdAt),
            filterMethod: (filter, row) => {
              console.log('filter = ', filter);
              const title = row[filter.id];
              console.log('row title = ', title);
              return title && title.indexOf(filter.value) != -1;
            }
          },
          {
            Header: "명령",
            accessor: "actions",
            sortable: false,
            filterable: false
          }
        ]}
      >
        <QuestionForm />
      </Board>
    </div>
  )
}

Questions.propTypes = {

}

export default Questions

