/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { isLoaded, isEmpty } from 'react-redux-firebase'
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";
import { rolesString } from "variables/constants";

import { useFirebase } from 'react-redux-firebase';
import Spinner from "reactstrap/lib/Spinner";


export default function UserProfile() {
  const profile = useSelector(state => state.firebase.profile);
  console.log('profile = ', profile);

  const firebase = useFirebase();

  const [password, setPassword] = useState();
  const [updating, setUpdating] = useState(false);
  const [alert, setAlert] = useState(null);


  const changePassword = async () => {
    const functions = firebase.app().functions('asia-northeast1');
    const changePasswordCallable = functions.httpsCallable('changePassword');
    if (!password) {
      return;
    }

    try {
      setUpdating(true);
      await changePasswordCallable({ password });
      onChangeSuccess();


    } catch (e) {
      onChangeFail();
      console.error('failed to call changePassword: ', e);
    } finally {
      setUpdating(false);
    }
  }
  const onChangeSuccess = async () => {
    await setAlert(<ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px" }}
      onConfirm={async () => {
        setUpdating(false);
        hideAlert();
       // await firebase.logout();
      }
      }
      confirmBtnBsStyle="info"
      title="비밀번호 변경 완료"
    />
    );
  };
  const onChangeFail = () => {
    setAlert(<ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px" }}
      onConfirm={() => hideAlert()}
      confirmBtnBsStyle="info"
      title="비밀번호 변경 실패"
    />
    );
  };

  const inputAlert = () => {
    setAlert(<ReactBSAlert
      input
      showCancel
      style={{ display: "block", marginTop: "-100px" }}
      title={'탈퇴 사유 간단히 써주세요'}
      onConfirm={e => inputConfirmAlert(e)}
      onCancel={() => setAlert(null)}
      confirmBtnBsStyle="danger"
      cancelBtnBsStyle="info"
      confirmBtnText="제출"
      cancelBtnText="취소"
      disabled={updating}
    />);
  }

  const inputConfirmAlert = async (e) => {
    const functions = firebase.app().functions('asia-northeast1');
    const requestDeleteUserCallable = functions.httpsCallable('requestDeleteUser');
    if (!e) {
      alert('이유를 입력해주세요.')
      return;
    }

    setUpdating(true);
    try {
      const { data } = await requestDeleteUserCallable({
        reason: e,
        name: profile?.name,
        channel: profile?.channel,
        group: profile?.group,
      });
      console.log('httpscallble data = ', data);
      if (data.result === 'success') {
        onSuccess();
      } else {
        onFailure(data.message);
      }
    } catch (e) {
      console.error('failed to httpscall: ', e);
    } finally {
      setUpdating(false);
    }
  };

  const hideAlert = () => setAlert(null);
  const onSuccess = () => {
    setAlert(<ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px" }}
      onConfirm={() => hideAlert()}
      confirmBtnBsStyle="info"
      title="탈퇴 신청 완료"
    />
    );
  };

  const onFailure = (message) => {
    setAlert(<ReactBSAlert
      error
      style={{ display: "block", marginTop: "-100px" }}
      onConfirm={() => hideAlert()}
      confirmBtnBsStyle="info"
      title="탈퇴 요청 실패"
    >
      {message}
    </ReactBSAlert>
    );
  };

  return isLoaded(profile) && !isEmpty(profile) && (
    <>
      {alert}
      <div className="content">
        <Row>
          <Col md="4">
            <Card className="card-user">
              <CardBody>
                <h5 className="title text-center">{profile.name}</h5>
                <p className="text-muted text-center">{rolesString[profile.role - 1]}</p>
                <FormGroup>
                  <label>새 비밀번호 설정</label>
                  <Row className='align-items-center'>
                    <Col xs={8}>
                      <Input
                        onChange={(e) => {
                          const newPassword = e.target.value;
                          console.log(newPassword);
                          setPassword(newPassword);
                        }}
                      />
                    </Col>
                    <Col>
                      <Button block color='primary' onClick={changePassword}>{updating && <Spinner size='sm' />}변경</Button>
                    </Col>

                  </Row>
                </FormGroup>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="button-container">
                  <Row>
                    <Col>
                      <span>비밀번호가 변경되면 로그아웃되며,<br />새 비밀번호로 다시 로그인하셔야 합니다.</span>
                    </Col>
                  </Row>
                </div>
              </CardFooter>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">탈퇴 신청</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <Button block color='danger' onClick={inputAlert}>탈퇴 신청</Button>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="button-container">
                  <Row>
                    <Col>
                      <span>탈퇴 신청시, 관리자가 검토후 탈퇴 처리가 됩니다.</span>
                    </Col>
                  </Row>
                </div>
              </CardFooter>
            </Card>
          </Col>
          {/* <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">Edit Profile</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>Company (disabled)</label>
                        <Input
                          defaultValue="Creative Code Inc."
                          disabled
                          placeholder="Company"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-1" md="3">
                      <FormGroup>
                        <label>Username</label>
                        <Input
                          defaultValue="michael23"
                          placeholder="Username"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="4">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          Email address
                      </label>
                        <Input placeholder="Email" type="email" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>First Name</label>
                        <Input
                          defaultValue="Chet"
                          placeholder="Company"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="6">
                      <FormGroup>
                        <label>Last Name</label>
                        <Input
                          defaultValue="Faker"
                          placeholder="Last Name"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Address</label>
                        <Input
                          defaultValue="Melbourne, Australia"
                          placeholder="Home Address"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>City</label>
                        <Input
                          defaultValue="Melbourne"
                          placeholder="City"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-1" md="4">
                      <FormGroup>
                        <label>Country</label>
                        <Input
                          defaultValue="Australia"
                          placeholder="Country"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="4">
                      <FormGroup>
                        <label>Postal Code</label>
                        <Input placeholder="ZIP Code" type="number" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>About Me</label>
                        <Input
                          className="textarea"
                          type="textarea"
                          cols="80"
                          rows="4"
                          defaultValue="Oh so, your weak rhyme You doubt I'll bother,
                      reading into it"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col> */}
        </Row>
      </div>
    </>
  )
}
