import React, { useState, useEffect } from 'react'
import { useFirestoreConnect, useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import ConfirmableForm from 'views/forms/ConfirmableForm';

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  CardSubtitle,
  CardLink,
  CardText,
  ListGroup,
  ListGroupItem,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Badge,
  ModalHeader,
  Modal,
  ModalBody,
  Progress
} from "reactstrap";
import CourseForm from 'views/forms/CourseForm';


function CurriculumVersion({ location }) {
  const { version } = location?.state;

  const versionId = version?.version;

  const firestore = useFirestore();
  useFirestoreConnect(() => [
    {
      collection: `curriculumVersions/${versionId}/courses`,
      orderBy: [
        ['title']
      ]
    }
  ]);

  const courses = useSelector(state => state.firestore.ordered[`curriculumVersions/${versionId}/courses`]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [filteredCourses, setFilteredCourses] = useState(null);
  const [isSearching, setIsSearching] = useState(false);


  useEffect(() => {
    console.log('courses: ', courses);
    setFilteredCourses(courses);
    return () => {
      // cleanup
    }
  }, [courses]);


  return (
    <div className='content'>
      <Breadcrumb className='sticky-top'>
        <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
        <BreadcrumbItem><a href="./curriculum-versions">커리큘럼 버전 목록</a></BreadcrumbItem>
        <BreadcrumbItem active>{version?.version}</BreadcrumbItem>
      </Breadcrumb>
      
      <Jumbotron>
        <h1 className="display-5"><strong>커리큘럼 버전</strong></h1>
        <p className="lead">커리큘럼 버전 하위인 코스를 생성 및 관리합니다.</p>
        <hr className="my-2" />
        <p><strong>⚠️NOTE</strong> 각 코스는 하나 이상의 차시(lessons)으로 구성되어있습니다.</p>
      </Jumbotron>

      <Row>
        <Col>
          <Row className="align-items-center justify-content-between" style={{ width: '24rem' }}>
            <Col><h6>코스 목록 ({courses?.length || 0})</h6></Col>
            <Col xs={2}></Col>
            <Col>
              <Button color='primary' size='sm'
                onClick={() => setSelectedCourse({})}
              >새로 추가하기</Button>
            </Col>
          </Row>
          <InputGroup style={{ width: '20rem' }}>
            <Input type="text" placeholder="코스 이름으로 검색" onChange={(e) => {
              console.log(e.target.value);
              setFilteredCourses(courses.filter(course => course.title.indexOf(e.target.value) !== -1));
            }} />
            <InputGroupAddon addonType="append">
              <InputGroupText><i className="nc-icon nc-zoom-split"></i></InputGroupText>
            </InputGroupAddon>
          </InputGroup>

          <Row>
            {
              filteredCourses && filteredCourses.map(course => <Col key={course.id}><Card style={{ width: '20rem' }}>
                <CardBody onClick={() => setSelectedCourse(course)}>
                  <CardTitle>
                    <div style={{ flex: 1, justifyContent: 'space-between', alignItems: 'space-between' }}>
                      <Row className="align-items-center justify-content-between">
                        <Col>
                          {course.title}
                        </Col>
                        <Col xs={{ size: 'auto' }} className='justify-contents-flex-end'>
                          <Badge color='primary'>{course.courseType}</Badge>
                        </Col>
                      </Row>
                    </div>
                  </CardTitle>
                  <CardSubtitle>{course.courseDescription}</CardSubtitle>
                  <Row className="align-items-center">
                    <Col><Progress value={(course.lessonCount || 0) / course.lessons * 100} animated={false} /></Col>
                    <Col xs={{ size: 'auto' }}><span>{course.lessonCount || 0} / {course.lessons}</span></Col>
                  </Row>
                  <NavLink
                    to={{ pathname: `./course-home`, state: { curriculumVersion: version, course } }}
                  >코스 홈</NavLink>
                </CardBody>
              </Card>
              </Col>)
            }
          </Row>
        </Col>
        <Modal
          isOpen={Boolean(selectedCourse)}
          size={'lg'}
        >
          <ModalBody>
            <ConfirmableForm>
              <CourseForm
                version={version}
                course={selectedCourse}
                onCancel={() => setSelectedCourse(null)}
                afterSave={() => setSelectedCourse(null)}
                afterDelete={() => setSelectedCourse(null)}
              />
            </ConfirmableForm>
          </ModalBody>
        </Modal>
      </Row>
    </div >
  )
}

export default CurriculumVersion
