import React, { useState, useEffect } from 'react'
import { timerTrainingCodes } from 'variables/constants';

import _ from 'lodash';
import arrayMove from 'array-move';

import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
} from 'reactstrap';
import { timerTrainingCategories } from 'variables/constants';
import { useFirestore } from 'react-redux-firebase';


function TimerTrainingCodesForm({ version: _version, toggle, afterSave }) {
  console.log('_version = ', _version);
  const [version, setVersion] = useState(_version);
  const [codes, setCodes] = useState(_version?.timerTrainingCodes || timerTrainingCodes);
  const [errorMessage, setErrorMessage] = useState(null);
  const [onSaving, setOnSaving] = useState(false);

  const firestore = useFirestore();

  useEffect(() => {
    console.log('codes = ', codes);
    return () => {
      // cleanup
    }
  }, [codes])

  const onSave = async () => {
    console.log('press save');

    setOnSaving(true);

    try {
      const contentVersionRef = firestore.doc(`contentVersions/${version.id}`);
      const dataToUpdate = _.clone(version);
      delete dataToUpdate.createdAt;

      await contentVersionRef.update({
        ...dataToUpdate,
        timerTrainingCodes: codes,
        updatedAt: firestore.FieldValue.serverTimestamp(),
      });
    } catch (error) {
      console.error('Failed to save : ', error);
    }
    setOnSaving(false);
    afterSave && afterSave({
      ...version,
      timerTrainingCodes: codes,
    });
    toggle && toggle();
  }

  const onCancel = () => {
    toggle();
  }


  const onChange = (e, idx, name, typeFn) => {
    const value = e.target.value;
    const newCodes = codes.map((code, _idx) => idx === _idx ? { ...code, [name]: typeFn ? typeFn(value) : value } : code);
    setCodes(newCodes);
  }

  const addCode = (index) => {
    const newCodes = _.clone(codes);
    newCodes.splice(index + 1, 0, { id: '', title: '', category: '' });
    setCodes(newCodes);
  }

  const removeCode = (index) => {
    const newCodes = codes.filter((code, _idx) => index !== _idx);
    setCodes(newCodes);
  }

  const moveUp = (index) => {
    if (index === 0) {
      return;
    }
    const newCodes = arrayMove(codes, index, --index);
    setCodes(newCodes);
  }

  const moveDown = (index) => {
    if (index === codes.length - 1) {
      return;
    }
    const newCodes = arrayMove(codes, index, ++index);
    setCodes(newCodes);
  }

  return (
    <div className='content'>
      {
        codes.map((code, idx) => <Row key={idx} className='d-flex justify-content-center align-item-center'>
          <Col>
            <Row className="align-items-center justify-content-between">
              <Col><label>아이디</label></Col>
            </Row>
            <FormGroup>
              <Input
                value={code?.id || ''}
                type='number'
                placeholder="아이디"
                onChange={e => onChange(e, idx, 'id', t => Number(t))}
              />
            </FormGroup>
          </Col>
          <Col>
            <Row className="align-items-center justify-content-between">
              <Col><label>동작</label></Col>
            </Row>
            <FormGroup>
              <Input
                value={code?.title || ''}
                placeholder="제목"
                onChange={e => onChange(e, idx, 'title')} />
            </FormGroup>
          </Col>
          <Col>
            <Row className="align-items-center justify-content-between">
              <Col><label>카테고리</label></Col>
            </Row>
            <FormGroup>
              <Input
                type={'select'}
                value={code?.category || ''}
                onChange={e => onChange(e, idx, 'category')}>
                {/* <option></option> */}
                {
                  timerTrainingCategories.map((cat, idx) => <option key={idx} value={idx}>{cat}</option>)
                }
              </Input>
            </FormGroup>
          </Col>
          <Col>
            <Row className="align-items-center justify-content-between">
              <Col><label>초당칼로리</label></Col>
            </Row>
            <FormGroup>
              <Input
                type='number'
                value={code?.metValue || ''}
                placeholder=""
                onChange={e => onChange(e, idx, 'metValue')}
                onBlur={e => onChange(e, idx, 'metValue', t => Number(t))}
              />
            </FormGroup>
          </Col>
          <Col className='d-flex align-items-end'>
            <Button className="btn-icon btn-round" size="sm" color="primary" onClick={() => addCode(idx)}>
              <i className="fa fa-plus" />
            </Button>
            <Button className="btn-icon btn-round" size="sm" color="danger" onClick={() => removeCode(idx)}>
              <i className="fa fa-minus" />
            </Button>
            <Button className="btn-icon" size="sm" color="info" onClick={() => moveUp(idx)}>
              <i className="fa fa-arrow-up" />
            </Button>
            <Button className="btn-icon" size="sm" color="info" onClick={() => moveDown(idx)}>
              <i className="fa fa-arrow-down" />
            </Button>
          </Col>
        </Row>)

      }

      {
        errorMessage
          ? <div>
            <p className="text-danger">{errorMessage}</p>
          </div>

          : null
      }

      <Row>
        <Col className='text-right'>
          <Button color="primary" disabled={onSaving} onClick={onSave}>저장</Button>
          <Button color="warning" onClick={onCancel}>취소</Button>
        </Col>
      </Row>


    </div>
  )
}

export default TimerTrainingCodesForm
