import React, { useState, useEffect, useRef } from 'react'

import { useFirestoreConnect, useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";

import {
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap'
import LessonForm from 'views/forms/LessonForm';
import Board from 'views/components/Board';
import moment from 'moment';

function CourseHome({ location }) {
  const { curriculumVersion, course } = location?.state;

  const [contents, setContents] = useState(null);
  const [lessons, setLessons] = useState(null);

  const firestore = useFirestore();
  useFirestoreConnect(() => [
    {
      collection: `curriculumVersions/${curriculumVersion.id}/courses/${course.id}/lessons`,
      orderBy: ['no'],
      storeAs: 'lessons'
    },
    {
      collection: `contentVersions/${curriculumVersion.contentVersion}/contents`,
      orderBy: ['contentId'],
      storeAs: 'contents'
    },
    {
      collection: `contentVersions`,
      doc: curriculumVersion.contentVersion,
    }
  ]);

  const _lessons = useSelector(state => state.firestore.ordered['lessons']);
  console.log('lessons => ', _lessons);

  // contents object
  const _contents = useSelector(state => state.firestore.data['contents']);
  console.log('contents => ', _contents);

  // contents array
  const allContents = useSelector(state => state.firestore.ordered['contents']);

  const contentVersion = useSelector(
    ({ firestore: { data } }) => data.contentVersions && data.contentVersions[curriculumVersion.contentVersion]
  );

  const boardEl = useRef(null);

  const data = _lessons && _lessons.map(item => ({
    ...item,
    actions: (
      // we've added some custom button actions
      <div className="actions-left">
        {/* use this button to add a like kind of action */}
        {/* <Button
          onClick={() => {
            console.log('clicked');
            console.log(item);
            console.log(boardEl);
          }}
          color="info"
          size="sm"
          className="btn-icon btn-link like"
        >
          <i className="fa fa-heart" />
        </Button>{" "} */}
        {/* use this button to add a edit kind of action */}
        <Button
          onClick={() => {
            boardEl.current.edit(item);
          }}
          color="warning"
          size="sm"
          className="btn-icon btn-link edit"
        >
          <i className="fa fa-edit" style={{ fontSize: '1.5em' }} />
        </Button>{" "}
        {/* use this button to remove the data row */}
        <Button
          onClick={() => {
            console.log('clicked');
            boardEl.current.delete(() => {
              firestore.doc(`curriculumVersions/${curriculumVersion.id}/courses/${course.id}/lessons/${item.id}`).delete();
            });
          }}
          color="danger"
          size="sm"
          className="btn-icon btn-link remove"
        >
          <i className="fa fa-times" style={{ fontSize: '1.5em' }} />
        </Button>{" "}
      </div>
    )
  }))


  useEffect(() => {
    setLessons(_lessons);
    return () => {
      // cleanup
    }
  }, [_lessons]);

  useEffect(() => {
    setContents(_contents);
    return () => {
      // cleanup
    }
  }, [_contents])

  return (
    <div className='content'>
      <Breadcrumb className='sticky-top'>
        <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
        <BreadcrumbItem><a href="./curriculum-versions">커리큘럼 버전 목록</a></BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={{ pathname: `./curriculum-version`, state: { version: curriculumVersion } }}>
            {curriculumVersion?.version}
          </NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem active>{course?.title} 코스</BreadcrumbItem>
      </Breadcrumb>

      <Jumbotron>
        <h1 className="display-5"><strong>{course?.title} 코스</strong></h1>
        <p className="lead">코스에서 차시별 커리큘럼을 생성 및 관리합니다.</p>
        <hr className="my-2" />
        <p>각 코스 상세로 들어가서 차시를 등록할 수 있습니다.</p>
        <p className="lead">
          <Button color="primary"
            onClick={() => {
              boardEl.current.add();
            }}>새로운 차시 등록</Button>
        </p>
      </Jumbotron>
      {/* <Modal
        isOpen={Boolean(selectedLesson)}
        size={'lg'}
      >
        <ModalHeader toggle={() => setSelectedLesson(null)}>{selectedLesson?.id ? '차시 수정' : '차시 생성'}</ModalHeader>
        <ModalBody>
          <LessonForm
            curriculumVersion={curriculumVersion}
            course={course}
            allContents={allContents || []}
            lesson={selectedLesson || {}}
            onCancel={() => setSelectedLesson(null)}
            afterSave={() => setSelectedLesson(null)}
          />
        </ModalBody>
      </Modal> */}
      <Board
        modalTitle={'차시'}
        data={data}
        ref={boardEl}
        columns={[
          {
            accessor: 'no',
            Header: '차시',
            width: 70,
            filterMethod: (filter, row) => {
              console.log('filter = ', filter);
              const title = row[filter.id].props.children;
              console.log('row title = ', title);
              return title && title.indexOf(filter.value) != -1;
            }
          },
          {
            id: 'contents',
            Header: '콘텐츠',
            style: { 'whiteSpace': 'unset' },
            accessor: item => {
              console.log('item = ', item);
              const contentNames = item.contents?.map(content => contents ? contents[content.contentId]?.title : null).join(', ');
              return contentNames
            },
            filterMethod: (filter, row) => {
              console.log('filter = ', filter);
              const title = row[filter.id].props.children;
              console.log('row title = ', title);
              return title?.indexOf(filter.value) != -1;
            }
          },
          {
            Header: "메모",
            accessor: "memo"
          },
          {
            id: 'createdAt',
            Header: "등록일시",
            accessor: d => d.createdAt ? moment(d.createdAt.toDate()).format('YYYY-MM-DD HH:mm') : ''
          },
          {
            id: 'updatedAt',
            Header: "수정일시",
            accessor: d => d.updatedAt ? moment(d.updatedAt.toDate()).format('YYYY-MM-DD HH:mm') : ''
          },
          {
            Header: "명령",
            accessor: "actions",
            sortable: false,
            filterable: false
          }
        ]}
        hideAddNewButton={true}
      >
        <LessonForm
          curriculumVersion={curriculumVersion}
          course={course}
          allContents={allContents || []}
          contents={contents || {}}
          contentVersion={contentVersion}
        // onCancel={() => setSelectedLesson(null)}
        // afterSave={() => setSelectedLesson(null)}
        />

      </Board>
    </div>
  )
}

export default CourseHome
