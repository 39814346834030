/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { NavLink } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

import { withFirebase } from "react-redux-firebase";

import avatar from "assets/img/default-avatar.png";
import logo from "assets/img/logoM.png";
//import logoModoo from "assets/img/400x162_rev.png";
import logoModoo from "assets/img/logo_modoo.png";

import { connect, useSelector } from "react-redux";
import * as actions from "actions";
import { bindActionCreators } from "redux";
import { compose } from "redux";

import _ from "lodash";
import { rolesString } from "variables/constants";
import { useTranslation, withTranslation } from "react-i18next";
import i18n from "i18next";
import classnames from "classnames";
import { Button, Collapse, Nav, Container } from "reactstrap";
var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getCollapseStates(props.routes);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
    };
  }

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route ./regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <li
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {this.props.i18n.t(prop.name)}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {this.props.i18n.t(prop.name)}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li
          className={this.activeRoute(prop.layout + prop.path, prop.children)}
          key={key}
        >
          <NavLink to={prop.layout + prop.path} activeClassName="">
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>{this.props.i18n.t(prop.name)}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">
                  {this.props.i18n.t(prop.name)}
                </span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName, children) => {
    if (
      this.props.location.pathname.indexOf(routeName) > -1 ||
      _.some(
        children?.map(
          (child) => this.props.location.pathname.indexOf(child) > -1
        )
      )
    )
      return "active";
    else return "";
  };
  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentDidUpdate(e) {
    if (
      window.outerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  toggleSidebar = () => {
    console.log("click toggle sidebar");
    document.documentElement.classList.toggle("nav-open");
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen,
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };
  render() {
    // const { t, i18n } = useTranslation()
    // const changeLanguage = lng => {
    //   i18n.changeLanguage(lng);
    // };
    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}
      >
        <div className="logo">
          {/* <a href="/" className="simple-text logo-mini">
            <div className="logo-img">
              <img src={logo} alt="logoM" />
            </div>
          </a> */}
          <a href="/" className="simple-text logo-normal">
            <div className="msp title">
              {/* 모두의 스포츠 플랫폼 */}
              <img src={logoModoo} className="text-center p-2" />
            </div>
            {/* <img src={logoModoo} alt='모두의 스포츠 플랫폼' /> */}
          </a>
        </div>

        <div className="sidebar-wrapper" ref="sidebar">
          <div className="user">
            <div className="photo">
              <img
                src={this.props?.currentUser?.photoURL || avatar}
                alt="Avatar"
              />
            </div>
            <div className="info">
              <a
                // href="#"
                data-toggle="collapse"
                style={{ color: "#fff" }}
                aria-expanded={this.state.openAvatar}
                onClick={() =>
                  this.setState({ openAvatar: !this.state.openAvatar })
                }
              >
                <span>
                  <div>{this.props?.currentUser?.displayName || "관리자"}</div>
                  <div>
                    <small>
                      (
                      {this.props.i18n.t(
                        rolesString[this.props.profile?.role - 1]
                      )}
                      )
                    </small>
                  </div>
                  <b className="caret" />
                </span>
              </a>
              <Collapse isOpen={this.state.openAvatar}>
                <ul className="nav">
                  {/* <li>
                    <NavLink to="./user-profile" activeClassName="">
                      <span className="sidebar-mini-icon">MP</span>
                      <span className="sidebar-normal">내 프로필</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="./user-profile" activeClassName="">
                      <span className="sidebar-mini-icon">EP</span>
                      <span className="sidebar-normal">회원</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="./user-profile" activeClassName="">
                      <span className="sidebar-mini-icon">S</span>
                      <span className="sidebar-normal">Settings</span>
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink to="/auth/login" activeClassName="">
                      <span className="sidebar-mini-icon">LO</span>
                      <span className="sidebar-normal">
                        <div
                          onClick={async () =>
                            await this.props.firebase.logout()
                          }
                        >
                          {this.props.i18n.t("로그아웃")}
                        </div>
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </div>
          </div>
          <Nav>{this.createLinks(this.props.routes)}</Nav>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase,
  withTranslation()
)(Sidebar);
