/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import UserProfile from "views/pages/UserProfile.jsx";

import Dashboard from "views/Dashboard.jsx";

// insol created
import Users from "views/users/Users";
import ReportMember from "views/reports/ReportMember";
import ReportHome from "views/reports/ReportHome";
import Group from "views/groups/Group";
import Subgroup from "views/groups/Subgroup";
import Member from "views/groups/Member";
import ReportSubgroup from "views/reports/ReportSubgroup";
import ReportGroup from "views/reports/ReportGroup";
import Measurements from "views/groups/Measurements";
import CourseDetails from "views/groups/CourseDetails";
import PhysicalTests from "views/groups/PhysicalTests";
import CurriculumResults from "views/groups/CurriculumResults";
import i18n from "i18next";

const redirect = true;

let routes = [
  {
    path: "/group",
    name: i18n.t("가맹점 홈"),
    icon: "nc-icon nc-bank",
    component: Group,
    layout: "/group-admin",
  },
  {
    redirect: true,
    path: "/subgroup",
    name: "클래스",
    icon: "nc-icon nc-bank",
    component: Subgroup,
    layout: "/group-admin",
  },
  {
    redirect: true,
    path: "/member",
    name: "멤버",
    icon: "nc-icon nc-bank",
    component: Member,
    layout: "/group-admin",
  },
  {
    redirect: true,
    path: "/measurements",
    name: "신체측정",
    icon: "nc-icon nc-bank",
    component: Measurements,
    layout: "/group-admin",
  },
  {
    redirect: true,
    path: "/courseDetails",
    name: "수강내역",
    icon: "nc-icon nc-bank",
    component: CourseDetails,
    layout: "/group-admin",
  },
  {
    redirect: true,
    path: "/physicalTests",
    name: "체력장",
    icon: "nc-icon nc-bank",
    component: PhysicalTests,
    layout: "/group-admin",
  },
  {
    redirect: true,
    path: "/curriculumResults",
    name: "커리큘럼 수행결과",
    icon: "nc-icon nc-bank",
    component: CurriculumResults,
    layout: "/group-admin",
  },
  {
    redirect: false,
    path: "/users",
    name: i18n.t("사용자 관리"),
    icon: "nc-icon nc-circle-10",
    component: Users,
    layout: "/group-admin",
  },
  {
    redirect: false,
    path: "/report-home",
    name: i18n.t("리포트"),
    icon: "nc-icon nc-paper",
    component: ReportHome,
    layout: "/group-admin",
  },
  {
    redirect: true,
    path: "/report-member",
    name: "리포트",
    icon: "nc-icon nc-paper",
    component: ReportMember,
    layout: "/group-admin",
  },
  {
    redirect: true,
    path: "/report-subgroup",
    name: "리포트",
    icon: "nc-icon nc-paper",
    component: ReportSubgroup,
    layout: "/group-admin",
  },
  {
    redirect: true,
    path: "/report-group",
    name: "리포트",
    icon: "nc-icon nc-paper",
    component: ReportGroup,
    layout: "/group-admin",
  },
  {
    redirect: redirect,
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/group-admin",
  },
  {
    redirect: redirect,
    path: "/user-profile",
    name: "UserProfile",
    mini: "UP",
    component: UserProfile,
    layout: "/group-admin",
  },
];

export default routes;
