import React, { useState } from "react";
import PropTypes from "prop-types";

import ReactTable from "react-table";
import { useTranslation } from "react-i18next";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  ModalFooter,
} from "reactstrap";

import ReactBSAlert from "react-bootstrap-sweetalert";
import { forwardRef, useImperativeHandle } from "react";

const Board = forwardRef(
  (
    {
      title,
      modalTitle,
      data,
      columns,
      children,
      hideAddNewButton = false,
      ...remainProps
    },
    ref
  ) => {
    const [modal, setModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [alert, setAlert] = useState(null);
    const { t, i18n } = useTranslation();
    const translations = {
      previousText: t("이전"),
      nextText: t("다음"),
      loadingText: t("로딩중..."),
      noDataText: t("데이터가 비어져있습니다."),
      pageText: t("페이지"),
      ofText: t("/"),
      rowsText: t("행"),
    };
    useImperativeHandle(ref, () => ({
      edit: (item, callbackFn) => {
        setCurrentItem(item);
        setModal(true);
        // confirm(<div>수정되었습니다.</div>, callbackFn, true, null)
      },
      delete: (callbackFn) => {
        console.log("delete");
        confirm(
          <div>
            {t("삭제하면 복구할 수 없습니다.")}
            <br />
            {t("계속 진행하시겠습니까?")}
          </div>,
          callbackFn,
          null,
          true
        );
      },
      add: (item) => {
        setCurrentItem({});
        setModal(true);
      },
    }));

    const toggle = () => setModal(!modal);

    const closeConfirm = (callback) => {
      confirm(
        <div>
          {t("작성 중인 내용이 사라집니다.")}
          <br />
          {t("계속 진행하시겠습니까?")}
        </div>,
        () => setModal(false),
        false,
        true
      );
    };

    // const closeConfirm = () => {
    //   confirm('작성 중인 내용이 사라집니다. 계속 진행하시겠습니까?', () => setModal(false));
    // }

    const afterSave = () => {
      confirm(t("저장되었습니다!"), () => setModal(false), true);
    };

    const confirm = (
      message = t("확실합니까?"),
      callbackFn,
      isSuccess,
      isWarning
    ) => {
      basicAlert(
        message,
        () => {
          callbackFn();
          hideAlert();
        },
        () => hideAlert(),
        isSuccess,
        isWarning
      );
    };

    // alerts
    const basicAlert = (msg, onConfirm, onCancel, isSuccess, isWarning) => {
      setAlert(
        <ReactBSAlert
          warning={isWarning}
          success={isSuccess}
          style={{ display: "block", marginTop: "-100px" }}
          title={msg || "Here's a message!"}
          onConfirm={() => (onConfirm ? onConfirm() : hideAlert())}
          onCancel={() => (onCancel ? onCancel() : hideAlert())}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText={t("확인")}
          cancelBtnText={t("취소")}
          showCancel={!isSuccess}
        />
      );
    };

    const hideAlert = () => {
      setAlert(null);
    };

    return (
      <>
        {alert}
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">{title}</CardTitle>
                {hideAddNewButton ? null : (
                  <Button
                    color="primary"
                    onClick={() => {
                      setCurrentItem(null);
                      setModal(true);
                    }}
                  >
                    {t("새로 추가하기")}
                  </Button>
                )}
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={data}
                  filterable
                  columns={columns}
                  defaultPageSize={10}
                  showPaginationTop={false}
                  showPaginationBottom={true}
                  className="-striped -highlight primary-pagination actions-center"
                  {...translations}
                  {...remainProps}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={modal}
          // toggle={closeConfirm}
          size={"lg"}
        >
          <ModalHeader>
            {modalTitle ? `${modalTitle} ` : `${title} ${t("세부정보")} `}
            {currentItem ? t("수정") : t("신규")}
          </ModalHeader>
          <ModalBody>
            {React.Children.map(children, (child) =>
              React.cloneElement(child, {
                closeConfirm,
                afterSave,
                toggle,
                currentItem,
              })
            )}
          </ModalBody>
          {/* <ModalFooter>
        </ModalFooter> */}
        </Modal>
      </>
    );
  }
);

Board.propTypes = {
  title: PropTypes.string,
  modalTitle: PropTypes.string,
  data: PropTypes.array,
  columns: PropTypes.array,
  children: PropTypes.element,
  hideAddNewButton: PropTypes.bool,
};

export default Board;
