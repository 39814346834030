import React, { useRef, useState } from 'react'
import readXlsxFile from 'read-excel-file'
import {
  Input,
  Button,
  Container,
  Row,
  Col,
  Spinner,
} from 'reactstrap'
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { newMembersSchema } from 'variables/schema';
import _ from 'lodash';

export default function AddSubgroupMembers({ channel, group, subgroup, closeModal }) {

  console.log('channel', channel);
  console.log('group', group);
  console.log('subgroup', subgroup)
  console.log('closeModal', closeModal);


  const [file, setFile] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [members, setMembers] = useState();
  const [saving, setSaving] = useState(false);
  const fileInputRef = useRef();
  const firestore = useFirestore();

  const handleFileChange = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (!file) {
      setIsLoaded(false);
      setFile(null);
      setMembers(null);
      setSaving(false);
      return;
    }
    if (/['xls'|'xlsx']$/.test(file.name)) {
      console.log('합당한 파일임');
    } else {
      console.log('합당한 파일이 아님');
      alert('엑셀 파일이 아닙니다. 파일을 점검해주세요.');
      return;
    }
    console.log('file = ', file);

    setFile(file);

    try {
      const { rows, error } = await readXlsxFile(file, {
        schema: newMembersSchema[0],
        sheet: '클래스 및 멤버 입력 폼'
      });
      console.log('rows ', rows);
      console.log('error = ', error);
      if (error) {
        alert('멤버 데이터를 가져오는데 문제가 생겼습니다. 엑셀 파일을 점검해주세요.', error);
        return;
      }

      const members = rows.map(row => {
        const { studentNum, name, age, gender, startAt, endAt } = row;

        return {
          studentNum,
          name,
          age,
          gender,
          startAt,
          endAt,
          classMemberNum: studentNum,
        }
      })
      setMembers(members);

      setIsLoaded(true);

    } catch (error) {
      console.error(error);
      alert('파일에 문제가 있습니다');
      return;
    }
  }

  const onSave = async () => {
    if (!isLoaded) {
      alert('파일을 선택해주세요');
      return;
    }

    setSaving(true);

    // 해당 서브그룹(클래스)의 학생들을 생성한다.
    await Promise.all(members.map(member => {
      const memberRef = firestore.collection(`channels/${channel.id}/groups/${group.id}/members`).doc();
      const subgroupRef = firestore.collection(`channels/${channel.id}/groups/${group.id}/subgroups`).doc(subgroup.id);
      const batch = firestore.batch();
      batch.set(memberRef, {
        id: memberRef.id,
        status: 1,
        subgroupIds: [subgroup.id],
        subgroups: [{
          subgroupId: subgroup.id,
          subgroupTitle: subgroup.title,
          classMemberNum: member.studentNum,
        }],
        ...member,
        createdAt: firestore.FieldValue.serverTimestamp(),
        updatedAt: firestore.FieldValue.serverTimestamp(),
      });
      batch.update(subgroupRef, {
        members: firestore.FieldValue.arrayUnion(memberRef.id)
      });
      return batch.commit();
    }))

    setSaving(false);
    closeModal();
  }

  return (
    <Container>
      <Row className='m-1 p-5 border-bottom'>
        <Col><span>엑셀파일로 학생 생성하기</span></Col>
        <Col><Input type="file" onChange={handleFileChange} ref={fileInputRef} /></Col>
      </Row>
      {
        members
          ? <Row className='m-3'>
            <Col className='text-muted text-center'>
              <li>학생: {members.length} 명</li>
              <p>을 생성하려고 합니다.</p>
            </Col>
          </Row>

          : <Row className='m-3'>
            <Col><a href='https://firebasestorage.googleapis.com/v0/b/msp-production.appspot.com/o/assets%2F%E1%84%80%E1%85%B3%E1%84%85%E1%85%AE%E1%86%B8%E1%84%8E%E1%85%AE%E1%84%80%E1%85%A1%E1%84%90%E1%85%A6%E1%86%B7%E1%84%91%E1%85%B3%E1%86%AF%E1%84%85%E1%85%B5%E1%86%BA(subgroup).xlsx?alt=media&token=98add331-fb24-4c2a-9c37-a6e7d5d2420a'>
              예시 파일 다운받기</a></Col>
          </Row>
      }
      <Row className='d-flex justify-content-between m-1'>
        <Button color='secondary' onClick={() => closeModal()}>취소</Button>
        <Button color='primary' disabled={!Boolean(isLoaded) && !Boolean(saving)} onClick={onSave}> {saving && <Spinner className='mr-3' size="sm" />}생성시작</Button>
      </Row>
    </Container>
  )

};




