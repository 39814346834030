import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useFirestoreConnect } from "react-redux-firebase";
import _, { isEmpty } from "lodash";
import ReactBSAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import ReactDatetime from "react-datetime";
import { activeStatusString, activeStatus } from "variables/constants";
import { useTranslation } from "react-i18next";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Progress,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

import { newGroupSchema } from "../../variables/schema";
import readXlsxFile from "read-excel-file";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { asyncForEach } from "helpers/async";
import { koreanAge } from "helpers/datetime";
import GroupPicker from "views/components/GroupPicker";

const initUser = {
  role: "",
  name: "",
  email: "",
  password: "",
  channel: null,
  group: null,
  subgroup: null,
  member: null,
};

function MemberForm(props) {
  const { channel } = props;
  const [subgroup, setSubgroup] = useState(props.subgroup || null);
  const [group, setGroup] = useState(props.group || null);
  const [member, setMember] = useState(props.member || null);
  const [classMemberNum, setClassMemberNum] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModified, setIsModified] = useState(false);
  const [onSaving, setOnSaving] = useState(false);
  const [alert, setAlert] = useState(null);
  const { t, i18n } = useTranslation();

  const firebase = useFirebase();
  const firestore = useFirestore();

  const getClassMemberNumFromSubgroup = async (subgroupId) => {
    if (props.member?.subgroupIds?.includes(subgroupId)) {
      const subgroup = _.findLast(props.member.subgroups, { subgroupId });
      return subgroup.classMemberNum;
    } else {
      const classMemberColsnap = await firestore
        .collection(`channels/${channel.id}/groups/${group.id}/members`)
        .where("subgroupIds", "array-contains", subgroupId)
        .get();
      console.log("classMemberColsnap = ", classMemberColsnap);
      const classMembers = classMemberColsnap.docs.map((doc) =>
        _.findLast(doc.get("subgroups"), { subgroupId: subgroupId })
      );
      const orderedMembers = _.orderBy(
        classMembers,
        "classMemberNum",
        "desc"
      ).filter((member) => member);
      console.log("orderedMembers = ", orderedMembers);
      const _classMemberNum =
        orderedMembers.length > 0 ? orderedMembers[0].classMemberNum + 1 : 1;
      console.log("new class member num = ", _classMemberNum);
      return _classMemberNum;
    }
  };

  const getStudentNumber = async () => {
    if (props.member?.studentNum) {
      return props.member.studentNum;
    } else {
      const memberColsnap = await firestore
        .collection(`channels/${channel.id}/groups/${group.id}/members`)
        .orderBy("studentNum", "desc")
        .limit(1)
        .get();
      const studentNum =
        memberColsnap.size > 0
          ? memberColsnap.docs[0].get("studentNum") + 1
          : 1;
      return studentNum;
    }
  };

  const setMemberWithStudentNumber = async (newSubgroup) => {
    const studentNum = await getStudentNumber();
    const classMemberNum = await getClassMemberNumFromSubgroup(newSubgroup.id);

    setClassMemberNum(classMemberNum);

    setMember({
      ...member,
      subgroupIds: [newSubgroup.id],
      subgroups: [
        {
          classMemberNum,
          subgroupId: newSubgroup.id,
          subgroupTitle: newSubgroup.title,
        },
      ],
      studentNum,
      classMemberNum,
    });
    return;
  };

  useEffect(() => {
    if (props.member?.id) {
      const _member = _.clone(props.member);
      delete _member.id;
      setMember(_member);
    } else {
      setMemberWithStudentNumber(props.subgroup);
    }
    return () => {
      //clean up
    };
  }, []);

  useEffect(() => {
    const currentMember = _.cloneDeep(props.member);
    // if (currentMember === null){
    //   return
    // }
    if (currentMember?.id) {
      delete currentMember.id;
    }
    const modified = !_.isEqual(currentMember, member);
    console.log("modified: ", modified);
    setIsModified(modified);
    return () => {
      // cleanup
    };
  }, [member]);

  const onSave = async () => {
    //memebers 내에 있는 subgroupIds, subgroupTitle, subgroups 변경
    //새로운 subgroupsId에 맞게 members Id 값 추가
    //기존 subgroupsId에 members에 있는 member Id 삭제

    console.log("press save");

    setOnSaving(true);

    const timestamp = {
      updatedAt: firestore.FieldValue.serverTimestamp(),
    };

    const nPhysicalFitnessTests = props.member?.nPhysicalFitnessTests;
    const nMeasurements = props.member?.nMeasurements;

    const status = member?.status ? member?.status : activeStatus.normal;
    console.log(`@@@@@status`, status);

    if (!props.member?.id) {
      timestamp["createdAt"] = firestore.FieldValue.serverTimestamp();
      nPhysicalFitnessTests = 0;
      nMeasurements = 0;
    }

    try {
      console.log(`###props.group.id`, props.group.id);
      console.log(`###group.id`, group.id);

      // update member.subgroups and member.subgroupIds,
      let memberRef = firestore.collection(
        `channels/${channel.id}/groups/${props.group.id}/members`
      );
      if (props.member?.id) {
        memberRef = memberRef.doc(props.member?.id);
      } else {
        memberRef = memberRef.doc();
      }

      console.log("member = ", member);
      await memberRef.set({
        ...member,
        ...timestamp,
        status,
      });

      if (props.subgroup.id !== subgroup.id) {
        // remove member id from orignal subgroup
        const subgroupRef = firestore.doc(
          `channels/${channel.id}/groups/${props.group.id}/subgroups/${props.subgroup.id}`
        );
        const members = await subgroupRef.get("members");
        const newMembers = members.filter(
          (member) => member.id !== props.member.id
        );
        await subgroupRef.update({
          members: newMembers,
        });
      }

      // add member id to new subgroup
      const destSubgroupRef = firestore.doc(
        `channels/${channel.id}/groups/${props.group.id}/subgroups/${subgroup.id}`
      );
      await destSubgroupRef.update({
        members: firestore.FieldValue.arrayUnion(memberRef.id),
      });
    } catch (error) {
      console.error("Failed to save : ", error);
    }
    setOnSaving(false);
    props.afterSave && props.afterSave();
  };

  const onDelete = async () => {
    const memberRef = firestore
      .collection(`channels/${channel.id}/groups/${props.group.id}/members`)
      .doc(props.member.id);
    await memberRef.delete();
    const subgroup = firestore.doc(
      `channels/${channel.id}/groups/${props.group.id}/subgroups/${props.subgroup.id}`
    );
    await subgroup.update({
      members: firestore.FieldValue.arrayRemove(memberRef.id),
    });
    props.afterDelete && props.afterDelete();
  };

  const onCancel = () => {
    console.log("onCancel!");
    if (isModified) {
      props.closeConfirm(() => {
        props.onCancel();
        props.toggle();
      });
    } else {
      props.onCancel && props.onCancel();
      props.toggle();
    }
  };

  const warningWithConfirmAndCancelMessage = () =>
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={
          <div>
            {t("삭제하면 복구할 수 없습니다.")}
            <br />
            {t("계속 진행하시겠습니까?")}
          </div>
        }
        onConfirm={() => {
          onDelete();
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText={t("확인")}
        cancelBtnText={t("취소")}
        showCancel
      >
        {t("지우면 완전히 삭제됩니다. 지우시겠습니까?")}
      </ReactBSAlert>
    );

  const onChange = (e, name, typeFn) => {
    const value = e.target.value;
    console.log("value: ", value);
    if (name === "birthday") {
      setMember({
        ...member,
        [name]: typeFn ? typeFn(value) : value,
        age: koreanAge(value),
      });
    } else if (name === "classMemberNum") {
      console.log("member.subgroups", member.subgroups);
      console.log("value = ", value);
      const newSubgroups =
        member.subgroups &&
        _.clone(member.subgroups).map((subgroup) => {
          console.log("props.subgroup.id = ", props.subgroup.id);
          console.log("subgroup.subgroupId = ", subgroup.subgroupId);
          return subgroup.subgroupId === props.subgroup.id
            ? { ...subgroup, classMemberNum: typeFn(value) }
            : subgroup;
        });
      console.log("newSubgroups", newSubgroups);
      setMember({ ...member, subgroups: newSubgroups });
    } else {
      setMember({ ...member, [name]: typeFn ? typeFn(value) : value });
    }
  };

  const onSelected = (selected) => {
    if (!selected.subgroup) {
      //todo : show alert
      return;
    }

    console.log("selected: ", selected);
    console.log("member: ", selected.original?.member);
    if (selected.subgroup.id !== subgroup.id) {
      setMemberWithStudentNumber(selected.subgroup);
    }
    setSubgroup(selected.subgroup);
    console.log(subgroup);
  };

  return (
    <div className="content">
      {alert}
      {/* title */}
      <Row>
        <Col>
          <div className="text-right">
            <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
              *
            </span>{" "}
            {t("필수 입력 사항입니다")}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <GroupPicker
            channel={channel}
            group={group}
            subgroup={subgroup}
            member={member}
            cbSelected={onSelected}
            viewMode={["subgroup"]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>{t("번호")}</label>
              <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                *
              </span>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={
                _.findLast(member?.subgroups, { subgroupId: subgroup.id })
                  ?.classMemberNum || ""
              }
              placeholder={t("번호")}
              type="number"
              onChange={(e) => onChange(e, "classMemberNum", (t) => Number(t))}
            />
          </FormGroup>
        </Col>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>{t("이름 / ID")}</label>
              <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                *
              </span>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={member?.name || ""}
              placeholder={t("회원 이름")}
              onChange={(e) => onChange(e, "name")}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>{t("생년월일")}</label>
            </Col>
          </Row>
          <FormGroup>
            <Input
              type={"date"}
              value={member?.birthday || ""}
              placeholder="예)2002-02-28"
              max={"9999-12-31"}
              onChange={(e) => onChange(e, "birthday")}
            />
          </FormGroup>
        </Col>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>{t("나이")}</label>
            </Col>
          </Row>
          <FormGroup>
            <Input
              type={"number"}
              disabled={member?.birthday}
              value={member?.age || ""}
              placeholder={t("나이")}
              onChange={(e) => onChange(e, "age", (txt) => Number(txt))}
            />
          </FormGroup>
        </Col>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>{t("성별")}</label>
            </Col>
          </Row>
          <FormGroup>
            <Input
              type={"select"}
              value={member?.gender || ""}
              placeholder="예)2002-02-28"
              onChange={(e) => onChange(e, "gender")}
            >
              <option></option>
              <option>{t("남")}</option>
              <option>{t("여")}</option>
            </Input>
          </FormGroup>
        </Col>
      </Row>
      {errorMessage ? (
        <div>
          <p className="text-danger">{errorMessage}</p>
        </div>
      ) : null}
      <Row>
        <Col xs={4}>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>{t("상태")}</label>
            </Col>
          </Row>
          <FormGroup row>
            {activeStatusString.map((status, index) => (
              <FormGroup key={index} check>
                <Label check>
                  <Input
                    type="radio"
                    name="status"
                    defaultChecked={
                      member?.status
                        ? index + 1 === member?.status
                        : index === 0
                    }
                    value={index + 1}
                    onClick={(e) => onChange(e, "status", (t) => Number(t))}
                  />{" "}
                  {t(status)}
                </Label>
              </FormGroup>
            ))}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>{t("수강시작일")}</label>
            </Col>
          </Row>
          <FormGroup>
            <ReactDatetime
              inputProps={{
                className: "form-control",
                placeholder: t("수강시작일"),
              }}
              value={
                member?.startAt
                  ? member.startAt.toDate
                    ? moment(member.startAt.toDate()).format(
                        "YYYY-MM-DD a hh:mm:ss"
                      )
                    : moment(member.startAt).format("YYYY-MM-DD a hh:mm:ss")
                  : ""
              }
              locale={"ko"}
              dateFormat={"YYYY-MM-DD"}
              timeFormat={"A hh:mm:ss"}
              onChange={(date) => {
                setMember({ ...member, startAt: date.toDate() });
              }}
            />
          </FormGroup>
        </Col>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>{t("수강종료일")}</label>
            </Col>
          </Row>
          <FormGroup>
            <ReactDatetime
              inputProps={{
                className: "form-control",
                placeholder: t("수강종료일"),
              }}
              value={
                member?.endAt
                  ? member.endAt.toDate
                    ? moment(member.endAt.toDate()).format(
                        "YYYY-MM-DD a hh:mm:ss"
                      )
                    : moment(member.endAt).format("YYYY-MM-DD a hh:mm:ss")
                  : ""
              }
              locale={t("ko")}
              dateFormat={"YYYY-MM-DD"}
              timeFormat={"A hh:mm:ss"}
              onChange={(date) => {
                setMember({ ...member, endAt: date.toDate() });
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      {member?.createdAt && (
        <Row>
          <Col>
            <Row className="align-items-center justify-content-between">
              <Col>
                <label>{t("수강 등록일시")}</label>
              </Col>
            </Row>
            <FormGroup>
              <Input
                disabled
                value={
                  moment(member?.createdAt.toDate()).format(
                    "YYYY-MM-DD HH:mm"
                  ) || ""
                }
              />
            </FormGroup>
          </Col>
          <Col></Col>
        </Row>
      )}
      <Row>
        <Col className="text-right">
          <Button
            color="primary"
            disabled={
              onSaving ||
              !Boolean(
                member?.classMemberNum &&
                  member?.name &&
                  member?.name.length > 1
              ) ||
              !isModified
            }
            onClick={onSave}
          >
            {t("저장")}
          </Button>
          {/* <Button color="primary" disabled={onSaving || !Boolean(member?.classMemberNum && member?.name && member?.name.length > 1) } onClick={onSave}>저장</Button> */}
          <Button color="warning" onClick={onCancel}>
            {t("취소")}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

MemberForm.propTypes = {
  subgroup: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  member: PropTypes.object,
  afterSave: PropTypes.func,
  afterDelete: PropTypes.func,
  onCancel: PropTypes.func,
};

export default MemberForm;
