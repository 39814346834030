import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useFirestoreConnect } from "react-redux-firebase";
import _ from "lodash";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { useTranslation } from "react-i18next";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Progress,
  Row,
  Col,
  Spinner,
  Button,
  ButtonGroup,
  UncontrolledTooltip,
} from "reactstrap";

import { newGroupSchema } from "../../variables/schema";
import readXlsxFile from "read-excel-file";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { asyncForEach } from "helpers/async";
import CoursePicker from "views/components/CoursePicker";
import DayAndTimePicker from "components/Pickers/DayAndTimePicker";
import { activeStatus } from "variables/constants";
import { nanoid } from "nanoid";
import { activeStatusString } from "variables/constants";

function SubgroupForm(props) {
  const [channel, setChannel] = useState(props.channel || null);
  const [subgroup, setSubgroup] = useState(props.subgroup || null);
  const [group, setGroup] = useState(props.group || null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [onSaving, setOnSaving] = useState(false);
  const [alert, setAlert] = useState(null);
  const [isModified, setIsModified] = useState(false);
  const { t, i18n } = useTranslation();

  const firebase = useFirebase();
  const firestore = useFirestore();

  useEffect(() => {
    if (!channel) {
      firestore
        .doc(`channels/${props.channel.id}`)
        .get()
        .then((docsnap) => {
          const _channel = docsnap.data();
          setChannel(_channel);
        });
    }
    return () => {
      // cleanup
    };
  }, []);

  // useEffect(() => {
  //   if (subgroup !== null) {
  //     console.log('subgroup in check')
  //     checkValidity();
  //   }
  //   console.log('can save with onSaving? in subgroupForm = ', onSaving);
  //   console.log('can save with isModified in subgroupForm = ', !isModified);
  //   console.log('errorMessage in subgroupForm = ', errorMessage);

  //   return () => {
  //     // cleanup
  //   }
  // }, [subgroup])

  useEffect(() => {
    if (props.subgroup) {
      const _subgroup = _.clone(props.subgroup);
      delete _subgroup.id;
      setSubgroup(_subgroup);
    }
    return () => {
      // cleanup
    };
  }, [props.subgroup]);

  useEffect(() => {
    console.log("subgroup title1 =>", subgroup?.title1);
    console.log("subgroup title2 =>", subgroup?.title2);
    if (subgroup?.title1 || subgroup?.title2) {
      setSubgroup({
        ...subgroup,
        title: [subgroup?.title1, subgroup?.title2].filter((x) => x).join(" "),
      });
    }
    return () => {
      // cleanup
    };
  }, [subgroup?.title1, subgroup?.title2]);

  useEffect(() => {
    if (subgroup) {
      console.log("subgroup before checkvalidity =>", subgroup);
      checkValidity();
    }
    const currentSubgroup = _.cloneDeep(props.subgroup);
    console.log(`currentSubgroup`, currentSubgroup);
    console.log("subgroup in new form = ", subgroup);
    // console.log('object keys subgroup =>', Object.keys(subgroup).length);
    // console.log('title1 subgroup =>', subgroup.title);
    if (currentSubgroup == null && subgroup === null) {
      return;
    }

    const modified = !_.isEqual(currentSubgroup, subgroup);

    console.log("modified in subgroupForm = ", modified);

    setIsModified(modified);
    console.log("isModified in subgroupForm = ", isModified);

    return () => {
      // cleanup
    };
  }, [subgroup]);
  //check validity

  const checkValidity = () => {
    console.log("checkValidity => ", subgroup);
    const {
      title1,
      title2,
      course,
      status,
      startAt,
      endAt,
      dayAndTimes,
      classroom,
    } = subgroup;
    //민호 - 순서와 상관없이 attribute 이름이 같으면 저장됨.
    if (!title1) {
      setErrorMessage("학년을 입력해주세요.");
      console.log("check error message =>", errorMessage);
      return false;
    }
    if (!title2) {
      setErrorMessage("반명/시간을 입력해주세요.");
      return false;
    }
    // if (!course) {
    //   setErrorMessage('설명을 3글자 이상 입력해주세요.');
    //   return false;
    // }

    // if (!status) {
    //   setErrorMessage('콘텐츠 타입을 설정해주세요.');
    //   return false;
    // }

    if (!startAt) {
      setErrorMessage("시작일 선택해주세요.");
      return false;
    }
    if (!endAt) {
      setErrorMessage("종료일을 선택해주세요");
      return false;
    }
    if (!dayAndTimes || dayAndTimes.length === 0) {
      setErrorMessage("요일 및 날짜를 선택해주세요");
      return false;
    }
    // if(dayAndTimes?.length>0){
    //   console.log('날짜 데이터가 있습니다');
    //   console.log(dayAndTimes);
    //   if(!(dayAndTimes.start&&dayAndTimes.end)){
    //     setErrorMessage("날짜를 선택해주세요");
    //     return false;
    //   }
    // }
    // if (!classroom) {
    //   setErrorMessage('수업 교실을 선택해주세요');
    //   return false;
    // }
    console.log("check error message =>", errorMessage);
    setErrorMessage(null); // no error
    return true;
  };

  const generateSchedules = () => {
    const { title, startAt, endAt, classroom, dayAndTimes } = subgroup;
    // find days
    let start = startAt?.toDate ? moment(startAt?.toDate()) : moment(startAt);
    let end = endAt?.toDate ? moment(endAt?.toDate()) : moment(endAt);

    const schedules = [];

    console.log("start = ", start);
    console.log("end = ", end);

    if (start > end) {
      throw Error("시작 시간이 종료 시간보다 이전이어야 합니다.");
    }

    while (start < end) {
      console.log("dayAndTimes: ", dayAndTimes);
      console.log("subgroup ", subgroup);
      dayAndTimes.forEach((dayAndTime) => {
        if (start.day() === dayAndTime.dayOfWeek) {
          const newSchedule = {
            id: `id${nanoid(6)}`,
            title,
            // classroomId: classroom,
            start: null,
            end: null,
          };

          const startComponent = dayAndTime.start.split(":");
          start.hour(startComponent[0]);
          start.minute(startComponent[1]);
          newSchedule.start = start.toDate();

          const endComponent = dayAndTime.end.split(":");
          start.hour(endComponent[0]);
          start.minute(endComponent[1]);
          newSchedule.end = start.toDate();

          console.log("let us add a schedule: ", newSchedule);
          schedules.push(newSchedule);
        }
      });
      start = start.add(1, "day");
    }
    return schedules;
  };

  const onSave = async () => {
    console.log("press save");
    setOnSaving(true);
    const timestamp = {
      updatedAt: firestore.FieldValue.serverTimestamp(),
    };

    const nMembers =
      props.subgroup?.nMembers || props.subgroup?.members?.length;
    const completedContents = props.subgroup?.completedContents || 0;

    const status = props.subgroup?.status
      ? props.subgroup?.status
      : activeStatus.normal;
    const classroom = props.subgroup?.classroom
      ? props.subgroup?.classroom
      : "1";

    if (!props.subgroup?.id) {
      timestamp["createdAt"] = firestore.FieldValue.serverTimestamp();
      //completedContents = 0;
      // status = group.status;
    }

    try {
      let subgroupRef = firestore.collection(
        `channels/${props.channel.id}/groups/${props.group.id}/subgroups`
      );
      if (props.subgroup?.id) {
        subgroupRef = subgroupRef.doc(props.subgroup.id);
      } else {
        subgroupRef = subgroupRef.doc();
      }

      const schedules = generateSchedules();
      console.log("schedules data  =>", schedules);
      console.log("before upload firestore =>", subgroup);
      await subgroupRef.set({
        members: [],
        completedContents: completedContents,
        status,
        classroom,
        ...subgroup,
        ...timestamp,
        course: subgroup.course
          ? { id: subgroup.course.id, title: subgroup.course.title }
          : null,
        schedules: schedules,
      });
    } catch (error) {
      console.error("Failed to save : ", error);
    }
    setOnSaving(false);
    props.afterSave && props.afterSave(() => props.onCancel());
  };

  const onDelete = async () => {
    const subgroupRef = firestore
      .collection(`channels/${channel.id}/groups/${props.group.id}/subgroups`)
      .doc(props.subgroup.id);
    await subgroupRef.delete();
    props.afterDelete && props.afterDelete();
  };

  const onCancel = () => {
    console.log("onCancel!");
    if (isModified) {
      props.closeConfirm(() => props.onCancel());
    } else {
      props.onCancel && props.onCancel();
      props.toggle();
    }
  };

  const warningWithConfirmAndCancelMessage = () =>
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={
          <div>
            삭제하면 복구할 수 없습니다.
            <br />
            계속 진행하시겠습니까?
          </div>
        }
        onConfirm={() => {
          onDelete();
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText={t("확인")}
        cancelBtnText={t("취소")}
        showCancel
      >
        지우면 완전히 삭제됩니다. 지우시겠습니까?
      </ReactBSAlert>
    );

  const onChange = (e, name, typeFn) => {
    const value = e.target.value;
    console.log("value = ", value);
    setSubgroup({ ...subgroup, [name]: typeFn ? typeFn(value) : value });
  };

  return (
    <div className="content">
      {alert}
      {/* title */}
      <Row>
        <Col>
          <div className="text-right">
            <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
              *
            </span>{" "}
            {t("필수 입력 사항입니다")}
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>{t("학년/과목")}</label>
              <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                *
              </span>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={subgroup?.title1 || ""}
              placeholder={t("1학년 혹은 6세/축구")}
              onChange={(e) => onChange(e, "title1")}
            />
          </FormGroup>
        </Col>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>{t("반명/시간")}</label>
              <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                *
              </span>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={subgroup?.title2 || ""}
              placeholder={t("1반 혹은 고급반/월요일 3시반")}
              onChange={(e) => onChange(e, "title2")}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row></Row>
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>{t("표시되는 반 이름")}</label>
              <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                *
              </span>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={subgroup?.title || ""}
              placeholder={t(
                "화면에 보이는 반 이름 예) 4학년 2반 또는 개나리반"
              )}
              readOnly
              onChange={(e) => onChange(e, "title")}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <CoursePicker
            curriculumVersion={channel?.curriculumVersion}
            cbSelected={(selected) => {
              setSubgroup({ ...subgroup, ...selected });
            }}
            course={subgroup?.course}
            checkDisableId={group?.groupCategory?.channel?.id}
          />
        </Col>
        <Col xs={6}>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>{t("상태")}</label>
              <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                *
              </span>
            </Col>
          </Row>
          <FormGroup row>
            {activeStatusString.map((status, index) => (
              <FormGroup key={index} check>
                <Label check>
                  <Input
                    type="radio"
                    name="status"
                    defaultChecked={
                      subgroup?.status
                        ? index + 1 === subgroup?.status
                        : index === 0
                    }
                    value={index + 1}
                    onClick={(e) => onChange(e, "status", (t) => Number(t))}
                  />{" "}
                  {t(status)}
                </Label>
              </FormGroup>
            ))}
          </FormGroup>
        </Col>
      </Row>
      {/* {
        errorMessage
          ? <div>
            <p className="text-danger">{errorMessage}</p>
          </div>

          : null
      } */}
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>{t("시작일")}</label>
              <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                *
              </span>
            </Col>
          </Row>
          <FormGroup>
            <ReactDatetime
              inputProps={{
                className: "form-control",
                placeholder: t("클래스 시작일"),
              }}
              value={
                subgroup?.startAt
                  ? subgroup.startAt.toDate
                    ? moment(subgroup.startAt.toDate()).format("YYYY-MM-DD")
                    : moment(subgroup.startAt).format("YYYY-MM-DD")
                  : ""
              }
              locale={"ko"}
              dateFormat={"YYYY-MM-DD"}
              timeFormat={"A hh:mm:ss"}
              onChange={(date) => {
                let newDate = date;
                if (typeof date === "string") {
                  newDate = moment(date);
                }

                if (newDate.isValid()) {
                  setSubgroup({ ...subgroup, startAt: newDate.toDate() });
                }
              }}
            />
          </FormGroup>
        </Col>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>{t("종료일")}</label>
              <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                *
              </span>
            </Col>
          </Row>
          <FormGroup>
            <ReactDatetime
              inputProps={{
                className: "form-control",
                placeholder: t("클래스 종료일"),
              }}
              value={
                subgroup?.endAt
                  ? subgroup.endAt.toDate
                    ? moment(subgroup.endAt.toDate()).format("YYYY-MM-DD")
                    : moment(subgroup.endAt).format("YYYY-MM-DD")
                  : ""
              }
              locale={"ko"}
              dateFormat={"YYYY-MM-DD"}
              timeFormat={"A hh:mm:ss"}
              onChange={(date) => {
                let newDate = date;
                if (typeof date === "string") {
                  newDate = moment(date);
                }

                if (newDate.isValid()) {
                  setSubgroup({ ...subgroup, endAt: newDate.toDate() });
                }
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>{t("수업 요일 및 시간")}</label>
              <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                *
              </span>
            </Col>
          </Row>
          <FormGroup>
            <DayAndTimePicker
              data={subgroup?.dayAndTimes || []}
              onChange={(updatedData) => {
                console.log("updatedData = ", updatedData);
                console.log("subgroup = ", subgroup);
                setSubgroup({ ...subgroup, dayAndTimes: updatedData });
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>{t("수업 교실")}</label>
              <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                *
              </span>
            </Col>
          </Row>
          <FormGroup row>
            {props.group?.classrooms?.map((classroom, index) => (
              <FormGroup key={index} check>
                <Label check>
                  <Input
                    type="radio"
                    name="classroom"
                    defaultChecked={
                      subgroup?.classroom ? classroom.id : index === 0
                    }
                    value={classroom.id}
                    onClick={(e) => onChange(e, "classroom")}
                  />{" "}
                  {classroom.title}
                </Label>
              </FormGroup>
            ))}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className="text-right">
          <Button
            color="primary"
            disabled={onSaving || !isModified || Boolean(errorMessage)}
            onClick={onSave}
          >
            {onSaving ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            {t("저장")}
          </Button>
          <Button color="warning" onClick={onCancel}>
            {t("취소")}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

SubgroupForm.propTypes = {
  subgroup: PropTypes.object,
  group: PropTypes.object.isRequired,
  afterSave: PropTypes.func,
  afterDelete: PropTypes.func,
  onCancel: PropTypes.func,
};

export default SubgroupForm;
