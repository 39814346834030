/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Buttons from "views/components/Buttons.jsx";
import Calendar from "views/Calendar.jsx";
import Charts from "views/Charts.jsx";
import Dashboard from "views/Dashboard.jsx";
import ExtendedForms from "views/forms/ExtendedForms.jsx";
import ExtendedTables from "views/tables/ExtendedTables.jsx";
import FullScreenMap from "views/maps/FullScreenMap.jsx";
import GoogleMaps from "views/maps/GoogleMaps.jsx";
import GridSystem from "views/components/GridSystem.jsx";
import Icons from "views/components/Icons.jsx";
import LockScreen from "views/pages/LockScreen.jsx";
import Login from "views/pages/Login.jsx";
import Notifications from "views/components/Notifications.jsx";
import Panels from "views/components/Panels.jsx";
import ReactTables from "views/tables/ReactTables.jsx";
import Register from "views/pages/Register.jsx";
import RegularForms from "views/forms/RegularForms.jsx";
import RegularTables from "views/tables/RegularTables.jsx";
import SweetAlert from "views/components/SweetAlert.jsx";
import Timeline from "views/pages/Timeline.jsx";
import Typography from "views/components/Typography.jsx";
import UserProfile from "views/pages/UserProfile.jsx";
import ValidationForms from "views/forms/ValidationForms.jsx";
import VectorMap from "views/maps/VectorMap.jsx";
import Widgets from "views/Widgets.jsx";
import Wizard from "views/forms/Wizard.jsx";
import Sensors from "views/sensors/Sensors.jsx";
import GroupCategories from 'views/groups/GroupCategories';
// insol created
import Users from 'views/users/Users';
import Managers from 'views/users/Managers';
import Groups from 'views/groups/Groups';
import Franchises from 'views/groups/Franchises';
import ReportMember from 'views/reports/ReportMember';
import ReportHome from 'views/reports/ReportHome';
import Channels from 'views/groups/Channels';
import Home from 'views/groups/Home';
import Group from "views/groups/Group";
import Licenses from "views/groups/Licenses";
import AllLicenses from "views/groups/AllLicenses";
import Subgroup from 'views/groups/Subgroup';
import Member from "views/groups/Member";
import ContentVersions from "views/contents/ContentVersions";
import ContentVersion from "views/contents/ContentVersion";
import ContentHome from 'views/contents/ContentHome';
import ReportSubgroup from "views/reports/ReportSubgroup";
import ReportGroup from "views/reports/ReportGroup";
import Measurements from "views/groups/Measurements";
import CourseDetails from "views/groups/CourseDetails";
import PhysicalTests from "views/groups/PhysicalTests";
import CurriculumVersions from "views/contents/CurriculumVersions";
import CurriculumVersion from "views/contents/CurriculumVersion";
import CourseHome from "views/contents/CourseHome";
import CurriculumResults from "views/groups/CurriculumResults";
import Questions from "views/qbanks/Questions";
import pcLogs from "views/pclogs/pcLogs";
import { useTranslation } from "react-i18next";

const redirect = true;

const routes = [
  {
    redirect: true,
    path: "/home",
    name: "홈",
    component: Home,
    layout: "/admin",
  },
  {
    redirect: true,
    path: "/content-versions",
    name: "콘텐츠 버전 리스트",
    icon: "nc-icon nc-bank",
    component: ContentVersions,
    layout: "/admin",
  },
  {
    path: "/content-home",
    name: "콘텐츠 관리",
    icon: "nc-icon nc-briefcase-24",
    component: ContentHome,
    layout: "/admin",
    collapse: true,
    state: 'content-home',
    views: [
      {
        path: "/content-versions",
        name: "콘텐츠 버전 관리",
        mini: "Con",
        component: ContentVersions,
        layout: "/admin",
        children: ["/content-version"]
      },
      {
        redirect: true,
        path: "/content-version",
        name: "콘텐츠 버전 리스트",
        icon: "nc-icon nc-bank",
        component: ContentVersion,
        layout: "/admin",
      },
      {
        path: "/curriculum-versions",
        name: "커리큘럼 버전 관리",
        mini: "Cur",
        component: CurriculumVersions,
        layout: "/admin",
        children: ["/curriculum-version", "/course-home"]
      },
      {
        path: "/question-bank",
        name: "O/X 문제은행",
        mini: "Cur",
        component: Questions,
        layout: "/admin",
      },
      {
        redirect: true,
        path: "/curriculum-version",
        name: "커리큘럼 버전",
        icon: "nc-icon nc-bank",
        component: CurriculumVersion,
        layout: "/admin",
      },
      {
        redirect: true,
        path: "/course-home",
        name: "코스 홈",
        icon: "nc-icon nc-bank",
        component: CourseHome,
        layout: "/admin",
      },
    ]
  },
  {
    path: "/channels",
    name: "유통 관리",
    icon: "nc-icon nc-globe",
    component: Channels,
    layout: "/admin",
    collapse: true,
    state: 'channel-home',
    views: [
      {
        path: "/channels",
        name: "총판 관리",
        mini: "CHN",
        component: Channels,
        layout: "/admin",
      },
      {
        path: "/groupCategories",
        name: '대리점 관리',
        mini: "AGC",
        component: GroupCategories,
        layout: "/admin"
      },
      {
        path: "/franchises",
        name: "가맹점 관리",
        mini: "FRC",
        component: Franchises,
        layout: "/admin",
        children: ["/franchise", "/subgroup", "/member"]
      },
      {
        redirect: true,
        path: "/franchise",
        name: "가맹점 홈",
        icon: "nc-icon nc-bank",
        component: Group,
        layout: "/admin",
      },
      {
        redirect: true,
        path: "/subgroup",
        name: "클래스",
        icon: "nc-icon nc-bank",
        component: Subgroup,
        layout: "/admin",
      },
      {
        redirect: true,
        path: "/member",
        name: "멤버",
        icon: "nc-icon nc-bank",
        component: Member,
        layout: "/admin",
      },
    ]
  },
  // {
  //   path: "/settings",
  //   name: "설정",
  //   icon: "nc-icon nc-settings-gear-65",
  //   layout: "/admin",
  //   collapse: true,
  //   state: 'settings',
  //   views: [
  //     {
  //       path: "/sensors",
  //       name: '센서',
  //       icon: "nc-icon nc-sound-wave",s
  //       component: Sensors,
  //       layout: "/admin",
  //     },
  //   ]
  // },
  // {
  //   path: "/pc-log",
  //   name: "PC 접속 로그",
  //   icon: "nc-icon nc-single-copy-04",
  //   component: pcLogs,
  //   layout: "/admin"
  // },
  {
    path: "/all-licenses",
    name: "라이센스 관리",
    icon: "nc-icon nc-settings",
    component: AllLicenses,
    layout: "/admin"
  },
  {
    path: "/report-home",
    name: "회원 관리",
    icon: "nc-icon nc-paper",
    component: ContentHome,
    layout: "/admin",
    collapse: true,
    state: 'Report-home',
    views: [
      // 2020-11-11 Temporary task
      {
        path: "/managers",
        name: "관리자 계정 관리",
        mini: "MNG",
        component: Managers,
        layout: "/admin",
      },
      {
        path: "/users",
        name: "회원 계정 및 정보",
        mini: "USR",
        component: Users,
        layout: "/admin",
      },
      {
        path: "/report-home",
        name: "회원별 레포트 관리",
        mini: "RPT",
        component: ReportHome,
        layout: "/admin"
      },
      {
        redirect: true,
        path: "/report-member",
        name: "회원별 레포트 보기",
        mini: "RPT",
        component: ReportMember,
        layout: "/admin"
      },
    ]
  },
  // {
  //   path: ,
  //   name: "리포트",
  //   icon: "nc-icon nc-paper",
  //   component: ReportHome,
  //   layout: "/admin",
  // },
  {
    redirect: true,
    path: "/report-member",
    name: "리포트",
    icon: "nc-icon nc-paper",
    component: ReportMember,
    layout: "/admin",
  },
  {
    redirect: true,
    path: "/report-member",
    name: "리포트",
    icon: "nc-icon nc-paper",
    component: ReportMember,
    layout: "/student",
  },
  {
    redirect: true,
    path: "/report-subgroup",
    name: "리포트",
    icon: "nc-icon nc-paper",
    component: ReportSubgroup,
    layout: "/admin",
  },
  {
    redirect: true,
    path: "/report-group",
    name: "리포트",
    icon: "nc-icon nc-paper",
    component: ReportGroup,
    layout: "/admin",
  },
  {
    redirect: true,
    path: "/content-version",
    name: "콘텐츠 버전",
    icon: "nc-icon nc-bank",
    component: ContentVersion,
    layout: "/admin",
  },
  {
    redirect: true,
    path: "/curriculum-versions",
    name: "커리큘럼 버전 리스트",
    icon: "nc-icon nc-bank",
    component: CurriculumVersions,
    layout: "/admin",
  },
  {
    redirect: true,
    path: "/measurements",
    name: "신체측정",
    icon: "nc-icon nc-bank",
    component: Measurements,
    layout: "/admin",
  },
  {
    redirect: true,
    path: "/courseDetails",
    name: "수강내역",
    icon: "nc-icon nc-bank",
    component: CourseDetails,
    layout: "/admin",
  },
  {
    redirect: true,
    path: "/physicalTests",
    name: "체력장",
    icon: "nc-icon nc-bank",
    component: PhysicalTests,
    layout: "/admin",
  },
  {
    redirect: true,
    path: "/curriculumResults",
    name: "커리큘럼 수행결과",
    icon: "nc-icon nc-bank",
    component: CurriculumResults,
    layout: "/admin",
  },
  {
    redirect: true,
    path: "/groups",
    name: "가맹점 목록",
    mini: "GRP",
    component: Groups,
    layout: "/admin",
  },

  {
    redirect: true,
    path: "/licenses",
    name: "라이센스",
    icon: "nc-icon nc-bank",
    component: Licenses,
    layout: "/admin",
  },
  {
    redirect: true,
    path: "/all-licenses",
    name: "라이센스",
    icon: "nc-icon nc-bank",
    component: AllLicenses,
    layout: "/admin"
  },
  {
    redirect: redirect,
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin"
  },
  {
    redirect: redirect,
    collapse: true,
    name: "Pages",
    icon: "nc-icon nc-book-bookmark",
    state: "pagesCollapse",
    views: [
      {
        path: "/timeline",
        name: "Timeline",
        mini: "T",
        component: Timeline,
        layout: "/admin"
      },
      {
        path: "/login",
        name: "Login",
        mini: "L",
        component: Login,
        layout: "/auth"
      },
      {
        path: "/register",
        name: "Register",
        mini: "R",
        component: Register,
        layout: "/auth"
      },
      {
        path: "/lock-screen",
        name: "LockScreen",
        mini: "LS",
        component: LockScreen,
        layout: "/auth"
      },
      {
        path: "/user-profile",
        name: "UserProfile",
        mini: "UP",
        component: UserProfile,
        layout: "/admin"
      }
    ]
  },
  {
    redirect: redirect,
    collapse: true,
    name: "Components",
    icon: "nc-icon nc-layout-11",
    state: "componentsCollapse",
    views: [
      {
        path: "/buttons",
        name: "Buttons",
        mini: "B",
        component: Buttons,
        layout: "/admin"
      },
      {
        path: "/grid-system",
        name: "Grid System",
        mini: "GS",
        component: GridSystem,
        layout: "/admin"
      },
      {
        path: "/panels",
        name: "Panels",
        mini: "P",
        component: Panels,
        layout: "/admin"
      },
      {
        path: "/sweet-alert",
        name: "Sweet Alert",
        mini: "SA",
        component: SweetAlert,
        layout: "/admin"
      },
      {
        path: "/notifications",
        name: "Notifications",
        mini: "N",
        component: Notifications,
        layout: "/admin"
      },
      {
        path: "/icons",
        name: "Icons",
        mini: "I",
        component: Icons,
        layout: "/admin"
      },
      {
        path: "/typography",
        name: "Typography",
        mini: "T",
        component: Typography,
        layout: "/admin"
      }
    ]
  },
  {
    redirect: redirect,
    collapse: true,
    name: "Forms",
    icon: "nc-icon nc-ruler-pencil",
    state: "formsCollapse",
    views: [
      {
        path: "/regular-forms",
        name: "Regular Forms",
        mini: "RF",
        component: RegularForms,
        layout: "/admin"
      },
      {
        path: "/extended-forms",
        name: "Extended Forms",
        mini: "EF",
        component: ExtendedForms,
        layout: "/admin"
      },
      {
        path: "/validation-forms",
        name: "Validation Forms",
        mini: "VF",
        component: ValidationForms,
        layout: "/admin"
      },
      {
        path: "/wizard",
        name: "Wizard",
        mini: "W",
        component: Wizard,
        layout: "/admin"
      }
    ]
  },
  {
    redirect: redirect,
    collapse: true,
    name: "Tables",
    icon: "nc-icon nc-single-copy-04",
    state: "tablesCollapse",
    views: [
      {
        path: "/regular-tables",
        name: "Regular Tables",
        mini: "RT",
        component: RegularTables,
        layout: "/admin"
      },
      {
        path: "/extended-tables",
        name: "Extended Tables",
        mini: "ET",
        component: ExtendedTables,
        layout: "/admin"
      },
      {
        path: "/react-tables",
        name: "React Tables",
        mini: "RT",
        component: ReactTables,
        layout: "/admin"
      }
    ]
  },
  {
    redirect: redirect,
    collapse: true,
    name: "Maps",
    icon: "nc-icon nc-pin-3",
    state: "mapsCollapse",
    views: [
      {
        path: "/google-maps",
        name: "Google Maps",
        mini: "GM",
        component: GoogleMaps,
        layout: "/admin"
      },
      {
        path: "/full-screen-map",
        name: "Full Screen Map",
        mini: "FSM",
        component: FullScreenMap,
        layout: "/admin"
      },
      {
        path: "/vector-map",
        name: "Vector Map",
        mini: "VM",
        component: VectorMap,
        layout: "/admin"
      }
    ]
  },
  {
    redirect: redirect,
    path: "/widgets",
    name: "Widgets",
    icon: "nc-icon nc-box",
    component: Widgets,
    layout: "/admin"
  },
  {
    redirect: redirect,
    path: "/charts",
    name: "Charts",
    icon: "nc-icon nc-chart-bar-32",
    component: Charts,
    layout: "/admin"
  },
  {
    redirect: redirect,
    path: "/calendar",
    name: "Calendar",
    icon: "nc-icon nc-calendar-60",
    component: Calendar,
    layout: "/admin"
  }
];

export default routes;
