/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// insol created
import Users from 'views/users/Users';
import Groups from 'views/groups/Groups';
import ReportMember from 'views/reports/ReportMember';
import ReportHome from 'views/reports/ReportHome';
import Channels from 'views/groups/Channels';
import Group from "views/groups/Group";
import GroupCategories from 'views/groups/GroupCategories';
import Licenses from "views/groups/Licenses";
import AllLicenses from "views/groups/AllLicenses";
import Subgroup from 'views/groups/Subgroup';
import Member from "views/groups/Member";
import ContentVersions from "views/contents/ContentVersions";
import ContentVersion from "views/contents/ContentVersion";
import ContentHome from 'views/contents/ContentHome';
import ReportSubgroup from "views/reports/ReportSubgroup";
import ReportGroup from "views/reports/ReportGroup";
import Measurements from "views/groups/Measurements";
import CourseDetails from "views/groups/CourseDetails";
import PhysicalTests from "views/groups/PhysicalTests";
import CurriculumVersions from "views/contents/CurriculumVersions";
import CurriculumVersion from "views/contents/CurriculumVersion";
import CourseHome from "views/contents/CourseHome";
import CurriculumResults from "views/groups/CurriculumResults";
import { roles } from "variables/constants";
import UserProfile from 'views/pages/UserProfile';

const redirect = true;

const routes = [
  {
    path: "/users",
    name: "사용자 관리",
    icon: "nc-icon nc-circle-10",
    component: Users,
    layout: "/channel-admin",
  },
  {
    path: "/groups",
    name: "총판",
    icon: "nc-icon nc-bank",
    component: Groups,
    layout: "/channel-admin",
  },
  {
    redirect: true,
    path: "/content-home",
    name: "콘텐츠",
    icon: "nc-icon nc-bank",
    component: ContentHome,
    layout: "/channel-admin",
  },
  {
    redirect: true,
    path: "/content-versions",
    name: "콘텐츠 버전 리스트",
    icon: "nc-icon nc-bank",
    component: ContentVersions,
    layout: "/channel-admin",
  },
  {
    redirect: true,
    path: "/content-version",
    name: "콘텐츠 버전",
    icon: "nc-icon nc-bank",
    component: ContentVersion,
    layout: "/channel-admin",
  },
  {
    redirect: true,
    path: "/curriculum-versions",
    name: "커리큘럼 버전 리스트",
    icon: "nc-icon nc-bank",
    component: CurriculumVersions,
    layout: "/channel-admin",
  },
  {
    redirect: true,
    path: "/curriculum-version",
    name: "커리큘럼 버전",
    icon: "nc-icon nc-bank",
    component: CurriculumVersion,
    layout: "/channel-admin",
  },
  {
    redirect: true,
    path: "/course-home",
    name: "코스 홈",
    icon: "nc-icon nc-bank",
    component: CourseHome,
    layout: "/channel-admin",
  },
  {
    redirect: true,
    path: "/subgroup",
    name: "클래스",
    icon: "nc-icon nc-bank",
    component: Subgroup,
    layout: "/channel-admin",
  },
  {
    redirect: true,
    path: "/member",
    name: "멤버",
    icon: "nc-icon nc-bank",
    component: Member,
    layout: "/channel-admin",
  },
  {
    redirect: true,
    path: "/measurements",
    name: "신체측정",
    icon: "nc-icon nc-bank",
    component: Measurements,
    layout: "/channel-admin",
  },
  {
    redirect: true,
    path: "/courseDetails",
    name: "수강내역",
    icon: "nc-icon nc-bank",
    component: CourseDetails,
    layout: "/channel-admin",
  },
  {
    redirect: true,
    path: "/physicalTests",
    name: "체력장",
    icon: "nc-icon nc-bank",
    component: PhysicalTests,
    layout: "/channel-admin",
  },
  {
    redirect: true,
    path: "/curriculumResults",
    name: "커리큘럼 수행결과",
    icon: "nc-icon nc-bank",
    component: CurriculumResults,
    layout: "/channel-admin",
  },
  {
    path: "/report-home",
    name: "리포트",
    icon: "nc-icon nc-paper",
    component: ReportHome,
    layout: "/channel-admin",
  },
  {
    redirect: true,
    path: "/report-member",
    name: "리포트",
    icon: "nc-icon nc-paper",
    component: ReportMember,
    layout: "/channel-admin",
  },
  {
    redirect: true,
    path: "/report-subgroup",
    name: "리포트",
    icon: "nc-icon nc-paper",
    component: ReportSubgroup,
    layout: "/channel-admin",
  },
  {
    redirect: true,
    path: "/report-group",
    name: "리포트",
    icon: "nc-icon nc-paper",
    component: ReportGroup,
    layout: "/channel-admin",
  },
  {
    redirect: true,
    path: "/group",
    name: "가맹점 홈",
    icon: "nc-icon nc-bank",
    component: Group,
    layout: "/channel-admin",
  },
  {
    redirect: true,
    path: "/licenses",
    name: "라이센스",
    icon: "nc-icon nc-bank",
    component: Licenses,
    layout: "/channel-admin",
  },
  {
    redirect: true,
    path: "/all-licenses",
    name: "라이센스",
    icon: "nc-icon nc-bank",
    component: AllLicenses,
    layout: "/channel-admin"
  },
  {
    path: "/settings",
    name: "설정",
    icon: "nc-icon nc-settings-gear-65",
    layout: "/channel-admin",
    collapse: true,
    state: 'settings',
    views: [
      {
        path: "/groupCategories",
        name: '대리점',
        icon: "nc-icon nc-tag-content",
        component: GroupCategories,
        layout: "/channel-admin"
      }
    ]
  },
  {
    redirect: true,
    path: "/user-profile",
    name: "UserProfile",
    mini: "UP",
    component: UserProfile,
    layout: "/channel-admin"
  }
];

export default routes;
