import React, { useState, useEffect } from 'react'
import { useFirestoreConnect, useFirestore, populate } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import { Tabs, Tab } from 'react-bootstrap'
import Measurements from "views/groups/Measurements";
import CourseDetails from "views/groups/CourseDetails";
import PhysicalTests from "views/groups/PhysicalTests";
import CurriculumResults from "views/groups/CurriculumResults";
import { getCustomClaimsFromLocalStorage } from "helpers/localStorage";
import { useTranslation } from "react-i18next";

import _ from 'lodash';

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  CardSubtitle,
  CardLink,
  CardText,
  ListGroup,
  ListGroupItem,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Jumbotron,
} from "reactstrap";
import classnames from 'classnames';



function Member({ location, history }) {
  const profile = useSelector(state => state.firebase.profile);
  const { t, i18n } = useTranslation();
  // const [channel, setChannel] = useState(null);
  // const [group, setGroup] = useState(null);
  // const [subgroup, setSubgroup] = useState(null);
  // const [member, setMember] = useState(null);

  
  const customClaims = getCustomClaimsFromLocalStorage();
  
  let { channel, group, subgroup, member } = customClaims;
  if(profile.role!=1){
    channel = location?.state.channel ? location.state.channel : profile.channel;
  }
  if(profile.role!=1){
    group = location?.state.group ? location.state.group : profile.group;
  }
  if(profile.role!=1){
    subgroup = location?.state.subgroup ? location.state.subgroup : profile.subgroup;
  }
  if(profile.role!=1){
    member = location?.state.member ? location.state.member : profile.member;
  }
  // if(!(member && subgroup)){
  //   channel = { ...channel, id: customClaims?.channelId }
  //   subgroup = {id: customClaims.subgroupIds[0]};
  //   member = {id:customClaims.memberId[0]};
  // }


  const firestore = useFirestore();
  // const getContents = async () => {
  //   try {
  //     if (!member?.name) {
  //       console.log('get member in useEffect');
  //       console.log('after make channel id', channel);
  //       await firestore.doc(`channels/${customClaims.channelId}/groups/${customClaims.groupId}/members/${customClaims.memberId[0]}`).get()
  //         .then(docsnap => {
  //           member = docsnap.data();
  //           console.log("member in useEffect", docsnap.data());
  //         })
  //     }
  //     if (!subgroup?.title) {
  //       await firestore.doc(`channels/${customClaims.channelId}/groups/${customClaims.groupId}/subgroups/${customClaims.subgroupIds[0]}`).get()
  //         .then(docsnap => {
  //           subgroup = docsnap.data();
  //           console.log("subgroup in useEffect", docsnap.data());
  //         })
  //     }
  //   }
  //   catch (e) {
  //     console.log('error in member => ', e);
  //   }

  // }

  useEffect(() => {
    // getContents();
    return () => {
      // cleanup
    };
  }, []);


  const ControlledTabs = (props) => {
    const [key, setKey] = useState('curriculumResults');
    return (
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey="curriculumResults" title={t("커리큘럼 수행")}>
          <CurriculumResults state={{ channel, group, subgroup, member }} />
        </Tab>
        <Tab eventKey="measurements" title={t("신체 측정")}>
          <Measurements state={{ channel, group, subgroup, member }} />
        </Tab>
        <Tab eventKey="physicalTests" title={t("체력장 수행")}>
          <PhysicalTests state={{ channel, group, subgroup, member }} />
        </Tab>
        {/* <Tab eventKey="enrolledClass" title="수강 클래스">
          <CourseDetails state={{ channel, group, subgroup, member }} />
        </Tab> */}
      </Tabs>
    );
  }

  return (
    <div className='content'>
      <Breadcrumb className='sticky-top'>
        <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
        {/* <BreadcrumbItem><a href="./channels">총판 목록</a></BreadcrumbItem>
        <BreadcrumbItem>{channel?.title}</BreadcrumbItem> */}
        {
          profile.role != 4 ? <BreadcrumbItem>
            <NavLink to={{ pathname: `./franchises`, state: { channel, group } }}>
              {t("가맹점 목록")}
          </NavLink></BreadcrumbItem> : null
        }

        {/* <BreadcrumbItem>
          <NavLink to={{ pathname: `./groups`, state: { channel } }}>
            {channel?.title}
          </NavLink>
        </BreadcrumbItem> */}

        {profile.role != 4||profile.role != 1 ?  <BreadcrumbItem>
          <NavLink to={{ pathname: `./franchise`, state: { channel, group } }}>
            {group?.title}
          </NavLink>
        </BreadcrumbItem> : ''}
        <BreadcrumbItem>
          <NavLink to={{ pathname: `./subgroup`, state: { channel, group, subgroup } }}>
            {subgroup?.title}
          </NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem active>{member?.name}{t("님")}</BreadcrumbItem>
      </Breadcrumb>
      <Jumbotron>
        <Button className="float-right" color="secondary"
          onClick={() => history.push({
            pathname: './report-member',
            state: { channel, group, subgroup, member, byMember: 'true' }
          })}
        >{t("결과리포트")}</Button>
        <h1 className="display-3"><strong>{member?.name}</strong>{t("님")}</h1>
        <p className="lead">{t("이곳에서")} <strong>{member?.name} </strong>{t("님의 커리큘럼, 신체측정, 체력장 등 누적데이터를 볼 수 있습니다.")}</p>
        <hr className="my-2" />
        <p>{t("먼저 신체측정 정보를 입력하고 체력장 수행을 해야 결과 리포트를 볼 수 있습니다.")}</p>

        <p className="lead">
        </p>
        <ControlledTabs />
      </Jumbotron>
    </div>
  )
}

export default Member