import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  ButtonGroup,
  Button,
  Container,
  Row,
  Col,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Input,
  InputGroup,
  Collapse
} from 'reactstrap'

import moment from "moment";

import { Calendar, momentLocalizer } from 'react-big-calendar'
import SweetAlert from "react-bootstrap-sweetalert";

import cn from 'classnames'
import _ from 'lodash';

import { useFirestore } from 'react-redux-firebase';
import { useTranslation } from "react-i18next";

// import { events } from "variables/general.jsx";

const localizer = momentLocalizer(moment);
const colors = ["primary", "success", "info", "warning", "danger", "purple"];

const CustomToolbar = (props) => {
  const { t, i18n } = useTranslation();
  console.log('localizer =>', localizer);

  const [selectedClassroomId, setSelectedClassroomId] = useState(null);

  let {
    localizer: { messages },
    label,
    classrooms,
  } = props

  const navigate = action => {
    props.onNavigate(action)
  }

  const viewNamesGroup = (messages) => {
    
    let viewNames = props.views
    const view = props.view

    if (viewNames.length > 1) {
      return <ButtonGroup>
        {
          viewNames.map(name => (
            <Button
              key={name}
              className={cn({ 'rbc-active': view === name, 'mr-1': true })}
              onClick={() => props.onView(name)}
            >
              {messages[name]}
            </Button>
          ))
        }
      </ButtonGroup>
    }
  }

  return <Container className='content msp'>
    <Row className="rbc-toolbar d-flex justify-content-between">
      <Col>
        <ButtonGroup >
          <Button className='mr-1'
            onClick={() => {
              setSelectedClassroomId(null);
              props.onSelectClassroom(null);
            }}
          >{t("전체")}</Button>
          {
            classrooms?.map((classroom, idx) => {
              const searchResult = _.findIndex(classrooms, { id: classroom.id });
              let colorNames = "";
              if (searchResult !== -1) {
                const reminderValue = searchResult % colors.length;
                colorNames = `bg-${colors[reminderValue]}`
              }
              return <Button
                key={classroom.id}
                className={cn({ 'mr-1': true, [colorNames]: true, 'border border-dark': selectedClassroomId === classroom.id })}
                onClick={() => {
                  setSelectedClassroomId(classroom.id);
                  props.onSelectClassroom(classroom.id);
                }}
              >
                {classroom.title}
              </Button>
            })
          }
        </ButtonGroup>
      </Col>
      <Col className='d-flex justify-content-center'>
        <ButtonGroup>
          <Button
            className='mr-1'
            onClick={() => navigate('PREV')}
          >
            {messages.previous}
          </Button>
          <Button
            className='mr-1'
            onClick={() => navigate('TODAY')}
          >
            {messages.today}
          </Button>
          <Button
            className='mr-1'
            onClick={() => navigate('NEXT')}
          >
            {messages.next}
          </Button>
        </ButtonGroup>
      </Col>
      <Col className='text-right'>
        {viewNamesGroup(messages)}
      </Col>
    </Row>
    <Row className="rbc-toolbar">
      <Col className="text-center">
        <span className="rbc-toolbar-label">{label}</span>
      </Col>
    </Row>


  </Container >

}

const PopoverContent = ({ event }) => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [theDate, setTheDate] = useState(moment(event.start).format('YYYY-MM-DD'));
  const [startTime, setStartTime] = useState(moment(event.start).format('H:mm'));
  const [endTime, setEndTime] = useState(moment(event.end).format('H:mm'));

  const firestore = useFirestore();

  return (
    <>
      <PopoverHeader className='text-primary'>{event.title}</PopoverHeader>
      <PopoverBody>
        <div>
          <Row>
            <Col>
              {moment(event.start).format('a h:mm')}~{moment(event.end).format('a h:mm')}
            </Col>
          </Row>
          <Row>
            <Collapse isOpen={isOpen}>
              <Container>
                <Row>
                  <Col>
                    <Input
                      type='date'
                      value={theDate}
                      onChange={(e) => setTheDate(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      type='time'
                      value={startTime}
                      onChange={(e) => setStartTime(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      type='time'
                      value={endTime}
                      onChange={(e) => setEndTime(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className='text-center'>
                    <Button
                      className="btn-icon btn-link text-danger"
                      onClick={() => setIsOpen(false)}
                    >
                      {t("취소")}
                  </Button>
                  </Col>
                  <Col className='text-center'>
                    <Button
                      className="btn-icon btn-link"
                      onClick={async () => {
                        try {
                          const { channelId, groupId, subgroupId } = event;
                          const path = `channels/${channelId}/groups/${groupId}/subgroups/${subgroupId}`;
                          const ref = firestore.doc(path);
                          const docSnap = await ref.get();
                          const subgroup = docSnap.data();
                          const { schedules = [] } = subgroup;
                          const newSchedules = schedules.map(item => item.id === event.id
                            ? {
                              ...item,
                              start: new Date(`${theDate} ${startTime}`),
                              end: new Date(`${theDate} ${endTime}`),
                            }
                            : item);
                          ref.update({ schedules: newSchedules });
                        } catch (error) {
                          console.error('failed to update subgroup: ', error);
                        }
                      }}
                    >
                      {t("저장")}
                  </Button>
                  </Col>
                </Row>
              </Container>
            </Collapse>
          </Row>
          {
            isOpen
              ? null
              : <Row>
                <Col className='text-center'>
                  <Button
                    className="btn-icon delete btn-link text-danger"
                    onClick={async () => {
                      try {
                        const { channelId, groupId, subgroupId } = event;
                        const path = `channels/${channelId}/groups/${groupId}/subgroups/${subgroupId}`;
                        const ref = firestore.doc(path);
                        const docSnap = await ref.get();
                        const subgroup = docSnap.data();
                        const { schedules = [] } = subgroup;
                        const newSchedules = schedules.filter(item => item.id !== event.id);
                        ref.update({ schedules: newSchedules });
                      } catch (error) {
                        console.error('failed to get subgroup: ', error);
                      }
                    }}
                  >
                    {t("삭제")}
                  </Button>
                </Col>
                <Col className='text-center'>
                  <Button
                    className="btn-icon btn-link edit"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    {t("수정")}
                  </Button>
                </Col>
              </Row>
          }
        </div>
      </PopoverBody>
    </>
  );
}



function TimeTable(props) {
  const { classrooms, events = [] } = props;
  const [filteredEvents, setFilteredEvents] = useState(events);
  const [selectedClassroomId, setSelectedClassroomId] = useState(null);
  const { t, i18n } = useTranslation();

  const firestore = useFirestore();

  useEffect(() => {
    console.log('selectedClassroomId = ', selectedClassroomId);
    if (selectedClassroomId) {
      setFilteredEvents(events.filter(e => e.classroomId === selectedClassroomId));
    } else {
      setFilteredEvents(events);
    }
    return () => {
      // cleanup
    }
  }, [selectedClassroomId])

  useEffect(() => {
    setFilteredEvents(events);
    return () => {
      // cleanup
    }
  }, [events])



  const renderEvent = (props) => {
    const { event } = props;
    return <div id={event.id}>
      <div className='text-bold'>{event.title}</div>
      <UncontrolledPopover placement="bottom" target={event.id} trigger='legacy'>
        <PopoverContent event={event} />
      </UncontrolledPopover>
    </div>
  }

  // console.log('events = ', events);
  return (
    <div className={`content ${props?.className}`} >
      <Calendar
        min={new Date(2020, 12, 29, 6)}
        max={new Date(2020, 12, 29, 21)}
        // toolbar={false}
        formats={{
          monthHeaderFormat: 'YYYY MMMM',
          dayRangeHeaderFormat: ({ start, end }, culture, local) =>
            local.format(start, "MMMM DD", culture) +
            " - " +
            local.format(
              end,
              "MMMM DD",
              culture
            ),
          agendaHeaderFormat: ({ start, end }, culture, local) =>
            local.format(start, "MMMM DD", culture) +
            " - " +
            local.format(
              end,
              "MMMM DD",
              culture
            ),
          agendaDateFormat: 'MMMM DD dddd',
          dayHeaderFormat: 'YYYY MMMM DD dddd'
        }}
        localizer={localizer}
        events={filteredEvents}
        messages={{
          allDay: t('종일'),
          today: t('오늘'),
          previous: t('이전'),
          next: t('다음'),
          month: t('월'),
          week: t('주별'),
          day: t('일별'),
          agenda: t('항목별'),
          date: t('날짜'),
          time: t('시간'),
          event: t('항목'),
          noEventsInRange: <div className='text-center text-info'>{t("해당기간에 수업이 없습니다.")}</div>
        }}
        // popup
        components={
          {
            toolbar: props => <CustomToolbar {...props} classrooms={classrooms} onSelectClassroom={(classroomId) => setSelectedClassroomId(classroomId)} />,
            event: renderEvent,
          }
        }
        eventPropGetter={
          (event, start, end, isSelected) => {
            let newStyle = {
              backgroundColor: "lightgrey",
              color: 'black',
              borderRadius: "0px",
              border: "none"
            };

            const searchResult = _.findIndex(classrooms, { id: event.classroomId });
            let className = "";
            if (searchResult !== -1) {
              const reminderValue = searchResult % colors.length;
              className = `bg-${colors[reminderValue]}`
            }

            return {
              className,
              style: newStyle,
              onClick: () => alert(`${event.title}`)
            };
          }
        }

        // resources={props.classrooms}
        // resourceIdAccessor="id"
        // resourceTitleAccessor="title"
        defaultView="week"
      />
    </div >
  )
}

TimeTable.propTypes = {
  schedules: PropTypes.arrayOf(PropTypes.shape({
    at: PropTypes.instanceOf(Date).isRequired,  // 시작 시간
    duration: PropTypes.number,                 // 몇 분간 수업하는지? 
    title: PropTypes.string.isRequired,         // 제목
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,   // 필터링용 테그 (강의실 구분용)
  })),

}

export default TimeTable

