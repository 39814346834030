import React from "react";
import { hot } from "react-hot-loader/root";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.1.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import RRFPrvider from "RRF";
import RouteProvider from "routes/RouteProvider";

const App = () => (
  <RRFPrvider>
    <RouteProvider />
  </RRFPrvider>
);

export default process.env.NODE_ENV === "development" ? hot(App) : App;
