import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _, { isUndefined } from 'lodash';

import Board from 'views/components/Board.jsx'
import { useFirestoreConnect, useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useTable } from 'react-table';
import { NavLink } from "react-router-dom";

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import { useRef, useEffect } from 'react';
import moment from 'moment';
import LicenseForm from 'views/forms/LicenseForm';
import { asyncForEach } from 'helpers/async';


function Licenses({ location }) {
  const { channel, group } = location?.state;

  const firestore = useFirestore();

  const [items, setItems] = useState(null);
  const [selectedItem, setSelectedItem] = useState({ channel, group });



  const watchLicenses = () => {
    const ref = group
      ? firestore.collection(`v2Licenses`)
        .where('channelId', '==', channel.id)
        .where('groupId', '==', group.id)
        .orderBy('createdAt', 'desc')
      : firestore.collection('v2Licenses')
        .where('channelId', '==', channel.id)
        .orderBy('createdAt', 'desc');
    return ref.onSnapshot((async querySnapshot => {
      var licenses = [];

      await asyncForEach(querySnapshot.docs, async (doc) => {
        try {
          // console.log('path = ', doc.ref.path);
          // console.log('parent collection = ', doc.ref.parent.parent.path);
          // console.log('parent parent collection = ', doc.ref.parent.parent.parent.parent.path);

          // console.log('group = ', group);
          // console.log('channel = ', channel);

          licenses.push({ id: doc.id, ...doc.data() });
          console.log('licenses = ', licenses);
        } catch (e) {
          console.error('failed to get licenses: ', e);
        }
      });
      setItems(licenses);
    }));
  }


  useEffect(() => {
    const unsubscribe = watchLicenses();
    return () => {
      unsubscribe();
    }
  }, [])

  const boardEl = useRef(null);

  const data = items
    ? items.map((item) => ({
      ...item,
      actions: (
        // we've added some custom button actions
        <div className="actions-left">
          {/* use this button to add a like kind of action */}
          {/* <Button
          onClick={() => {
            console.log('clicked');
            console.log(item);
            console.log(boardEl);
          }}
          color="info"
          size="sm"
          className="btn-icon btn-link like"
        >
          <i className="fa fa-heart" />
        </Button>{" "} */}
          {/* use this button to add a edit kind of action */}
          <Button
            onClick={async () => {
              console.log('clicked');
              const channelRef = firestore.doc(`channels/${item?.channelId || item.channel?.id}`);
              const channel = (await channelRef.get()).data();
              const groupRef = firestore.doc(`channels/${item?.channelId || item.channel?.id}/groups/${item?.groupId || item.group?.id}`);
              const group = (await groupRef.get()).data();
              setSelectedItem({ ...item, channel: { ...channel, id: channelRef.id }, group: { ...group, id: groupRef.id } });
              boardEl.current.edit(item);
            }}
            color="warning"
            size="sm"
            className="btn-icon btn-link edit"
          >
            <i className="fa fa-edit" style={{ fontSize: '1.5em' }} />
          </Button>{" "}
          {/* use this button to remove the data row */}
          <Button
            onClick={() => {
              console.log('clicked');
              boardEl.current.delete(() => {
                firestore.doc(`v2Licenses/${item.id}`).delete();
              });
            }}
            color="danger"
            size="sm"
            className="btn-icon btn-link remove"
          >
            <i className="fa fa-times" style={{ fontSize: '1.5em' }} />
          </Button>{" "}
        </div>
      )
    }))
    : [];
  // console.log('data', data);

  return (
    <div className="content">
      <Breadcrumb className='sticky-top'>
        <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
        <BreadcrumbItem><a href="./channels">총판 목록</a></BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={{ pathname: `./groups`, state: { channel } }}>
            {channel?.title}
          </NavLink>
        </BreadcrumbItem>
        {
          group
            ?
            <BreadcrumbItem>
              <NavLink to={{ pathname: `./franchise`, state: { channel, group } }}>
                {group.title}
              </NavLink>
            </BreadcrumbItem>

            : null
        }
        <BreadcrumbItem active>{group ? `가맹점 라이센스` : `총판 라이센스`}</BreadcrumbItem>
      </Breadcrumb>
      <Board
        title="라이센스"
        data={data}
        ref={boardEl}
        hideAddNewButton={isUndefined(group)}
        columns={[
          {
            Header: "라이센스 키",
            accessor: 'licenseKey',
            filterMethod: (filter, row) => {
              console.log('filter = ', filter);
              const title = row[filter.id];
              console.log('row title = ', title);
              return title.indexOf(filter.value.toUpperCase()) != -1 || title.indexOf(filter.value.toLowerCase()) != -1;
            }
          },
          {
            Header: "총판",
            accessor: "channel.title"
          },
          {
            Header: "가맹점",
            accessor: "group.title"
          },
          {
            Header: "라이센스 설명",
            accessor: 'description'
          },
          {
            id: 'startAt',
            Header: "시작일",
            accessor: d => d.startAt ? moment(d.startAt.toDate()).format('YYYY-MM-DD HH:mm') : ''
          },
          {
            id: 'endAt',
            Header: "종료일",
            accessor: d => d.endAt ? moment(d.endAt.toDate()).format('YYYY-MM-DD HH:mm') : ''
          },
          {
            id: 'active',
            Header: "상태",
            accessor: d => d.active ? '활성' : '비활성'
          },
          {
            Header: "명령",
            accessor: "actions",
            sortable: false,
            filterable: false
          }
        ]}
      >
        {
          selectedItem
            ? <LicenseForm
              channel={selectedItem?.channel}
              group={selectedItem?.group}
            />
            : null
        }
      </Board>
    </div>
  )
}

Licenses.propTypes = {

}

export default Licenses

