import React from 'react'

import {
  Breadcrumb,
  BreadcrumbItem,
  Jumbotron,
  Button
} from 'reactstrap';

function ContentHome({ history }) {

  return (
    <div className='content'>

      <Breadcrumb className='sticky-top'>
        <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
        <BreadcrumbItem active>콘텐츠</BreadcrumbItem>
      </Breadcrumb>

      <Jumbotron>
        <h1 className="display-3">콘텐츠</h1>
        <p className="lead">이곳에서 콘텐츠 목록, 커리큘럼의 버전별로 관리할 수 있습니다.</p>
        <hr className="my-2" />
        <p>고객별로 공급할 커리큘럼과 자유모드 콘텐츠를 조합하여 라이센스를 발급합니다.</p>
        <p className="lead">
          <Button color="primary"
            onClick={() => history.push('./content-versions')}>콘텐츠 버전관리</Button>
          <Button color="primary"
            onClick={() => history.push('./curriculum-versions')}>커리큘럼 버전관리</Button>
          <Button color='secondary'
            onClick={() => history.push('./all-licenses')}>라이센스 현황</Button>
        </p>
      </Jumbotron>
    </div>
  )
}

export default ContentHome
