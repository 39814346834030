import React, { useState, useEffect } from 'react'

import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import _ from 'lodash';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  FormGroup,
  Input,
} from "reactstrap";

import Select from "react-select";

import readXlsxFile from 'read-excel-file'
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { asyncForEach } from 'helpers/async';
import { newContentSchema } from 'variables/schema';
import { CURRICULUM_VERSION_STATUS_STRING } from 'variables/status';
import { CONTENT_VERSION_STATUS } from 'variables/status';

function CurriculumVersionForm(props) {
  console.log('props = ', props);
  const [curriculumVersion, setCurriculumVersion] = useState(props.currentItem || {});
  const [infoMessage, setInfoMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModified, setIsModified] = useState(false);

  const [onSaving, setOnSaving] = useState(false);

  const firebase = useFirebase();
  const firestore = useFirestore();

  useFirestoreConnect([
    {
      collection: 'contentVersions',
      where: [
        ['contentVersionStatus', '==', CONTENT_VERSION_STATUS.DEPLOYED]
      ],
      orderBy: [
        ['createdAt', 'desc']
      ],
      storeAs: 'activeContentVersions'
    }
  ]);

  const contentVersions = useSelector(state => state.firestore.ordered['activeContentVersions']);

  console.log('contentVersions', contentVersions);

  const getContentString = (version) => {
    if (!contentVersions || !version) {
      return null;
    }
    console.log('version = ', version);
    const content = _.find(contentVersions, { version });
    // console.log('content = ', content);
    return `${content.version}`;
    //return `${content.version} ${content.changelog}`;
  }

  const checkValidity = () => {
    const { version, changelog, curriculumVersionStatus } = curriculumVersion;

    console.log('version = ', version);
    console.log('changelog', changelog);
    console.log('contentVersionState = ', curriculumVersionStatus);

    if (!version || version.length < 5) {
      setErrorMessage('버전 정보를 제대로 입력해주세요.');
      return false;
    }

    if (curriculumVersionStatus === undefined) {
      setErrorMessage('커리큘럼 버전의 상태값을 설정해주세요.');
      return false;
    }

    return true;
  }

  useEffect(() => {
    const currentItem = _.cloneDeep(props.currentItem);
    if (currentItem == null && Object.keys(curriculumVersion).length === 0) {
      return
    }
    const modified = !_.isEqual(currentItem, curriculumVersion);
    console.log("modified: ", modified);
    console.log(`currentItem`, currentItem);
    console.log(`curriculumVersion`, curriculumVersion);


    setIsModified(modified);
    return () => {
      // cleanup
    };
  }, [curriculumVersion]);

  const onSave = async () => {
    console.log('press save');

    if (!checkValidity()) {
      // setErrorMessage('유효하지 않는 값입니다.');
      return;
    }

    setOnSaving(true);

    let timestamp;

    if (props.currentItem?.id) {
      timestamp = {
        updatedAt: firestore.FieldValue.serverTimestamp()
      };
    } else {
      timestamp = {
        createdAt: firestore.FieldValue.serverTimestamp(),
        updatedAt: firestore.FieldValue.serverTimestamp()
      };
    }

    try {
      const curriculumVersionRef = firestore.collection('curriculumVersions').doc(curriculumVersion.version);

      if (!props.currentItem) {
        const docSnap = await curriculumVersionRef.get();
        if (docSnap.exists) {
          setErrorMessage('이미 존재하는 버전으로 생성할 수 없습니다.');
          throw Error('exisiting-version-error');
        }
      }

      await curriculumVersionRef.set({
        ...curriculumVersion,
        ...timestamp,
      });
      setErrorMessage(null);
      props.toggle();
      props.afterSave && props.afterSave();
    } catch (error) {
      console.error('Failed to save : ', error);
    } finally {
      setOnSaving(false);
    }
  }

  const onCancel = () => {
    if (isModified) {
      props.closeConfirm();
    } else {
      props.toggle();
    }
  }

  const onChange = (e, name, typeFn) => {
    const value = e.target.value;
    setCurriculumVersion({ ...curriculumVersion, [name]: typeFn ? typeFn(value) : value });
  }

 

  return (

    <div className='content'>
      <Row>
        <Col>
          <div className="text-right"><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span> 필수 입력 사항입니다</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className='align-items-center justify-content-between'>
            <Col><label>사용 콘텐츠 버전</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="contentVersion"
              value={{
                value: curriculumVersion?.contentVersion,
                label: getContentString(curriculumVersion?.contentVersion)
              }}
              onChange={({ value }) => {
                // console.log('value', value);
                if (value !== curriculumVersion?.contentVersion) {
                  setCurriculumVersion({ ...curriculumVersion, contentVersion: value })
                  // console.log('program', program);
                }
              }}
              options={[
                ...(
                  contentVersions
                    ? contentVersions.map((item, idx) => ({ value: item.version, label: getContentString(item.version) }))
                    : []
                )
              ]}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className='align-items-center justify-content-between'>
            <Col><label>커리큘럼 버전 이름</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Input
              value={curriculumVersion?.version || ''}
              placeholder='예) 1.1.0'
              onChange={e => onChange(e, 'version')} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className='align-items-center justify-content-between'>
            <Col><label>커리큘럼 버전 설명</label></Col>
          </Row>
          <FormGroup>
            <Input
              value={curriculumVersion?.changelog || ''}
              type="textarea"
              placeholder={'예) 다국어 버전'}
              onChange={e => onChange(e, 'changelog')} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className='align-items-center justify-content-between'>
            <Col><label>커리큘럼 버전 상태</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="curriculumVersionStatus"
              value={{
                value: (curriculumVersion?.curriculumVersionStatus),
                label: (CURRICULUM_VERSION_STATUS_STRING[curriculumVersion?.curriculumVersionStatus])
              }}
              onChange={value => {
                // console.log('value', value);
                if (value !== curriculumVersion?.curriculumVersionStatus) {
                  setCurriculumVersion({ ...curriculumVersion, curriculumVersionStatus: Number(value.value)})
                  // console.log('program', program);
                }
              }}
              options={[
                ...(
                  curriculumVersion
                    ? CURRICULUM_VERSION_STATUS_STRING.map((item, idx) => ({ value: idx, label: item }))
                    : []
                )
              ]}
              placeholder="커리큘럼 버전 설정하기"
            />
          </FormGroup>
        </Col>
      </Row>
      {
        errorMessage
          ? <div>
            <p className="text-danger">{errorMessage}</p>
          </div>
          : null
      }

      <Row>
        <Col className='text-right'>
          <Button color="primary" disabled={onSaving || !Boolean(curriculumVersion?.version && curriculumVersion?.contentVersion && curriculumVersion?.curriculumVersionStatus) || !isModified} onClick={onSave}>
            {
              onSaving ?
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                : null
            }
            저장
            </Button>
          <Button color="warning" onClick={onCancel}>취소</Button>
        </Col>
      </Row>

    </div>
  )
}

export default CurriculumVersionForm
