import _ from 'lodash';

const parityString = 'INSOL21';
const validString = '12345678ABCDEFGHIJKLMNQRSTUVWXYZ';


export const genLicenseKey = () => {
  const threeParities = _.sampleSize(parityString, 3);
  console.log(threeParities);
  const thirteenCharacters = _.sampleSize(validString, 13);

  const key = _.range(16).map((index) => {
    return [3, 11, 15].includes(index)
      ? threeParities.shift()
      : thirteenCharacters.shift()
  });

  console.log('key = ', key);

  return _.chunk(key, 4).map(group => group.join('')).join('-');
}

export const validateLicenseKey = (key) => {
  const norm = key.replace(/-/g, '');
  console.log('norm', norm);
  console.log('norm.length = ', norm.length);
  if (norm.length !== 16) {
    return false;
  }

  console.log('here', norm);
  const result = norm.split('').map((c, idx) => {
    console.log('c = ', c);
    return [3, 11, 15].includes(idx)
      ? parityString.includes(c)
      : validString.includes(c)
  });

  console.log('result = ', result);

  return !_.some(result, false)
}