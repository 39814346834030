export const getSelectValues = (event) => {
  let opts = [], opt;

  for (let i = 0, len = event.target.options.length; i < len; i++) {
    opt = event.target.options[i];

    if (opt.selected) {
      opts.push(opt);
    }
  }
  // console.log('opts: ', opts);
  return opts;
}