import React, { useState, useEffect, useRef } from 'react'
import { useFirestoreConnect, useFirestore, populate, useFirebase } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";

import _ from 'lodash';

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  CardSubtitle,
  CardLink,
  CardText,
  ListGroup,
  ListGroupItem,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Badge,
  Jumbotron
} from "reactstrap";
import moment from 'moment';
import Board from 'views/components/Board.jsx'
import ContentVersionForm from 'views/forms/ContentVersionForm';
import { CONTENT_VERSION_STATUS_STRING } from 'variables/status';
import ReactBSAlert from "react-bootstrap-sweetalert";

function ContentVersions(props) {
  const [showModal, setShowModal] = useState(false);
  const [alert, setAlert] = useState(null);
  const [onCopying, setOnCopying] = useState(false);
  const [data, setData] = useState([]);

  const firestore = useFirestore();
  const firebase = useFirebase();
  useFirestoreConnect([
    { collection: 'contentVersions' }
  ]);

  const items = useSelector(state => state.firestore.ordered.contentVersions);
  const boardEl = useRef(null);


  useEffect(() => {
    const mappedData = items && items.map((item) => ({
      ...item,
      actions: (
        // we've added some custom button actions
        <div className="actions-left">
          {/* use this button to add a like kind of action */}
          <Button
            onClick={() => inputAlert(item)}
            color="warning"
            size="sm"
            className="btn-icon btn-link"
          >
            <i className="fa fa-copy" style={{ fontSize: '1.5em' }} />
          </Button>{" "}
          {/* use this button to add a edit kind of action */}
          <Button
            onClick={() => {
              boardEl.current.edit(item);
            }}
            color="warning"
            size="sm"
            className="btn-icon btn-link edit"
          >
            <i className="fa fa-edit" style={{ fontSize: '1.5em' }} />
          </Button>{" "}
          {/* use this button to remove the data row */}
          <Button
            onClick={() => {
              console.log('clicked');
              boardEl.current.delete(async () => {
                console.log('item: ', item);
                console.log('path: ', `contentVersions/${item.version}`);
                try {
                  const checkCurriculumUsed = await firestore.collection(`curriculumVersions`).where('contentVersion', '==', item.version).get();

                  if (checkCurriculumUsed.size > 0) {
                    deleteFailure();
                    return;
                  }

                  await firestore.doc(`contentVersions/${item.version}`).delete();
                } catch (error) {
                  console.error('failed to delete content version: ', error);
                }
              });
            }}
            color="danger"
            size="sm"
            className="btn-icon btn-link remove"
          >
            <i className="fa fa-times" style={{ fontSize: '1.5em' }} />
          </Button>{" "}
        </div>
      )
    }))

    setData(mappedData);

    return () => {
      // cleanup
    }
  }, [items]);

  const hideAlert = () => {
    setAlert(null);
  };

  const copyContentVersion = async (fromVersion, toVersion) => {
    console.log('fromVersion = ', fromVersion);
    console.log('toVersion = ', toVersion);
    const functions = firebase.app().functions('asia-northeast1');
    console.log('functions = ', functions);
    const copy = functions.httpsCallable('copyContentVersion');
    try {
      return await copy({ fromVersion, toVersion });
    } catch (e) {
      console.error('failed to httpsCallable: ', e);
    }
  }


  const inputAlert = (item) => {
    setAlert(<ReactBSAlert
      input
      showCancel
      style={{ display: "block", marginTop: "-100px" }}
      title={'복사해서 만들 새 콘텐츠 버전을 입력해주세요.'}
      onConfirm={e => inputConfirmAlert(e, item)}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      validationMsg="콘텐츠 버전명이 입력되지 않았습니다!"
      cancelBtnBsStyle="danger"
      confirmBtnText="복사"
      cancelBtnText="취소"
      // disabled={onCopying}
    />);
  }

  const inputConfirmAlert = async (e, item) => {
    setOnCopying(true);
    try {
      const { data } = await copyContentVersion(item.id, e);
      console.log('httpscallble data = ', data);
      if (data.result === 'success') {
        copySuccess();
      } else {
        copyFailure(data.message);
      }
    } catch (e) {
      console.error('failed to httpscall: ', e);
    } finally {
      setOnCopying(false);
    }
  };

  const copySuccess = () => {
    setAlert(<ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px" }}
      onConfirm={() => hideAlert()}
      confirmBtnBsStyle="info"
      title="복사완료"
    />
    );
  };

  const copyFailure = (message) => {
    setAlert(<ReactBSAlert
      error
      style={{ display: "block", marginTop: "-100px" }}
      onConfirm={() => hideAlert()}
      confirmBtnBsStyle="info"
      title="복사실패"
    >
      {message}
    </ReactBSAlert>
    );
  };

  const deleteFailure = (message) => {
    setAlert(<ReactBSAlert
      error
      style={{ display: "block", marginTop: "-100px" }}
      onConfirm={() => hideAlert()}
      confirmBtnBsStyle="danger"
      title="커리큘럼에서 사용중인 콘텐츠는 삭제할 수 없습니다. 먼저 커리큘럼에서 사용하지 않도록 설정해주세요."
    >
      {message}
    </ReactBSAlert>
    );
  };


  return (
    <div className='content'>
      <Breadcrumb className='sticky-top'>
        <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
        <BreadcrumbItem active>콘텐츠 버전</BreadcrumbItem>
      </Breadcrumb>
      <Jumbotron>
        <h1 className="display-5"><strong>콘텐츠 버전 목록</strong></h1>
        <p className="lead">이곳에서 전체 콘텐츠와 관련 정보를 확인할 수 있습니다.</p>
        <hr className="my-2" />
        <p><strong>⚠️NOTE</strong> 콘텐츠 버전은 기존 콘텐츠의 수정 및 활용이 가능한, <strong>복사</strong>가 가능합니다.</p>
      </Jumbotron>
      {alert}
      <Board
        modalTitle="콘텐츠 버전 입력폼"
        data={data}
        ref={boardEl}
        columns={[
          {
            id: 'version',
            Header: "콘텐츠 버전",
            accessor: d => {
              // console.log('d', d);
              const version = _.clone(d);
              delete version.actions;
              return <NavLink
                to={{ pathname: `./content-version`, state: { version } }}
              >{d.version}</NavLink>
            },
            filterMethod: (filter, row) => {
              console.log('filter = ', filter);
              const title = row[filter.id].props.children;
              console.log('row title = ', title);
              return title.indexOf(filter.value) != -1 || title.indexOf(filter.value.toUpperCase()) != -1 || title.indexOf(filter.value.toLowerCase()) != -1;
            }
          },
          {
            width: 140,
            id: 'status',
            Header: '상태',
            accessor: d => {
              let _color = 'warning'
              if (d.contentVersionStatus === 2) {_color="primary"}
              if (d.contentVersionStatus === 3) {_color="danger"}
              return <Badge color={_color}>{CONTENT_VERSION_STATUS_STRING[d.contentVersionStatus || 0]}</Badge>
            },
            filterMethod: (filter, row) => {
              if (filter.value === "all") {
                return true;
              }
              if (CONTENT_VERSION_STATUS_STRING[filter?.value] === row[filter.id].props.children) {
                return true;
              }
              return false;
            },
            Filter: ({ filter, onChange }) =>
              <select
                onChange={event => {
                  console.log('event.target.value = ', event.target.value);
                  return onChange(event.target.value)
                }}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
              >
                <option value="all">전체</option>
                {
                  CONTENT_VERSION_STATUS_STRING.map((status, idx) => <option key={idx} value={idx}>{status}</option>)
                }
              </select>
          },
          {
            Header: "설명",
            accessor: "changelog",
            filterMethod: (filter, row) => {
              console.log('filter = ', filter);
              const title = row[filter.id];
              console.log('row title = ', title);
              return title && title.indexOf(filter.value) != -1;
            }
          },
          {
            id: 'createdAt',
            Header: "등록일시",
            accessor: d => d.createdAt ? moment(d.createdAt.toDate()).format('YYYY-MM-DD HH:mm') : '',
            filterMethod: (filter, row) => {
              console.log('filter = ', filter);
              const title = row[filter.id];
              console.log('row title = ', title);
              return title && title.indexOf(filter.value) != -1;
            }
          },
          {
            id: 'updatedAt',
            Header: "수정일시",
            accessor: d => d.updatedAt ? moment(d.updatedAt.toDate()).format('YYYY-MM-DD HH:mm') : '',
            filterMethod: (filter, row) => {
              console.log('filter = ', filter);
              const title = row[filter.id];
              console.log('row title = ', title);
              return title && title.indexOf(filter.value) != -1;
            }
          },
          {
            Header: "명령",
            accessor: "actions",
            sortable: false,
            filterable: false
          }
        ]}
      >
        <ContentVersionForm />
      </Board>
    </div>
  )
}

export default ContentVersions
