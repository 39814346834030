import { getDefaultRouteByRole } from "layouts/DefaultRoutes";
import _ from "lodash";
/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState, useCallback } from "react";
import { useFirebase } from "react-redux-firebase";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Container,
  Col,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";

//const logo = require('../../assets/img/logo-modoo.png'); 이전 로고
const logo = require("../../assets/img/logo_modoo.png");

function Login({ history }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { i18n } = useTranslation();

  const firebase = useFirebase();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const unblock = useCallback(history.block());
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  useEffect(() => {
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  });

  const changeLanguageBtn = (lng) => {
    i18n.changeLanguage(lng);
  };

  const login = () => {
    return firebase
      .login({ email, password })
      .then(() => {
        console.log("login 후에");
        if (unblock) {
          console.log("block?");
          unblock();
        }
        console.log("to / ??");
        history.push("/");
      })
      .catch((e) => {
        setError(e);
        switch (e.code) {
          case "auth/invalid-email":
            setMessage(i18n.t("유효하지 않은 이메일입니다"));
            break;
          case "auth/user-not-found":
            setMessage(i18n.t("이메일 또는 비밀번호를 확인해주세요"));
            break;
          case "auth/wrong-password":
            setMessage(i18n.t("이메일 또는 비밀번호를 확인해주세요"));
            break;
        }
        console.log("message보여줘", message);
        // setTimeout(() => setError(''), 3000);
      });
  };

  const submit = () => login();

  return (
    <div className="login-page">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form action="" className="form" method="">
              <Card className="card-login">
                <CardHeader>
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggle}
                    className="float-right"
                  >
                    <DropdownToggle className="bg-transparent text-muted float-right">
                      {i18n.t("한국어")}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => changeLanguageBtn("ko")}>
                        한국어
                      </DropdownItem>
                      <DropdownItem onClick={() => changeLanguageBtn("en")}>
                        English
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </CardHeader>
                <CardHeader>
                  <img src={logo} className="text-center p-5" />
                  <h3 className="header text-center">{i18n.t("로그인")}</h3>
                </CardHeader>
                <CardBody>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={i18n.t("이메일 주소")}
                      type="text"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={i18n.t("비밀번호")}
                      type="password"
                      autoComplete="off"
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyDown={(e) => (e.key === "Enter" ? submit() : null)}
                    />
                  </InputGroup>

                  <Button
                    block
                    className="btn-round mb-3"
                    color="warning"
                    href="#pablo"
                    onClick={login}
                  >
                    {i18n.t("시작하기")}
                  </Button>
                </CardBody>
                <CardFooter>
                  <p className="text-danger text-center">{message}</p>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("assets/img/bg/platform-bg.jpeg")})`,
        }}
      />
    </div>
  );
}

export default Login;
