/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import "lang/i18n";
import i18n from "i18next";
import App from "App";

var userLanguage = window.navigator.userLanguage || window.navigator.language;
i18n.changeLanguage(
  ["korean", "ko", "kr"].includes(userLanguage) ? "ko" : "en"
);

ReactDOM.render(<App />, document.getElementById("root"));
