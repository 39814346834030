import { getCustomClaimsFromLocalStorage } from "helpers/localStorage";
import { roles } from "variables/constants";

export const getDefaultRouteByRole = (role) => {
  switch (role) {
    case roles.admin:
      return "/admin/home";
    case roles.channelAdmin:
      return "/channel-admin/groups";
    case roles.groupAdmin:
      return "/group-admin/group";
    case roles.teacher:
      return "/teacher/subgroup";
    case roles.parents:
      return "/parents/member";
    case roles.student:
      return "/student/member";
    default:
      return "/";
  }
};

export const checkAuthorization = async (path) => {
  const { role } = await getCustomClaimsFromLocalStorage();
  if (role === roles.admin && path === "admin") {
    return true;
  }

  if (role === roles.channelAdmin && path === "channel-admin") {
    return true;
  }

  if (role === roles.groupAdmin && path === "group-admin") {
    return true;
  }

  if (role === roles.teacher && path === "teacher") {
    return true;
  }

  if (role === roles.parent && path === "parents") {
    return true;
  }

  if (role === roles.student && path === "student") {
    return true;
  }

  return false;
};
