import React from "react";
import { Bar } from "react-chartjs-2";

function BarChartWithPercentile({ data }) {
  console.log("data = ", data);
  return (
    <div className="mt-4 px-2">
      <h5 className="text-muted">{data.title}</h5>
      <Bar height={250} data={data.dataset} options={data.options} />
      <div>
        <h6>{data.content}</h6>
      </div>
      {/* <div className="text-muted graph-comment">{data.comment}</div> */}
    </div>
  );
}

export default BarChartWithPercentile;
