export const gender = {
  남: 1,
  여: 2,
};

export const roles = {
  student: 1,
  parents: 2,
  teacher: 3,
  groupAdmin: 4,
  franchiser: 5,
  channelAdmin: 6,
  admin: 7,
};

export const rolesString = [
  "회원",
  "보호자",
  "클래스관리자",
  "가맹점관리자",
  "대리점관리자",
  "총판관리자",
  "최고관리자",
];

export const memberStatus = {
  leave: 1, //휴학, 병가
  normal: 2, // 재학
  transfered: 3, // 전학
  deleted: 4, // 퇴학
  excluded: 5, // 제외 인원
};

export const memberStatusString = ["휴학", "재학", "전학", "퇴학", "제외"];

export const contentTypes = ["SP", "MS", "MR", "TT"];

export const sensorTypes = ["KINECT- v2", "LIDAR", "ASTRA", "센서4", "센서5"];

export const skinTypes = [
  "옐로우",
  "MNC",
  "옐로우플레이",
  "아이스크림",
  "퍼플",
];

export const optionUITypes = [
  "Time",
  "Slider",
  "Select",
  "Multi Select",
  "Dropdown",
  "Checkbox",
];
export const optionDataTypes = [
  "integer",
  "float",
  "string",
  "object",
  "array",
  "datetime",
  "date",
  "time",
  "json",
];

export const courseTypes = [
  "preschool", //유치원
  "elementary school", //초등학교
  "institute", // 사설학원
];

export const timerTrainingCategories = [
  "베이직 트레이닝",
  "정적 트레이닝",
  "파워 트레이닝",
];

export const timerTrainingCodes = [
  { id: 1, title: "스트레칭", category: 0 },
  { id: 2, title: "제자리걷기", category: 0 },
  { id: 3, title: "제자리 사이드 스텝", category: 0 },
  { id: 4, title: "팔펴고 어깨 돌리기", category: 0 },
  { id: 5, title: "잼잼", category: 0 },
  { id: 6, title: "점핑잭", category: 0 },
  { id: 7, title: "스탠딩 사이드 니업", category: 0 },
  { id: 8, title: "잔발치기", category: 0 },
  { id: 9, title: "스쿼트 자세 버티기", category: 1 },
  { id: 10, title: "런지자세 버티기", category: 1 },
  { id: 11, title: "한 다리 균형 잡기", category: 1 },
  { id: 12, title: "플랭크", category: 1 },
  { id: 13, title: "호프", category: 2 },
  { id: 14, title: "엑스호프", category: 2 },
  { id: 15, title: "바운드", category: 2 },
  { id: 16, title: "인앤아웃", category: 2 },
  { id: 17, title: "플라이오잭", category: 2 },
  { id: 18, title: "스쿼트 점프", category: 2 },
  { id: 19, title: "스플릿 스쿼트 점프", category: 2 },
];

export const timerTrainingSet = [
  { id: 1, title: "근력", repeat: 1, contents: [5] },
];

export const msActivitiy = ["잼잼", "잼잼", "잼잼", "잼잼"];
export const meActivitiy = [
  "제자리 반복점프",
  "사이드 반복점프",
  "제자리 반복점프",
  "사이드 반복점프",
];
export const ceActivitiy = [
  "제자리 걷기",
  "제자리 걷기",
  "제자리 걷기",
  "제자리 걷기",
];
export const agActivitiy = [
  "전신반응검사",
  "사이드 스텝",
  "사이드 스텝",
  "전신반응검사",
];

// PC클라이언트 쪽의 요청: https://www.notion.so/80f7d0b37bf3464b9724cead09dbb9b6
export const sensorTypeStrings = [
  "센서가 없는 경우",
  "성능이 좋은 터치 센서",
  "성능이 좋지 않은 터치 센서",
  "깊이 카메라 센서",
];

export const activeStatus = {
  normal: 1, // 정상
  expired: 2, // 만료
  ban: 3, // 차단
};

export const activeStatusString = ["정상", "만료", "차단"];
export const activeStatusBoolean = {
  active: 1, // 활성화
  inactive: 2, // 비활성화
};

export const activeStatusBooleanString = ["활성화", "비활성화"];

export const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];

export const questionTypes = {
  ox: "OX퀴즈",
  "multiple choice": "객관식",
  "short answer": "주관식",
};

export const questionLanguages = {
  Korean: "한국어",
  English: "영어",
  Chinese: "중국어",
};
