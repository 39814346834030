import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useFirestoreConnect, useFirestore, populate, isLoaded, isEmpty } from 'react-redux-firebase';
import Board from 'views/components/Board.jsx'
import { NavLink } from "react-router-dom";
import ConfirmableForm from 'views/forms/ConfirmableForm';
import MemberForm from 'views/forms/MemberForm';
import { useTranslation } from "react-i18next";

// reactstrap components
import {
  Button,
  Row,
  ModalHeader,
  Modal,
  ModalBody,
  Alert,
  Col
} from "reactstrap";
import moment from 'moment-with-locales-es6';

// import CurriculumResultForm from 'views/forms/CurriculumResultForm';

moment.locale('ko');


function ClassPhysicalStrengths(props) {
  const { channel, group, subgroup, members } = props.state;
  const [selectedItem, setSelectedItem] = useState(null);
  const boardEl = useRef(null);
  const firestore = useFirestore();
  const [toastMessage, setToastMessage] = useState(null);
   console.log('group = ', group);
   console.log('subgroup = ', subgroup);
   console.log('members = ', members);
   const { t, i18n } = useTranslation();
  
  const [mergedMembers, setMergedMembers] = useState([]);

  const getLatestMeasurementFromMember = async (memberId) => {
    console.log("in get LatestMeasurement");
    const ref = firestore
      .collection(
        `channels/${channel.id}/groups/${group.id}/members/${memberId}/measurements`
      )
      .orderBy("createdAt", "desc")
      .limit(1);
    const colsnap = await ref.get();
    const MeasurementResult = colsnap.size > 0 ? colsnap.docs[0].data() : null;
    console.log("MeasurementResult = ", MeasurementResult);
    return MeasurementResult;
  };

  // let physicalTestList = [];

  const getContents = async () => {
    console.log("<<<<<<<<<< getContents");

    if (members) {
      const measurementList = await Promise.all(
        members?.map((member) => getLatestMeasurementFromMember(member.id))
      );
      console.log("MeasurementLists = ",measurementList);

      setMergedMembers(
        members.map((member, index) => ({
          ...member,
          measurement: measurementList[index],
        }))
      );
      
    }

  }

  useEffect(() => {
    getContents();
    console.log('set MergedMembers in measurement', mergedMembers);
    return () => {
      // cleanup
    };
  }, []);

  const data = mergedMembers.map((member) => ({
      ...member,
      actions: (
        <div className="actions-left">
          <Button
            onClick={() => {
              console.log('clicked');
              setSelectedItem(member);
            }}
            color="warning"
            size="sm"
            className="btn-icon btn-link edit"
          >
            <i className="fa fa-edit" style={{ fontSize: '1.5em' }} />
          </Button>{" "}
          {/* use this button to remove the data row */}
          <Button
            onClick={() => {
              console.log('DELETE clicked');
              boardEl.current.delete(async () => {
                try {
                  console.log(`@@@member.id`, member.id);
                  await firestore.doc(`channels/${channel.id}/groups/${group.id}/members/${member.id}`).delete();
                } catch (error) {
                  console.error('failed to delete member: ', error);
                }
              });
            }}
            color="danger"
            size="sm"
            className="btn-icon btn-link remove"
          >
            <i className="fa fa-times" style={{ fontSize: '1.5em' }} />
          </Button>{" "}
        </div>
      )
    }));

  return (
    <div className='content'>
      {alert}
      <Alert isOpen={Boolean(toastMessage)} toggle={() => setToastMessage(null)} color="info">
        {toastMessage}
      </Alert>
      <Board
        title={t("체력장 수행 목록")}
        hideAddNewButton
        data={data}
        ref={boardEl}
        columns={[
          {
            Header: t("회원 ID"),
            width: 80,
            accessor: 'classMemberNum'
          },
          {
            id: 'name',
            width: 150,
            Header: t("이름"),
            accessor: d => {
              // console.log('d', d);
              const member = _.clone(d);
              delete member.actions;
              return <NavLink
                to={{ pathname: `./member`, state: { channel, group, subgroup, member } }}
              >{d.name}</NavLink>
            },
            sortMethod: (a, b) => {
              const x = a.props.children;
              const y = b.props.children;
              if (x === y) {
                return x > y ? 1 : -1;
              }
              return x > y ? 1 : -1;
            },
            filterMethod: (filter, row) => {
              console.log('filter = ', filter);
              const title = row[filter.id].props.children;
              console.log('row title = ', title);
              return title && title.indexOf(filter.value) != -1;
            }
          },
          {
            id: 'createdAt',
            Header: t("측정일시"),
            accessor: d => d.createdAt ? moment(d.createdAt.toDate()).format('YYYY-MM-DD HH:mm') : ''
          },
          {
            id: 'height',
            Header: t("키"),
            accessor: d => d.measurement?.height? Number(d.measurement.height).toFixed(2):'',
          },
          {
            id: 'weight',
            Header: t("체중"),
            accessor: d => d.measurement?.weight?Number(d.measurement.weight).toFixed(2):''
          },
          {
            id: 'muscleMass',
            Header: t("근육량"),
            accessor: d => d.measurement?.muscleMass?Number(d.measurement.muscleMass).toFixed(2):''
          },
          {
            id: 'bodyFat',
            Header: t("체지방"),
            accessor: d => d.measurement?.muscleMass?Number(d.measurement.bodyFat).toFixed(2):'',
          },
          {
            id: 'updatedAt',
            Header: t("수정일시"),
            accessor: d => d.updatedAt ? moment(d.updatedAt.toDate()).format('YYYY-MM-DD HH:mm') : ''
          },
        ]}
      >
        <MemberForm
          channel={channel}
          group={group}
          subgroup={subgroup}
          member={selectedItem}
        // onCancel={() => setSelectedItem(null)} 
        />
      </Board>
      <Modal
        isOpen={Boolean(selectedItem)}
        size={'lg'}
      >
        <ModalHeader>멤버 정보</ModalHeader>
        <ModalBody>
          <ConfirmableForm>
            <MemberForm
              channel={channel}
              group={group}
              subgroup={subgroup}
              member={selectedItem}
              // 현재 setToastMessage - afterSave, afterDelete 작동 안함
              // afterSave={() => {
              //   console.log(`@@@afterSave 작동중`)
              //   setSelectedItem(null);
              //   setToastMessage('저장되었습니다.');
              //   setTimeout(() => setToastMessage(null), 3000);
              // }}
              // afterDelete={() => {
              //   setSelectedItem(null);
              //   setToastMessage('삭제되었습니다.');
              //   setTimeout(() => setToastMessage(null), 3000);
              // }}
              onCancel={() => setSelectedItem(null)} />
          </ConfirmableForm>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ClassPhysicalStrengths

