import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash';

import Board from 'views/components/Board.jsx'
import { useFirestoreConnect, useFirestore, useFirebase } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useTable } from 'react-table';
import { NavLink } from "react-router-dom";

import ReactRoundedImage from "react-rounded-image";
import empty from "../../assets/img/empty.png";

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Jumbotron,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { useRef, useEffect } from 'react';
import moment from 'moment';
import AddGroupForm from 'views/forms/AddGroupForm';
import GroupForm from 'views/forms/GroupForm';
import { roles, memberStatus } from 'variables/constants';
import { getCustomClaimsFromLocalStorage } from 'helpers/localStorage';
import ConfirmableForm from 'views/forms/ConfirmableForm';
import RevenueForm from 'views/forms/RevenueForm';
import { activeStatusString } from 'variables/constants';


function Groups({ location, history }) {

  const profile = useSelector(state => state.firebase.profile);
  const customClaims = getCustomClaimsFromLocalStorage();
  const { channelId } = customClaims;

  let channel = location?.state?.channel;
  if (!channel && profile?.role === roles.channelAdmin && profile?.channel) {
    console.log('profile.channel = ', profile.channel);
    channel = profile.channel;
  }

  const img = channel?.subLogo ? channel.subLogo : empty;

  const [settingOpen, setSettingOpen] = useState(false);
  const toggleSetting = () => setSettingOpen(prevState => !prevState);

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedGroupRevenue, setSelectedGroupRevenue] = useState(null);

  const firestore = useFirestore();
  const path = `channels/${channel?.id || channelId}/groups`;
  console.log('firestore path = ', path);
  useFirestoreConnect([
    {
      collection: path,
      orderBy: [
        ['title']
      ],
      storeAs: 'groups'
    },
  ]);

  const items = useSelector(state => state.firestore.ordered['groups']);

  console.log('items = ', items);

  const boardEl = useRef(null);

  const data = items
    ? items.map((item) => ({
      ...item,
      actions: (
        // we've added some custom button actions
        <div className="actions-left">
          <Button
            onClick={() => {
              console.log('clicked');
              setSelectedGroupRevenue(item);
            }}
            color="info"
            size="sm"
            className="btn-icon btn-link like"
          >
            <div className="icon-big"><i className="fa fa-dollar" /></div>
          </Button>{" "}
          <Button
            onClick={() => {
              console.log('clicked');
              // boardEl.current.edit(item);
              setSelectedGroup(item);
            }}
            color="warning"
            size="sm"
            className="btn-icon btn-link edit"
          >
            <i className="fa fa-edit" style={{ fontSize: '1.5em' }} />
          </Button>{" "}
          {/* use this button to remove the data row */}
          <Button
            onClick={() => {
              console.log('clicked');
              boardEl.current.delete(() => {
                firestore.doc(`channels/${channel.id}/groups/${item.id}`).delete();
              });
            }}
            color="danger"
            size="sm"
            className="btn-icon btn-link remove"
          >
            <i className="fa fa-times" style={{ fontSize: '1.5em' }} />
          </Button>{" "}
        </div>
      )
    }))
    : [];
  // console.log('data', data);

  return (
    <div className="content">
      <Breadcrumb className='sticky-top'>
        <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
        {
          profile.role === roles.admin
            ? <BreadcrumbItem><a href="./channels">총판 목록</a></BreadcrumbItem>
            : null
        }
        <BreadcrumbItem active>{channel?.title}</BreadcrumbItem>
      </Breadcrumb>
      <Jumbotron>
        <h1 className="display-5"><strong>{channel?.title}</strong></h1>
        <p className="lead">이곳에서 전체 <strong>{items?.length || 0}개</strong> 가맹점과 관련 정보를 확인할 수 있습니다.</p>
        <hr className="my-2" />
        <p><strong>⚠️NOTE</strong> 가맹점에서의 상태(정상/만료/차단) 변경은 가맹점 라이센스의 상태를 변경하게 됩니다.</p>
      </Jumbotron>
      <Board
        modalTitle="새 가맹점 추가"
        data={data}
        ref={boardEl}
        columns={[
          {
            id: 'title',
            Header: "가맹점명",
            accessor: d => {
              // console.log('d', d);
              const group = _.clone(d);
              delete group.actions;
              return <NavLink
                to={{ pathname: `./franchise`, state: { channel, group } }}
              >{d.title}</NavLink>
            },
            sortMethod: (a, b) => {
              const x = a.props.children;
              const y = b.props.children;
              if (x === y) {
                return x > y ? 1 : -1;
              }
              return x > y ? 1 : -1;
            },
            filterMethod: (filter, row) => {
              console.log('filter = ', filter);
              const title = row[filter.id].props.children;
              console.log('row title = ', title);
              return title && title.indexOf(filter.value) != -1;
            }
          },
          {
            Header: '카테고리',
            Header: '대리점',
            accessor: 'groupCategory.title'
          },
          {
            id: 'createdAt',
            Header: "30일내 실행횟수",
            accessor: d => d.createdAt ? moment(d.createdAt.toDate()).format('YYYY-MM-DD HH:mm') : ''
          },
          {
            id: 'createdAt',
            Header: "라이센스 개수",
            accessor: 'nLicenses'
          },
          {
            Header: "회원수",
            accessor: 'nMembers'
          },
          {
            Header: "클래스수",
            accessor: 'nSubgroups'
          },
          {
            id: 'status',
            Header: "상태",
            accessor: d => {
              const channel = _.clone(d);
              delete channel.actions;
              return activeStatusString[d.status - 1];
            }
          },
          {
            Header: "명령",
            accessor: "actions",
            sortable: false,
            filterable: false
          }
        ]}
      >
        <AddGroupForm channel={channel} />
      </Board>
      <Modal
        isOpen={Boolean(selectedGroup)}
        toggle={() => setSelectedGroup(null)}
      >
        <ModalHeader toggle={() => setSelectedGroup(null)}>가맹점 정보</ModalHeader>
        <ModalBody>
          <ConfirmableForm>
            <GroupForm
              channel={channel}
              group={selectedGroup}
              afterSave={() => setSelectedGroup(null)}
              onCancel={() => setSelectedGroup(null)}
            />
          </ConfirmableForm>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={Boolean(selectedGroupRevenue)}
        toggle={() => setSelectedGroupRevenue(null)}
      >
        <ModalHeader toggle={() => setSelectedGroupRevenue(null)}>가맹점 매출 정보</ModalHeader>
        <ModalBody>
          <ConfirmableForm>
            <RevenueForm
              channel={channel}
              group={selectedGroupRevenue}
              afterSave={() => setSelectedGroupRevenue(null)}
              onCancel={() => setSelectedGroupRevenue(null)}
            />
          </ConfirmableForm>
        </ModalBody>
      </Modal>
    </div>
  )
}

Groups.propTypes = {

}

export default Groups

