import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import {
  useFirestoreConnect,
  useFirestore,
  populate,
  isLoaded,
  isEmpty,
} from "react-redux-firebase";
import Board from "views/components/Board.jsx";
import { NavLink } from "react-router-dom";
import ConfirmableForm from "views/forms/ConfirmableForm";
import MemberForm from "views/forms/MemberForm";
import { useTranslation } from "react-i18next";

// reactstrap components
import {
  Button,
  Row,
  ModalHeader,
  Modal,
  ModalBody,
  Alert,
  Col,
} from "reactstrap";
import moment from "moment-with-locales-es6";

moment.locale("ko");

function ClassPhysicalStrengths(props) {
  const { channel, group, subgroup, members } = props.state;
  const [selectedItem, setSelectedItem] = useState(null);
  const boardEl = useRef(null);
  const firestore = useFirestore();
  const [toastMessage, setToastMessage] = useState(null);
  const { t, i18n } = useTranslation();
  console.log("group in class physical tests = ", group);
  console.log("subgroup  in class physical tests = ", subgroup);
  console.log("member in class physical tests= ", members);

  const [mergedMembers, setMergedMembers] = useState([]);

  const getLatestPhysicalTestsFromMember = async (memberId) => {
    console.log("in get LatestPhysicalTests");
    const ref = firestore
      .collection(
        `channels/${channel.id}/groups/${group.id}/members/${memberId}/physicalTests`
      )
      .orderBy("createdAt", "desc")
      .limit(1);
    const colsnap = await ref.get();
    const physicalTestResult = colsnap.size > 0 ? colsnap.docs[0].data() : null;
    console.log("physicalTestResult = ", physicalTestResult);
    return physicalTestResult;
  };

  // let physicalTestList = [];

  const getContents = async () => {
    console.log("<<<<<<<<<< getContents");

    if (members) {
      const physicalTestList = await Promise.all(
        members?.map((member) => getLatestPhysicalTestsFromMember(member.id))
      );
      console.log("physicalTesTLists = ", physicalTestList);

      setMergedMembers(
        members.map((member, index) => ({
          ...member,
          physicalTest: physicalTestList[index],
        }))
      );
    }
  }

  useEffect(() => {
    getContents();
    console.log('set MergedMembers in physical tests', mergedMembers);
    return () => {
      // cleanup
    };
  }, []);


  const data = mergedMembers.map((member) => ({
    ...member,
    actions: (
      <div className="actions-left">
        <Button
          onClick={() => {
            console.log("clicked");
            setSelectedItem(member);
          }}
          color="warning"
          size="sm"
          className="btn-icon btn-link edit"
        >
          <i className="fa fa-edit" style={{ fontSize: "1.5em" }} />
        </Button>{" "}
        {/* use this button to remove the data row */}
        <Button
          onClick={() => {
            console.log("DELETE clicked");
            boardEl.current.delete(async () => {
              try {
                console.log(`@@@member.id`, member.id);
                await firestore
                  .doc(
                    `channels/${channel.id}/groups/${group.id}/members/${member.id}`
                  )
                  .delete();
              } catch (error) {
                console.error("failed to delete member: ", error);
              }
            });
          }}
          color="danger"
          size="sm"
          className="btn-icon btn-link remove"
        >
          <i className="fa fa-times" style={{ fontSize: "1.5em" }} />
        </Button>{" "}
      </div>
    ),
  }));

  return (
    <div className="content">
      {alert}
      <Alert
        isOpen={Boolean(toastMessage)}
        toggle={() => setToastMessage(null)}
        color="info"
      >
        {toastMessage}
      </Alert>
      <Board
        title={t("체력장 수행 목록")}
        hideAddNewButton
        data={data}
        ref={boardEl}
        columns={[
          {
            Header: t("회원 ID"),
            width: 80,
            accessor: "classMemberNum",
          },
          {
            id: "name",
            width: 150,
            Header: t("이름"),
            accessor: (d) => {
              // console.log('d', d);
              const member = _.clone(d);
              console.log("personal member name =>", member.id);
              delete member.actions;
              return (
                <NavLink
                  to={{
                    pathname: `./member`,
                    state: { channel, group, subgroup, member },
                  }}
                >
                  {d.name}
                </NavLink>
              );
            },
            sortMethod: (a, b) => {
              const x = a.props.children;
              const y = b.props.children;
              if (x === y) {
                return x > y ? 1 : -1;
              }
              return x > y ? 1 : -1;
            },
            filterMethod: (filter, row) => {
              console.log("filter = ", filter);
              const title = row[filter.id].props.children;
              console.log("row title = ", title);
              return title && title.indexOf(filter.value) != -1;
            },
          },
          {
            id: "ms",
            Header: t("근력"),
            accessor: d => d.physicalTest ? d.physicalTest.ms : '0',
            // accessor: d=> members&&physicalTestList[physicalTestList.findIndex(i=>i.di==d.id)].ms,
          },
          {
            id: "me",
            Header: t("근지구력"),
            accessor: d => d.physicalTest ? d.physicalTest.me : '0',
          },
          {
            id: "ce",
            Header: t("심폐지구력"),
            accessor: d => d.physicalTest ? d.physicalTest.ce : '0',
          },
          {
            id: "me",
            Header: t("민첩성"),
            accessor: d => d.physicalTest ? d.physicalTest.ag : '0',
          },
          {
            id: "po",
            Header: t("순발력"),
            accessor: d => d.physicalTest ? d.physicalTest.po : '0',
          },
          {
            id: "co",
            Header: t("협응성"),
            accessor: d => d.physicalTest ? d.physicalTest.co : '0',
          },
          {
            id: "ba",
            Header: t("평형성"),
            accessor: d => d.physicalTest ? d.physicalTest.ba : '0',
          },
        ]}
      >
        <MemberForm
          channel={channel}
          group={group}
          subgroup={subgroup}
          member={selectedItem}
        // onCancel={() => setSelectedItem(null)}
        />
      </Board>
      <Modal isOpen={Boolean(selectedItem)} size={"lg"}>
        <ModalHeader>{t("맴버 정보")}</ModalHeader>
        <ModalBody>
          <ConfirmableForm>
            <MemberForm
              channel={channel}
              group={group}
              subgroup={subgroup}
              member={selectedItem}
              // 현재 setToastMessage - afterSave, afterDelete 작동 안함
              // afterSave={() => {
              //   console.log(`@@@afterSave 작동중`)
              //   setSelectedItem(null);
              //   setToastMessage('저장되었습니다.');
              //   setTimeout(() => setToastMessage(null), 3000);
              // }}
              // afterDelete={() => {
              //   setSelectedItem(null);
              //   setToastMessage('삭제되었습니다.');
              //   setTimeout(() => setToastMessage(null), 3000);
              // }}
              onCancel={() => setSelectedItem(null)}
            />
          </ConfirmableForm>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ClassPhysicalStrengths;
