import React, { useState, useEffect } from 'react'
import PropTypes, { number } from 'prop-types'

import _ from 'lodash';
import moment from 'moment';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Progress,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

import Select from "react-select";
import Switch from "react-bootstrap-switch";
import ReactDatetime from "react-datetime";

import { newGroupSchema } from '../../variables/schema';
import readXlsxFile from 'read-excel-file'
import { useFirebase, useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { licenseTypes } from 'variables/constants';
import { genLicenseKey } from 'helpers/licenseKey';
import { useSelector } from 'react-redux';
import { getSelectValues } from 'helpers/input';
import { sensorTypeStrings } from 'variables/constants';
import GroupPicker from 'views/components/GroupPicker';
import { skinTypes } from 'variables/constants';

function LicenseForm(props) {

  const [license, setLicense] = useState(props.currentItem || null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModified, setIsModified] = useState(false);
  const [onSaving, setOnSaving] = useState(false);

  const [availableContentIds, setAvailableContentIds] = useState(props.currentItem?.availableContentIds || props.channel?.availableContentIds || []);
  const [availableContents, setAvailableContents] = useState([]);
  const [numberOfLicenses, setNumberOfLicenses] = useState(1);

  const firebase = useFirebase();
  const firestore = useFirestore();


  useFirestoreConnect([
    {
      collection: 'sensors',
      orderBy: 'name',
      storeAs: 'sensors'
    }
  ]);

  const sensors = useSelector(state => state.firestore.ordered.sensors);
  console.log('sensors = ', sensors);

  useEffect(() => {
    if (license && props.channel !== license.channel) {
      const channelRef = firestore.doc(`channels/${license.channel.id}`);
      channelRef.get()
        .then(docSnap => {
          const { contentVersion, availableContentIds } = docSnap.data();
          console.log('availableContentIds = ', availableContentIds);
          setAvailableContentIds(availableContentIds)
          const contentsRef = firestore.collection(`contentVersions/${contentVersion}/contents`);
          return contentsRef.get()
        })
        .then(colsnap => {
          const contents = colsnap.docs.map(doc => ({ id: doc.ref.id, ...doc.data() }));
          return setAvailableContents(_.sortBy(contents, o => Number(o.id)));
        })
    }

    return () => {
      // cleanup
    }
  }, [license]);

  useEffect(() => {
    if (props.currentItem) {
      const _license = _.clone(props.currentItem);
      delete _license.id;
      setLicense(_license);
    }
    return () => {
      // cleanup
    }
  }, [props.currentItem]);

  useEffect(() => {
    const currentItem = _.cloneDeep(props.currentItem);
    if (currentItem === null && license && Object.keys(license)?.length <= 1) {
      return
    }
    const modified = !_.isEqual(currentItem, license);
    setIsModified(modified);
    return () => {
      // cleanup
    };
  }, [license]);

  const checkValidity = () => {

    console.log("Log test In checkValidity")
    console.log("license.numberOfLicenses : ", license.numberOfLicenses);
    console.log("license.channel : ", license.numberOfLicenses);
    console.log("license.numberOfLicenses", license?.numberOfLicenses);
    if (license?.numberOfLicenses && (license.numberOfLicenses < 1 || license.numberOfLicenses > 100)) {
      alert('라이센스 개수는 최소 1, 최대 100까지 입력할 수 있습니다.')
      return false;
    }
    if (!license?.channel) {
      alert('총판을 설정하셔야 합니다.');
      return false;
    }
    if (!license?.group) {
      alert('가맹점을 설정하셔야 합니다.')
      return false;
    }

    return true;
  }

  const onSave = async () => {
    console.log('press save');

    if (!checkValidity()) {
      return;
    }

    setOnSaving(true);

    const timestamp = {
      updatedAt: firestore.FieldValue.serverTimestamp()
    }

    try {
      // 기존 라이센스
      if (props.currentItem?.id) {
        const licenseRef = firestore.collection(`v2Licenses`).doc(props.currentItem?.id);
        console.log('license = ', license);
        await licenseRef.set({
          ...license,
          channelId: license?.channel?.id,
          groupId: license?.group?.id,
          ...timestamp,
        });
      } else {
        // 신규로 만든 경우
        timestamp['createdAt'] = firestore.FieldValue.serverTimestamp();
        await Promise.all(_.range(numberOfLicenses).map(idx => {
          const licenseRef = firestore.collection('v2Licenses').doc();
          return licenseRef.set({
            ...license,
            licenseKey: genLicenseKey(),
            channelId: license?.channel?.id,
            groupId: license?.group?.id,
            ...timestamp
          })
        }));
      }
    } catch (error) {
      console.error('Failed to save : ', error);
    }
    setOnSaving(false);
    props.afterSave && props.afterSave();
    props.toggle();
  }

  const onCancel = () => {
    if (isModified) {
      props.closeConfirm();
    } else {
      props.toggle();
    }
  }

  const onChange = (e, name, typeFn) => {
    if (name === 'skinType') {
      // console.log(`###여기서 동작해줘`);
      setLicense({ ...license, [name]: e.value });
      return;
    }
    const value = e.target.value;
    console.log('value = ', value);
    console.log('e.target = ', e.target);
    setLicense({ ...license, [name]: typeFn ? typeFn(value) : value });
  }

  const toggleSelectedContentWithSensorType = (sensorType) => {
    // console.log('sensorType = ', sensorType);
    console.log('availableContents = ', availableContents);
    const filteredContents = availableContents.filter(content => content.sensorType === sensorType);
    // console.log('filteredContents = ', filteredContents);
    setLicense({ ...license, availableContentIds: _.union(filteredContents.map(c => c.contentId), license?.availableContentIds) });
  }

  return (
    <div className='content'>
      <Row>
        <Col>
          <div className="text-right"><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span> 필수 입력 사항입니다</div>
        </Col>
      </Row>

      {
        !props.currentItem?.id &&
          <>       
          <Row>
            <Col><label>생성 라이센스 개수</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
            <Row>
              <Col xs={6}>
                <FormGroup>
                  <Input
                    type='number'
                    value={String(numberOfLicenses)}
                    min={1}
                    max={100}
                    onChange={(e) => {
                      const t = e.target.value;
                      const n = Number(t);
                      setNumberOfLicenses(n);
                    }}
                    onBlur={(e) => {
                      const t = e.target.value;
                      const n = Number(t);
                      if (n < 1 || n > 100) {
                        alert('최소 1, 최대 100까지 입력할 수 있습니다.')
                        setNumberOfLicenses(1);
                        return;
                      }
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
      }

      {/* licenseKey */}
      <Row>
        {
          props.currentItem?.id
            ? <Col xs={6}>
              <Row className="align-items-center justify-content-between">
                <Col><label>라이센스 키</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
              </Row>
              <FormGroup>
                <Input
                  disabled
                  value={license?.licenseKey || ''}
                  placeholder="XXXX-XXXX-XXXX-XXXX"
                  onChange={e => onChange(e, 'licenseKey')} />
              </FormGroup>
            </Col>
            : null
        }
        {/* active */}
        <Col xs={6}>
          <Row className="align-items-center justify-content-between">
            <Col><label>활성화 상태</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Switch
              onColor="default"
              offColor="default"
              defaultValue={license?.active || false}
              onChange={(el, state) => {
                setLicense({ ...license, [el.props.name]: state });
                console.log(`working@@@@@ 라이센스 엑티브`, license.active)
              }}
              name='active'
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <GroupPicker
            mode={2}
            viewMode={['channel', 'group']}
            channel={license?.channel}
            group={license?.group}
            cbSelected={(selected) => {
              const updatedData = {
                channel: _.pick(selected?.channel, ['id', 'title']),
                group: _.pick(selected?.group, ['id', 'title'])
              };
              console.log('updatedData = ', updatedData);
              setLicense({
                ...license,
                ...updatedData,
              });
            }}
          />
        </Col>
      </Row>
      {/* description */}
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>설명</label></Col>
          </Row>
          <FormGroup>
            <Input
              value={license?.description || ''}
              placeholder="본 라이센스 대한 설명"
              onChange={e => onChange(e, 'description')} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>라이센스 시작일</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <ReactDatetime
              inputProps={{
                className: "form-control",
                placeholder: "라이센스 시작일",
              }}
              value={license?.startAt
                ? license.startAt.toDate
                  ? moment(license.startAt.toDate()).format("YYYY-MM-DD")
                  : moment(license.startAt).format("YYYY-MM-DD")
                : ''}
              locale={'ko'}
              dateFormat={"YYYY-MM-DD"}
              timeFormat={"A hh:mm:ss"}
              onChange={(date) => {
                let newDate = date
                if (typeof date === 'string') {
                  newDate = moment(date)
                }
                if (newDate.isValid()) {
                  setLicense({ ...license, startAt: newDate.toDate() })
                }
              }}
            />
          </FormGroup>
        </Col>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>라이센스 종료일</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <ReactDatetime
              inputProps={{
                className: "form-control",
                placeholder: "라이센스 종료일",
              }}
              value={license?.endAt
                ? license.endAt.toDate
                  ? moment(license.endAt.toDate()).format("YYYY-MM-DD")
                  : moment(license.endAt).format("YYYY-MM-DD")
                : ''}
              locale={'ko'}
              dateFormat={"YYYY-MM-DD"}
              timeFormat={"A hh:mm:ss"}
              onChange={(date) => {
                let newDate = date
                if (typeof date === 'string') {
                  newDate = moment(date)
                }
                if (newDate.isValid()) {
                  setLicense({ ...license, endAt: newDate.toDate() })
                }
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      {/* clientVersion */}
      <Row>
      
        <Col xs={6}>
          <Row className="align-items-center justify-content-between">
            <Col><label>PC 최신 버전</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Input
              //disabled={Boolean(license?.createdAt)}
              value={license?.clientVersion || ''}
              placeholder="PC 최신 버전"
              onChange={e => onChange(e, 'clientVersion')} />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <Row className="align-items-center justify-content-between">
            <Col><label>PC 설치된 버전</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Input
              value={license?.distributedClientVersion || ''}
              placeholder="PC 클라이언트에 설치된 버전"
              onChange={e => onChange(e, 'distributedClientVersion')} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>사용하는 센서</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span>
            </Col>
          </Row>

          <FormGroup check>
            {
              sensors?.map((sensor, index) => {
                const selectedSensors = license?.sensors || [];
                console.log('selectedSensors = ', selectedSensors);
                return <Label key={index} check style={{ paddingRight: 20 }}>
                  <Input
                    defaultChecked={Boolean(_.findLast(selectedSensors, { name: sensor.name }))}
                    type="checkbox"
                    onChange={e => {
                      // console.log('e.target.checked = ', e.target.checked);
                      // console.log('sensor = ', sensor);

                      const newSensors = e.target.checked
                        ? _.union(selectedSensors, [sensor])
                        : selectedSensors.filter(s => s.id !== sensor.id);

                      // console.log('newSensors = ', newSensors);
                      setLicense({
                        ...license,
                        sensors: newSensors
                      })
                    }}
                  />
                  <span className="form-check-sign" />
                  {sensor.name}
                </Label>
              })
            }
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="align-items-center  justify-content-between">
            <Col><label>가용 콘텐츠</label></Col>
          </Row>
          <Row>
            {
              sensorTypeStrings.map((item, idx) => <Button key={idx} className='xs' onClick={() => toggleSelectedContentWithSensorType(idx)}>{item}</Button>)
            }
          </Row>
          <FormGroup>
            <Input
              type="select"
              name="selectMulti"
              id="availableContentIds"
              value={license?.availableContentIds}
              multiple
              onChange={(e) => {
                const selected = getSelectValues(e);
                const availableContentIds = selected.map(i => Number(i.value));
                console.log('availableContentIds = ', availableContentIds);
                setLicense({ ...license, availableContentIds });
              }}
            >
              {
                availableContents.map(content =>
                  <option value={content.contentId} key={content.contentId}>
                    [{content.contentId}] {content.title}
                  </option>)
              }
            </Input>
            <div className='text-info text-quote'>복수선택방법:<br />1) 맥에서는 Command + 클릭<br />2) 윈도우에서는 Control + 클릭</div>
          </FormGroup>
        </Col>
      </Row>
      
      <Row>
      <Col xs={6}>
          <Row className="align-items-center justify-content-between">
            <Col><label>스킨 타입</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Select
              className="react-select info"
              classNamePrefix="react-select"
              placeholder="스킨 타입 선택"
              name="multipleSelect"
              value={license?.skinType && { value: license?.skinType, label: skinTypes[license.skinType] }}
              onChange={e => onChange(e, 'skinType')}
              options={[
                ...skinTypes.map((item, idx) => ({ value: idx, label: item }))
              ]}
            />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <Row className="align-items-center justify-content-between">
            <Col><label>Non DB mode</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Switch
              onColor="default"
              offColor="default"
              defaultValue={license?.noneDBMode || false}
              onChange={(el, state) => {
                console.log('handleSwitch. el:', el);
                console.log('name:', el.props.name);
                console.log('new state:', state);
                setLicense({ ...license, [el.props.name]: state });
              }}
              name='noneDBMode'
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Row className="align-items-center justify-content-between">
            <Col><label>기기 ID</label></Col>
          </Row>
          <FormGroup>
            <Input
                value={license?.macAddress || ''}
                placeholder="XX-XX-XX-XX-XX-XX"
                onChange={e => onChange(e, 'macAddress')} />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <Row className="align-items-center justify-content-between">
            <Col><label>커리큘럼 사용여부</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Switch
              onColor="default"
              offColor="default"
              defaultValue={license?.useCurriculum || false}
              onChange={(el, state) => {
                console.log('handleSwitch. el:', el);
                console.log('name:', el.props.name);
                console.log('new state:', state);
                setLicense({ ...license, [el.props.name]: state });
              }}
              name='useCurriculum'
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Row className="align-items-center justify-content-between">
            <Col><label>체력장 사용 여부</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Switch
              onColor="default"
              offColor="default"
              defaultValue={license?.usePhysicalTest || false}
              onChange={(el, state) => {
                console.log('handleSwitch. el:', el);
                console.log('name:', el.props.name);
                console.log('new state:', state);
                setLicense({ ...license, [el.props.name]: state });
              }}
              name='usePhysicalTest'
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className='text-right'>
          <Button color="primary" disabled={onSaving || !isModified} onClick={onSave}>저장</Button>
          <Button color="warning" onClick={onCancel}>취소</Button>
        </Col>
      </Row>
      {/* {
        errorMessage
          ? <div>
            <p className="text-danger">{errorMessage}</p>
          </div>

          : null
      } */}
    </div>
  )
}


LicenseForm.propTypes = {
  license: PropTypes.object,
  channel: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  afterSave: PropTypes.func,
  onCancel: PropTypes.func,
};

export default LicenseForm

