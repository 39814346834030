import React from 'react';
import _ from 'lodash';

export default function MultipleSelector({ data }) {

  const { items, keys } = data; // items: 다중 선택할 리스트 아이템, keys: 선택한 아이템을 담을 키 배열

  // keys example
  /*
    [
      { id: 'availableContentIds', label: '가능한 콘텐츠', values: [], callbackFunction },
      { id: 'advertisementContentIds', label: '광고용 콘텐츠', values: [], callbackFunction }
    ]
  */


  console.log('items = ', items);
  console.log('keys = ', keys);

  return (
    <div className='mb-2'>
      <div className='row px-2'>
        <div className='col'>아이템</div>
        {
          keys.map(key => <div
            key={key.id}
            className='col'>{key.label} |
            <span
              className='btn btn-link'
              onClick={() => {
                if (key?.values?.length === items?.length) {
                  key.callbackFunction([]);
                } else {
                  key.callbackFunction(items.map(item => item.id));
                }
              }}>
              {
                key?.values?.length === items?.length
                  ? 'none'
                  : 'all'
              }
            </span>
          </div>)
        }
      </div>
      <div className='border p-2' style={{ maxHeight: '200px', overflow: 'auto' }}>
        {
          items.map((item, idx) =>
            <div key={idx} className='row'>
              <div className='col'>{item.label}</div>
              {
                keys.map(key => <div className='col' key={key.id}>
                  <input
                    checked={key.values?.includes(item.id)}
                    type='checkbox'
                    onChange={e => {
                      let updatedValues = [];
                      if (e.target.checked) {
                        updatedValues = _.union(key.values, [item.id]);
                      } else {
                        updatedValues = _.difference(key.values, [item.id]);
                      }
                      return key?.callbackFunction(updatedValues);
                    }} />
                </div>
                )
              }
            </div>)
        }

      </div>
    </div>
  )
}
