import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import _ from 'lodash';
import ReactBSAlert from "react-bootstrap-sweetalert";

import ConfirmableForm from './ConfirmableForm';

// reactstrap components
import {
  Label,
  FormGroup,
  Input,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";

import { useFirebase, useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { asyncForEach } from 'helpers/async';
import ImageUpload from 'components/CustomUpload/ImageUpload';
import { roles, activeStatusString, rolesString } from 'variables/constants';
import GroupCategoryPicker from 'views/components/GroupCategoryPicker';
import numeral from 'numeral';

function RevenueForm(props) {

  const { channel } = props;
  const [group, setGroup] = useState(props.group || null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModified, setIsModified] = useState(false);
  const profile = useSelector(state => state.firebase.profile);
  
  const [onSaving, setOnSaving] = useState(false);
  const [alert, setAlert] = useState(null);
  const [file, setFile] = useState(null);

  const firebase = useFirebase();
  const firestore = useFirestore();

  useEffect(() => {
    if (props.group) {
      const _group = _.clone(props.group);
      delete _group.id;
      setGroup(_group);
    }
    return () => {
      // cleanup
    }
  }, [props.group])

  useEffect(() => {
    const currentItem = _.cloneDeep(props.group);
    console.log(`currentItem`, currentItem);
    if (currentItem?.id) {
      delete currentItem.id;
    }
    const modified = !_.isEqual(currentItem, group) || file != null;
    // console.log(`file`, file);
    //console.log("modified: ", modified);
    setIsModified(modified);
    return () => {
      // cleanup
    };
  }, [group, file]);

  const onGroupCategorySelected = (groupCategory) => {
    console.log('groupCategory = ', groupCategory);
    if (groupCategory) {
      setGroup({
        ...group,
        groupCategory
      })
    }
  }

  const onSave = async () => {
    console.log('press save');
    setOnSaving(true);

    const timestamp = {
      updatedAt: firestore.FieldValue.serverTimestamp()
    }

    try {
      let groupRef = firestore.collection(`channels/${channel.id}/groups`);
      if (props.group?.id) {
        groupRef = groupRef.doc(props.group.id);
      } else {
        groupRef = groupRef.doc();
      }
      await groupRef.set({
        ...group,
        ...timestamp,
      });
    } catch (error) {
      console.error('Failed to save : ', error);
    }

    setOnSaving(false);
    props.afterSave && props.afterSave();
  }

  const onCancel = () => {
    console.log('onCancel!');
    if (isModified) {
      props.closeConfirm(() => props.onCancel());
    } else {
      props.onCancel();
    }
  }

  const onChange = (e, name, typeFn) => {
    const value = e.target.value;
    setGroup({ ...group, [name]: typeFn ? typeFn(value) : value });
  }

  return (
    <div className='content'>
      {alert}
      {/* title */}
      <Row>
        <Col>
          <div className="text-right"><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span> 필수 입력 사항입니다</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>가맹점 이름</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Input
              disabled
              value={group?.title || ''}
              />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>가맹점 판매금액</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            {
              profile.role > roles.groupAdmin?
              <Input
              value={ numeral(group?.sales).format('0,0') || 0 }
              placeholder="금액을 숫자로 입력해주세요 (예시) 200000000"
              onChange={e => onChange(e, 'sales', (t) => Number(t))} />
              :
              <Input
              disabled
              value={ numeral(group?.sales).format('0,0') || 0 }
              />
            }
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className='text-right'>
          <Button color="primary" disabled={onSaving || !Boolean(group?.title && group.title.length > 0) || !isModified} onClick={onSave}>저장</Button>
          <Button color="warning" onClick={onCancel}>취소</Button>
        </Col>
      </Row>
    </div>
  )
}


RevenueForm.propTypes = {
  group: PropTypes.object,
  afterSave: PropTypes.func,
  afterDelete: PropTypes.func,
  onCancel: PropTypes.func,
};

export default RevenueForm

