export const comments = {
  ms: [
    ["근력4-1", "근력4-2", "근력4-3", "근력4-4", "근력4-5"],
    ["근력3-1", "근력3-2", "근력3-3", "근력3-4", "근력3-5"],
    ["근력2-1", "근력2-2", "근력2-3", "근력2-4", "근력2-5"],
    ["근력1-1", "근력1-2", "근력1-3", "근력1-4", "근력1-5"],
  ],
  me: [
    ["근지구력4-1", "근지구력4-2", "근지구력4-3", "근지구력4-4", "근지구력4-5"],
    ["근지구력3-1", "근지구력3-2", "근지구력3-3", "근지구력3-4", "근지구력3-5"],
    ["근지구력2-1", "근지구력2-2", "근지구력2-3", "근지구력2-4", "근지구력2-5"],
    ["근지구력1-1", "근지구력1-2", "근지구력1-3", "근지구력1-4", "근지구력1-5"],
  ],
  ce: [
    [
      "심폐지구력4-1",
      "심폐지구력4-2",
      "심폐지구력4-3",
      "심폐지구력4-4",
      "심폐지구력4-5",
    ],
    [
      "심폐지구력3-1",
      "심폐지구력3-2",
      "심폐지구력3-3",
      "심폐지구력3-4",
      "심폐지구력3-5",
    ],
    [
      "심폐지구력2-1",
      "심폐지구력2-2",
      "심폐지구력2-3",
      "심폐지구력2-4",
      "심폐지구력2-5",
    ],
    [
      "심폐지구력1-1",
      "심폐지구력1-2",
      "심폐지구력1-3",
      "심폐지구력1-4",
      "심폐지구력1-5",
    ],
  ],
  ag: [
    ["민첩성4-1", "민첩성4-2", "민첩성4-3", "민첩성4-4", "민첩성4-5"],
    ["민첩성3-1", "민첩성3-2", "민첩성3-3", "민첩성3-4", "민첩성3-5"],
    ["민첩성2-1", "민첩성2-2", "민첩성2-3", "민첩성2-4", "민첩성2-5"],
    ["민첩성1-1", "민첩성1-2", "민첩성1-3", "민첩성1-4", "민첩성1-5"],
  ],
  po: [
    ["순발력4-1", "순발력4-2", "순발력4-3", "순발력4-4", "순발력4-5"],
    ["순발력3-1", "순발력3-2", "순발력3-3", "순발력3-4", "순발력3-5"],
    ["순발력2-1", "순발력2-2", "순발력2-3", "순발력2-4", "순발력2-5"],
    ["순발력1-1", "순발력1-2", "순발력1-3", "순발력1-4", "순발력1-5"],
  ],
  co: [
    ["협응성4-1", "협응성4-2", "협응성4-3", "협응성4-4", "협응성4-5"],
    ["협응성3-1", "협응성3-2", "협응성3-3", "협응성3-4", "협응성3-5"],
    ["협응성2-1", "협응성2-2", "협응성2-3", "협응성2-4", "협응성2-5"],
    ["협응성1-1", "협응성1-2", "협응성1-3", "협응성1-4", "협응성1-5"],
  ],
  ba: [
    ["평형성4-1", "평형성4-2", "평형성4-3", "평형성4-4", "평형성4-5"],
    ["평형성3-1", "평형성3-2", "평형성3-3", "평형성3-4", "평형성3-5"],
    ["평형성2-1", "평형성2-2", "평형성2-3", "평형성2-4", "평형성2-5"],
    ["평형성1-1", "평형성1-2", "평형성1-3", "평형성1-4", "평형성1-5"],
  ],
};
