import React from "react";
import PropTypes from "prop-types";

import _ from "lodash";

import Board from "views/components/Board.jsx";
import UserForm from "views/forms/UserForm";
import {
  useFirestoreConnect,
  useFirestore,
  useFirebase,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useTable } from "react-table";
import { activeStatusString } from "variables/constants";
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Jumbotron,
  Badge,
} from "reactstrap";
import { useState, useRef, useEffect } from "react";
import moment from "moment";
import { rolesString } from "variables/constants";
import { getCustomClaimsFromLocalStorage } from "helpers/localStorage";
import { roles } from "variables/constants";
import { useTranslation } from "react-i18next";

function Managers(props) {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [alert, setAlert] = useState(null);
  const firestore = useFirestore();
  const firebase = useFirebase();

  const customClaims = getCustomClaimsFromLocalStorage();
  const { role, channelId, groupId, subgroupIds } = customClaims;

  console.log("customClaims = ", customClaims);

  let whereOptions = null;
  switch (role) {
    case roles.admin:
      whereOptions = ["role", ">", roles.parents];
      break;
    case roles.channelAdmin:
      whereOptions = [
        ["channel.id", "==", channelId],
        ["role", ">", roles.parents],
      ];
      break;
    case roles.groupAdmin:
      whereOptions = [
        ["channel.id", "==", channelId],
        ["group.id", "==", groupId],
        ["role", "<", roles.groupAdmin],
      ];
      break;
    case roles.teacher:
      whereOptions = [
        ["channel.id", "==", channelId],
        ["group.id", "==", groupId],
        ["subgroup.id", "==", subgroupIds ? subgroupIds[0] : null],
        ["role", "<", roles.groupAdmin],
      ];
      break;
    default:
    //do nothing
  }

  console.log("whereOptions = ", whereOptions);

  useFirestoreConnect([
    {
      collection: "users",
      where: whereOptions,
    },
    {
      collection: "channels",
    },
  ]);

  const items = useSelector((state) => state.firestore.ordered.users);
  const channels = useSelector((state) => state.firestore.ordered.channels);
  const boardEl = useRef(null);

  const isManager = true;

  const basicAlert = (msg, onConfirm, onCancel, isSucess) => {
    setAlert(
      <ReactBSAlert
        success={isSucess}
        style={{ display: "block", marginTop: "-100px" }}
        title={msg || "Here's a message!"}
        onConfirm={() => (onConfirm ? onConfirm() : hideAlert())}
        onCancel={() => (onCancel ? onCancel() : hideAlert())}
        confirmBtnBsStyle="info"
      />
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  useEffect(() => {
    console.log("items=", items);
    const _data =
      items &&
      items.map((item) => ({
        ...item,
        actions: (
          <div className="actions-left">
            <Button
              onClick={() => {
                console.log("clicked");
                boardEl.current.edit(item);
              }}
              color="warning"
              size="sm"
              className="btn-icon btn-link edit"
            >
              <i className="fa fa-edit" style={{ fontSize: "1.5em" }} />
            </Button>{" "}
            <Button
              onClick={() => {
                console.log("clicked");
                boardEl.current.delete(async () => {
                  const functions = firebase.app().functions("asia-northeast1");
                  const deleteUser = functions.httpsCallable("deleteUser");
                  const response = await deleteUser({ uid: item.id });
                  console.log("deleteUser response = ", response);
                  basicAlert(
                    `계정을 성공적으로 삭제했습니다.`,
                    () => hideAlert(),
                    null,
                    true
                  );
                });
              }}
              color="danger"
              size="sm"
              className="btn-icon btn-link remove"
            >
              <i className="fa fa-times" style={{ fontSize: "1.5em" }} />
            </Button>{" "}
          </div>
        ),
      }));

    setData(_data);
    console.log("data=", _data);
    return () => {
      // cleanup
    };
  }, [items]);

  return (
    <div className="content">
      {alert}
      <Breadcrumb className="sticky-top">
        <BreadcrumbItem>
          <a href="/">Home</a>
        </BreadcrumbItem>
        <BreadcrumbItem active>관리자 계정 목록</BreadcrumbItem>
      </Breadcrumb>
      <Jumbotron>
        <h1 className="display-5">
          <strong>관리자 목록</strong>
        </h1>
        <p className="lead">
          이곳에서 등록된 전체 관리자 정보를 확인할 수 있습니다.
        </p>
        <hr className="my-2" />
        <p>
          <strong>⚠️NOTE</strong> 관리자 정보의 상태(정상/만료/차단) 변경은 aiai
          홈페이지 사용에 직접적인 영향을 미칩니다.
        </p>
      </Jumbotron>
      <Board
        modalTitle="관리자 입력폼"
        data={data}
        ref={boardEl}
        columns={[
          {
            Header: "이름",
            accessor: "name",
            filterMethod: (filter, row) => {
              console.log("filter = ", filter);
              const title = row[filter.id];
              console.log("row title = ", title);
              return title && title.indexOf(filter.value) != -1;
            },
          },
          {
            Header: "이메일",
            accessor: "email",
            filterMethod: (filter, row) => {
              console.log("filter = ", filter);
              const title = row[filter.id];
              console.log("row title = ", title);
              return title && title.indexOf(filter.value) != -1;
            },
          },
          {
            width: 100,
            id: "status",
            Header: "상태",
            accessor: (d) => {
              const channel = _.clone(d);
              delete channel.actions;
              let _color = "primary";
              if (d.status === 2) {
                _color = "warning";
              }
              if (d.status === 3) {
                _color = "danger";
              }
              return (
                <Badge color={_color}>
                  {activeStatusString[d.status - 1 || 0]}
                </Badge>
              );
            },
            filterMethod: (filter, row) => {
              if (filter.value === "all") {
                return true;
              }
              if (
                activeStatusString[filter?.value] ===
                row[filter.id].props.children
              ) {
                return true;
              }
              return false;
            },
            Filter: ({ filter, onChange }) => (
              <select
                onChange={(event) => {
                  console.log("event.target.value = ", event.target.value);
                  return onChange(event.target.value);
                }}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
              >
                <option value="all">전체</option>
                {activeStatusString.map((status, idx) => (
                  <option key={idx} value={idx}>
                    {t(status)}
                  </option>
                ))}
              </select>
            ),
          },
          {
            id: "channel",
            Header: "총판",
            accessor: "channel.title",
            filterMethod: (filter, row) => {
              if (filter.value === "all") {
                return true;
              }
              if (filter?.value === row[filter.id]) {
                return true;
              }
              return false;
            },
            Filter: ({ filter, onChange }) => (
              <select
                onChange={(event) => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
              >
                <option value="all">총판 전체</option>
                {channels?.map((channel) => (
                  <option key={channel.id} value={channel.title}>
                    {channel.title}
                  </option>
                ))}
              </select>
            ),
          },
          {
            Header: "가맹점",
            accessor: "group.title",
            filterMethod: (filter, row) => {
              console.log("filter = ", filter);
              const title = row[filter.id];
              console.log("row title = ", title);
              console.log(`filter.value`, filter.value);
              return title && title.indexOf(filter.value) != -1;
            },
          },
          {
            id: "role",
            Header: "권한",
            accessor: (d) =>
              _.isUndefined(d.role) ? "" : rolesString[d.role - 1],
            filterMethod: (filter, row) => {
              console.log("filter = ", filter);
              const title = row[filter.id];
              console.log("row title = ", title);
              return title && title.indexOf(filter.value) != -1;
            },
          },
          {
            id: "createdAt",
            Header: "등록일시",
            accessor: (d) =>
              d.createdAt
                ? moment(d.createdAt.toDate()).format("YYYY-MM-DD HH:mm")
                : "",
            filterMethod: (filter, row) => {
              console.log("filter = ", filter);
              const title = row[filter.id];
              console.log("row title = ", title);
              return title && title.indexOf(filter.value) != -1;
            },
          },

          {
            Header: "명령",
            accessor: "actions",
            sortable: false,
            filterable: false,
          },
        ]}
      >
        <UserForm state={isManager} modalTitle={"관리자"} />
      </Board>
    </div>
  );
}

Managers.propTypes = {};

export default Managers;
