import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useFirestoreConnect, useFirebaseConnect } from 'react-redux-firebase';
import _ from 'lodash';

import {
  Row,
  Button,
  Col,
  FormGroup,
  Input
} from 'reactstrap';

import Select from "react-select";

import { useFirebase, useFirestore } from 'react-redux-firebase';
import { courseTypes } from 'variables/constants';
import { useSelector } from 'react-redux';
import { timerTrainingCodes as _theCodes } from 'variables/constants';
import { getSelectValues } from 'helpers/input';


function LessonForm(props) {

  const [lesson, setLesson] = useState(props.currentItem || {});
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModified, setIsModified] = useState(false);
  const [onSaving, setOnSaving] = useState(false);
  const [lessonId, setLessonId] = useState(props.currentItem?.id || null);

  const [timerTrainingCodes] = useState(props.timerTrainingCodes || _theCodes);

  const firebase = useFirebase();
  const firestore = useFirestore();

  useEffect(() => {
    if (props.currentItem?.id) {
      setLessonId(props.currentItem.id);
    } else {
      setLessonId(firestore.collection(`curriculumVersions/${props.curriculumVersion.id}/courses/${props.course.id}/lessons`).doc().id);
    }

    return () => {
      // cleanup
    }
  }, []);

  useEffect(() => {
    if (props.currentItem) {
      const _lesson = _.clone(props.currentItem);
      delete _lesson.id;
      setLesson(_lesson);
    }
    return () => {
      // cleanup
    }
  }, [props.currentItem]);

  const checkValidity = () => {
  }

  useEffect(() => {
    const currentItem = _.cloneDeep(props.currentItem);
    console.log(`currentItem`, currentItem);
    if (currentItem?.id) {
      delete currentItem.id;
    }
    const modified = !_.isEqual(currentItem, lesson);
    console.log("modified: ", modified);
    setIsModified(modified);
    return () => {
      // cleanup
    };
  }, [lesson]);

  const onSave = async () => {
    console.log('press save');

    setOnSaving(true);

    const timestamp = {
      updatedAt: firestore.FieldValue.serverTimestamp()
    }

    if (!props.currentItem?.id) {
      timestamp['createdAt'] = firestore.FieldValue.serverTimestamp();
    }

    try {
      const lessonRef = firestore.collection(`curriculumVersions/${props.curriculumVersion.id}/courses/${props.course.id}/lessons`).doc(lessonId);
      console.log('lessonRef = ', lessonRef);
      console.log('lesson = ', lesson);
      console.log('timestamp = ', timestamp);
      const actionRemovedLesson = _.omit(lesson, ['actions']);
      await lessonRef.set({
        ...actionRemovedLesson,
        ...timestamp,
      });
    } catch (error) {
      console.error('Failed to save : ', error);
    }
    setOnSaving(false);
    props.afterSave && props.afterSave(() => props.onCancel());
  }

  const onCancel = () => {
    if (isModified) {
      props.closeConfirm();
    } else {
      props.toggle();
    }
  }


  const onChange = (e, name, typeFn) => {
    const value = e.target.value;
    setLesson({ ...lesson, [name]: typeFn ? typeFn(value) : value });
  }

  const addContent = () => {
    const { contents = [] } = lesson;
    setLesson({
      ...lesson,
      contents: [
        ...contents,
        {
          contentId: null,
        }
      ]
    });
  }

  const deleteContent = (idx) => {
    console.log('oldContents = ', lesson.contents);
    const newContents = lesson.contents.filter((item, i) => i !== idx);
    console.log('newContents = ', newContents);
    setLesson({ ...lesson, contents: newContents });
  }

  const addTTOption = (index) => {
    const { contents = [] } = lesson;
    const { options = [] } = contents[index];

    contents[index] = { ...contents[index], options: [...options, {}] }

    setLesson({
      ...lesson,
      contents
    });
  }

  return (
    <div className="content">
      <Row>
        <Col>
          <h5 className='text-info'>차시 정보</h5>
        </Col>
        <Col>
          <div className="text-right"><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span> 필수 입력 사항입니다</div>
        </Col>
      </Row>
      {/* 차시 */}
      <Row>
        <Col xs={6}>
          <Row className="align-items-center justify-course-between">
            <Col><label>차시</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Input
              value={lesson?.no || ''}
              placeholder="차시"
           //   disabled= {props.currentItem?.no}
              onChange={e => onChange(e, 'no', (text) => Number(text))}
            // valid={verification.no.isValid === true}
            // invalid={verification.no.isValid === false}
            />
          </FormGroup>
        </Col>
      </Row>
      {/* 차시 메모 */}
      <Row>
        <Col xs={6}>
          <Row className="align-items-center justify-course-between">
            <Col><label>차시 메모</label></Col>
          </Row>
          <FormGroup>
            <Input
              value={lesson?.memo || ''}
              placeholder="메모"
              onChange={e => onChange(e, 'memo')} />
          </FormGroup>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col xs={{ size: 'auto' }} style={{ paddingTop: 12 }}>
          <h5 className='text-info'>콘텐츠 추가</h5>
        </Col>
        <Col>
          <Button className="btn-icon btn-round" size="sm" color="primary" onClick={addContent}>
            <i className="fa fa-plus" />
          </Button>
        </Col>
      </Row>
      {
        lesson?.contents?.map((content, idx) => <Row key={idx}>
          <Col className="mb-2">
            <Row>
              <Col>
                <Row className='align-items-center justify-content-between'>
                  <Col><label>콘텐츠 선택하기</label></Col>
                </Row>
                <FormGroup>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="content"
                    value={{
                      value: content?.contentId || '',
                      label: (content?.contentId && props.contents) ? props.contents[content.contentId].title : '',
                    }}
                    onChange={({ value, label }) => {
                      if (value !== content?.contentId) {
                        const _content = _.find(props.allContents, { contentId: value });
                        const newContents = lesson.contents.map((item, index) => idx === index
                          ? _.pick(_content, ['contentId', 'options', 'contentType'])
                          : item);
                        setLesson({ ...lesson, contents: newContents });
                      }
                    }}
                    options={[
                      {
                        value: "",
                        label: "콘텐츠 선택",
                        isDisabled: true
                      },
                      ...props.allContents.map((item, idx) => ({ value: item.contentId, label: item.title }))
                    ]}
                    placeholder="콘텐츠 버전 설정하기"
                  />
                </FormGroup>
              </Col>
              <Col md="2" className='text-center text-vertical-center'>
                <Button className="btn-round btn-icon" size="sm" color="danger" onClick={() => deleteContent(idx)}>
                  <i className="fa fa-minus" />
                </Button>
              </Col>
            </Row>
            {
              content.contentType === 'TT'
                ? <Row className="ml-2 mb-2 mr-2 p-2 border border-light bg-light">
                  <Col>
                    <Row className="align-items-center">
                      <Col xs={{ size: 'auto', offset: 0, }} style={{ paddingTop: 12 }}>
                        <h6 className='text-info'>종목 추가</h6>
                      </Col>
                      <Col>
                        <Button className="btn-icon btn-round" size="sm" color="primary" onClick={() => addTTOption(idx)}>
                          <i className="fa fa-plus" />
                        </Button>
                      </Col>
                    </Row>
                    {
                      content.options.map((option, idx2) => <Row key={idx2} className="align-items-center mb-2">
                        <Col>타이머 트레이닝 종목 선택</Col>
                        <Col>
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name={''}
                            value={{
                              value: option.ttId,
                              label: _.find(timerTrainingCodes, { id: option.ttId })?.title
                            }}
                            onChange={({ value, label }) => {
                              if (value !== option?.value) {
                                const newContents = lesson.contents.map((item, index) =>
                                  idx === index
                                    ? { ...item, options: item.options.map((option2, idx3) => idx3 === idx2 ? { ...option, ttId: value, title: label } : option2) }
                                    : item);
                                setLesson({ ...lesson, contents: newContents });
                              }
                            }}
                            options={[
                              {
                                value: "",
                                label: "종목 선택",
                                isDisabled: true
                              },
                              ...timerTrainingCodes.map((item, idx) => ({ value: item.id, label: item.title }))
                            ]}
                          />
                        </Col>
                        <Col>
                          <Input
                            value={String(option?.length)}
                            placeholder="시간설정(초)"
                            type="number"
                            onChange={e => {
                              const newContents = lesson.contents.map((item, index) =>
                                idx === index
                                  ? { ...item, options: item.options.map((option2, idx3) => idx3 === idx2 ? { ...option, length: Number(e.target.value) } : option2) }
                                  : item);
                              setLesson({ ...lesson, contents: newContents });
                            }} />
                        </Col>
                        <Col md="2" className='text-center text-vertical-center'>
                        <Button className="btn-round btn-icon" size="sm" color="danger" onClick={(e) => {
                              const newContents = lesson.contents.map((item, index) =>
                                idx === index
                                  ? { ...item, options: item.options.filter((option2, idx3) => idx3 != index)}
                                  : item);
                              setLesson({ ...lesson, contents: newContents });
                            }}>
                          <i className="fa fa-minus" />
                        </Button>
                        </Col>
                      </Row>) 
                    }
                  </Col>
                </Row>
                : <Row className="ml-2 mb-2 mr-2 p-2 border border-light bg-light">
                  <Col>
                    {
                      content.options?.map((option, idx2) => <Row key={idx2}>
                        <Col xs={{ size: 'auto' }}>옵션 {idx2 + 1}</Col>
                        <Col>
                          {`${option?.title} - ${option?.id}`}
                          <FormGroup>
                            <Input
                              type="select"
                              name="selectMulti"
                              id="defaultValues"
                              value={_.isUndefined(option?.values)
                                ? option?.defaultValues
                                : option?.values}
                              multiple
                              onChange={(e) => {
                                const selected = getSelectValues(e);
                                const values = selected.map(i => Number(i.value));
                                console.log(values);
                                if (!_.isEqual(_.sortBy(values), _.sortBy(option.values))) {
                                  const newContents = lesson.contents.map((item, index) =>
                                    idx === index
                                      ? { ...item, options: item.options.map(option2 => option2.id === option.id ? { ...option, values } : option2) }
                                      : item);
                                  setLesson({ ...lesson, contents: newContents });
                                }
                              }}
                            >
                              {
                                option.items.map((item, index) =>
                                  <option value={index} key={index}>
                                    [index] {item}
                                  </option>
                                )
                              }
                            </Input>
                          </FormGroup>
                          {/* <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name={option?.id}
                            value={{
                              value: _.isUndefined(option?.value)
                                ? option?.defaultValues
                                  ? option?.defaultValues[0]
                                  : 0
                                : option?.value,
                              label: option?.items[
                                _.isUndefined(option?.value)
                                  ? option?.defaultValues
                                    ? option?.defaultValues[0]
                                    : 0
                                  : option?.value
                              ]
                            }}
                            onChange={({ value, label }) => {
                              if (value !== option?.value) {
                                const newContents = lesson.contents.map((item, index) =>
                                  idx === index
                                    ? { ...item, options: item.options.map(option2 => option2.id === option.id ? { ...option, value } : option2) }
                                    : item);
                                setLesson({ ...lesson, contents: newContents });
                              }
                            }}
                            options={[
                              {
                                value: "",
                                label: "옵션 값 선택",
                                isDisabled: true
                              },
                              ...option?.items?.map((item, idx) => ({ value: idx, label: item }))
                            ]}
                            placeholder="콘텐츠 버전 설정하기"
                          /> */}
                        </Col>
                      </Row>)
                    }
                  </Col>
                </Row>
            }
          </Col>
        </Row>
        )
      }
      {
        errorMessage
          ? <div>
            <p className="text-danger">{errorMessage}</p>
          </div>
          : null
      }
      <Row>
        <Col className='text-right'>
          <Button color="primary" disabled={onSaving || !Boolean(lesson.no)|| !isModified} onClick={onSave}>저장</Button>
          <Button color="warning" onClick={onCancel}>취소</Button>
        </Col>
      </Row>
    </div >
  )
}
LessonForm.propTypes = {
  curriculumVersion: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  allContents: PropTypes.array.isRequired,
}
export default LessonForm