import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import Board from "views/components/Board.jsx";
import { useFirestoreConnect, useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useTable } from "react-table";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { useRef, useEffect } from "react";
import moment from "moment";
import BodyMeasurementForm from "views/forms/BodyMeasurementForm";
import SparkChart from "components/Charts/SparkChart";

function Measurements(props) {
  // const { channel, group, subgroup, member } = props.location?.state;
  console.log("group = ", props.state);
  const { t, i18n } = useTranslation();
  const { channel, group, subgroup, member } = props.state;

  const firestore = useFirestore();
  useFirestoreConnect([
    {
      collection: `channels/${channel.id}/groups/${group.id}/members/${member.id}/measurements`,
      orderBy: [["createdAt", "desc"]],
      storeAs: "measurements",
    },
  ]);

  const items = useSelector((state) => state.firestore.ordered["measurements"]);
  const boardEl = useRef(null);

  const data =
    items &&
    items.map((item) => ({
      ...item,
      actions: (
        // we've added some custom button actions
        <div className="actions-left">
          {/* use this button to add a like kind of action */}
          {/* <Button
          onClick={() => {
            console.log('clicked');
            console.log(item);
            console.log(boardEl);
          }}
          color="info"
          size="sm"
          className="btn-icon btn-link like"
        >
          <i className="fa fa-heart" />
        </Button>{" "} */}
          {/* use this button to add a edit kind of action */}
          <Button
            onClick={() => {
              console.log("clicked");
              boardEl.current.edit(item);
            }}
            color="warning"
            size="sm"
            className="btn-icon btn-link edit"
          >
            <i className="fa fa-edit" style={{ fontSize: "1.5em" }} />
          </Button>{" "}
          {/* use this button to remove the data row */}
          <Button
            onClick={() => {
              console.log("clicked");
              boardEl.current.delete(() => {
                firestore
                  .doc(
                    `channels/${channel.id}/groups/${group.id}/members/${member.id}/measurements/${item.id}`
                  )
                  .delete();
              });
            }}
            color="danger"
            size="sm"
            className="btn-icon btn-link remove"
          >
            <i className="fa fa-times" style={{ fontSize: "1.5em" }} />
          </Button>{" "}
        </div>
      ),
    }));
  // console.log('data', data);

  return (
    <div style={{ marginTop: "10px" }} className="content">
      {items ? (
        <Row>
          <Col md={3}>
            <SparkChart
              dataset={items}
              title={t("키") + "(cm)"}
              fieldPath={"height"}
            />
          </Col>
          <Col md={3}>
            <SparkChart
              dataset={items}
              title={t("몸무게") + "(kg)"}
              fieldPath={"weight"}
            />
          </Col>
          <Col md={3}>
            <SparkChart
              dataset={items}
              title={t("체지방") + "(kg)"}
              fieldPath={"bodyFat"}
            />
          </Col>
          <Col md={3}>
            <SparkChart
              dataset={items}
              title={t("근육량") + "(kg)"}
              fieldPath={"muscleMass"}
            />
          </Col>
        </Row>
      ) : null}
      <Board
        // title={`${member?.name}님의 측정 목록`}
        modalTitle={t("새 측정 추가")}
        data={data}
        ref={boardEl}
        columns={[
          {
            id: "createdAt",
            Header: t("측정일시"),
            accessor: (d) =>
              d.createdAt
                ? moment(d.createdAt.toDate()).format("YYYY-MM-DD HH:mm")
                : "",
          },
          {
            id: "height",
            Header: t("키"),
            accessor: (d) => Number(d.height).toFixed(2),
          },
          {
            id: "weight",
            Header: t("체중"),
            accessor: (d) => Number(d.weight).toFixed(2),
          },
          {
            id: "muscleMass",
            Header: t("근육량"),
            accessor: (d) =>
              d?.muscleMass
                ? Number(d.muscleMass).toFixed(2)
                : t("결과치 없음"),
          },
          {
            id: "bodyFat",
            Header: t("체지방"),
            accessor: (d) =>
              d?.bodyFat ? Number(d.bodyFat).toFixed(2) : t("결과치 없음"),
          },
          {
            id: "updatedAt",
            Header: t("수정일시"),
            accessor: (d) =>
              d.updatedAt
                ? moment(d.updatedAt.toDate()).format("YYYY-MM-DD HH:mm")
                : "",
          },
          {
            Header: t("명령"),
            accessor: "actions",
            sortable: false,
            filterable: false,
          },
        ]}
      >
        <BodyMeasurementForm
          channel={channel}
          group={group}
          subgroup={subgroup}
          member={member}
        />
      </Board>
    </div>
  );
}

Measurements.propTypes = {};

export default Measurements;
