import React, { useState, useEffect } from 'react'

import { useFirestoreConnect } from 'react-redux-firebase';
import _ from 'lodash';
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  FormGroup,
  Input,
} from "reactstrap";

import Select from "react-select";

import readXlsxFile from 'read-excel-file'
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { asyncForEach } from 'helpers/async';
import { newContentSchema } from 'variables/schema';
import { CONTENT_VERSION_STATUS_STRING } from 'variables/status';
import { auth } from 'firebase';

function ContentVersionForm(props) {

  console.log('props = ', props);
  const [contentVersion, setContentVersion] = useState(props.currentItem || {});
  const [infoMessage, setInfoMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModified, setIsModified] = useState(false);
  const [onSaving, setOnSaving] = useState(false);
  const [alert, setAlert] = useState(null);
  
  const firebase = useFirebase();
  const firestore = useFirestore();

  useEffect(() => {
    checkValidity();
    return () => {
      // cleanup
    }
  }, [contentVersion])

  const checkValidity = () => {
    console.log('contentVersion In checkValidity = ', contentVersion);
    const { version, changelog, contentVersionStatus } = contentVersion;

    console.log('version = ', version);
    console.log('changelog', changelog);
    console.log('contentVersionState = ', contentVersionStatus);

    if (!version || version.length < 3) {
      setErrorMessage('버전 이름을 제대로 입력해주세요.');
      return false;
    }

    if (contentVersionStatus === undefined) {
      setErrorMessage('콘텐츠 버전의 상태값을 설정해주세요.');
      return false;
    }

    setErrorMessage(null); // no error
    return true;
  }
  const onDelete = async () => {
    console.log('>>>>>>>>>>>>>>>>>>>>>del??');
    const ref = firestore.collection(`contentVersions`).doc(props.currentItem.id);
    await ref.delete();
    props.toggle();
    props.afterDelete && props.afterDelete();
  }

  const warningWithConfirmAndCancelMessage = () => setAlert(
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title={<div>삭제하면 복구할 수 없습니다.<br />계속 진행하시겠습니까?</div>}
      onConfirm={() => {
        onDelete();
        setAlert(null);
      }}
      onCancel={() => setAlert(null)}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      confirmBtnText="확인"
      cancelBtnText="취소"
      showCancel
    >
      지우면 완전히 삭제됩니다. 지우시겠습니까?
        </ReactBSAlert>
  )

  useEffect(() => {
    const currentItem = _.cloneDeep(props.currentItem);
    //object.keys의 역할 - 민호
    console.log('contentVersion check =>', Object.keys(contentVersion));
    if (currentItem == null && Object.keys(contentVersion).length === 0) {
      return
    }
    console.log("before modified: ", !_.isEqual(currentItem, contentVersion));
    const modified = !_.isEqual(currentItem, contentVersion);
    console.log(`currentItem`, currentItem);
    console.log(`contentVersion`, contentVersion);
    console.log("modified: ", modified);
    setIsModified(modified);
    console.log("checking isModified when open new form in content");
    return () => {
      // cleanup
    };
  }, [contentVersion]);

  const onSave = async () => {
    console.log('press save');

    if (!checkValidity()) {
      // setErrorMessage('유효하지 않는 값입니다.');
      return;
    }

    setOnSaving(true);

    let timestamp;

    if (props.currentItem?.id) {
      timestamp = {
        updatedAt: firestore.FieldValue.serverTimestamp()
      };
    } else {
      timestamp = {
        createdAt: firestore.FieldValue.serverTimestamp(),
        updatedAt: firestore.FieldValue.serverTimestamp()
      };
    }

    try {
      const contentVersionRef = firestore.collection('contentVersions').doc(contentVersion.version);

      if (!props.currentItem) {
        const docSnap = await contentVersionRef.get();
        if (docSnap.exists) {
          setErrorMessage('이미 존재하는 버전으로 생성할 수 없습니다.');
          throw Error('exisiting-version-error');
        }
      }

      await contentVersionRef.set({
        ...contentVersion,
        ...timestamp,
      });
      setErrorMessage(null);
      props.toggle();
      props.afterSave && props.afterSave();
    } catch (error) {
      console.error('Failed to save : ', error);
    } finally {
      setOnSaving(false);
    }
  }
  const onCancel = () => {
    if (isModified) {
      props.closeConfirm();
    } else {
      console.log('check cancel in content version form');
      props.toggle();
    }
   }

  const onChange = (e, name, typeFn) => {
    const value = e.target.value;
    setContentVersion({ ...contentVersion, [name]: typeFn ? typeFn(value) : value });
  }


  return (

    <div className='content'>
      {alert}
      <Row>
        <Col>
          <div className="text-right"><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span> 필수 입력 사항입니다</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className='align-items-center justify-content-between'>
            <Col><label>콘텐츠 버전 이름</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={contentVersion?.version || ''}
              disabled={Boolean(props.currentItem)}
              placeholder='예) 1.1.0'
              onChange={e => onChange(e, 'version')} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
      <Col><label>※ 콘텐츠 버전 이름의 경우, 추후 수정불가</label></Col>
      </Row>
      <Row>
        <Col>
          <Row className='align-items-center justify-content-between'>
            <Col><label>콘텐츠 버전 설명</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}> </span>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={contentVersion?.changelog || ''}
              type="textarea"
              placeholder={'예)- 1.0.5 콘텐츠 "스페이스디펜스" 추가/삭제'}
              onChange={e => onChange(e, 'changelog')} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className='align-items-center justify-content-between'>
            <Col><label>상태</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span>
            </Col>
          </Row>
          <FormGroup>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="contentVersionStatus"
              value={{
                value: contentVersion?.contentVersionStatus,
                label: CONTENT_VERSION_STATUS_STRING[contentVersion?.contentVersionStatus]
              }}
              onChange={value => {
                // console.log('value', value);
                if (value !== contentVersion?.contentVersionStatus) {
                  setContentVersion({ ...contentVersion, contentVersionStatus: value.value })
                  // console.log('program', program);
                }
              }}
              options={[
                ...(
                  contentVersion
                    ? CONTENT_VERSION_STATUS_STRING.map((item, idx) => ({ value: idx, label: item }))
                    : []
                )
              ]}
              placeholder="콘텐츠 버전 설정하기"
            />
          </FormGroup>
        </Col>
      </Row>
      {/* {
        errorMessage
          ? <div>
            <p className="text-danger">{errorMessage}</p>
          </div>
          : null
      } */}
      <Row>
        {/* {
          props.currentItem?.id
            ? <Col>
              <Button color="danger" onClick={warningWithConfirmAndCancelMessage}>삭제</Button>
            </Col>
            : null
        } */}

        <Col className='text-right'>
          <Button color="primary" disabled={onSaving || !isModified || Boolean(errorMessage)} onClick={onSave}>
            {
              onSaving ?
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                : null
            }
            저장
            </Button>
          <Button color="warning" onClick={onCancel}>취소</Button>
        </Col>
      </Row>

    </div>
  )
}

export default ContentVersionForm
