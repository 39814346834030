import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useFirestoreConnect, useFirestore, populate, isLoaded, isEmpty } from 'react-redux-firebase';
import Board from 'views/components/Board.jsx'
import { NavLink } from "react-router-dom";
import ConfirmableForm from 'views/forms/ConfirmableForm';
import MemberForm from 'views/forms/MemberForm';
import { useTranslation } from "react-i18next";

// reactstrap components
import {
  Button,
  Row,
  ModalHeader,
  Modal,
  ModalBody,
  Alert,
  Col
} from "reactstrap";
import moment from 'moment-with-locales-es6';
import { checkPropTypes } from 'prop-types';

moment.locale('ko');

function ClassPerformedCurriculums(props) {
  const { channel, group, subgroup, members } = props.state;
  const [selectedItem, setSelectedItem] = useState(null);
  const boardEl = useRef(null);
  const firestore = useFirestore();
  const [toastMessage, setToastMessage] = useState(null);
  const { t, i18n } = useTranslation();
  console.log('subgroup curriculumProgress =>', subgroup.curriculumProgress);

  const data = subgroup?.curriculumProgress?.map((member) => ({
    ...member,
    actions: (
      <div className="actions-left">
        <Button
          onClick={() => {
            console.log('clicked');
            setSelectedItem(member);
          }}
          color="warning"
          size="sm"
          className="btn-icon btn-link edit"
        >
          <i className="fa fa-edit" style={{ fontSize: '1.5em' }} />
        </Button>{" "}
        {/* use this button to remove the data row */}
      </div>
    )
  }));

  return (
    <div className='content'>
      {alert}
      <Alert isOpen={Boolean(toastMessage)} toggle={() => setToastMessage(null)} color="info">
        {toastMessage}
      </Alert>
      <Board
        title={t("커리큘럼 목록")}
        // hideAddNewButton
        data={data}
        ref={boardEl}
        columns={[
          {
            Header: t("차시"),
            accessor: 'curriculumNo'
          },
          {
            id: 'progress',
            Header: t("진행율(%)"),
            accessor: d => d.progress?Number(d.progress).toFixed(2):'',
          },
          {
            Header: "",
          },
        ]}
      >
        <MemberForm
          channel={channel}
          group={group}
          subgroup={subgroup}
          member={selectedItem}
        // onCancel={() => setSelectedItem(null)} 
        />
      </Board>
      <Modal
        isOpen={Boolean(selectedItem)}
        size={'lg'}
      >
        <ModalHeader>멤버 정보</ModalHeader>
        <ModalBody>
          <ConfirmableForm>
            <MemberForm
              channel={channel}
              group={group}
              subgroup={subgroup}
              member={selectedItem}
              // 현재 setToastMessage - afterSave, afterDelete 작동 안함
              // afterSave={() => {
              //   console.log(`@@@afterSave 작동중`)
              //   setSelectedItem(null);
              //   setToastMessage('저장되었습니다.');
              //   setTimeout(() => setToastMessage(null), 3000);
              // }}
              // afterDelete={() => {
              //   setSelectedItem(null);
              //   setToastMessage('삭제되었습니다.');
              //   setTimeout(() => setToastMessage(null), 3000);
              // }}
              onCancel={() => setSelectedItem(null)} />
          </ConfirmableForm>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ClassPerformedCurriculums

