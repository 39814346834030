import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useFirestoreConnect, useFirestore, populate, isLoaded, isEmpty } from 'react-redux-firebase';
import Board from 'views/components/Board.jsx'
import { NavLink } from "react-router-dom";
import ConfirmableForm from 'views/forms/ConfirmableForm';
import MemberForm from 'views/forms/MemberForm';
import { IgrLinearGaugeModule } from 'igniteui-react-gauges';
import { IgrLinearGauge } from 'igniteui-react-gauges';
import { useTranslation } from "react-i18next";


// reactstrap components
import {
  Button,
  Row,
  ModalHeader,
  Modal,
  ModalBody,
  Alert,
  Col
} from "reactstrap";
import moment from 'moment-with-locales-es6';

moment.locale('ko');

function ClassMemberLists(props) {
  const { channel, group, subgroup, members } = props.state;
  const [selectedItem, setSelectedItem] = useState(null);
  const boardEl = useRef(null);
  const firestore = useFirestore();
  const [toastMessage, setToastMessage] = useState(null);
  const { t, i18n } = useTranslation();
  // console.log('group = ', group);
  // console.log('subgroup = ', subgroup);
   console.log('members in classmemberLists = ', members);

  const data = members
    ? members.map((member) => ({
      ...member,
      actions: (
        <div className="actions-left">
          <Button
            onClick={() => {
              console.log('clicked');
              setSelectedItem(member);
            }}
            color="warning"
            size="sm"
            className="btn-icon btn-link edit"
          >
            <i className="fa fa-edit" style={{ fontSize: '1.5em' }} />
          </Button>{" "}
          {/* use this button to remove the data row */}
          <Button
            onClick={() => {
              console.log('DELETE clicked');
              boardEl.current.delete(async () => {
                try {
                  const memberRef = firestore.collection(`channels/${channel.id}/groups/${group.id}/members`).doc(member.id);
                  await memberRef.delete();
                  const subgroupRef = firestore.doc(`channels/${channel.id}/groups/${group.id}/subgroups/${subgroup.id}`);
                  await subgroupRef.update({
                    members: firestore.FieldValue.arrayRemove(memberRef.id)
                  });
                } catch (error) {
                  console.error('failed to delete member: ', error);
                }
              });
            }}
            color="danger"
            size="sm"
            className="btn-icon btn-link remove"
          >
            <i className="fa fa-times" style={{ fontSize: '1.5em' }} />
          </Button>{" "}
        </div>
      )
    }))
    : [];

  return (
    <div className='content'>
      {alert}
      <Alert isOpen={Boolean(toastMessage)} toggle={() => setToastMessage(null)} color="info">
        {toastMessage}
      </Alert>
      
      <Board
        title={t("회원목록")}
        // hideAddNewButton
        data={data}
        ref={boardEl}
        columns={[
          {
            Header: t("회원 ID"),
            width: 80,
            accessor: 'classMemberNum'
          },
          {
            id: 'name',
            width: 150,
            Header: t("이름"),
            accessor: d => {
              // console.log('d', d);
              const member = _.clone(d);
              delete member.actions;
              return <NavLink
                to={{ pathname: `./member`, state: { channel, group, subgroup, member } }}
              >{d.name}</NavLink>
            },
            sortMethod: (a, b) => {
              const x = a.props.children;
              const y = b.props.children;
              if (x === y) {
                return x > y ? 1 : -1;
              }
              return x > y ? 1 : -1;
            },
            filterMethod: (filter, row) => {
              console.log('filter = ', filter);
              const title = row[filter.id].props.children;
              console.log('row title = ', title);
              return title && title.indexOf(filter.value) != -1;
            }
          },
          {
            Header: t("생년월일"),
            id: 'birthday',
            accessor: d => d.birthday ? d?.birthday : ''
          },
          {
            Header: t("나이"),
            id: 'age',
            accessor: d => d.age ? d?.age : ''
          },
          {
            id: 'gender',
            Header: t("성별"),
            accessor: d => d.gender ? d?.gender : ''
          },
          {
            id: 'startAt',
            Header: t("수강시작일"),
            accessor: d => d.startAt ?  moment(d.startAt.toDate()).format('YYYY-MM-DD') : ''
          },

          {
            id: 'endAt',
            Header: t("수강종료일"),
            accessor: d => d.endAt ?  moment(d.endAt.toDate()).format('YYYY-MM-DD') : '',
          },

          {
            Header: t("명령"),
            accessor: "actions",
            sortable: false,
            filterable: false
          }
        ]}
      >
        <MemberForm
          channel={channel}
          group={group}
          subgroup={subgroup}
          member={selectedItem}
          onCancel={() => setSelectedItem(null)}
        />
      </Board>
      <Modal
        isOpen={Boolean(selectedItem)}
        size={'lg'}
      >
        <ModalHeader>멤버 정보</ModalHeader>
        <ModalBody>
          <ConfirmableForm>
            <MemberForm
              channel={channel}
              group={group}
              subgroup={subgroup}
              member={selectedItem}
              onCancel={() => setSelectedItem(null)}
              afterSave={() => {
                console.log(`@@@afterSave 작동중`)
                setSelectedItem(null);
                setToastMessage('저장되었습니다.');
                setTimeout(() => setToastMessage(null), 3000);
              }}
              afterDelete={() => {
                setSelectedItem(null);
                setToastMessage('삭제되었습니다.');
                setTimeout(() => setToastMessage(null), 3000);
              }}
            />
          </ConfirmableForm>
        </ModalBody>
      </Modal>
    </div>
  )
}


export default ClassMemberLists

