import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _, { isUndefined } from 'lodash';

import Board from 'views/components/Board.jsx'
import { useFirestoreConnect, useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useTable } from 'react-table';
import { NavLink } from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ReactTable from "react-table";
import Charts from "views/Charts.jsx";
import {Line, Bar} from 'react-chartjs-2';
import { useTranslation } from "react-i18next";

//for charts
import {chartExample9, chartExample10} from "variables/charts.jsx";

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  CardTitle,
  CardText,
  Table,
  Row,
  Col,
  Jumbotron,
  Badge
} from "reactstrap";
import { useRef, useEffect } from 'react';
import moment from 'moment';
import ChannelForm from 'views/forms/ChannelForm';
import Select from "react-select";

const data2 = {
  // labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [{
      type: 'line',
      label: '가맹점',
      data: [97, 89, 92, 97, 106, 120],
      fill: false,
      backgroundColor: '#13B784',
      borderColor: '#13B784',
      hoverBackgroundColor: '#13B784',
      hoverBorderColor: '#13B784',
      yAxisID: 'y-axis-2'
    },{
      label: '사용자',
      type:'bar',
      data: [5200, 4800, 5500, 6400, 6000, 6700],
      fill: false,
      borderColor: '#fbc658',
      backgroundColor: '#fbc658',
      pointBorderColor: '#fbc658',
      pointBackgroundColor: '#fbc658',
      pointHoverBackgroundColor: '#fbc658',
      pointHoverBorderColor: '#fbc658',
      yAxisID: 'y-axis-1'
    },]
  };

const options = {
  responsive: true,
  labels: ['20년 06월', '20년 07월', '20년 08월', '20년 09월', '20년 10월', '20년 11월'],
  tooltips: {
    mode: 'label'
  },
  elements: {
    line: {
      fill: false
    }
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: false
        },
        labels: ['20년 06월', '20년 07월', '20년 08월', '20년 09월', '20년 10월', '20년 11월'],
      }
    ],
    yAxes: [
      {
        type: 'linear',
        display: true,
        position: 'left',
        id: 'y-axis-2',
        scaleLabel: {
          display: true,
          labelString: '가맹점 수'
        },
        gridLines: {
          display: false
        },
        labels: {
          show: true
        }
      },
      {
        type: 'linear',
        display: true,
        position: 'right',
        id: 'y-axis-1',
        scaleLabel: {
          display: true,
          labelString: '사용자 수'
        },
        gridLines: {
          display: false
        },
        labels: {
          show: true
        }
      }
    ]
  }
  };

function Home(props) {
  const { t, i18n } = useTranslation()

  const firestore = useFirestore();
  useFirestoreConnect([
    { collection: 'channels' },
    { collection: 'contentVersions' },
    {
      collectionGroup: 'groups',
      storeAs: 'groups'
    },
  ]);

  const [optionChannels, setOptionChannels] = useState([]);
  const channelItems = useSelector(state => state.firestore.ordered.channels);
  const groupItems = useSelector(state => state.firestore.ordered['groups']);
  const contentVersions = useSelector(state => state.firestore.ordered.contentVersions);
  const boardEl = useRef(null);
  const [alert, setAlert] = useState(null);


useEffect(() => {
  if (channelItems) {
    console.log('channels = ', channelItems);
    setOptionChannels(channelItems);
  }
  return () => {
    // cleanup
  }
}, [channelItems])


  const numeral = require('numeral');

  let totalNumOfGroups = 0;
  let totalNumOfMembers = 0;
  let totalSales = 0;

  // channelItems.map((item))
  for (var i in channelItems){
    totalNumOfGroups += channelItems[i].nGroups;
    totalNumOfMembers += channelItems[i].nMembers;
    totalSales += channelItems[i].sales;
  }

  const channeldata = channelItems && channelItems.map((item) => (
    {
    ...item,
    actions: (
      <div className="actions-left">
        <Button
          onClick={() => {
            console.log('clicked');
            boardEl.current.edit(item);
          }}
          color="warning"
          size="sm"
          className="btn-icon btn-link edit"
        >
          <i className="fa fa-edit" style={{ fontSize: '1.5em' }} />
        </Button>{" "}
        <Button
          onClick={() => {
            console.log('clicked');
            warningWithConfirmAndCancelMessage(item);
          }}
          color="danger"
          size="sm"
          className="btn-icon btn-link remove"
        >
          <i className="fa fa-times" style={{ fontSize: '1.5em' }} />
        </Button>{" "}
      </div>
    )
  }))

  const groupdata = groupItems && groupItems.map((item) => ({
    ...item,
    actions: (
      <div className="actions-left">
        <Button
          onClick={() => {
            console.log('clicked');
            boardEl.current.edit(item);
          }}
          color="warning"
          size="sm"
          className="btn-icon btn-link edit"
        >
          <i className="fa fa-edit" style={{ fontSize: '1.5em' }} />
        </Button>{" "}
        <Button
          onClick={() => {
            console.log('clicked');
            warningWithConfirmAndCancelMessage(item);
          }}
          color="danger"
          size="sm"
          className="btn-icon btn-link remove"
        >
          <i className="fa fa-times" style={{ fontSize: '1.5em' }} />
        </Button>{" "}
      </div>
    )
  }))


  const warningWithConfirmAndCancelMessage = (item) => setAlert(
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title={<div>삭제하면 복구할 수 없습니다.<br />계속 진행하시겠습니까?</div>}
      onConfirm={() => {
        firestore.doc(`channels/${item.id}`).delete();
        setAlert(null);
      }}
      onCancel={() => setAlert(null)}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      confirmBtnText="확인"
      cancelBtnText="취소"
      showCancel
    >
      지우면 완전히 삭제됩니다. 지우시겠습니까?
        </ReactBSAlert>
  )

  const yesterday = moment().subtract(1,'days').format("YYYY.MM.DD");
  const current = moment().format("YY.MM.DD HH:MM");
  const today = moment().format("MM.DD");
  const thisMonth = moment().format("MM");
  const lasstMonth = moment().format("MM");
  
  
  return (
    <div className="content msp">
      {alert}
      {/* <Breadcrumb className='sticky-top'>
        <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
      </Breadcrumb> */}
       <Row>
          <Col lg="4" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
              <Row>
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-globe text-primary" />
                  </div>
                </Col>
                <Col md="8" xs="7">
                  <div className="numbers">
                    <p className="card-category">{t("채널 수")}</p>
                    <CardTitle tag="p">{channelItems?.length || 0}</CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>                      
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats text-right">
                  실시간 기준 | {current}
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="4" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-layout-11 text-success" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">가맹점 수</p>
                      <CardTitle tag="p">{totalNumOfGroups}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats text-right">
                실시간 기준 | {current}
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="4" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-badge text-danger" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">회원 수</p>
                      <CardTitle tag="p">{numeral( totalNumOfMembers ).format( '0,0' )}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats text-right">
                실시간 기준 | {current}
                </div>
              </CardFooter>
            </Card>
          </Col>
          {/* <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-money-coins text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">매출 (천원)</p>
                      <CardTitle tag="p">{numeral( totalSales ).format( '0,0' )}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats text-right">
                  이번달 기준 | {thisMonth}.01 ~ {today}
                </div>
              </CardFooter>
            </Card>
          </Col> */}
        </Row>
        <Row>
          <Col md="6">
            <Card className="card-chart">
              <CardHeader>
                <CardTitle tag="h4">총판별 통계</CardTitle>
                <p className="card-category">
                - 전월 대비 총판별 통계
                </p>
            </CardHeader>
            <CardBody>
              <ReactTable
                previousText={'<'}
                nextText={'>'}
                data={channeldata}
                columns={[
                  {
                    Header: "총판명",
                    accessor: "title"
                  },
                  {
                    Header: "가맹점수",
                    id: "numberOfGroupCategories",
                    accessor: d => {
                      const gap = d.nGroupCategories - d.nGroupCategoriesPrevMonth;
                      const badgeColor = gap > 0 ? 'success' : gap ? 'danger' :'primary';
                      const symbol = gap > 0 ? '▲' : gap ? '▼' :'';
                    return <div>{d.nGroupCategories} &nbsp; {symbol && <Badge color={badgeColor}>{symbol}{Math.abs(gap) || ""}</Badge>}</div>
                    },
                  },
                  {
                    Header: "대리점수",
                    id: "numberOfGroups",
                    accessor: d => {
                      const gap2 = d.Groups - d.GroupsPrevMonth;
                      const badgeColor2 = gap2 > 0 ? 'success' : gap2 ? 'danger' :'primary';
                      // const symbol2 = gap2 > 0 ? '▲' : gap2 ? '▼' :'⁃';
                      const symbol2 = gap2 > 0 ? '▲' : gap2 ? '▼' :'';
                    return <div>{d.nGroups} &nbsp; {symbol2 && <Badge color={badgeColor2}>{symbol2}{Math.abs(gap2) || ""}</Badge>}</div>
                    },
                  },
                  {
                    Header: "회원수",
                    id: "nMembers",
                    accessor: d => {
                      const gap3 = d.nMembers - d.nMembersPrevMonth;
                      // console.log('gap3', gap3);
                      const badgeColor3 = gap3 > 0 ? 'success' : gap3 ? 'danger' :'primary';
                      const symbol3 = gap3 > 0 ? '▲' : gap3 ? '▼' :'';
                    return <div>{d.nMembers} &nbsp; {symbol3 && <Badge color={badgeColor3}>{symbol3}{Math.abs(gap3) || ""}</Badge>}</div>
                    },
                  },
                ]}
                // loading={true}
                showPageJump={false}
                pageText={'페이지'}
                ofText={'/'}
                defaultPageSize={5}
                showPageSizeOptions={false}
                // showPaginationBottom
                /*
                  You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                */
                className="-striped -highlight primary-pagination"
              />
            </CardBody>
          </Card>
          </Col>
          {/* <Col md="5">
          <Card className="card-chart">
            <CardHeader>
              <CardTitle tag="h4">가맹점/회원 수 변화 그래프</CardTitle>
              <p className="card-category">- 최근 6개월 데이터</p>
            </CardHeader>
            <CardBody>
              <Bar
              data={data2}
              options={options}
              // plugins={plugins}
              height={200}
              />
              <Select
              className="react-select"
              classNamePrefix="react-select"
              options={[
               ...optionChannels.map((item, idx)=> ({
                 value: item,
                 label: item.title
               }))
              ]}
              placeholder="총판 선택"
            />
            </CardBody>
          </Card>
          </Col> */}
          <Col md="6">
          <Card className="card-chart">
          <CardHeader>
            <CardTitle tag="h4">가맹점 통계</CardTitle>
            <p className="card-category">
              - 전월 대비 가맹점 통계
            </p>
            </CardHeader>
            <CardBody>
              <ReactTable
                previousText={'<'}
                nextText={'>'}
                data={groupdata}
                columns={[
                  {
                    Header: "가맹점명",
                    accessor: "title"
                  },
                  {
                    Header: "라이센스수",
                    id: "numOfLicenses",
                    accessor: d => {
                      const gap4 = d.nLicenses - d.nLicensesPrevMonth;
                      // console.log('gap4', gap4);
                      const badgeColor4 = gap4 > 0 ? 'success' : gap4 ? 'danger' :'primary';
                      const symbol4 = gap4 > 0 ? '▲' : gap4 ? '▼' :'';
                    return <div>{d.nLicenses} &nbsp; {symbol4 && <Badge color={badgeColor4}>{symbol4}{Math.abs(gap4) || ""}</Badge>}</div>
                    }
                  },
                  {
                    Header: "클래스수",
                    id: "numOfSubgroups",
                    accessor: d => {
                      const gap5 = d.nSubgroups - d.nSubgroupsPrevMonth;
                      // console.log('gap5', gap5);
                      const badgeColor5 = gap5 > 0 ? 'success' : gap5 ? 'danger' :'primary';
                      const symbol5 = gap5 > 0 ? '▲' : gap5 ? '▼' :'';
                    return <div>{d.nSubgroups} &nbsp; {symbol5 && <Badge color={badgeColor5}>{symbol5}{Math.abs(gap5) || ""}</Badge>}</div>
                    }
                  },
                  {
                    Header: "회원수",
                    id: "numOfMembers",
                    accessor: d => {
                      const gap6 = d.nMembers - d.nMembersPrevMonth;
                      console.log('gap6', gap6);
                      const badgeColor6 = gap6 > 0 ? 'success' : gap6 ? 'danger' :'primary';
                      const symbol6 = gap6 > 0 ? '▲' : gap6 ? '▼' :'';
                    return <div>{d.nMembers} &nbsp; {symbol6 && <Badge color={badgeColor6}>{symbol6}{Math.abs(gap6) || ""}</Badge>}</div>
                    }
                  },
                ]}
                // loading={true}
                showPageJump={false}
                pageText={'페이지'}
                ofText={'/'}
                defaultPageSize={5}
                showPageSizeOptions={false}
                // showPaginationBottom
                /*
                  You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                */
                className="-striped -highlight primary-pagination"
              />
            </CardBody>
          </Card>
        </Col>
        {/* <Col md="5">
          <Card className="card-chart">
            <CardHeader>
            <CardTitle tag="h4">매출 변화 그래프</CardTitle>
            <p className="card-category"> 최근 6개월 데이터</p>
            </CardHeader>
            <CardBody>
              <Line
                data={chartExample9.data}
                options={chartExample9.options}
              />
              <Select
              className="react-select"
              classNamePrefix="react-select"
              options={[
               ...optionChannels.map((item, idx)=> ({
                 value: item,
                 label: item.title
               }))
              ]}
              placeholder="총판 선택"
            />
            </CardBody>
          </Card>
        </Col> */}
      </Row>
    </div>
  )
}

Home.propTypes = {

}

export default Home

