import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import {
  useFirestore,
  useFirebase,
  useFirestoreConnect,
} from "react-redux-firebase";

import Tree from "@naisutech/react-tree";
import Select from "react-select";

import { Row, Col, FormGroup } from "reactstrap";
import { get } from "lodash";
import { roles } from "variables/constants";
import { useTranslation } from "react-i18next";

import _ from "lodash";

function GroupPicker({
  role,
  channel: originalChannel,
  group: originalGroup,
  subgroup: originalSubgroup,
  member: originalMember,
  channelId,
  groupId,
  mode = 3,
  cbSelected,
  viewMode = ["channel", "group", "subgroup", "member"],
}) {
  console.log("mode = ", mode);

  const [optionChannels, setOptionChannels] = useState([]);
  const [optionGroups, setOptionGroups] = useState([]);
  const [optionSubgroups, setOptionSubgroups] = useState([]);
  const [optionMembers, setOptionMembers] = useState([]);

  const [selectedChannel, setSelectedChannel] = useState(
    originalChannel || null
  );
  const [selectedGroup, setSelectedGroup] = useState(originalGroup || null);
  const [selectedSubgroup, setSelectedSubgroup] = useState(
    originalSubgroup || null
  );
  const [selectedMember, setSelectedMember] = useState(originalMember || null);
  const { t, i18n } = useTranslation();
  console.log("role = ", selectedChannel);
  try {
    useFirestoreConnect([
      {
        collection: "channels",
        orderBy: "title",
      },
    ]);
  } catch (error) {
    console.error("failed to get channels", error);
  }

  const firestore = useFirestore();

  const channels = useSelector((state) => state.firestore.ordered.channels);

  const reset = () => {
    initOptions();
  };

  const onSelect = (selection) => {};

  const initOptions = async () => {
    const forceUpdate = true;
    if (selectedChannel) {
      await selectChannel(selectedChannel, forceUpdate);
    }
    if (selectedGroup) {
      await selectGroup(selectedGroup, forceUpdate);
    }
    if (selectedSubgroup) {
      await selectSubgroup(selectedSubgroup, forceUpdate);
    }
    if (selectedMember) {
      await selectMember(selectedMember, forceUpdate);
    }
  };

  useEffect(() => {
    console.log("selected channel = ", selectedChannel);
    console.log("selected group = ", selectedGroup);
    console.log("selected subgroup = ", selectedSubgroup);
    initOptions();
    return () => {
      // cleanup
    };
  }, []);

  useEffect(() => {
    console.log("ueffect channel", originalChannel);
    setSelectedChannel(originalChannel);
    return () => {
      // cleanup
    };
  }, [originalChannel]);

  useEffect(() => {
    console.log("ueffect group", originalGroup);
    setSelectedGroup(originalGroup);
    return () => {
      // cleanup
    };
  }, [originalGroup]);

  useEffect(() => {
    console.log("ueffect subgroup", originalSubgroup);
    setSelectedSubgroup(originalSubgroup);
    return () => {
      // cleanup
    };
  }, [originalSubgroup]);

  useEffect(() => {
    console.log("ueffect member", originalMember);
    setSelectedMember(originalMember);
    return () => {
      // cleanup
    };
  }, [originalMember]);

  const getGroups = async (selectedChannel) => {
    console.log(`channels/${selectedChannel.id}/groups`);
    try {
      const ref = firestore
        .collection(`channels/${selectedChannel.id}/groups`)
        .orderBy("title");
      const colSnap = await ref.get();
      return colSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (e) {
      return Promise.reject("failed to get groups: ", e);
    }
  };

  const getSubgroups = async (selectedGroup) => {
    console.log(
      `channels/${selectedChannel.id}/groups/${selectedGroup.id}/subgroups`
    );
    try {
      const ref = firestore
        .collection(
          `channels/${selectedChannel.id}/groups/${selectedGroup.id}/subgroups`
        )
        .orderBy("title");
      const colSnap = await ref.get();
      return colSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (e) {
      return Promise.reject("failed to get subgroups: ", e);
    }
  };

  const getMembers = async (selectedSubgroup) => {
    // console.log(`channels/${selectedChannel.id}/groups/${selectedGroup.id}/subgroups/${selectedSubgroup.id}`);
    try {
      const ref = firestore.doc(
        `channels/${selectedChannel.id}/groups/${selectedGroup.id}/subgroups/${selectedSubgroup.id}`
      );
      const docSnap = await ref.get();
      const { members: memberIds = [] } = docSnap.data();
      console.log("memberIds = ", memberIds);
      const memberDocs = await Promise.all(
        memberIds.map(
          async (uid) =>
            await firestore
              .doc(
                `channels/${selectedChannel.id}/groups/${selectedGroup.id}/members/${uid}`
              )
              .get()
        )
      );
      const members = memberDocs.map((doc) => ({ id: doc.id, ...doc.data() }));
      console.log("members = ", members);
      return members;
    } catch (e) {
      return Promise.reject("failed to get members ", e);
    }
  };

  useEffect(() => {
    if (channels) {
      console.log("channels = ", channels);
      setOptionChannels(channels);
    }
    return () => {
      // cleanup
    };
  }, [channels]);

  useEffect(() => {
    if (optionChannels && optionChannels.length > 0) {
      if (channelId) {
        setSelectedChannel(_.findLast(optionChannels, { id: channelId }));
      }
    }

    return () => {
      // cleanup
    };
  }, [optionChannels]);

  useEffect(() => {
    if (optionGroups && optionGroups.length > 0) {
      if (groupId) {
        setSelectedGroup(_.findLast(optionGroups, { id: groupId }));
      }
    }
    return () => {
      // cleanup
    };
  }, [optionGroups]);

  const selectChannel = async (channel, force = false) => {
    if (!channel) {
      setOptionGroups(null);
      setSelectedChannel(null);
      setSelectedGroup(null);
      setSelectedSubgroup(null);
      setSelectedMember(null);
      setOptionSubgroups([]);
      cbSelected({
        channel: null,
        group: null,
        subgroup: null,
        member: null,
        original: {
          channel: originalChannel,
          group: originalGroup,
          subgroup: originalSubgroup,
          member: originalMember,
        },
      });
    } else if (force || selectedChannel?.id !== channel.id) {
      const groups = await getGroups(channel);
      setOptionGroups(groups);
      setSelectedChannel(channel);
      setSelectedGroup(null);
      setSelectedSubgroup(null);
      setSelectedMember(null);
      setOptionSubgroups([]);
      cbSelected({
        channel,
        group: null,
        subgroup: null,
        member: null,
        original: {
          channel: originalChannel,
          group: originalGroup,
          subgroup: originalSubgroup,
          member: originalMember,
        },
      });
    }
  };

  const selectGroup = async (group, force = false) => {
    if (!group) {
      setOptionSubgroups(null);
      setSelectedGroup(null);
      setSelectedSubgroup(null);
      setSelectedMember(null);
      cbSelected({
        channel: selectedChannel,
        group: null,
        subgroup: null,
        member: null,
        original: {
          channel: originalChannel,
          group: originalGroup,
          subgroup: originalSubgroup,
          member: originalMember,
        },
      });
    } else if (force || selectedGroup?.id !== group.id) {
      const subgroups = await getSubgroups(group);
      setOptionSubgroups(subgroups);
      setSelectedGroup(group);
      setSelectedSubgroup(null);
      setSelectedMember(null);
      cbSelected({
        channel: selectedChannel,
        group,
        subgroup: null,
        member: null,
        original: {
          channel: originalChannel,
          group: originalGroup,
          subgroup: originalSubgroup,
          member: originalMember,
        },
      });
    }
  };

  const selectSubgroup = async (subgroup, force = false) => {
    if (!subgroup) {
      setOptionMembers(null);
      setSelectedSubgroup(null);
      setSelectedMember(null);
      cbSelected({
        channel: selectedChannel,
        group: selectedGroup,
        subgroup: null,
        member: null,
        original: {
          channel: originalChannel,
          group: originalGroup,
          subgroup: originalSubgroup,
          member: originalMember,
        },
      });
    } else if (force || selectedSubgroup?.id !== subgroup.id) {
      const members = await getMembers(subgroup);
      setOptionMembers(members);
      setSelectedSubgroup(subgroup);
      setSelectedMember(null);
      cbSelected({
        channel: selectedChannel,
        group: selectedGroup,
        subgroup,
        member: null,
        original: {
          channel: originalChannel,
          group: originalGroup,
          subgroup: originalSubgroup,
          member: originalMember,
        },
      });
    }
  };

  const selectMember = async (member, force = false) => {
    if (!member) {
      setSelectedMember(null);
      cbSelected({
        channel: selectedChannel,
        group: selectedGroup,
        subgroup: selectedSubgroup,
        member: null,
      });
    } else if (force || selectedMember?.id !== member.id) {
      setSelectedMember(member);
      cbSelected({
        channel: selectedChannel,
        group: selectedGroup,
        subgroup: selectedSubgroup,
        member: member,
      });
    }
  };

  return (
    <div>
      <Row>
        {viewMode.includes("channel") ? (
          <Col xs={12 / viewMode.length}>
            <Row className="align-items-center justify-content-between">
              <Col>
                <label>{t("총판")}</label>
                <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                  *
                </span>
              </Col>
            </Row>
            <FormGroup>
              <Select
                className="react-select"
                classNamePrefix="react-select"
                value={{
                  value: selectedChannel,
                  label: selectedChannel?.title,
                }}
                onChange={(item) => {
                  if (item.value?.id !== selectedChannel?.id) {
                    selectChannel(item.value);
                  }
                }}
                isDisabled={role == 4 ? true : false}
                options={[
                  {
                    value: null,
                    label: t("선택안함"),
                  },
                  ...(optionChannels?.map((item, idx) => ({
                    value: item,
                    label: item.title,
                  })) || []),
                ]}
                placeholder="총판을 선택하세요."
              />
            </FormGroup>
          </Col>
        ) : null}

        {viewMode.includes("group") ? (
          <Col xs={12 / viewMode.length}>
            <Row className="align-items-center justify-content-between">
              <Col>
                <label>{t("가맹점")}</label>
                {mode >= 2 ? (
                  <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                    *
                  </span>
                ) : null}
              </Col>
            </Row>
            <FormGroup>
              <Select
                isDisabled={mode < 2 || role == 4 ? true : false}
                className="react-select"
                classNamePrefix="react-select"
                value={{ value: selectedGroup, label: selectedGroup?.title }}
                onChange={(item) => {
                  if (item.value?.id !== selectedGroup?.id) {
                    selectGroup(item.value);
                  }
                }}
                options={[
                  {
                    value: null,
                    label: t("선택안함"),
                  },
                  ...(optionGroups?.map((item, idx) => ({
                    value: item,
                    label: item.title,
                  })) || []),
                ]}
                placeholder="가맹점(학교,학원)을 선택하세요."
              />
            </FormGroup>
          </Col>
        ) : null}

        {viewMode.includes("subgroup") ? (
          <Col xs={12 / viewMode.length}>
            <Row className="align-items-center justify-content-between">
              <Col>
                <label>{t("클래스(반)")}</label>
                {mode >= 3 ? (
                  <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                    *
                  </span>
                ) : null}
              </Col>
            </Row>
            <FormGroup>
              <Select
                isDisabled={mode < 3}
                className="react-select"
                classNamePrefix="react-select"
                value={{
                  value: selectedSubgroup,
                  label: selectedSubgroup?.title,
                }}
                onChange={(item) => {
                  if (item.value?.id !== selectedSubgroup?.id) {
                    selectSubgroup(item.value);
                  }
                }}
                options={[
                  {
                    value: null,
                    label: t("선택안함"),
                  },
                  ...(optionSubgroups?.map((item, idx) => ({
                    value: item,
                    label: item.title,
                  })) || []),
                ]}
                placeholder="클래스(반)을 선택하세요."
              />
            </FormGroup>
          </Col>
        ) : null}

        {viewMode.includes("member") ? (
          <Col xs={12 / viewMode.length}>
            <Row className="align-items-center justify-content-between">
              <Col>
                <label>{t("회원 이름")}</label>
                {mode >= 4 ? (
                  <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                    *
                  </span>
                ) : null}
              </Col>
            </Row>
            <FormGroup>
              <Select
                isDisabled={mode < 4}
                className="react-select"
                classNamePrefix="react-select"
                value={{ value: selectedMember, label: selectedMember?.name }}
                onChange={(item) => {
                  console.log("member item =  ", item);
                  if (item.value?.id !== selectedMember?.id) {
                    selectMember(item.value);
                  }
                }}
                options={[
                  {
                    value: null,
                    label: t("선택안함"),
                  },
                  ...(optionMembers?.map((item, idx) => ({
                    value: item,
                    label: item.name,
                  })) || []),
                ]}
                placeholder="회원을 선택하세요."
              />
            </FormGroup>
          </Col>
        ) : null}
      </Row>
    </div>
  );
}

export default GroupPicker;
