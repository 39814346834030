import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import Board from "views/components/Board.jsx";
import {
  useFirestoreConnect,
  useFirestore,
  useFirebase,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useTable } from "react-table";
import { NavLink } from "react-router-dom";

import ReactRoundedImage from "react-rounded-image";
import empty from "../../assets/img/empty.png";
import { activeStatusString } from "variables/constants";

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Jumbotron,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Badge,
} from "reactstrap";
import { useRef, useEffect } from "react";
import moment from "moment";
import AddGroupForm from "views/forms/AddGroupForm";
import GroupForm from "views/forms/GroupForm";
import { roles, memberStatus } from "variables/constants";
import { getCustomClaimsFromLocalStorage } from "helpers/localStorage";
import ConfirmableForm from "views/forms/ConfirmableForm";
import { useTranslation } from "react-i18next";

function Franchises({ location, history }) {
  const profile = useSelector((state) => state.firebase.profile);
  const customClaims = getCustomClaimsFromLocalStorage();
  const { channelId } = customClaims;
  const { t, i18n } = useTranslation();

  let whereOptions = null;

  let channel = location?.state?.channel;
  if (!channel && profile?.role === roles.channelAdmin && profile?.channel) {
    console.log("profile.channel = ", profile.channel);
    channel = profile.channel;
    whereOptions = [["channel.id", "==", channel.id]];
  }

  console.log("channel in Franchises= ", channel);

  const img = channel?.subLogo ? channel.subLogo : empty;

  const [settingOpen, setSettingOpen] = useState(false);
  const toggleSetting = () => setSettingOpen((prevState) => !prevState);

  const [selectedGroup, setSelectedGroup] = useState(null);

  const firestore = useFirestore();

  console.log("whereOptions = ", whereOptions);
  useFirestoreConnect([
    {
      collectionGroup: "groups",
      // where: whereOptions,
      storeAs: "groups",
    },
    {
      collection: "channels",
      storeAs: "channels",
    },
  ]);

  const items = useSelector(
    (state) => state.firestore.ordered["groups"]
  )?.filter((item) => item.groupCategory);
  const channels = useSelector((state) => state.firestore.ordered.channels);
  console.log("items = ", items);
  channels && console.log("after useFirestoreconnect channel = ", channels);

  const boardEl = useRef(null);

  const data = items
    ? items.map((item) => ({
        ...item,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a like kind of action */}
            {/* <Button
          onClick={() => {
            console.log(item);
            console.log(boardEl);
          }}
          color="info"
          size="sm"
          className="btn-icon btn-link like"
        >
          <i className="fa fa-heart" />
        </Button>{" "} */}
            {/* use this button to add a edit kind of action */}
            <Button
              onClick={() => {
                console.log("clicked");
                console.log(item);
                boardEl.current.edit(item);
                // setSelectedGroup(item);
              }}
              color="warning"
              size="sm"
              className="btn-icon btn-link edit"
            >
              <i className="fa fa-edit" style={{ fontSize: "1.5em" }} />
            </Button>{" "}
            {/* use this button to remove the data row */}
            <Button
              onClick={() => {
                console.log("clicked");
                boardEl.current.delete(() => {
                  firestore
                    .doc(
                      `channels/${item.groupCategory.channel.id}/groups/${item.id}`
                    )
                    .delete();
                });
              }}
              color="danger"
              size="sm"
              className="btn-icon btn-link remove"
            >
              <i className="fa fa-times" style={{ fontSize: "1.5em" }} />
            </Button>{" "}
          </div>
        ),
      }))
    : [];
  // console.log('data', data);

  return (
    <div className="content">
      <Breadcrumb className="sticky-top">
        ›
        <BreadcrumbItem>
          <a href="/">Home</a>
        </BreadcrumbItem>
        <BreadcrumbItem active>{t("가맹점 목록")}</BreadcrumbItem>
      </Breadcrumb>
      <Jumbotron>
        <h1 className="display-5">
          <strong>{t("가맹점 목록")}</strong>
        </h1>
        <p className="lead">
          {t("전체 가맹점(학교,학원)으로 접근할 수 있는 포털입니다.")}
        </p>
        <hr className="my-2" />
        <p>
          <strong>⚠️NOTE</strong>{" "}
          {t(
            "가맹점에서의 상태(정상/만료/차단) 변경은 가맹점 라이센스의 상태를 변경하게 됩니다."
          )}
        </p>
      </Jumbotron>
      <Board
        modalTitle={t("가맹점 입력폼")}
        data={data}
        ref={boardEl}
        columns={[
          {
            id: "title",
            Header: t("가맹점명"),
            accessor: (d) => {
              const group = _.clone(d);
              channel =
                channels &&
                channels[
                  channels.findIndex((i) => i.id == d.groupCategory.channel.id)
                ];
              console.log("channel index in columns", channel);
              delete group.actions;
              return (
                <NavLink
                  to={{ pathname: `./franchise`, state: { channel, group } }}
                >
                  {d.title}
                </NavLink>
              );
            },
            sortMethod: (a, b) => {
              const x = a.props.children;
              const y = b.props.children;
              if (x === y) {
                return x > y ? 1 : -1;
              }
              return x > y ? 1 : -1;
            },
            filterMethod: (filter, row) => {
              console.log("filter = ", filter);
              const title = row[filter.id].props.children;
              console.log("row title = ", title);
              return (
                (title && title.indexOf(filter.value.toUpperCase()) != -1) ||
                title.indexOf(filter.value.toLowerCase()) != -1
              );
            },
          },
          {
            width: 120,
            id: "status",
            Header: t("상태"),
            accessor: (d) => {
              let _color = "primary";
              if (d.status === 2) {
                _color = "warning";
              }
              if (d.status === 3) {
                _color = "danger";
              }
              return (
                <Badge color={_color}>
                  {activeStatusString[d.status - 1 || 0]}
                </Badge>
              );
            },
            filterMethod: (filter, row) => {
              if (filter.value === "all") {
                return true;
              }
              if (
                activeStatusString[filter?.value] ===
                row[filter.id].props.children
              ) {
                return true;
              }
              return false;
            },
            Filter: ({ filter, onChange }) => (
              <select
                onChange={(event) => {
                  console.log("event.target.value = ", event.target.value);
                  return onChange(event.target.value);
                }}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
              >
                <option value="all">전체</option>
                {activeStatusString.map((status, idx) => (
                  <option key={idx} value={idx}>
                    {t(status)}
                  </option>
                ))}
              </select>
            ),
          },
          {
            id: "channel",
            Header: t("총판명"),
            accessor: "groupCategory.channel.title",
            filterMethod: (filter, row) => {
              if (filter.value === "all") {
                return true;
              }

              if (filter?.value === row[filter.id]) {
                return true;
              }
              return false;
            },
            Filter: ({ filter, onChange }) => (
              <select
                onChange={(event) => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
              >
                <option value="all">총판 전체</option>
                {channels?.map((channel) => (
                  <option key={channel.id} value={channel.title}>
                    {channel.title}
                  </option>
                ))}
              </select>
            ),
          },
          {
            Header: t("카테고리"),
            Header: t("대리점명"),
            accessor: "groupCategory.title",
            filterMethod: (filter, row) => {
              console.log("filter = ", filter);
              const title = row[filter.id];
              console.log("row title = ", title);
              return title && title.indexOf(filter.value) != -1;
            },
          },
          {
            width: 100,
            id: "createdAt",
            Header: t("대리점명"),
            accessor: "nLicenses",
          },
          {
            width: 100,
            Header: t("대리점명"),
            accessor: "nSubgroups",
          },
          {
            width: 100,
            Header: t("회원수"),
            accessor: "nMembers",
          },
          {
            Header: t("명령"),
            accessor: "actions",
            sortable: false,
            filterable: false,
          },
        ]}
      >
        {/* <AddGroupForm channel={channel} /> */}
        <GroupForm channel={channel} />
      </Board>
    </div>
  );
}

Franchises.propTypes = {};

export default Franchises;
