import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useFirestoreConnect } from "react-redux-firebase";
import _ from "lodash";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Switch from "react-bootstrap-switch";
import { activeStatusString } from "variables/constants";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Progress,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

import {
  useFirebase,
  useFirestore,
  isLoaded,
  isEmpty,
} from "react-redux-firebase";
import GroupPicker from "views/components/GroupPicker";
import { useSelector } from "react-redux";
import { roles } from "variables/constants";
import { activeStatus } from "variables/constants";
import { useTranslation } from "react-i18next";

function GroupCategoryForm(props) {
  const profile = useSelector((state) => state.firebase.profile);
  console.log("profile = ", profile);
  const { t } = useTranslation();

  const [groupCategory, setGroupCategory] = useState(props.currentItem || null);

  const [errorMessage, setErrorMessage] = useState(null);
  const [onSaving, setOnSaving] = useState(false);
  const [alert, setAlert] = useState(null);
  const [isModified, setIsModified] = useState(false);

  const firebase = useFirebase();
  const firestore = useFirestore();

  useEffect(() => {
    if (props.currentItem) {
      const _groupCategory = _.clone(props.currentItem);
      delete _groupCategory.id;
      setGroupCategory(_groupCategory);
    }
    return () => {
      // cleanup
    };
  }, [props.currentItem]);

  useEffect(() => {
    if (
      isLoaded(profile) &&
      !isEmpty(profile) &&
      profile.role === roles.channelAdmin
    ) {
      setGroupCategory({
        ...groupCategory,
        channel: profile.channel,
      });
    }
    return () => {
      // cleanup
    };
  }, [profile]);

  useEffect(() => {
    const currentGroupCategory = _.cloneDeep(props.currentItem);

    if (currentGroupCategory?.id) {
      delete currentGroupCategory.id;
    }

    const modified = !_.isEqual(currentGroupCategory, groupCategory);
    setIsModified(modified);
    return () => {
      // cleanup
    };
  }, [groupCategory]);

  const onSave = async () => {
    console.log("press save");
    console.log("groupCategory = ", groupCategory);

    const timestamp = {
      updatedAt: firestore.FieldValue.serverTimestamp(),
    };

    const status = groupCategory?.status
      ? groupCategory?.status
      : activeStatus.normal;
    console.log(`@@@@@status`, status);

    if (!props.currentItem?.id) {
      timestamp["createdAt"] = firestore.FieldValue.serverTimestamp();
    }

    setOnSaving(true);

    try {
      let groupCategoyRef = firestore.collection(`groupCategories`);
      if (props.currentItem?.id) {
        groupCategoyRef = groupCategoyRef.doc(props.currentItem.id);
      } else {
        groupCategoyRef = groupCategoyRef.doc();
      }

      await groupCategoyRef.set({
        ...groupCategory,
        ...timestamp,
        status,
      });
      props.afterSave && props.afterSave();
    } catch (error) {
      console.error("Failed to save : ", error);
    }
    setOnSaving(false);
    props.toggle();
  };

  const onDelete = async () => {
    const groupCategoryRef = firestore
      .collection(`groupCategories`)
      .doc(props.currentItem.id);
    await groupCategoryRef.delete();
    props.toggle();
  };

  const onCancel = () => {
    if (isModified) {
      props.closeConfirm();
    } else {
      props.toggle();
    }
    setGroupCategory(null);
  };

  const warningWithConfirmAndCancelMessage = () =>
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={
          <div>
            삭제하면 복구할 수 없습니다.
            <br />
            계속 진행하시겠습니까?
          </div>
        }
        onConfirm={() => {
          onDelete();
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="확인"
        cancelBtnText="취소"
        showCancel
      >
        지우면 완전히 삭제됩니다. 지우시겠습니까?
      </ReactBSAlert>
    );

  const onChange = (e, name, typeFn) => {
    const value = e.target.value;
    setGroupCategory({
      ...groupCategory,
      [name]: typeFn ? typeFn(value) : value,
    });
  };

  const onSelected = (selected) => {
    console.log("selected.channel: ", selected?.channel);
    if (selected?.channel?.id === "") {
      return;
    }
    if (selected?.channel?.id !== groupCategory?.channel?.id) {
      setGroupCategory({
        ...groupCategory,
        channel: _.pick(selected.channel, ["id", "title"]),
      });
    }
  };

  return (
    <div className="content">
      {alert}
      {/* title */}
      <Row>
        <Col>
          <div className="text-right">
            <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
              *
            </span>{" "}
            필수 입력 사항입니다
          </div>
        </Col>
      </Row>
      {isLoaded(profile) &&
      !isEmpty(profile) &&
      profile.role === roles.admin ? (
        <Row>
          <Col>
            <FormGroup>
              <GroupPicker
                channel={groupCategory?.channel}
                cbSelected={onSelected}
                viewMode={["channel"]}
              />
            </FormGroup>
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col xs={6}>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>대리점명</label>
              <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                *
              </span>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={groupCategory?.title || ""}
              placeholder="예) 대구지사"
              onChange={(e) => onChange(e, "title")}
            />
          </FormGroup>
        </Col>
        <Col xs={4}>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>상태</label>
              <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                *
              </span>
            </Col>
          </Row>
          <FormGroup row>
            {activeStatusString.map((status, index) => (
              <FormGroup key={index} check>
                <Label check>
                  <Input
                    type="radio"
                    name="status"
                    defaultChecked={
                      groupCategory?.status
                        ? index + 1 === groupCategory?.status
                        : index === 0
                    }
                    value={index + 1}
                    onClick={(e) => onChange(e, "status", (t) => Number(t))}
                  />{" "}
                  {t(status)}
                </Label>
              </FormGroup>
            ))}
          </FormGroup>
        </Col>
      </Row>
      {errorMessage ? (
        <div>
          <p className="text-danger">{errorMessage}</p>
        </div>
      ) : null}
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>설명</label>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={groupCategory?.description || ""}
              placeholder="예) 대구 및 경산 일부 지점"
              onChange={(e) => onChange(e, "description")}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>대리점 담당자 이름</label>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={groupCategory?.director || ""}
              placeholder="대리점 담당자 이름을 입력해주세요"
              onChange={(e) => onChange(e, "director")}
            />
          </FormGroup>
        </Col>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>대리점 담당 연락처</label>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={groupCategory?.directorPhone}
              placeholder="000-0000-0000"
              onChange={(e) => onChange(e, "directorPhone")}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>대리점 주소</label>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={groupCategory?.directorAddress}
              placeholder="대리점 주소를 입력해주세요"
              onChange={(e) => onChange(e, "directorAddress")}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        {props.subgroup?.id ? (
          <Col>
            <Button color="danger" onClick={warningWithConfirmAndCancelMessage}>
              삭제
            </Button>
          </Col>
        ) : null}
        <Col className="text-right">
          <Button
            color="primary"
            disabled={
              onSaving ||
              !Boolean(
                groupCategory?.channel &&
                  groupCategory?.title &&
                  groupCategory?.title.length > 0
              ) ||
              !isModified
            }
            onClick={onSave}
          >
            저장
          </Button>
          <Button color="warning" onClick={onCancel}>
            취소
          </Button>
        </Col>
      </Row>
    </div>
  );
}

GroupCategoryForm.propTypes = {
  sensor: PropTypes.object,
  afterSave: PropTypes.func,
  afterDelete: PropTypes.func,
  onCancel: PropTypes.func,
};

export default GroupCategoryForm;
