import React, { Component } from 'react'
import PhysicalTestsReport from './PhysicalTestsReport'

export default class PrintablePhysicalTestsReport extends Component {
  render() {
    return (
      <div>
        <PhysicalTestsReport {...this.props} />
      </div>
    )
  }
}
