import React from 'react'

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";

import { Line } from 'react-chartjs-2';
import _ from 'lodash';
import moment from 'moment';


const SparkChart = ({ dataset = [], title, fieldPath, limit = 5 }) => {
  const items = _.takeRight(_.reverse(_.clone(dataset)), limit);
  const data = {
    labels: items.map(item => moment(item?.createdAt?.toDate()).format('MM/DD')),
    datasets: [
      {
        label: title,
        borderColor: "#6bd098",
        pointRadius: 0,
        pointHoverRadius: 0,
        fill: false,
        borderWidth: 3,
        data: items.map(item => _.get(item, fieldPath))
      }
    ]
  }
  const options = {
    legend: {
      display: false
    },

    tooltips: {
      enabled: false
    },

    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: "#9f9f9f",
            beginAtZero: false,
            maxTicksLimit: 3,
            padding: 5
          },
          gridLines: {
            drawBorder: false,
            zeroLineColor: "transparent",
            color: "rgba(255,255,255,0.05)"
          }
        }
      ],

      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(255,255,255,0.1)",
            zeroLineColor: "transparent",
            display: false
          },
          ticks: {
            padding: 5,
            fontColor: "#9f9f9f"
          }
        }
      ]
    }
  }

  return (
      <Card  className="card-chart">
        <CardHeader style={{padding:"0px 10px", margin:"0px"}}>
          <CardTitle>{title}</CardTitle>
        </CardHeader>
        <CardBody>
          <Line
            data={data}
            options={options}
          />
        </CardBody>
      </Card>
  )
}


export default SparkChart