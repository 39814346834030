import React, { useState, useEffect } from "react";
// import PropTypes from 'prop-types'
// import { useFirestoreConnect } from 'react-redux-firebase';
import _ from "lodash";
import Switch from "react-bootstrap-switch";
import { useTranslation } from "react-i18next";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Progress,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  Spinner,
  FormFeedback,
} from "reactstrap";

// import ReactDatetime from "react-datetime";

import Select from "react-select";
// import Switch from "react-bootstrap-switch";
import ReactBSAlert from "react-bootstrap-sweetalert";

// core components
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import { categories } from "variables/constants";
import { regions } from "variables/constants";
import { schoolAge } from "variables/constants";
import { useSelector } from "react-redux";
import { useFirestore, useFirebase } from "react-redux-firebase";
import { isValidPhoneNumber } from "helpers/validation";
import { isValidEmailAddress } from "helpers/validation";
import { rolesString, roles } from "variables/constants";
import GroupPicker from "views/components/GroupPicker";
import { activeStatusString } from "variables/constants";
import { activeStatus } from "variables/constants";

function UserForm(props) {
  const isManager = props?.state ? props.state : null;
  console.log(`@@@isManager`, isManager);

  const initUser = {
    role: isManager ? roles.teacher : roles.student,
    name: "",
    email: "",
    password: "",
    channel: null,
    group: null,
    subgroup: null,
    member: null,
  };

  const firestore = useFirestore();
  const firebase = useFirebase();
  const [user, setUser] = useState(
    props.currentItem ? _.cloneDeep(props.currentItem) : _.cloneDeep(initUser)
  );
  const [docId, setDocId] = useState(null);
  const [alert, setAlert] = useState(null);
  const [verification, setVerification] = useState({
    name: { isValid: false, message: null },
    email: { isValid: false, message: null },
    // password: { isValid: false, message: null },
    // groupPicker: { isValid: false, message: null },
  });
  const [isVerified, setIsVerified] = useState(false);
  const [onSaving, setOnSaving] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [mode, setMode] = useState(3);
  const { t, i18n } = useTranslation();

  const profile = useSelector((state) => state.firebase.profile);
  console.log("profile = ", profile);

  useEffect(() => {
    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then(({ claims }) => {
        console.log("claims = ", claims);
        if (
          !claims.admin &&
          ![roles.groupAdmin, roles.channelAdmin, roles.admin].includes(
            claims.role
          )
        ) {
          basicAlert("글 작성 권한이 없습니다", () => props.toggle(), null);
        }
      });
    const docId =
      (props.currentItem && props.currentItem.id) ||
      firestore.collection("users").doc().id;
    // console.log('docId', docId);
    setDocId(docId);
    return () => {
      // cleanup
    };
  }, []);

  useEffect(() => {
    console.log("user = ", user);
    const { name, email, password, role, channel, group, subgroup, member } =
      user;

    //verification
    setVerification({
      name: {
        isValid: Boolean(name && name.length > 1),
        message: "두 글자 이상 입력해주세요",
      },
      email: {
        isValid: isValidEmailAddress(email),
        message: "정확한 이메일을 입력해주세요",
      },
      // password: { isValid: Boolean(password && password.length > 5), message: '패스워드는 6글자 이상' },
      groupPicker: {
        isValid:
          role === roles.parents
            ? Boolean(channel && group && subgroup && member)
            : role === roles.student || role === roles.teacher
            ? Boolean(channel && group && subgroup)
            : role === roles.groupAdmin
            ? Boolean(channel && group)
            : role === roles.channelAdmin || role === roles.franchiser
            ? Boolean(channel)
            : false,
        message: "가맹점 피커에 필수 정보를 입력해주세요.",
      },
    });

    // Make a userTemp for comparison
    const userTemp = _.clone(user);
    delete userTemp.original;

    setIsModified(
      props.currentItem === null
        ? !_.isEqual(initUser, user)
        : !_.isEqual(props.currentItem, userTemp)
    );
    //role mode
    switch (role) {
      case roles.student:
        setMode(3);
        break;
      case roles.parents:
        setMode(4);
        break;
      case roles.teacher:
        setMode(3);
        break;
      case roles.groupAdmin:
        setMode(2);
        break;
      case roles.channelAdmin:
      case roles.franchiser:
        setMode(1);
        break;
      default:
        setMode(3);
    }
  }, [user]);

  // check verified all
  useEffect(() => {
    // console.log('verfication', verification);
    const invalidItems = _.filter(
      verification,
      (value, key) => value.isValid !== true
    );
    console.log("invalidItems", invalidItems);
    const isVerified = invalidItems.length === 0;
    // console.log('isVerfied', isVerified);
    setIsVerified(isVerified);
  }, [verification]);

  const verificationIndicator = (id) => {
    // console.log("verification[id].isValid", verification[`${id}`].isValid);
    if (verification[`${id}`].isValid) {
      return (
        <i
          className="nc-icon nc-check-2"
          style={{ color: "lightgreen", fontSize: "1.5em" }}
        />
      );
    } else {
      return (
        <>
          <i
            className="nc-icon nc-alert-circle-i"
            style={{ color: "red", fontSize: "1.5em" }}
            id={`${id}_invalid`}
          />
          <UncontrolledTooltip target={`${id}_invalid`}>
            {verification[`${id}`].message}
          </UncontrolledTooltip>
        </>
      );
    }
  };

  const onChange = (e, name, typeFn) => {
    const value = e.target.value;
    setUser({ ...user, [name]: typeFn ? typeFn(value) : value });
  };

  // console.log(`@@@working`, customClaims.role);

  const onSave = async () => {
    console.log("press save");

    if (!isVerified) {
      basicAlert(t("값을 확인해주세요"));
      return;
    }

    setOnSaving(true);

    const functions = firebase.app().functions("asia-northeast1");
    const createUser = functions.httpsCallable("createUser");
    const updateUser = functions.httpsCallable("updateUser");

    try {
      const {
        id: uid,
        email,
        password,
        name,
        role,
        channel,
        group,
        subgroup,
        member,
        active,
        status,
      } = user;
      console.log("role = ", role);
      if (props.currentItem) {
        const response = await updateUser({
          uid,
          email,
          password, // new password to change
          name,
          role,
          channel: channel ? { id: channel.id, title: channel.title } : null,
          group: group ? { id: group.id, title: group.title } : null,
          subgroup: subgroup
            ? { id: subgroup.id, title: subgroup.title }
            : null,
          member: member ? { id: member.id, name: member.name } : null,
          active,
          status,
        });
        console.log("[createUser] response = ", response);
        basicAlert(
          t("회원정보수정 완료 메시지", { name }),
          () => props.toggle(),
          null,
          true,
          null
        );
      } else {
        const response = await createUser({
          email,
          password,
          name,
          role,
          channel: channel ? { id: channel.id, title: channel.title } : null,
          group: group ? { id: group.id, title: group.title } : null,
          subgroup: subgroup
            ? { id: subgroup.id, title: subgroup.title }
            : null,
          member: member ? { id: member.id, name: member.name } : null,
          active: true,
          status: 1,
        });
        console.log("[createUser] response = ", response);
        basicAlert(
          t("회원생성 완료 메시지", { name, role: t(rolesString[role - 1]) }),
          () => props.toggle(),
          null,
          true,
          null
        );
      }
    } catch (e) {
      console.error(e);
      console.error("failed to createUser: ", e.details?.message || e.message);
      // const { error: { details: message } } = e;
      // console.log(e.details?.message, e.message)
      // console.log('message = ', message);
      isManager
        ? basicAlert(t("관리자 계정 생성에 실패했습니다."))
        : basicAlert(t("회원 및 보호자 계정 생성에 실패했습니다."));
    } finally {
      setOnSaving(false);
    }
  };

  const onCancel = () => {
    if (isModified) {
      props.closeConfirm();
    } else {
      props.toggle();
    }
  };

  // alerts
  const basicAlert = (msg, onConfirm, onCancel, isSuccess, isWarning) => {
    setAlert(
      <ReactBSAlert
        warning={isWarning}
        success={isSuccess}
        style={{ display: "block", marginTop: "-100px" }}
        title={msg || "Here's a message!"}
        onConfirm={() => (onConfirm ? onConfirm() : hideAlert())}
        onCancel={() => (onCancel ? onCancel() : hideAlert())}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="확인"
        cancelBtnText="취소"
        showCancel={!isSuccess}
      />
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };

  const addPhoto = () => {
    setUser({
      ...user,
      photos: [...user.photos, { file: null, description: "" }],
    });
  };

  const deletePhoto = (idx) => {
    const newPhotos = user.photos.filter((item, i) => i !== idx);
    setUser({ ...user, photos: newPhotos });
  };

  const onSelected = (selected) => {
    console.log("selected: ", selected);
    setUser({ ...user, ...selected });
  };

  return (
    <>
      <div className="content">
        {alert}
        <Form action="#" method="#">
          <Row>
            <Col>
              <div className="text-right">
                <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                  *
                </span>{" "}
                {t("필수 입력 사항입니다")}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col>
                  <label>{t("상태")}</label>
                </Col>
              </Row>
              <FormGroup row>
                {activeStatusString.map((status, index) => (
                  <FormGroup key={index} check>
                    <Label check>
                      <Input
                        type="radio"
                        name="status"
                        defaultChecked={
                          user?.status
                            ? index + 1 === user?.status
                            : index === 0
                        }
                        value={index + 1}
                        onClick={(e) => onChange(e, "status", (t) => Number(t))}
                      />{" "}
                      {t(status)}
                    </Label>
                  </FormGroup>
                ))}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col>
                  <label>{t("역할")}</label>
                  <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                    *
                  </span>
                </Col>
              </Row>
              <FormGroup>
                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  value={{
                    value: user.role,
                    label: t(rolesString[user.role - 1]),
                  }}
                  onChange={(item) => {
                    if (item.value !== user.role) {
                      setUser({
                        ...user,
                        role: item.value,
                        channel: null,
                        group: null,
                        subgroup: null,
                        member: null,
                      });
                    }
                  }}
                  options={[
                    ...rolesString.map((item, idx) => ({
                      value: idx + 1,
                      label: t(item),
                      isDisabled: idx !== 3,
                      // manager폼에서 넘어온 경우와 아닌 경우를 구분
                      /*
                      isDisabled: isManager
                        ? idx < 2 || idx > profile.role - 2
                        : idx > 1,
                        */
                    })),
                  ]}
                  placeholder={t("유저 역할 설정")}
                />
              </FormGroup>
            </Col>
          </Row>
          {[
            roles.student,
            roles.teacher,
            roles.parents,
            roles.channelAdmin,
            roles.franchiser,
            roles.groupAdmin,
          ].includes(user.role) ? (
            <Row>
              <Col>
                <GroupPicker
                  role={profile?.role}
                  channel={user?.channel || profile?.channel}
                  group={user?.group || profile?.group}
                  subgroup={user?.subgroup}
                  member={user?.member}
                  cbSelected={onSelected}
                  mode={mode}
                />
              </Col>
            </Row>
          ) : null}
          {/* 고객 성함 */}
          <Row>
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col>
                  <label>{isManager ? t("관리자 이름") : t("회원 이름")}</label>
                  <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                    *
                  </span>
                </Col>
              </Row>
              <FormGroup>
                <Input
                  value={user.name}
                  // disabled = {user.createdAt? true: false}
                  placeholder={t("홍길동")}
                  onChange={(e) => onChange(e, "name")}
                />
              </FormGroup>
            </Col>
          </Row>
          {/*  이메일 */}
          <Row>
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col>
                  <label>{t("이메일")}</label>
                  <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                    *
                  </span>
                </Col>
              </Row>
              <FormGroup>
                <Input
                  value={user.email}
                  disabled={user.createdAt ? true : false}
                  placeholder="insolmnt@insolmnt.com"
                  onChange={(e) => onChange(e, "email")}
                />
              </FormGroup>
            </Col>
          </Row>
          {/*  암호 */}
          <Row>
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col>
                  <label>
                    {props.currentItem ? t("변경할 암호") : t("신규 암호")}
                  </label>
                  {props.currentItem ? null : (
                    <span
                      className="ml-1"
                      style={{ color: "red", fontSize: 20 }}
                    >
                      *
                    </span>
                  )}
                </Col>
              </Row>
              <FormGroup>
                <Input
                  value={user.password}
                  placeholder={t("암호는 6글자 이상 입력해주세요")}
                  onChange={(e) => onChange(e, "password")}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="text-right">
              <Button
                color="primary"
                disabled={!isVerified || onSaving || !isModified}
                onClick={onSave}
              >
                {onSaving && <Spinner size="sm" color="warning" />} {t("저장")}
              </Button>
              <Button color="warning" onClick={onCancel}>
                {t("취소")}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

UserForm.propTypes = {};

export default UserForm;
