import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useFirebase, useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

import {
  Row,
  Col,
  FormGroup
} from 'reactstrap';

import Select from "react-select";

function GroupCategoryPicker(props) {
  const [selectedGroupCategory, setSelectedGroupCategory] = useState(props.groupCategory || null);

  let whereOptions = null;
  if (props.channel) {
    whereOptions = ['channel.id', '==', props.channel.id];
  }
  useFirestoreConnect(() => [
    {
      collection: `groupCategories`,
      where: whereOptions,
      orderBy: [
        ['title']
      ],
      storeAs: 'groupCategories'
    }
  ]);

  const groupCategories = useSelector(state => state.firestore.ordered[`groupCategories`]) || [];
  console.log('groupCategories = ', groupCategories);

  return (
    <div>
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>대리점</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Select
              className="react-select"
              classNamePrefix="react-select"
              value={{ value: selectedGroupCategory, label: selectedGroupCategory?.title }}
              onChange={item => {
                if (item.value.id !== selectedGroupCategory?.id) {
                  setSelectedGroupCategory(item.value);
                  props.cbSelected(item.value);
                }
              }}
              // isDisabled={channel}
              options={[
                // {
                //   value: "",
                //   label: "대리점 선택",
                //   isDisabled: true
                // },
                ...groupCategories?.map((item, idx) => ({ value: item, label: item.title }))
              ]}
              placeholder="대리점를 선택하세요."
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  )
}

GroupCategoryPicker.propTypes = {
  cbSelected: PropTypes.func.isRequired,
  channel: PropTypes.object.isRequired,
}

export default GroupCategoryPicker

