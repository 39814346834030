import React, { useState, useEffect, useRef } from 'react'
import { useFirestoreConnect, useFirestore, populate, useFirebase } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";

import _ from 'lodash';

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Badge,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  CardSubtitle,
  CardLink,
  CardText,
  ListGroup,
  ListGroupItem,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Jumbotron,
  ModalHeader,
  Modal,
  ModalBody,
} from "reactstrap";
import moment from 'moment';
import Board from 'views/components/Board.jsx'
import CurriculumVersionForm from 'views/forms/CurriculumVersionForm';
import { CURRICULUM_VERSION_STATUS_STRING } from 'variables/status';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { CURRICULUM_VERSION_STATUS } from 'variables/status';


function CurriculumVersions(props) {
  const [showModal, setShowModal] = useState(false);
  const [alert, setAlert] = useState(null);
  const [onCopying, setOnCopying] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);

  const firestore = useFirestore();
  const firebase = useFirebase();
  useFirestoreConnect([
    { collection: 'curriculumVersions' }
  ]);

  const items = useSelector(state => state.firestore.ordered.curriculumVersions);
  const boardEl = useRef(null);
  console.log('curriclumVersions =>', items)

  const data = items && items.map((item) => ({
    ...item,
    actions: (
      // we've added some custom button actions
      <div className="actions-left">
        {/* use this button to add a like kind of action */}
        <Button
          onClick={() => inputAlert(item)}
          color="warning"
          size="sm"
          className="btn-icon btn-link"
        >
          <i className="fa fa-copy" style={{ fontSize: '1.5em' }} />
        </Button>{" "}
        {/* use this button to add a edit kind of action */}
        <Button
          onClick={() => {
            console.log('clicked');
            boardEl.current.edit(item);
          }}
          color="warning"
          size="sm"
          className="btn-icon btn-link edit"
        >
          <i className="fa fa-edit" style={{ fontSize: '1.5em' }} />
        </Button>{" "}
        {/* use this button to remove the data row */}
        <Button
          onClick={() => {
            console.log('clicked');
            boardEl.current.delete(() => {
              firestore.doc(`curriculumVersions/${item.id}`).delete();
            });
          }}
          color="danger"
          size="sm"
          className="btn-icon btn-link remove"
        >
          <i className="fa fa-times" style={{ fontSize: '1.5em' }} />
        </Button>{" "}
      </div>
    )
  }))


  const hideAlert = () => {
    setAlert(null);
  };

  const copyCurriculumVersion = async (fromVersion, toVersion) => {
    console.log('fromVersion = ', fromVersion);
    console.log('toVersion = ', toVersion);
    const functions = firebase.app().functions('asia-northeast1');
    console.log('functions = ', functions);
    const copy = functions.httpsCallable('copyCurriculumVersion');
    try {
      return await copy({ fromVersion, toVersion });
    } catch (e) {
      console.error('failed to httpsCallable: ', e);
    }
  }

  const inputAlert = (item) => {
    // setAlert(

    //   <div className='content'>
    //     <Modal isOpen={isModalOpen}>
    //       <ModalBody>
    //         <Row>
    //           <Col>
    //             <div className="text-right"><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span> 필수 입력 사항입니다</div>
    //           </Col>
    //         </Row>
    //         <Row>
    //           <Col>
    //             <Row className='align-items-center justify-content-between'>
    //               <Col><label>복사해서 만들 새 커리큘럼</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span>
    //               </Col>
    //             </Row>

    //           </Col>
    //         </Row>
    //         <Row>
    //           <Col className='text-right'>
    //             <Button color="primary" disabled={onCopying} onClick={inputConfirmAlert('test', item)}>
    //               {
    //                 onCopying ?
    //                   <Spinner
    //                     as="span"
    //                     animation="border"
    //                     size="sm"
    //                     role="status"
    //                     aria-hidden="true"
    //                   />
    //                   : null
    //               }
    //         저장
    //         </Button>
    //             <Button color="warning" onClick={hideAlert}>취소</Button>
    //           </Col>
    //         </Row>

    //       </ModalBody>
    //     </Modal>

    //   </div>
    // )

    setAlert(<ReactBSAlert
      input
      showCancel
      style={{ display: "block", marginTop: "-100px" }}
      title={'복사해서 만들 새 커리큘럼 버전을 입력해주세요.'}
      onConfirm={e => inputConfirmAlert(e, item)}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="warning"
      confirmBtnText="복사"
      cancelBtnText="취소"
      disabled={onCopying}
    />);
  }

  async function inputConfirmAlert(e, item)  {
    setOnCopying(true);
    console.log('test disabled = ', onCopying)
    try {
      const { data } = await copyCurriculumVersion(item.id, e);
      console.log('httpscallble data = ', data);
      if (data.result === 'success') {
        copySuccess();
      } else {
        copyFailure(data.message);
      }
    } catch (e) {
      console.error('failed to httpscall: ', e);
    } finally {
      setOnCopying(false);
      console.log('after Alert = ', onCopying)
    }
  };

  const copySuccess = () => {
    setAlert(<ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px" }}
      onConfirm={() => hideAlert()}
      confirmBtnBsStyle="info"
      title="복사완료"
    />
    );
  };

  const copyFailure = (message) => {
    setAlert(<ReactBSAlert
      error
      style={{ display: "block", marginTop: "-100px" }}
      onConfirm={() => hideAlert()}
      confirmBtnBsStyle="info"
      title="복사실패"
    >
      {message}
    </ReactBSAlert>
    );
  };



  return (
    <div className='content'>
      <Breadcrumb className='sticky-top'>
        <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
        <BreadcrumbItem active>커리큘럼 버전</BreadcrumbItem>
      </Breadcrumb>
      <Jumbotron>
        <h1 className="display-5"><strong>커리큘럼 버전 목록</strong></h1>
        <p className="lead">이곳에서 전체 커리큘럼과 관련 정보를 확인할 수 있습니다.</p>
        <hr className="my-2" />
        <p><strong>⚠️NOTE</strong> 콘텐츠 버전은 기존 콘텐츠의 수정 및 활용이 가능한, <strong>복사</strong>가 가능합니다.</p>
      </Jumbotron>
      {alert}
      <Board
        modalTitle="커리큘럼 버전 입력폼"
        data={data}
        ref={boardEl}
        columns={[
          {
            id: 'version',
            Header: "커리큘럼 버전",
            accessor: d => {
              // console.log('d', d);
              const version = _.clone(d);
              delete version.actions;
              return <NavLink
                to={{ pathname: `./curriculum-version`, state: { version } }}
              >{d.version}</NavLink>
            },
            filterMethod: (filter, row) => {
              console.log('filter = ', filter);
              const title = row[filter.id].props.children;
              console.log('row title = ', title);
              return title && title.indexOf(filter.value) != -1;
            }
          },
          {
            width: 140,
            id: 'status',
            Header: '상태',
            accessor: d => {
              let _color = 'warning'
              if (d.curriculumVersionStatus === 2) { _color = "primary" }
              if (d.curriculumVersionStatus === 3) { _color = "danger" }
              return <Badge color={_color}>{CURRICULUM_VERSION_STATUS_STRING[d.curriculumVersionStatus || 0]}</Badge>;
            },
            filterMethod: (filter, row) => {
              if (filter.value === "all") {
                return true;
              }
              if (CURRICULUM_VERSION_STATUS_STRING[filter?.value] === row[filter.id].props.children) {
                return true;
              }
              return false;
            },
            Filter: ({ filter, onChange }) =>
              <select
                onChange={event => {
                  console.log('event.target.value = ', event.target.value);
                  return onChange(event.target.value)
                }}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
              >
                <option value="all">전체</option>
                {
                  CURRICULUM_VERSION_STATUS_STRING.map((status, idx) => <option key={idx} value={idx}>{status}</option>)
                }
              </select>
          },
          {
            Header: "콘텐츠 버전",
            accessor: "contentVersion",
            filterMethod: (filter, row) => {
              console.log('filter = ', filter);
              const title = row[filter.id];
              console.log('row title = ', title);
              return title && title.indexOf(filter.value) != -1;
            }
          },
          {
            Header: "설명",
            accessor: "changelog",
            filterMethod: (filter, row) => {
              console.log('filter = ', filter);
              const title = row[filter.id];
              console.log('row title = ', title);
              return title && title.indexOf(filter.value) != -1;
            }
          },
          {
            id: 'createdAt',
            Header: "등록일시",
            accessor: d => d.createdAt ? moment(d.createdAt.toDate()).format('YYYY-MM-DD HH:mm') : ''
          },
          {
            id: 'updatedAt',
            Header: "수정일시",
            accessor: d => d.updatedAt ? moment(d.updatedAt.toDate()).format('YYYY-MM-DD HH:mm') : ''
          },
          {
            Header: "명령",
            accessor: "actions",
            sortable: false,
            filterable: false
          }
        ]}
      >
        <CurriculumVersionForm />
      </Board>
    </div>
  )
}

export default CurriculumVersions
