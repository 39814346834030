import React, { useState, useEffect, useRef } from "react";
import {
  useFirestoreConnect,
  useFirestore,
  populate,
  isLoaded,
  isEmpty,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ConfirmableForm from "views/forms/ConfirmableForm";
import ClassMemberLists from "views/groups/ClassMemberLists";
import ClassPerformedCurriculums from "views/groups/ClassPerformedCurriculums";
import ClassPhysicalStrengths from "views/groups/ClassPhysicalStrengths";
import ClassPhysicalTests from "views/groups/ClassPhysicalTests";
import { useTranslation } from "react-i18next";

import _ from "lodash";

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  CardSubtitle,
  CardLink,
  CardText,
  ListGroup,
  ListGroupItem,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ModalHeader,
  Modal,
  ModalBody,
  Alert,
  Jumbotron,
} from "reactstrap";
import MemberForm from "views/forms/MemberForm";
import { roles, daysOfWeek } from "variables/constants";
import { getCustomClaimsFromLocalStorage } from "helpers/localStorage";
import Board from "views/components/Board.jsx";
import moment from "moment";
import { normalDateTime, normalDate } from "helpers/datetime";
import AddSubgroupMembers from "./AddSubgroupMembers";

function Subgroup({ location, history }) {
  const profile = useSelector((state) => state.firebase.profile);
  const boardEl = useRef(null);
  const { t, i18n } = useTranslation();
  // const [data, setData] = useState([]);

  let channel, group, subgroup;

  if (location?.state?.channel) {
    channel = location.state.channel;
    console.log("channel in subgroup", channel);
  }

  if (location?.state?.group) {
    group = location.state.group;
  }

  if (location?.state?.subgroup) {
    subgroup = location.state.subgroup;
  }

  if (!channel || !group || !subgroup) {
    if (isLoaded(profile) && !isEmpty(profile)) {
      channel = profile.channel;
      group = profile.group;
      subgroup = profile.subgroup;
    } else {
      const customClaims = getCustomClaimsFromLocalStorage();
      const { channelId, groupId, subgroupIds } = customClaims;
      channel = { id: channelId };
      group = { id: groupId };
      subgroup = { id: subgroupIds ? subgroupIds[0] : null };
    }
  }

  // 어떤 경우 자신의 클래스 아이디를 갖고 있지 않는 경우에는 이전으로 돌아가게 한다
  if (subgroup.id === null) {
    alert("클래스(반) 아이디가 제공되지 않았습니다.");
    history.goBack();
  }

  console.log("channel", channel);
  console.log("group", group);
  console.log("subgroup", subgroup);

  const groupId = group?.id;

  const firestore = useFirestore();

  const collectionPath = `channels/${channel.id}/groups/${groupId}/members`;
  const whereOptions = [["subgroupIds", "array-contains", subgroup.id]];
  console.log("collectionPath: ", collectionPath);
  console.log("whereOptions: ", whereOptions);

  useFirestoreConnect(() => [
    {
      collection: collectionPath,
      where: whereOptions,
      storeAs: "members",
    },
  ]);

  const [members, setMembers] = useState(null);
  const [filteredMembers, setFilteredMembers] = useState(null);
  const subgroupMembers = useSelector(
    (state) => state.firestore.ordered["members"]
  );
  const [selectedItem, setSelectedItem] = useState(null);
  const [toastMessage, setToastMessage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(!modalOpen);

  useEffect(() => {
    const items = subgroupMembers;
    const orderedItems = _.orderBy(
      items,
      (item) =>
        _.findLast(item.subgroups, { subgroupId: subgroup.id })?.classMemberNum
    );
    console.log("orderedItems = ", orderedItems);
    setMembers(orderedItems);
    return () => {
      setMembers(null);
      setFilteredMembers(null);
    };
  }, [subgroupMembers]);

  const ControlledTabs = (props) => {
    const [key, setKey] = useState("memberLists");

    return (
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey="memberLists" title={t("회원목록")}>
          <ClassMemberLists state={{ channel, group, subgroup, members }} />
        </Tab>
        <Tab eventKey="performingCurriculumLists" title={t("커리큘럼")}>
          <ClassPerformedCurriculums
            state={{ channel, group, subgroup, members }}
          />
        </Tab>
        <Tab eventKey="physicalStrengthLists" title={t("체력측정")}>
          <ClassPhysicalStrengths
            state={{ channel, group, subgroup, members }}
          />
        </Tab>
        <Tab eventKey="physicalFitnessTests" title={t("체력장")}>
          <ClassPhysicalTests state={{ channel, group, subgroup, members }} />
        </Tab>
      </Tabs>
    );
  };

  return (
    isLoaded(profile) && (
      <div className="content">
        <Alert
          isOpen={Boolean(toastMessage)}
          toggle={() => setToastMessage(null)}
          color="info"
        >
          {toastMessage}
        </Alert>
        <Breadcrumb className="sticky-top">
          <BreadcrumbItem>
            <a href="/">Home</a>
          </BreadcrumbItem>
          {profile.role != 4 ? (
            <BreadcrumbItem>
              <NavLink
                to={{ pathname: `./franchises`, state: { channel, group } }}
              >
                {t("가맹점 목록")}
              </NavLink>
            </BreadcrumbItem>
          ) : (
            ""
          )}
          {/* {
          profile.role === roles.admin
            ? <BreadcrumbItem><a href="./channels">총판 목록</a></BreadcrumbItem>
            : null
        }
        {
          profile.role > roles.groupAdmin
            ? <BreadcrumbItem>
              <NavLink to={{ pathname: `./groups`, state: { channel } }}>
                {channel?.title}
              </NavLink>
            </BreadcrumbItem>
            : null
        } */}
          {profile.role != 4 ? (
            <BreadcrumbItem>
              <NavLink
                to={{ pathname: `./franchise`, state: { channel, group } }}
              >
                {group?.title}
              </NavLink>
            </BreadcrumbItem>
          ) : (
            ""
          )}
          <BreadcrumbItem active>{subgroup?.title}</BreadcrumbItem>
        </Breadcrumb>
        <Jumbotron>
          <h1 className="display-3">
            <strong>{subgroup?.title}</strong>
          </h1>
          <p className="lead">
            {t("이곳은")} {members?.length}
            {t("의 회원들이 활동하는 클래스입니다.")} <br />
            {t(
              "이곳에서 회원들의 정보 및 수업 진행 상황을 확인할 수 있습니다."
            )}
          </p>
          <hr className="my-2" />
          <p>
            <strong>⚠️NOTE</strong> <br />
            {t(
              "- 먼저 차시와 체력장 수행을 해야 결과 리포트를 볼 수 있습니다."
            )}
            <br />
            {t(
              "- 회원 상태(정상/만료/차단) 변경은 회원 계정의 상태를 변경하게 됩니다."
            )}
          </p>
          <div>
            <span>
              &#8226;{t("생성일시")}: {normalDateTime(subgroup?.createdAt)}
            </span>
          </div>
          <div>
            <span>
              &#8226;{t("마지막 수정일시")}:{" "}
              {normalDateTime(subgroup?.updatedAt)}
            </span>
          </div>
          <div>
            <span>
              &#8226;{t("수강기간")}: {normalDate(subgroup?.startAt)}~
              {normalDate(subgroup?.endAt)}
            </span>
          </div>
          <div>
            <span>
              &#8226;{t("과정")}: {subgroup?.course?.title}
            </span>
          </div>
          <div>
            <span>
              &#8226;{t("매주")}{" "}
              {subgroup?.dayAndTimes
                ?.map((dayAndTime, idx) => (
                  <span key={idx}>
                    {t(daysOfWeek[dayAndTime?.dayOfWeek])} ({dayAndTime?.start}~
                    {dayAndTime?.end})
                  </span>
                ))
                ?.reduce((prev, curr) => [prev, ", ", curr])}
            </span>
          </div>
          {profile.role != 4 ? (
            <Button color="primary" onClick={toggleModal}>
              엑셀파일로 학생 생성하기
            </Button>
          ) : (
            ""
          )}
        </Jumbotron>
        <Row>
          <Col>
            <h6>{t("회원 검색")}</h6>
            <InputGroup style={{ width: "20rem" }}>
              <Input
                type="text"
                placeholder={t("회원 이름으로 검색")}
                onChange={async (e) => {
                  const name = e.target.value;
                  if (name.length < 2) {
                    setFilteredMembers(null);
                    return;
                  }
                  console.log("members = ", members);
                  const found = members.filter(
                    (member) => member.name?.indexOf(name) !== -1
                  );
                  setFilteredMembers(found);
                }}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <i className="nc-icon nc-zoom-split"></i>
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
            <Card style={{ width: "20rem" }}>
              <ListGroup flush>
                {filteredMembers &&
                  filteredMembers.map((member) => (
                    <ListGroupItem
                      key={member.id}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        history.push({
                          pathname: "./member",
                          state: {
                            channel,
                            group,
                            subgroup: member.subgroups[0],
                            member,
                          },
                        })
                      }
                    >
                      <div>
                        <i className="nc-icon nc-single-02"></i> {member.name} (
                        {member.subgroupTitle})
                      </div>
                      <div className="text-right text-muted">
                        {t("회원목록")}
                      </div>
                    </ListGroupItem>
                  ))}
              </ListGroup>
            </Card>
          </Col>
        </Row>
        <ControlledTabs />
        <Modal isOpen={Boolean(modalOpen)} size={"lg"} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            {t("엑셀 파일로 복수의 학생들을 한번에 추가합니다.")}
          </ModalHeader>
          <ModalBody>
            <ConfirmableForm>
              <AddSubgroupMembers
                channel={channel}
                group={group}
                subgroup={subgroup}
                closeModal={toggleModal}
              />
            </ConfirmableForm>
          </ModalBody>
        </Modal>
      </div>
    )
  );
}

export default Subgroup;
