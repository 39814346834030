import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useFirestoreConnect } from 'react-redux-firebase';
import _ from 'lodash';
import ReactBSAlert from "react-bootstrap-sweetalert";
import moment from 'moment';
import { useTranslation } from "react-i18next";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Progress,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";

import { newGroupSchema } from '../../variables/schema';
import readXlsxFile from 'read-excel-file'
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { asyncForEach } from 'helpers/async';
import { koreanAge } from 'helpers/datetime';

function BodyMeasurementForm(props) {
  const { channel } = props;

  const [subgroup, setSubgroup] = useState(props.subgroup || null);
  const [group, setGroup] = useState(props.group || null);
  const [member, setMember] = useState(props.member || null);
  const [measurement, setMeasurement] = useState(props.currentItem ? _.cloneDeep(props.currentItem) : null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [onSaving, setOnSaving] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [alert, setAlert] = useState(null);

  const firebase = useFirebase();
  const firestore = useFirestore();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (props.currentItem?.id) {
      const _measurement = _.clone(props.currentItem);
      delete _measurement.id;
      setMeasurement(_measurement);
    } else {
      setMeasurement({
        groupId: group.id,
        groupTitle: group.title,
        subgroupId: subgroup.id,
        subgroupTitle: subgroup.title,
        memberId: member.id,
        memberName: member.name,
      })
    }
    return () => {
      //clean up
    }
  }, [props.currentItem])

  useEffect(() => {
    const currentBM = _.cloneDeep(props.currentItem);
    if (measurement && Object.keys(measurement)?.length <= 6 ){
      return
    }
    if (currentBM?.id) {
      delete currentBM.id;
    }
    console.log(`currentBM`, currentBM);
    console.log(`measurement`, measurement);
    
    const modified = !_.isEqual(currentBM, measurement);
    console.log("modified: ", modified);
    setIsModified(modified);
    return () => {
      // cleanup
    };
  }, [measurement]);

  const onSave = async () => {
    console.log('press save');

    setOnSaving(true);

    const timestamp = {
      updatedAt: firestore.FieldValue.serverTimestamp()
    }

    if (!props.currentItem?.id) {
      timestamp['createdAt'] = firestore.FieldValue.serverTimestamp();
    }

    try {
      let measurementRef = firestore.collection(`channels/${channel.id}/groups/${props.group.id}/members/${member.id}/measurements`);
      if (props.currentItem?.id) {
        measurementRef = measurementRef.doc(props.currentItem?.id);
      } else {
        measurementRef = measurementRef.doc();
      }
      await measurementRef.set({
        ...measurement,
        ...timestamp,
      });
      props.afterSave && props.afterSave();
    } catch (error) {
      console.error('Failed to save : ', error);
    } finally {
      setOnSaving(false);
      props.toggle();
    }
  }

  const onDelete = async () => {
    const measurementRef = firestore.collection(`channels/${channel.id}/groups/${props.group.id}/members/${props.member.id}/measurements`).doc(props.currentItem.id);
    await measurementRef.delete();
    props.toggle();
    props.afterDelete && props.afterDelete();
  }

  const onCancel = () => {
    setMeasurement(null);
    if (isModified) {
      props.closeConfirm();
    } else {
      props.toggle();
    }
  }

  const warningWithConfirmAndCancelMessage = () => setAlert(
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title={<div>{t('삭제하면 복구할 수 없습니다.')}<br />{t("계속 진행하시겠습니까?")}</div>}
      onConfirm={() => {
        onDelete();
        setAlert(null);
      }}
      onCancel={() => setAlert(null)}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      confirmBtnText={t("확인")}
      cancelBtnText={t("취소")}
      showCancel
    >
      {t('지우면 완전히 삭제됩니다. 지우시겠습니까?')}
        </ReactBSAlert>
  )

  const onChange = (e, name, typeFn) => {
    const value = e.target.value;
    console.log('value: ', value);
    setMeasurement({ ...measurement, [name]: typeFn ? typeFn(value) : value });
  }

  return (
    <div className='content'>
      {alert}
      {/* name */}
      <Row>
        <Col>
          <div className="text-right"><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span>{t("필수 입력 사항입니다")}</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>{t("이름")}</label></Col>
          </Row>
          <FormGroup>
            <Input
              readOnly
              value={member?.name || ''}
            />
          </FormGroup>
        </Col>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>{t("생년월일")}</label></Col>
          </Row>
          <FormGroup>
            <Input
              type={'date'}
              readOnly
              value={member?.birthday || ''}
            />
          </FormGroup>
        </Col>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>{t("나이")}</label></Col>
          </Row>
          <FormGroup>
            <Input
              type={'number'}
              readOnly
              value={member?.age || ''}
            />
          </FormGroup>
        </Col>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>{t("성별")}</label></Col>
          </Row>
          <FormGroup>
            <Input
              type={'select'}
              readOnly
              value={member?.gender || ''}
            >
              <option></option>
              <option>{t("남")}</option>
              <option>{t("여")}</option>
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>{t("키")+'(cm)'}</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={measurement?.height}
              type='number'
              placeholder={t("측정된 키를 입력해주세요(숫자만)")}
              onChange={e => onChange(e, 'height', (t) => Number(t))} />
          </FormGroup>
        </Col>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>{t("몸무게")+'(kg)'}</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={measurement?.weight}
              type='number'
              placeholder={t("측정된 몸무게를 입력해주세요(숫자만)")}
              onChange={e => onChange(e, 'weight', (t) => Number(t))} />
          </FormGroup>

        </Col>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>{t("체지방")+'(kg)'}</label>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={measurement?.bodyFat}
              type='number'
              placeholder={t("측정된 체지방를 입력해주세요(숫자만)")}
              onChange={e => onChange(e, 'bodyFat', (t) => Number(t))} />
          </FormGroup>
        </Col>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>{t("근육량")+'(kg)'}</label>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={measurement?.muscleMass}
              type='number'
              placeholder={t("측정된 근육량를 입력해주세요(숫자만)")}
              onChange={e => onChange(e, 'muscleMass', (t) => Number(t))} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>{t("체수분")+'(kg)'}</label>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={measurement?.bodyWater}
              type='number'
              placeholder={t("측정된 체수분를 입력해주세요(숫자만)")}
              onChange={e => onChange(e, 'bodyWater', (t) => Number(t))} />
          </FormGroup>
        </Col>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>{t("단백질")+'(kg)'}</label>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={measurement?.bodyProtein}
              type='number'
              placeholder={t("측정된 단백질를 입력해주세요(숫자만)")}
              onChange={e => onChange(e, 'bodyProtein', (t) => Number(t))} />
          </FormGroup>
        </Col>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>{t("무기질")+'(kg)'}</label>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={measurement?.bodyMinerals}
              type='number'
              placeholder={t("측정된 무기질를 입력해주세요(숫자만)")}
              onChange={e => onChange(e, 'bodyMinerals', (t) => Number(t))} />
          </FormGroup>
        </Col>
      </Row>
      {
        errorMessage
          ? <div>
            <p className="text-danger">{errorMessage}</p>
          </div>

          : null
      }

      <Row>
        {/* {
          props.currentItem?.id
            ? <Col>
              <Button color="danger" onClick={warningWithConfirmAndCancelMessage}>삭제</Button>
            </Col>
            : null
        } */}
        <Col className='text-right'>
          <Button color="primary" disabled={onSaving || !Boolean(measurement?.height && measurement?.weight) || !isModified} onClick={onSave}>{t("저장")}</Button>
          <Button color="warning" onClick={onCancel}>{t("취소")}</Button>
        </Col>
      </Row>

    </div>
  )
}


BodyMeasurementForm.propTypes = {
  subgroup: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  member: PropTypes.object,
  afterSave: PropTypes.func,
  afterDelete: PropTypes.func,
  onCancel: PropTypes.func,
};

export default BodyMeasurementForm

