import moment from 'moment-with-locales-es6';

export const koreanManAge = (birth) => {
  var date = new Date();
  var year = date.getFullYear();
  var month = (date.getMonth() + 1);
  var day = date.getDate();

  if (month < 10) month = '0' + month;
  if (day < 10) day = '0' + day;
  var monthDay = month + day;

  if (birth) {
    birth = birth.replace('-', '').replace('-', '');
    var birthdayy = birth.substr(0, 4);
    var birthdaymd = birth.substr(4, 4);
    var age = monthDay < birthdaymd ? year - birthdayy - 1 : year - birthdayy;
  }
  return age;
}

export const koreanAge = (birth) => {
  var date = new Date();
  var year = date.getFullYear();

  if (birth) {
    birth = birth.replace('-', '').replace('-', '');
    var birthdayy = birth.substr(0, 4);
    var age = year - birthdayy + 1;
  }
  return age;

}

export const normalMoment = (aDate) => {
  // console.log('aDate type = ', typeof aDate);
  // console.log('aDate = ', aDate);
  return moment(aDate?.toDate
    ? aDate.toDate()
    : aDate?.seconds
      ? aDate?.seconds * 1000
      : aDate);
}
export const normalDateTime = (aDate) => {
  // console.log('aDate type = ', typeof aDate);
  // console.log('aDate = ', aDate);
  return normalMoment(aDate).format('YYYY-MM-DD HH:mm');
}
export const normalDate = (aDate) => {
  // console.log('aDate type = ', typeof aDate);
  // console.log('aDate = ', aDate);
  return normalMoment(aDate).format('YYYY-MM-DD');
}