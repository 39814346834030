export const CONTENT_VERSION_STATUS = {
  ON_DESIGN: 0,
  IN_REVIEW: 1,
  DEPLOYED: 2,
  RETIRED: 3
};

export const CONTENT_VERSION_STATUS_STRING = [
  '설계중',
  '검토중',
  '사용중',
  '사용하지 않음'
];

export const CURRICULUM_VERSION_STATUS = {
  ON_DESIGN: 0,
  IN_REVIEW: 1,
  DEPLOYED: 2,
  RETIRED: 3
};

export const CURRICULUM_VERSION_STATUS_STRING = [
  '설계중',
  '검토중',
  '사용중',
  '사용하지 않음'
];



