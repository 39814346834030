import { roles } from "variables/constants";

export const setUserProfileInLocalStorage = (profile) => {
  console.log("profile to localStorage: ", profile);
  localStorage.setItem("@insol/profile", JSON.stringify(profile));
};

export const getUserProfileFromLocalStorage = () => {
  const profile = JSON.parase(localStorage.getItem("@insol/profile"));
  console.log("profile from localStorage: ", profile);
  return profile;
};

export const setCustomClaimsInLocalStorage = (claims, channel, group, subgroup, member) => {
  const {
    admin = false,
    role = roles.student,
    user_id = null,
    channelId = null,
    groupId = null,
    subgroupIds = null,
    memberId = null,
  } = claims;

  const customClaims = {
    admin,
    role,
    user_id,
    channelId,
    groupId,
    subgroupIds,
    memberId,
    channel,
    group,
    subgroup,
    member
  };

  // console.log('custom claims to localStorage: ', customClaims);
  localStorage.setItem("@insol/customClaims", JSON.stringify(customClaims));
};

export const getCustomClaimsFromLocalStorage = () => {
  const customClaims = JSON.parse(localStorage.getItem("@insol/customClaims"));
  // console.log('custom claims from localStorage: ', customClaims);
  return customClaims;
};
