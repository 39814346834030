/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.fileInput = React.createRef();

    console.log('imageURL = ', this.props.imageUrl);
  }
  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        isRemoved: false,
        file: file,
        imagePreviewUrl: reader.result
      });
      this.props.onFileLoaded(file);
    };
    reader.readAsDataURL(file);
  }
  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  handleClick() {
    this.fileInput.current.click();
  }
  handleRemove() {
    this.setState({
      isRemoved: true,
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    });
    this.fileInput.current.value = null;
    this.props.onFileLoaded(null, true);
  }
  render() {
    return (
      <div className="fileinput text-center">
        <input type="file" onChange={this.handleImageChange} ref={this.fileInput} />
        <div className={"thumbnail" + (this.props.avatar ? " img-circle" : "")}>
          <img src={this.state.file === null && this.props.imageUrl ? this.props.imageUrl : this.state.imagePreviewUrl} alt="..." />
        </div>
        <div>
          {(this.state.file === null && !this.props.imageUrl) ? (
            <Button className="btn-round" onClick={() => this.handleClick()}>
              {this.props.avatar ? "Add Photo" : "Select image"}
            </Button>
          ) : (
              <span>
                <Button className="btn-round" onClick={() => this.handleClick()}>
                  Change
              </Button>
                {this.props.avatar ? <br /> : null}
                <Button
                  color="danger"
                  className="btn-round"
                  onClick={() => this.handleRemove()}
                >
                  <i className="fa fa-times" />
                Remove
              </Button>
              </span>
            )}
        </div>
      </div>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
  imageUrl: PropTypes.string,
  onFileLoaded: PropTypes.func,
};

export default ImageUpload;
