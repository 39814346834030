import React from 'react'
import { HorizontalBar } from 'react-chartjs-2';

function HorizontalBarChartWithPercentile({ value, percentile, max }) {
  // console.log('data = ', data);


  const data = {
    datasets: [
      {
        backgroundColor: 'rgba(35,85,164,0.2)',
        borderColor: 'rgba(35,85,164,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(35,85,164,0.4)',
        hoverBorderColor: 'rgba(35,85,164,1)',
        data: [value]
      }
    ]
  };

  const options = {
    legend: {
      display: false
    },
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true,
          fixedStepSize: 20,
          max: max || 100,
          min: 0,
        },
        stacked: true,
        gridLines: {
          display: true,
          color: ['gray', 'gray', 'gray', 'gray', 'gray', 'gray', 'gray', 'gray', 'gray', 'gray', 'gray'],
          zeroLineWidth: 4,
        },
      }]
    }
  }



  return (
    <div className='mt-4 px-2' >
      <HorizontalBar
        data={data}
        height={40}
        options={options}
      />
    </div>
  )
}

export default HorizontalBarChartWithPercentile
