import { number } from "prop-types";

export const newGroupSchema = [
  {
    '파일버전': {
      prop: 'version',
      type: Number,
      required: true,
    }
  }
  , {
    '가맹점명': {
      prop: 'title',
      type: String,
      required: true,
    },
    '총판': {
      prop: 'channel',
      type: String,
      required: true,
      oneOf: [
        '학교',
        '학원'
      ]
    },
  },
  {
    '클래스명': {
      prop: 'title',
      type: String,
      required: true,
    },
    '학년': {
      prop: 'grade',
      type: String,
      required: true,
    },
    '반': {
      prop: 'classNum',
      type: String,
      required: true
    },
    '인원수': {
      prop: 'numOfStudents',
      type: Number,
      required: true,
    },
    '회원연령': {
      prop: 'age',
      type: Number,
      required: true,
    }
  },
  {
    '회원번호': {
      prop: 'studentNum',
      required: true,
      type: String,
    },
    '클래스명': {
      prop: 'subgroupTitle',
      required: true,
      type: String,
    },
    '학년': {
      prop: 'gradeNum',
      required: true,
      type: String,
    },
    '반': {
      prop: 'classNum',
      required: true,
      type: String,
    },
    '반회원번호': {
      prop: 'classMemberNum',
      required: true,
      type: Number
    },
    '이름': {
      prop: 'name',
      required: true,
      type: String
    },
    '나이': {
      prop: 'age',
      required: true,
      type: Number,
    },
    '성별': {
      prop: 'gender',
      required: true,
      type: String,
    },
    '키': {
      prop: 'height',
      type: Number,
    },
    '몸무게': {
      prop: 'weight',
      type: Number,
    },
    '상태': {
      prop: 'status',
      type: String,
      oneOf: [
        '정상',
        '전학',
        '퇴학',
      ]
    }
  },
  {
    '이메일주소': {
      prop: 'email',
      type: String,
      required: true,
    },
    '비밀번호': {
      prop: 'password',
      type: String,
      required: true,
    },
    '이름': {
      prop: 'name',
      type: String,
      required: true,
    },
    '담당클래스명': {
      prop: 'subgroups',
      type: String,
      required: true,
    }
  }
];

export const newClassesAndMembersSchema = [
  {
    '학년/과목': {
      prop: 'title1',
      type: String,
      required: true,
    },
    '반명/시간': {
      prop: 'title2',
      type: String,
      required: true,
    },
    '학생번호': {
      prop: 'studentNum',
      type: String,
      required: true,
    },
    '이름': {
      prop: 'name',
      required: true,
      type: String
    },
    '나이': {
      prop: 'age',
      required: true,
      type: Number,
    },
    '성별': {
      prop: 'gender',
      required: true,
      type: String,
    },
    '수강시작': {
      prop: 'startAt',
      required: true,
      type: Date,
    },
    '수강만료': {
      prop: 'endAt',
      required: true,
      type: Date,
    },
  }
];

export const newMembersSchema = [
  {
    '학생번호': {
      prop: 'studentNum',
      type: String,
      required: true,
    },
    '이름': {
      prop: 'name',
      required: true,
      type: String
    },
    '나이': {
      prop: 'age',
      required: true,
      type: Number,
    },
    '성별': {
      prop: 'gender',
      required: true,
      type: String,
    },
    '수강시작': {
      prop: 'startAt',
      required: true,
      type: Date,
    },
    '수강만료': {
      prop: 'endAt',
      required: true,
      type: Date,
    },
  }
];

export const newContentSchema = [
  {
    '파일버전': {
      prop: 'version',
      type: String,
      required: true,
    },
    '변경내용': {
      prop: 'changelog',
      type: String,
      required: true,
    }
  },
  {
    'content_id': {
      prop: 'contentId',
      type: Number,
      required: true
    },
    'title': {
      prop: 'title',
      type: String,
      required: true
    },
    'content_type': {
      prop: 'contentType',
      type: String,
      required: true,
      oneOf: [
        'SP',
        'MS',
        'MR',
        'TM'
      ]
    },
    'sensor_type_first_id': {
      prop: 'sensorTypeFirstId',
      type: Number,
    },
    'sensor_type_second_id': {
      prop: 'sensorTypeSecondId',
      type: Number,
    },
    'content_description': {
      prop: 'contentDescription',
      type: String,
      required: true,
    },
    'player_number': {
      prop: 'playerNumber',
      type: Number,
      required: true,
    },
    'is_measurable': {
      prop: 'isMeasurable',
      type: Number,
      required: true,
    },
    'content_description_code': {
      prop: 'contentDescriptionCode',
      type: Number,
      required: true,
    },
    'title_code': {
      prop: 'titleCode',
      type: Number,
      required: true,
    },
    'met_value': {
      prop: 'metValue',
      type: Number,
    }
  }
];
