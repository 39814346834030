import React from 'react'
import PropTypes from 'prop-types'
import _, { indexOf } from 'lodash';

import Board from 'views/components/Board.jsx'
import { useFirestoreConnect, useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useTable } from 'react-table';
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";


// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import { useRef, useEffect } from 'react';
import moment from 'moment-with-locales-es6';

import SparkChart from 'components/Charts/SparkChart';
import RadarChart from 'components/Charts/RadarChart';
import CurriculumResultForm from 'views/forms/CurriculumResultForm';

moment.locale('ko');

function CurriculumResults(props) {
  console.log('props in curriculumResults = ', props);
  const { channel, group, subgroup, member } = props.state;
  //받아오는 데이터 중 channel id 값만 존재함. channel의 content verison, curriculumn version  정보 없음
  //channel id는 여기서 받아와야 함

  console.log('group = ', group.id);
  console.log('subgroup = ', subgroup.id);
  console.log('member = ', member.id);
  console.log('channel in CurriculumResults = ', channel.id);
  const firestore = useFirestore();
  const { t, i18n } = useTranslation();
  useFirestoreConnect([
    {
      collection: `channels/${channel?.id}/groups/${group?.id}/members/${member?.id}/curriculumResults`,
      orderBy: [
        ['createdAt', 'desc']
      ],
      storeAs: 'curriculumResults'
    },
    {
      collection: `channels/${channel?.id}/groups/${group?.id}/subgroups`,
      orderBy: [
        ['course']
      ],
      storeAs: 'courses'
    },
    {
      collection: `contentVersions/${channel?.contentVersion}/contents`,
      orderBy: [['contentId']],
      storeAs: 'contents'
    }
  ]);

  const items = useSelector(state => state.firestore.ordered['curriculumResults']);
  const boardEl = useRef(null);
  console.log('items in curriculumResults = ', items);
  const courses = useSelector(state => state.firestore.ordered['courses']);
  console.log('courses in curriculumResults = ', courses);
  //contents 데이터가 들어오지 못하고 있음
  const contents = useSelector(state => state.firestore.ordered['contents']);
  console.log('contents in contentsVersion = ', contents);
  const allContents = useSelector(state => state.firestore.ordered['contents']);
  console.log('all contents in curriculumResults = ', allContents);
  //courses && console.log('testId in curriculumResults = ', courses.findIndex(i=>i.course.id=='iboiBi2i8BioeDDjcle6'));

  const data = items && items.map((item) => ({
  ...item,
    actions: (
      // we've added some custom button actions
      <div className="actions-left">
        {/* use this button to add a like kind of action */}
        {/* <Button
          onClick={() => {
            console.log('clicked');
            console.log(item);
            console.log(boardEl);
          }}
          color="info"
          size="sm"
          className="btn-icon btn-link like"
        >
          <i className="fa fa-heart" />
        </Button>{" "} */}
        {/* use this button to add a edit kind of action */}
        <Button
          onClick={() => {
            console.log('clicked');
            boardEl.current.edit(item);
          }}
          color="warning"
          size="sm"
          className="btn-icon btn-link edit"
        >
          <i className="fa fa-edit" style={{ fontSize: '1.5em' }} />
        </Button>{" "}
        {/* use this button to remove the data row */}
        <Button
          onClick={() => {
            console.log('clicked');
            boardEl.current.delete(() => {
              firestore.doc(`channels/${channel.id}/groups/${group.id}/members/${member.id}/curriculumResults/${item.id}`).delete();
            });
          }}
          color="danger"
          size="sm"
          className="btn-icon btn-link remove"
        >
          <i className="fa fa-times" style={{ fontSize: '1.5em' }} />
        </Button>{" "}
      </div>
    )
  }))
  // console.log('data', data);

  return (
    <div style={{marginTop:'10px'}} className="content">
      <Board
        modalTitle={t('새 수행결과 추가')}
        data={data}
        ref={boardEl}
        columns={[
          {
            id: 'createdAt',
            Header: t("수행일시"),
            accessor: d => d.createdAt ? moment(d.createdAt.toDate()).format('YYYY-MM-DD HH:mm') : ''
          },
          {
            id: 'content',
            Header: t("콘텐츠"),
            accessor: d => d.contentId ? contents && contents[contents.findIndex(i=>i.id==d?.contentId)]?.title:'',
            filterMethod: (filter, row) => {
              console.log('filter = ', filter);
              const title = row[filter.id];
              console.log('row title = ', title);
              return title && title.indexOf(filter.value) != -1;
            }
          },
          {
            id: 'course',
            Header: t("코스명"),
            accessor: d =>d.courseId? courses&&courses[courses.findIndex(i=>i.course?.id==d?.courseId)]?.course.title:'',
          },
          {
            Header: t("차시"),
            accessor: 'lessonNo'
          },
          {
            id:'result',
            Header: t("결과치"),
            accessor: d=>d?.result === 0 ? t('결과치 없음') : parseFloat( d.result.toFixed(2))
          },
          {
            id: 'updatedAt',
            Header: t("수정일시"),
            accessor: d => d?.updatedAt ? moment(d.updatedAt.toDate()).format('YYYY-MM-DD HH:mm') : ''
          },
          {
            Header: t("명령"),
            accessor: "actions",
            sortable: false,
            filterable: false
          }
        ]}
      >
        <CurriculumResultForm
          channel={channel}
          group={group}
          subgroup={subgroup}
          member={member}
          contents={contents}
          allContents={allContents}
        />
      </Board>
    </div>
  )
}

CurriculumResults.propTypes = {

}

export default CurriculumResults