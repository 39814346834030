import React, { useEffect, useState, useMemo } from "react";
import { koreanAge } from "helpers/datetime";
import { genderString } from "helpers/gender";
import { genderAbbr } from "helpers/gender";
import { Row, Col, Table, Button } from "reactstrap";

import { getCustomClaimsFromLocalStorage } from "helpers/localStorage";
import _ from "lodash";
import moment from "moment";
import { useFirestoreConnect, useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import BarChartWithPercentile from "./BarChartWithPercentile";
import HorizontalBarChartWithPercentile from "./HorizontalBarChartWithPercentile";
import RadarChart from "./RadarChart";
import { comments } from "./comments.js";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  msActivitiy,
  meActivitiy,
  ceActivitiy,
  agActivitiy,
} from "variables/constants";
import { useTranslation } from "react-i18next";

function PhysicalTestsReport(props) {
  const { t, i18n } = useTranslation();
  console.log("props = ", props);
  const {
    channel,
    group,
    subgroup,
    member,
    physicalTests: givenPhysicalTests,
    measurements: givenMeasurements,
    curriculumResults: givenCurriculums,
    createdAt,
  } = props;

  console.log("member in report =>", member);
  console.log("givenMeasurements = ", givenMeasurements);
  console.log("givencurriculums = ", givenCurriculums);
  const { name = "홍길동", gender = 1, age, birthday = "2010-01-01" } = member;

  const [physicalTests, setPhysicalTests] = useState(
    givenPhysicalTests || null
  );
  const [measurements, setMeasurements] = useState(givenMeasurements || null);
  const [curriculumResults, setCurriculumResults] = useState(
    givenCurriculums || null
  );
  const [barChartData, setBarChartData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [percentiles, setPercentiles] = useState(null);
  const [percentileAge, setPercentileAge] = useState(null);

  const firestore = useFirestore();

  console.log("measurements = ", measurements);
  console.log("physicalTests = ", physicalTests);
  console.log("curriculumsResults = ", curriculumResults);

  const customClaims = getCustomClaimsFromLocalStorage();
  console.log("customClaims = ", customClaims);
  const { role } = customClaims;

  let whereOptions = ["result", ">", 0];

  // const getPhysicalTestResult = async(memberId)=>{
  //   console.log("in getphysicaltestresult");
  //   const ref = firestore
  //     .collection(
  //       `channels/${channel.id}/groups/${group.id}/members/${memberId}/physicalTests`
  //     )
  //     .orderBy("createdAt", "desc")
  //     .limit(3);
  //   const colsnap = await ref.get();
  //   const physicalResult = colsnap.size > 0 ? colsnap.docs[0].data() : null;
  //   console.log("physicalResult = ", physicalResult);
  //   return physicalResult;
  // }

  const getPercentiles = async (version) => {
    await firestore
      .doc(`percentiles/${version}`)
      .get()
      .then((docsnap) => {
        console.log("in getpPercentiles", docsnap.data());
        const _percentiles = docsnap.data();
        setPercentiles(_percentiles);
        if (5 <= age && age < 20) {
          setPercentileAge(age.toString());
        } else if (20 <= age && age < 30) {
          setPercentileAge("20-29");
        } else if (age < 40) {
          setPercentileAge("30-39");
        } else {
          setPercentileAge("40-49");
        }

        // console.log("percentiles 5 = ", percentiles['5']);
      });
  };

  useEffect(() => {
    // getPercentiles('v2Current');

    return () => {
      // cleanup
    };
  }, [i18n.language]);

  useEffect(() => {
    console.log("check percentileAge =>>", percentileAge);
    if (percentiles) {
      console.log("after get percentiles =>>", percentiles[percentileAge]);
      // percentiles[percentileAge];
    }
    // console.log('check percentiles =>>', percentiles['20-29']);
    // console.log('check gender', percentiles['20-29']['ag']);
    return () => {
      // cleanup
    };
  }, [percentileAge]);

  const queryConfig =
    physicalTests && measurements
      ? []
      : [
          {
            collection: `channels/${channel.id}/groups/${group.id}/members/${member.id}/physicalTests`,
            orderBy: [["createdAt"]],
            storeAs: "physicalTests",
          },
          {
            collection: `channels/${channel.id}/groups/${group.id}/members/${member.id}/measurements`,
            orderBy: [["createdAt"]],
            storeAs: "measurements",
          },
          {
            collection: `channels/${channel.id}/groups/${group.id}/members/${member.id}/curriculumResults`,
            where: whereOptions,
            orderBy: [["result"]],
            storeAs: "curriculumResults",
          },
        ];
  console.log("queryConfig = ", queryConfig);
  useFirestoreConnect(queryConfig);

  const _physicalTests = useSelector(
    (state) => state.firestore.ordered["physicalTests"]
  );
  const _curriculumResults = useSelector(
    (state) => state.firestore.ordered["curriculumResults"]
  );
  console.log("curriculum results in repoert=>", _curriculumResults);
  useEffect(() => {
    if (_physicalTests) {
      let tests = _physicalTests?.map((item, index) => ({
        ...item,
        sessionNo: index + 1,
      }));
      console.log("1:", tests);
      //tests = _.reverse(tests);
      console.log("2:", tests);
      tests = _.take(tests, 3);
      console.log("3:", tests);
      setPhysicalTests(tests);
    }
    return () => {
      // cleanup
    };
  }, [_physicalTests]);

  useEffect(() => {
    console.log("physicalTests = ", physicalTests);
    if(physicalTests && physicalTests.length>0){
    physicalTests.reverse();
    }
    // physicalTests = [] 
    const data = {
      labels: physicalTests?.map((item) => t("회차", { no: item.sessionNo === 1 ? 3: item.sessionNo === 3? 1: 2 })),
      dataset: {
        fill: true,
        borderWidth: 1,
        backgroundColor: [
          "#2355a4", //1회차
          "#624e9d", //2회차
          "#e74692", //3회차
        ],
      },
    };
    const options = {
      tooltips: {
        tooltipFillColor: "rgba(0,0,0,0.5)",
        tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        tooltipFontSize: 14,
        tooltipFontStyle: "normal",
        tooltipFontColor: "#fff",
        tooltipTitleFontFamily:
          "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        tooltipTitleFontSize: 14,
        tooltipTitleFontStyle: "bold",
        tooltipTitleFontColor: "#fff",
        tooltipYPadding: 6,
        tooltipXPadding: 6,
        tooltipCaretSize: 8,
        tooltipCornerRadius: 6,
        tooltipXOffset: 10,
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              fontColor: "#9f9f9f",
              fontStyle: "bold",
              beginAtZero: true,
              maxTicksLimit: 5,
              padding: 20,
            },
            gridLines: {
              zeroLineColor: "transparent",
              display: true,
              drawBorder: false,
              color: "#9f9f9f",
            },
          },
        ],
        xAxes: [
          {
            maxBarThickness: 20, // number (pixels)
            gridLines: {
              zeroLineColor: "blue",
              display: false,
              drawBorder: false,
              color: "transparent",
            },
            ticks: {
              padding: 20,
              fontColor: "#9f9f9f",
              fontStyle: "bold",
            },
          },
        ],
      },
    };

    let ageDivision = 3;
    if ((birthday !== "" && koreanAge(birthday) < 10) || age < 10) {
      ageDivision = 0;
    } else if ((birthday != "" && koreanAge(birthday) < 14) || age < 14) {
      ageDivision = 1;
    } else if ((birthday != "" && koreanAge(birthday) < 51) || age < 51) {
      ageDivision = 2;
    }

    // msActivitiy, meActivitiy, ceActivitiy, agActivitiy

    let transData = [
      {
        title: t("근력") + "(MS)",
        dataset: {
          ...data,
          datasets: [{ ...data.dataset, data: _.map(physicalTests, "ms") }],
        },
        // comment: t(_.sample(_.sample(comments.ms))),
        comment: t(comments.ms[0]),
        content: t(msActivitiy[ageDivision]),
        options,
      },
      {
        title: t("근지구력") + "(ME)",
        dataset: {
          ...data,
          datasets: [{ ...data.dataset, data: _.map(physicalTests, "me") }],
        },
        // comment: t(_.sample(_.sample(comments.me))),
        comment: t(comments.me[0]),
        content: t(meActivitiy[ageDivision]),
        options,
      },
      {
        title: t("심폐지구력") + "(CE)",
        dataset: {
          ...data,
          datasets: [{ ...data.dataset, data: _.map(physicalTests, "ce") }],
        },
        // comment: t(_.sample(_.sample(comments.ce))),
        comment: t(comments.ce[0]),
        content: t(ceActivitiy[ageDivision]),
        options,
      },
      {
        title: t("민첩성") + "(AG)",
        dataset: {
          ...data,
          datasets: [{ ...data.dataset, data: _.map(physicalTests, "ag") }],
        },
        // comment: t(_.sample(_.sample(comments.ag))),
        comment: t(comments.ag[0]),
        content: t(agActivitiy[ageDivision]),
        options,
      },
      {
        title: t("순발력") + "(PO)",
        dataset: {
          ...data,
          datasets: [{ ...data.dataset, data: _.map(physicalTests, "po") }],
        },
        // comment: t(_.sample(_.sample(comments.po))),
        comment: t(comments.po[0]),
        content: t("서전트 점프"),
        options,
      },
      {
        title: t("협응성") + "(CO)",
        dataset: {
          ...data,
          datasets: [{ ...data.dataset, data: _.map(physicalTests, "co") }],
        },
        // comment: t(_.sample(_.sample(comments.co))),
        comment: t(comments.co[0]),
        content: t("눈손 협응력"),
        options,
      },
      {
        title: t("평형성") + "(BA)",
        dataset: {
          ...data,
          datasets: [{ ...data.dataset, data: _.map(physicalTests, "ba") }],
        },
        // comment: t(_.sample(_.sample(comments.ba))),
        comment: t(comments.ba[0]),
        content: t("눈감고 외발서기"),
        options,
      },
    ];

    console.log("transformed data = ", transData);
    setBarChartData(transData);

    return () => {
      // cleanup
    };
  }, [physicalTests, i18n.language]);

  const _measurements = useSelector(
    (state) => state.firestore.ordered["measurements"]
  );

  useEffect(() => {
    if (_measurements) {
      let measurements = _measurements?.map((item, index) => ({
        ...item,
        sessionNo: index + 1,
      }));
      measurements = _.reverse(measurements);
      measurements = _.take(measurements, 3);
      setMeasurements(measurements);
    }

    return () => {
      // cleanup
    };
  }, [_measurements]);

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("저장되었습니다.")}
        onConfirm={() => setAlert(null)}
        confirmBtnBsStyle="info"
      >
        {t("레포트가 저장되었습니다.")}
      </SweetAlert>
    );
  };

  const failureAlert = () => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="저장실패"
        onConfirm={() => setAlert(null)}
        confirmBtnBsStyle="info"
      >
        레포트를 저장할 수 없습니다.
      </SweetAlert>
    );
  };

  const saveReport = async () => {
    const reportRef = firestore.collection(`reports`).doc();
    const reportData = {
      createdAt: firestore.FieldValue.serverTimestamp(),
      updatedAt: firestore.FieldValue.serverTimestamp(),
      member,
      channel,
      group,
      subgroup,
      measurements,
      physicalTests,
    };

    console.log("reportData = ", reportData);

    try {
      await reportRef.set(reportData);
      successAlert();
    } catch (error) {
      failureAlert();
    }
  };

  // console.log(`####현재 birthday check`, birthday);
  // console.log(`####현재 birthday 비어있어?`, birthday == "");

  return (
    <div className="container msp">
      {alert}
      {/* <div>
        {
          role > 3 &&
          <Button onClick={() => saveReport()}>저장</Button>
        }
      </div> */}
      {/* <div id="divToPrint" className="mt4" style={{
        // backgroundColor: '#f5f5f5',
        // width: '210mm',
        // minHeight: '297mm',
        // marginLeft: '15mm',
        // marginRight: '15mm',

      }}> */}
      <div>
        <div className="text-right">
          <img src={require("assets/img/logo_modoo.png")} width={150} />
        </div>
        <Row>
          <Col>
            <div>
              <span className="text-uppercase title">
                Physical fitness report
              </span>
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <div>
              <span className="name">{name} </span>
              {/* <span className="name-sub">({t(genderString(gender))}/</span> */}
              <span className="name-sub">({t((gender))}/</span>
              {/* {birthday == "" ? ( */}
                <span className="name-sub">{age})</span>
              {/* ) : (
                <span className="name-sub">{koreanAge(birthday)})</span>
              )} */}
            </div>
          </Col>
          <Col className="name-sub d-flex align-items-end justify-content-end">
            <span>
              {createdAt
                ? moment(createdAt.seconds * 1000).format("YYYY-MM-DD")
                : moment().format("YYYY-MM-DD")}
            </span>
          </Col>
        </Row>
        <Row className="mt-2">
          <Table borderless className="text-center">
            <thead>
              <tr>
                <td>{t("회차")}</td>
                <td>{t("기관")}</td>
                <td>{t("측정일자")}</td>
                <td>{t("신장")}</td>
                <td>{t("체중")}</td>
                <td>{t("등급")}</td>
              </tr>
            </thead>
            <tbody>
              {measurements?.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <td>{item?.sessionNo}</td>
                    <td>
                      {channel.title} - {group.title}
                    </td>
                    <td>
                      {moment(item.createdAt.seconds * 1000).format(
                        "YYYY-MM-DD"
                      )}
                    </td>
                    <td>{item.height?.toFixed(1)} cm</td>
                    <td>{item.weight?.toFixed(1)} kg</td>
                    <td>
                      {item.level || 1}
                      {t("등급")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Row>
        <Row className="mt-2">
          <Col xs={3} lg={3}>
            <div className="section-title">
              <span>{t("신체분석")}</span>
            </div>
          </Col>
          <Col className="d-flex justify-content-center align-items-center">
            <div className="section-line"></div>
          </Col>
          <Col
            xs={"auto"}
            className="bmi d-flex justify-content-center align-items-center"
          >
            <span className="thin p-1 px-3 ">{t("마름")}</span>
            <span className="normal p-1 px-3">{t("권장")}</span>
            <span className="fat p-1 px-3">{t("비만")}</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table borderless size={"xs"}>
              <tbody>
                {_.take(measurements, 1).length>0?_.take(measurements, 1).map((item, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <tr>
                        <td className="bold small-height">{t("신장")}</td>
                        <td className="small-height">
                          {item.height?.toFixed(1)} cm
                        </td>
                        <td className="bold small-height">
                          {t("체질량 지수")}
                        </td>
                        <td className="small-height">
                          {item.muscleMass?.toFixed(1)} kg/m<sup>2</sup>
                        </td>
                        <td className="text-muted small-height" width={"50%"}>
                          <HorizontalBarChartWithPercentile
                            value={item.height}
                            max={200}
                          />
                        </td>
                      </tr>
                      <tr className="small-height">
                        <td className="bold small-height">{t("체중")}</td>
                        <td className="small-height">
                          {item.weight?.toFixed(1)} kg
                        </td>
                        <td className="bold small-height">{t("체지방률")}</td>
                        <td className="small-height">
                          {item.bodyFat?.toFixed(1)} %
                        </td>
                        <td className="text-muted small-height">
                          <HorizontalBarChartWithPercentile
                            value={item.weight}
                            max={100}
                          />
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                }):<tr><td>신체측정 기록이 없습니다.</td></tr>}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={3} lg={3}>
            <div className="section-title">
              <span>{t("측정결과")}</span>
            </div>
          </Col>
          <Col className="d-flex justify-content-center align-items-center">
            <div className="section-line"></div>
          </Col>
          <Col
            xs={"auto"}
            className="measure d-flex justify-content-center align-items-center"
          >
            <span className="first p-1 px-3">
              {t("회차", {
                no: physicalTests ? (physicalTests[0]?.sessionNo === 1 ? 3:(physicalTests[0]?.sessionNo===3?1:2)) : "",
              })}
            </span>
            <span className="second p-1 px-3">
              {t("회차", {
                no: physicalTests ? physicalTests[1]?.sessionNo : "",
              })}
            </span>
            <span className="third p-1 px-3 ">
              {t("회차", {
                no: physicalTests ? (physicalTests[2]?.sessionNo=== 1 ? 3:(physicalTests[0]?.sessionNo===3?1:2)) : "",
              })}
            </span>
          </Col>
        </Row>
        <Row>
          {barChartData.map((item, idx) => (
            <Col key={idx} sm={12} lg={3} className="pb-3 col-print-3">
              <BarChartWithPercentile data={item} />
            </Col>
          ))}
          <Col sm={12} lg={3} className="mt-2 px-2 col-print-3">
            <RadarChart physicalTests={physicalTests} />
          </Col>
        </Row>
        {/* <Row>
          <Col xs={3} lg={3}>
            <div className="section-title">
              <span>{t("전문가 소견")}</span>
            </div>
          </Col>
          <Col className="d-flex justify-content-center align-items-center">
            <div className="section-line"></div>
          </Col> */}
          {/* <Col className='rounded mt-5 p-3' style={{ backgroundColor: 'lightgray', height: 100 }}>
          <h6>전문가 소견</h6>
        </Col> */}
        {/* </Row> */}
      </div>
    </div>
    // </div >
  );
}

export default PhysicalTestsReport;
