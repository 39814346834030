/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Buttons from "views/components/Buttons.jsx";
import Calendar from "views/Calendar.jsx";
import Charts from "views/Charts.jsx";
import Dashboard from "views/Dashboard.jsx";
import ExtendedForms from "views/forms/ExtendedForms.jsx";
import ExtendedTables from "views/tables/ExtendedTables.jsx";
import FullScreenMap from "views/maps/FullScreenMap.jsx";
import GoogleMaps from "views/maps/GoogleMaps.jsx";
import GridSystem from "views/components/GridSystem.jsx";
import Icons from "views/components/Icons.jsx";
import LockScreen from "views/pages/LockScreen.jsx";
import Login from "views/pages/Login.jsx";
import Notifications from "views/components/Notifications.jsx";
import Panels from "views/components/Panels.jsx";
import ReactTables from "views/tables/ReactTables.jsx";
import Register from "views/pages/Register.jsx";
import RegularForms from "views/forms/RegularForms.jsx";
import RegularTables from "views/tables/RegularTables.jsx";
import SweetAlert from "views/components/SweetAlert.jsx";
import Timeline from "views/pages/Timeline.jsx";
import Typography from "views/components/Typography.jsx";
import UserProfile from "views/pages/UserProfile.jsx";
import ValidationForms from "views/forms/ValidationForms.jsx";
import VectorMap from "views/maps/VectorMap.jsx";
import Widgets from "views/Widgets.jsx";
import Wizard from "views/forms/Wizard.jsx";

// insol created
import Users from 'views/users/Users';
import Groups from 'views/groups/Groups';
import ReportMember from 'views/reports/ReportMember';
import ReportHome from 'views/reports/ReportHome';
import Channels from 'views/groups/Channels';
import Group from "views/groups/Group";
import Licenses from "views/groups/Licenses";
import AllLicenses from "views/groups/AllLicenses";
import Subgroup from 'views/groups/Subgroup';
import Member from "views/groups/Member";
import ContentVersions from "views/contents/ContentVersions";
import ContentVersion from "views/contents/ContentVersion";
import ContentHome from 'views/contents/ContentHome';
import ReportSubgroup from "views/reports/ReportSubgroup";
import ReportGroup from "views/reports/ReportGroup";
import Measurements from "views/groups/Measurements";
import CourseDetails from "views/groups/CourseDetails";
import PhysicalTests from "views/groups/PhysicalTests";
import CurriculumVersions from "views/contents/CurriculumVersions";
import CurriculumVersion from "views/contents/CurriculumVersion";
import CourseHome from "views/contents/CourseHome";
import CurriculumResults from "views/groups/CurriculumResults";
import { roles } from "variables/constants";

const redirect = true;

const routes = [
  {
    path: "/member",
    name: "회원 홈",
    icon: "nc-icon nc-bank",
    component: Member,
    layout: "/parents",
  },
  {
    redirect: true,
    path: "/report-member",
    name: "리포트",
    icon: "nc-icon nc-paper",
    component: ReportMember,
    layout: "/parents",
  },
  {
    redirect: true,
    path: "/group",
    name: "가맹점 홈",
    icon: "nc-icon nc-bank",
    component: Group,
    layout: "/parents",
  },
  {
    redirect: redirect,
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/parents"
  },
  {
    redirect: true,
    path: "/user-profile",
    name: "UserProfile",
    mini: "UP",
    component: UserProfile,
    layout: "/parents"
  }
];

export default routes;
