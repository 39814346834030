import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useFirestoreConnect } from 'react-redux-firebase';
import _ from 'lodash';
import ReactBSAlert from "react-bootstrap-sweetalert";
import moment from 'moment';
import { useTranslation } from "react-i18next";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Progress,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";

import { newGroupSchema } from '../../variables/schema';
import readXlsxFile from 'read-excel-file'
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { asyncForEach } from 'helpers/async';
import { koreanAge } from 'helpers/datetime';

function PhysicalTestForm(props) {
  const { channel } = props;

  const [subgroup, setSubgroup] = useState(props.subgroup || null);
  const [group, setGroup] = useState(props.group || null);
  const [member, setMember] = useState(props.member || null);
  const [physicalTest, setPhysicalTest] = useState(props.currentItem ? _.cloneDeep(props.currentItem) : null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModified, setIsModified] = useState(false);
  const [onSaving, setOnSaving] = useState(false);
  const [alert, setAlert] = useState(null);

  const firebase = useFirebase();
  const firestore = useFirestore();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (props.currentItem?.id) {
      const _physicalTest = _.clone(props.currentItem);
      delete _physicalTest.id;
      setPhysicalTest(_physicalTest);
    } else {
      setPhysicalTest({
        groupId: group.id,
        groupTitle: group.title,
        subgroupId: subgroup.id,
        subgroupTitle: subgroup.title,
        memberId: member.id,
        memberName: member.name,
      })
    }
    return () => {
      //clean up
    }
  }, [props.currentItem])

  useEffect(() => {
    const currentItem = _.cloneDeep(props.currentItem);
    if ( physicalTest && Object.keys(physicalTest)?.length <= 6 ){
      return
    }
    if (currentItem?.id) {
      delete currentItem.id;
    }
    console.log(`currentItem`, currentItem);
    console.log(`physicalTest`, physicalTest);
    physicalTest && console.log(`physicalTest`, Object.keys(physicalTest).length);
    
    const modified = !_.isEqual(currentItem, physicalTest);
    console.log("modified: ", modified);
    setIsModified(modified);
    return () => {
      // cleanup
    };
  }, [physicalTest]);

  const onSave = async () => {
    console.log('press save');

    setOnSaving(true);

    const timestamp = {
      updatedAt: firestore.FieldValue.serverTimestamp()
    }

    if (!props.currentItem?.id) {
      timestamp['createdAt'] = firestore.FieldValue.serverTimestamp();
    }

    try {
      let physicalTestRef = firestore.collection(`channels/${channel.id}/groups/${props.group.id}/members/${member.id}/physicalTests`);
      if (props.currentItem?.id) {
        physicalTestRef = physicalTestRef.doc(props.currentItem?.id);
      } else {
        physicalTestRef = physicalTestRef.doc();
      }
      await physicalTestRef.set({
        ...physicalTest,
        ...timestamp,
      });
      props.afterSave && props.afterSave();
    } catch (error) {
      console.error('Failed to save : ', error);
    } finally {
      setOnSaving(false);
      props.toggle();
    }
  }

  const onDelete = async () => {
    const physicalTestRef = firestore.collection(`channels/${channel.id}/groups/${props.group.id}/members/${props.member.id}/physicalTests`).doc(props.currentItem.id);
    await physicalTestRef.delete();
    props.toggle();
    props.afterDelete && props.afterDelete();
  }

  const onCancel = () => {
    setPhysicalTest(null);
    if (isModified) {
      props.closeConfirm();
    } else {
      props.toggle();
    }
  }

  const warningWithConfirmAndCancelMessage = () => setAlert(
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title={<div>{t('삭제하면 복구할 수 없습니다.')}<br />{t('계속 진행하시겠습니까?')}</div>}
      onConfirm={() => {
        onDelete();
        setAlert(null);
      }}
      onCancel={() => setAlert(null)}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      confirmBtnText={t("확인")}
      cancelBtnText={t("취소")}
      showCancel
    >
      {t("지우면 완전히 삭제됩니다. 지우시겠습니까?")}
        </ReactBSAlert>
  )

  const onChange = (e, name, typeFn) => {
    const value = e.target.value;
    console.log('value: ', value);
    setPhysicalTest({ ...physicalTest, [name]: typeFn ? typeFn(value) : value });
  }

  return (
    <div className='content'>
      {alert}
      {/* name */}
      <Row>
        <Col>
          <div className="text-right"><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span>{t("필수 입력 사항입니다")}</div>
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <Row className="align-items-center justify-content-between">
            <Col><label>{t("이름")}</label></Col>
          </Row>
          <FormGroup>
            <Input
              readOnly
              value={member?.name || ''}
            />
          </FormGroup>
        </Col>
        <Col xs={3}>
          <Row className="align-items-center justify-content-between">
            <Col><label>{t("생년월일")}</label></Col>
          </Row>
          <FormGroup>
            <Input
              type={'date'}
              readOnly
              value={member?.birthday || ''}
            />
          </FormGroup>
        </Col>
        <Col xs={3}>
          <Row className="align-items-center justify-content-between">
            <Col><label>{t("나이")}</label></Col>
          </Row>
          <FormGroup>
            <Input
              type={'number'}
              readOnly
              value={member?.age || ''}
            />
          </FormGroup>
        </Col>
        <Col xs={3}>
          <Row className="align-items-center justify-content-between">
            <Col><label>{t("성별")}</label></Col>
          </Row>
          <FormGroup>
            <Input
              type={'select'}
              readOnly
              value={member?.gender || ''}
            >
              <option></option>
              <option>{t("남")}</option>
              <option>{t("여")}</option>
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Row className="align-items-center justify-content-between">
            <Col><label>{t("근력")}</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Input
              value={physicalTest?.ms}
              type='number'
              placeholder={t("근력 결과를 입력해주세요(숫자만)")}
              onChange={e => onChange(e, 'ms', (t) => Number(t))} />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <Row className="align-items-center justify-content-between">
            <Col><label>{t("근지구력")}</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Input
              value={physicalTest?.me}
              type='number'
              placeholder={t("근지구력 결과를 입력해주세요(숫자만)")}
              onChange={e => onChange(e, 'me', (t) => Number(t))} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Row className="align-items-center justify-content-between">
            <Col><label>{t("심폐지구력")}</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Input
              value={physicalTest?.ce}
              type='number'
              placeholder={t("심폐지구력 결과를 입력해주세요(숫자만)")}
              onChange={e => onChange(e, 'ce', (t) => Number(t))} />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <Row className="align-items-center justify-content-between">
            <Col><label>{t("민첩성")}</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Input
              value={physicalTest?.ag}
              type='number'
              placeholder={t("민첩성 결과를 입력해주세요(숫자만)")}
              onChange={e => onChange(e, 'ag', (t) => Number(t))} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Row className="align-items-center justify-content-between">
            <Col><label>{t("순발력")}</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Input
              value={physicalTest?.po}
              type='number'
              placeholder={t("순발력 결과를 입력해주세요(숫자만)")}
              onChange={e => onChange(e, 'po', (t) => Number(t))} />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <Row className="align-items-center justify-content-between">
            <Col><label>{t("협응성")}</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Input
              value={physicalTest?.co}
              type='number'
              placeholder={t("협응성 결과를 입력해주세요(숫자만)")}
              onChange={e => onChange(e, 'co', (t) => Number(t))} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Row className="align-items-center justify-content-between">
            <Col><label>{t("평형성")}</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Input
              value={physicalTest?.ba}
              type='number'
              placeholder={t("평형성 결과를 입력해주세요(숫자만)")}
              onChange={e => onChange(e, 'ba', (t) => Number(t))} />
          </FormGroup>
        </Col>
      </Row>
      {
        errorMessage
          ? <div>
            <p className="text-danger">{errorMessage}</p>
          </div>

          : null
      }

      <Row>
        {
          props.currentItem?.id
            ? <Col>
              <Button color="danger" onClick={warningWithConfirmAndCancelMessage}>삭제</Button>
            </Col>
            : null
        }
        <Col className='text-right'>
          <Button color="primary" disabled={onSaving || !Boolean(physicalTest?.ms && physicalTest?.me && physicalTest?.ce && physicalTest?.ag && physicalTest?.po && physicalTest?.co && physicalTest?.ba) || !isModified} onClick={onSave}>{t("저장")}</Button>

          <Button color="warning" onClick={onCancel}>{t("취소")}</Button>
        </Col>
      </Row>


    </div>
  )
}


PhysicalTestForm.propTypes = {
  subgroup: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired,
  afterSave: PropTypes.func,
  afterDelete: PropTypes.func,
  onCancel: PropTypes.func,
};

export default PhysicalTestForm

