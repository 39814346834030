import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import ReactBSAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import { skinTypes } from "variables/constants";
import { activeStatus, roles, activeStatusString } from "variables/constants";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Progress,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

import {
  useFirestoreConnect,
  useFirebase,
  useFirestore,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import Select from "react-select";
import { getSelectValues } from "helpers/input";
import ImageUpload from "components/CustomUpload/ImageUpload";
import Switch from "react-bootstrap-switch";
import MultipleSelector from "components/Selectors/MultipleSelector";
import { useTranslation } from "react-i18next";

function ChannelForm(props) {
  const [item, setItem] = useState(
    props.currentItem ? _.cloneDeep(props.currentItem) : null
  );
  const [errorMessage, setErrorMessage] = useState(null);
  const [onSaving, setOnSaving] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [contentVersionOptions, setContentVersionOptions] = useState([]);
  const [curriculumVersionOptions, setCurriculumVersionOptions] = useState([]);
  const [availableContents, setAvailableContents] = useState([]);
  const [alert, setAlert] = useState(null);
  const [file, setFile] = useState(null);

  const firebase = useFirebase();
  const firestore = useFirestore();

  const { t } = useTranslation();

  useFirestoreConnect([
    { collection: "contentVersions" },
    { collection: "curriculumVersions" },
  ]);

  const contentVersions = useSelector(
    (state) => state.firestore.ordered.contentVersions
  );
  const curriculumVersions = useSelector(
    (state) => state.firestore.ordered.curriculumVersions
  );
  // console.log('contentVersions: ', contentVersions);

  useEffect(() => {
    if (props.currentItem?.id) {
      const _item = _.clone(props.currentItem);
      delete _item.id;
      setItem(_item);
    } else {
      setItem({});
    }
    return () => {
      //clean up
    };
  }, [props.currentItem]);

  const getContents = async (contentVersion) => {
    const colsnap = await firestore
      .collection(`/contentVersions/${contentVersion}/contents`)
      .orderBy("contentId")
      .get();
    const contents = colsnap.docs.map((doc) => doc.data());
    setAvailableContents(contents);
    // console.log('contents = ', contents);
  };
  useEffect(() => {
    console.log("item contentVersion: ", item?.contentVersion);
    if (item?.contentVersion) {
      if (item?.contentVersion !== props.currentItem?.contentVersion) {
        setItem({ ...item, availableContentIds: [] });
      }
      getContents(item?.contentVersion);
    }
    return () => {
      // cleanup
    };
  }, [item?.contentVersion]);

  useEffect(() => {
    console.log("contentVersions = ", contentVersions);
    if (contentVersions) {
      const options = contentVersions.map((item) => ({
        value: item?.version,
        label: item?.version,
      }));
      console.log(options);
      setContentVersionOptions(options);
    }
    return () => {
      // cleanup
    };
  }, [contentVersions]);

  useEffect(() => {
    console.log("curriculumVersions = ", curriculumVersions);
    if (curriculumVersions) {
      const options = curriculumVersions.map((item) => ({
        value: item?.version,
        label: item?.version,
      }));
      console.log(options);
      setCurriculumVersionOptions(options);
    }
    return () => {
      // cleanup
    };
  }, [curriculumVersions]);

  useEffect(() => {
    const currentItem = _.cloneDeep(props.currentItem);
    console.log(`currentItem`, currentItem);
    console.log(`item`, item);

    if (currentItem?.id) {
      delete currentItem.id;
    }

    const modified =
      (!_.isEmpty(item) && !_.isEqual(currentItem, item)) || file !== null;
    console.log("modified: ", modified);
    setIsModified(modified);
    return () => {
      // cleanup
    };
  }, [item, file]);

  const onSave = async () => {
    console.log("press save");

    let subLogo = props.currentItem?.subLogo || null;

    setOnSaving(true);

    const timestamp = {
      updatedAt: firestore.FieldValue.serverTimestamp(),
    };

    let counts = _.pick(item, ["nGroups", "nGroupCategories", "nMembers"]);

    const status = item?.status ? item?.status : activeStatus.normal;

    if (!props.currentItem?.id) {
      timestamp["createdAt"] = firestore.FieldValue.serverTimestamp();
      console.log(`@@@@@작동`);
      counts = {
        nGroups: 0,
        nGroupCategories: 0,
        nMembers: 0,
      };
    }

    if (file) {
      try {
        const uploadingInfo = await firebase.uploadFile(
          "userContents",
          file,
          undefined,
          { name: (file) => `${+new Date()}${file.name}` }
        );
        const url = await uploadingInfo.uploadTaskSnapshot.ref.getDownloadURL();
        subLogo = url;
      } catch (e) {
        console.error("Failed to upload file: ", e);
        setErrorMessage("Failed to upload file");
        return;
      }
    }

    if (subLogo !== props.currentItem?.subLogo && props.currentItem?.subLogo) {
      // delete the file from storage
      const uriToDelete = _.findLast(
        new URL(props.currentItem?.subLogo).pathname.split("/")
      );
      try {
        const path = decodeURIComponent(uriToDelete);
        const ret = await firebase.deleteFile(path);
        console.log("deleted ", ret);
      } catch (e) {
        console.error("failed to delete file: ", e);
      }
    }

    try {
      let channelRef = firestore.collection(`channels`);
      if (props.currentItem?.id) {
        channelRef = channelRef.doc(props.currentItem?.id);
      } else {
        channelRef = channelRef.doc();
      }

      await channelRef.set({
        ...item,
        ...timestamp,
        subLogo,
        status,
        ...counts,
      });
      props.afterSave && props.afterSave();
    } catch (error) {
      console.error("Failed to save : ", error);
    } finally {
      setOnSaving(false);
      props.toggle();
    }
  };

  const onDelete = async () => {
    const channelRef = firestore
      .collection(`channels`)
      .doc(props.currentItem.id);
    await channelRef.delete();
    props.toggle();
    props.afterDelete && props.afterDelete();
  };

  const onCancel = () => {
    if (isModified) {
      props.closeConfirm();
    } else {
      props.toggle();
    }
    setItem(null);
  };

  const warningWithConfirmAndCancelMessage = () =>
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={
          <div>
            삭제하면 복구할 수 없습니다.
            <br />
            계속 진행하시겠습니까?
          </div>
        }
        onConfirm={() => {
          onDelete();
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="확인"
        cancelBtnText="취소"
        showCancel
      >
        지우면 완전히 삭제됩니다. 지우시겠습니까?
      </ReactBSAlert>
    );

  const onChange = (e, name, typeFn) => {
    if (name === "skinType") {
      setItem({ ...item, [name]: e.value });
      return;
    }
    const value = e.target.value;
    console.log("value: ", value);
    setItem({ ...item, [name]: typeFn ? typeFn(value) : value });
  };

  const handleFileLoaded = (file) => {
    setErrorMessage(null);
    console.log(file);
    if (!file) {
      setFile(null);
      setItem({ ...item, subLogo: null });
      return;
    }
    if (file?.size > 5 * 1024 * 1024) {
      setErrorMessage(
        "파일 사이즈가 너무 큽니다. 5MB이하로 줄여서 올려주세요."
      );
      setFile(null);
      return;
    }
    const ext = _.last(file?.name.split("."));
    console.log("ext = ", ext);
    if (!["jpg", "jpeg", "png"].includes(ext.toLowerCase())) {
      setErrorMessage(
        "파일 형식을 확인해주세요. 허용가능한 파일 형식: jpg, png"
      );
      setFile(null);
      return;
    }
    setFile(file);
  };

  return (
    <div className="content">
      {alert}
      <Row>
        <Col>
          <div className="text-right">
            <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
              *
            </span>{" "}
            필수 입력 사항입니다
          </div>
        </Col>
      </Row>
      {item?.createdAt && (
        <Row>
          <Col>
            <Row className="align-items-center justify-content-between">
              <Col>
                <label>총판 생성일시</label>
              </Col>
            </Row>
            <FormGroup>
              <Input
                disabled
                value={
                  moment(item?.createdAt.toDate()).format("YYYY-MM-DD HH:mm") ||
                  ""
                }
              />
            </FormGroup>
          </Col>
          <Col>
            <Row className="align-items-center justify-content-between">
              <Col>
                <label>총판 수정일시</label>
              </Col>
            </Row>
            <FormGroup>
              <Input
                disabled
                value={
                  moment(item?.updatedAt.toDate()).format("YYYY-MM-DD HH:mm") ||
                  ""
                }
              />
            </FormGroup>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>총판명</label>
              <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                *
              </span>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={item?.title || ""}
              placeholder="총판을 구분하는 이름을 정해주세요"
              onChange={(e) => onChange(e, "title")}
            />
          </FormGroup>
        </Col>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>총판 설명</label>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={item?.description}
              placeholder="총판에 대한 설명을 간결하게 기술해주세요"
              onChange={(e) => onChange(e, "description")}
            />
          </FormGroup>
        </Col>
      </Row>
      {item?.createdAt && (
        <Row>
          <Col>
            <Row className="align-items-center justify-content-between">
              <Col>
                <label>가맹점수</label>
              </Col>
            </Row>
            <FormGroup>
              <Input disabled value={item?.nGroups || 0} />
            </FormGroup>
          </Col>
          <Col>
            <Row className="align-items-center justify-content-between">
              <Col>
                <label>대리점수</label>
              </Col>
            </Row>
            <FormGroup>
              <Input disabled value={item?.nGroupCategrories || 0} />
            </FormGroup>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={6}>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>콘텐츠 버전</label>
              <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                *
              </span>
            </Col>
          </Row>
          <FormGroup>
            <Select
              className="react-select"
              classNamePrefix="react-select"
              value={{
                value: item?.contentVersion,
                label: item?.contentVersion,
              }}
              onChange={(i) => {
                if (i.value !== item?.contentVersion) {
                  setItem({
                    ...item,
                    contentVersion: i.value,
                  });
                }
              }}
              options={[
                {
                  value: "",
                  label: "콘텐츠 버전 설정",
                  isDisabled: true,
                },
                ...contentVersionOptions,
              ]}
              placeholder="코스 타입 설정"
            />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>커리큘럼 버전</label>
            </Col>
          </Row>
          <FormGroup>
            <Select
              className="react-select"
              classNamePrefix="react-select"
              value={{
                value: item?.curriculumVersion,
                label: item?.curriculumVersion,
              }}
              onChange={(i) => {
                if (i.value !== item?.curriculumVersion) {
                  setItem({
                    ...item,
                    curriculumVersion: i.value,
                  });
                }
              }}
              options={[
                {
                  value: "",
                  label: "커리큘럼 버전 설정",
                  isDisabled: true,
                },
                ...curriculumVersionOptions,
              ]}
              placeholder="코스 타입 설정"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="align-items-center  justify-content-between">
            <Col>
              <label>가용 콘텐츠</label>
              <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                *
              </span>
            </Col>
          </Row>
          <MultipleSelector
            data={{
              items: availableContents.map((content) => ({
                id: content.contentId,
                label: content.title,
              })),
              keys: [
                {
                  id: "availableContentIds",
                  label: "가능한 콘텐츠",
                  values: item?.availableContentIds,
                  callbackFunction: (values) =>
                    setItem({ ...item, availableContentIds: values }),
                },
                {
                  id: "advertisementContentIds",
                  label: "광고용 콘텐츠",
                  values: item?.advertisementContentIds,
                  callbackFunction: (values) =>
                    setItem({ ...item, advertisementContentIds: values }),
                },
              ],
            }}
          />
          {/* <FormGroup>
            <Input
              type="select"
              name="selectMulti"
              id="availableContentIds"
              value={item?.availableContentIds}
              multiple
              onChange={(e) => {
                const selected = getSelectValues(e);
                const availableContentIds = selected.map(i => Numbr(i.value));
                console.log('availableContentIds = ', availableContentIds);
                setItem({ ...item, availableContentIds });
              }}
            >
              {
                availableContents.map(content =>
                  <option value={content.contentId} key={content.contentId}>
                    [{content.contentId}] {content.title}
                  </option>)
              }
            </Input>
          </FormGroup> */}
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>스킨 타입</label>
              <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                *
              </span>
            </Col>
          </Row>
          <FormGroup>
            <Select
              className="react-select info"
              classNamePrefix="react-select"
              placeholder="스킨 타입 선택"
              name="multipleSelect"
              value={
                item?.skinType && {
                  value: item?.skinType,
                  label: skinTypes[item.skinType],
                }
              }
              onChange={(e) => onChange(e, "skinType")}
              options={[
                ...skinTypes.map((itm, idx) => ({ value: idx, label: itm })),
              ]}
            />
          </FormGroup>
        </Col>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>상태</label>
              <span className="ml-1" style={{ color: "red", fontSize: 20 }}>
                *
              </span>
            </Col>
          </Row>
          <FormGroup row>
            {activeStatusString.map((status, index) => (
              <FormGroup key={index} check>
                <Label check>
                  <Input
                    dis
                    type="radio"
                    name="status"
                    defaultChecked={
                      item?.status ? index + 1 === item?.status : index === 0
                    }
                    value={index + 1}
                    onClick={(e) => onChange(e, "status", (t) => Number(t))}
                  />{" "}
                  {t(status)}
                </Label>
              </FormGroup>
            ))}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>총판 담당자 이름</label>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={item?.director || ""}
              placeholder="총판 담당자 이름을 입력해주세요"
              onChange={(e) => onChange(e, "director")}
            />
          </FormGroup>
        </Col>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>총판 담당 연락처</label>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={item?.directorPhone}
              placeholder="000-0000-0000"
              onChange={(e) => onChange(e, "directorPhone")}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>총판 주소</label>
            </Col>
          </Row>
          <FormGroup>
            <Input
              value={item?.directorAddress}
              placeholder="총판 담당자 주소를 입력해주세요"
              onChange={(e) => onChange(e, "directorAddress")}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>
              <label>총판 서브 로고</label>
            </Col>
          </Row>
          <FormGroup>
            <ImageUpload
              onFileLoaded={handleFileLoaded}
              imageUrl={item?.subLogo}
            />
            {/* <Input
              value={item?.subLogo}
              placeholder="총판에 대한 설명을 간결하게 기술해주세요"
              onChange={e => onChange(e, 'description')} /> */}
          </FormGroup>
        </Col>
      </Row>
      <Row></Row>
      {errorMessage ? (
        <div>
          <p className="text-danger">{errorMessage}</p>
        </div>
      ) : null}
      <Row>
        {/* {
          props.currentItem?.id
            ? <Col>
              <Button color="danger" onClick={warningWithConfirmAndCancelMessage}>삭제</Button>
            </Col>
            : null
        } */}
        <Col className="text-right">
          <Button
            color="primary"
            disabled={
              onSaving ||
              !Boolean(
                item?.title && item?.title.length > 0 && item?.contentVersion
              ) ||
              !isModified
            }
            onClick={onSave}
          >
            저장
          </Button>
          <Button color="warning" onClick={onCancel}>
            취소
          </Button>
        </Col>
      </Row>
    </div>
  );
}

ChannelForm.propTypes = {
  afterSave: PropTypes.func,
  afterDelete: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ChannelForm;
