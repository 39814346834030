import React, { useState, useEffect } from 'react'

import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import _ from 'lodash';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  FormGroup,
  Input,
} from "reactstrap";

import Select from "react-select";

import readXlsxFile from 'read-excel-file'
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { asyncForEach } from 'helpers/async';
import { newContentSchema } from 'variables/schema';
import { CURRICULUM_VERSION_STATUS_STRING } from 'variables/status';
import { CONTENT_VERSION_STATUS } from 'variables/status';
import { questionTypes } from 'variables/constants';
import arrayMove from 'array-move';
import { questionLanguages } from 'variables/constants';
import GroupPicker from 'views/components/GroupPicker';

function QuestionForm(props) {
  console.log('props = ', props);
  const [questionBank, setQuestionBank] = useState(props.currentItem || { questions: [{ question: '', answer: '', answerDescription: '' }] });
  const [infoMessage, setInfoMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModified, setIsModified] = useState(false);

  const [onSaving, setOnSaving] = useState(false);

  const firebase = useFirebase();
  const firestore = useFirestore();

  // useFirestoreConnect([
  //   {
  //     collection: 'contentVersions',
  //     where: [
  //       ['contentVersionStatus', '==', CONTENT_VERSION_STATUS.DEPLOYED]
  //     ],
  //     orderBy: [
  //       ['createdAt', 'desc']
  //     ],
  //     storeAs: 'activeContentVersions'
  //   }
  // ]);


  const checkValidity = () => {
    const { title, subTitle, questions } = questionBank;

    console.log('title = ', title);
    console.log('subTitle', subTitle);
    console.log('questions = ', questions);

    if (!title || title.length < 0) {
      setErrorMessage('제목 정보를 제대로 입력해주세요.');
      return false;
    }

    if (!subTitle || subTitle.length < 0) {
      setErrorMessage('부제목 정보를 제대로 입력해주세요.');
      return false;
    }

    return true;
  }

  useEffect(() => {
    const currentItem = _.cloneDeep(props.currentItem);
    if (currentItem == null && Object.keys(questionBank).length === 0) {
      return
    }
    const modified = !_.isEqual(currentItem, questionBank);
    console.log("modified: ", modified);
    console.log(`currentItem`, currentItem);
    console.log(`questionBank`, questionBank);

    setIsModified(modified);
    return () => {
      // cleanup
    };
  }, [questionBank]);

  const onSave = async () => {
    console.log('press save');

    if (!checkValidity()) {
      // setErrorMessage('유효하지 않는 값입니다.');
      return;
    }

    setOnSaving(true);

    let timestamp;

    if (props.currentItem?.id) {
      timestamp = {
        updatedAt: firestore.FieldValue.serverTimestamp()
      };
    } else {
      timestamp = {
        createdAt: firestore.FieldValue.serverTimestamp(),
        updatedAt: firestore.FieldValue.serverTimestamp()
      };
    }

    try {
      const currentItemRef = questionBank.id
        ? firestore.collection('questionBank').doc(questionBank.id)
        : firestore.collection('questionBank').doc()

      // if (!props.currentItem) {
      //   const docSnap = await currentItemRef.get();
      //   if (docSnap.exists) {
      //     setErrorMessage('이미 존재하는 버전으로 생성할 수 없습니다.');
      //     throw Error('exisiting-version-error');
      //   }
      // }

      await currentItemRef.set({
        ...questionBank,
        ...timestamp,
      });
      setErrorMessage(null);
      props.toggle();
      props.afterSave && props.afterSave();
    } catch (error) {
      console.error('Failed to save : ', error);
    } finally {
      setOnSaving(false);
    }
  }

  const onCancel = () => {
    if (isModified) {
      props.closeConfirm();
    } else {
      props.toggle();
    }
  }

  const onChange = (e, name, typeFn) => {
    const value = e.target.value;
    setQuestionBank({ ...questionBank, [name]: typeFn ? typeFn(value) : value });
  }

  const onChangeQuestion = (e, idx, name, typeFn) => {
    const value = e.target.value;
    const newQuestions = questionBank.questions.map((q, _idx) => idx === _idx ? { ...q, [name]: typeFn ? typeFn(value) : value } : q);
    setQuestionBank({ ...questionBank, questions: newQuestions });
  }

  const addQuestion = (index) => {
    const newQuestions = _.clone(questionBank.questions);
    newQuestions.splice(index + 1, 0, { question: '', answer: '', answerDescription: '' });
    setQuestionBank({ ...questionBank, questions: newQuestions });
  }

  const removeQuestion = (index) => {
    const newQuestions = questionBank.questions.filter((q, _idx) => index !== _idx);
    setQuestionBank({ ...questionBank, questions: newQuestions });
  }

  const moveUp = (index) => {
    if (index === 0) {
      return;
    }
    const newQuestions = arrayMove(questionBank.questions, index, --index);
    setQuestionBank({ ...questionBank, questions: newQuestions });
  }

  const moveDown = (index) => {
    if (index === questionBank.questions.length - 1) {
      return;
    }
    const newQuestions = arrayMove(questionBank.questions, index, ++index);
    setQuestionBank({ ...questionBank, questions: newQuestions });
  }


  return (

    <div className='content'>
      <Row>
        <Col>
          <div className="text-right"><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span> 필수 입력 사항입니다</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <GroupPicker
            mode={2}
            viewMode={['channel', 'group']}
            channelId={questionBank?.channelId}
            groupId={questionBank?.groupId}
            cbSelected={(selected) => {
              const updatedData = {
                channelId: selected?.channel?.id || 'default',
                groupId: selected?.group?.id || 'default',
                channel: _.pick(selected?.channel, ['id', 'title']) || null,
                group: _.pick(selected?.group, ['id', 'title']) || null
              };
              console.log('updatedData = ', updatedData);
              setQuestionBank({
                ...questionBank,
                ...updatedData,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className='align-items-center justify-content-between'>
            <Col><label>주제</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Input
              value={questionBank?.title || ''}
              placeholder='ex) 역사'
              onChange={e => onChange(e, 'title')} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className='align-items-center justify-content-between'>
            <Col><label>제목</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Input
              value={questionBank?.subTitle || ''}
              placeholder='ex) 세계사'
              onChange={e => onChange(e, 'subTitle')} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className='align-items-center justify-content-between'>
            <Col><label>언어</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="type"
              value={{
                value: questionBank?.language,
                label: questionLanguages[questionBank?.language]
              }}
              onChange={({ value }) => {
                // console.log('value', value);
                if (value !== questionBank?.language) {
                  setQuestionBank({ ...questionBank, language: value })
                  // console.log('program', program);
                }
              }}
              options={Object.entries(questionLanguages).map(([k, v]) => ({ value: k, label: v }))}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className='align-items-center justify-content-between'>
            <Col><label>설명</label></Col>
          </Row>
          <FormGroup>
            <Input
              value={questionBank?.description || ''}
              onChange={e => onChange(e, 'description')} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className='align-items-center justify-content-between'>
            <Col><label>질문 리스트</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          {
            questionBank?.questions?.map((q, idx) => {
              return <Row key={idx}>
                <Col>
                  <Row className="align-items-center justify-content-between">
                    <Col><label>문제 {idx + 1}.</label></Col>
                  </Row>
                  <FormGroup>
                    <Input
                      type='textarea'
                      value={q?.question || ''}
                      placeholder="문제 내용"
                      onChange={e => onChangeQuestion(e, idx, 'question')} />
                  </FormGroup>
                </Col>
                <Col>
                  <Row className="align-items-center justify-content-between">
                    <Col><label>정답</label></Col>
                  </Row>
                  <FormGroup>
                    <Input
                      value={q?.answer || ''}
                      placeholder="정답"
                      onChange={e => onChangeQuestion(e, idx, 'answer')} />
                  </FormGroup>
                </Col>
                <Col>
                  <Row className="align-items-center justify-content-between">
                    <Col><label>정답설명</label></Col>
                  </Row>
                  <FormGroup>
                    <Input
                      type='textarea'
                      placeholder="정답에 대한 설명"
                      value={q?.answerDescription || ''}
                      onChange={e => onChangeQuestion(e, idx, 'answerDescription')} />
                  </FormGroup>
                </Col>
                <Col className='d-flex align-items-end'>
                  <Button className="btn-icon btn-round" size="sm" color="primary" onClick={() => addQuestion(idx)}>
                    <i className="fa fa-plus" />
                  </Button>
                  <Button className="btn-icon btn-round" size="sm" color="danger" onClick={() => removeQuestion(idx)}>
                    <i className="fa fa-minus" />
                  </Button>
                  <Button className="btn-icon" size="sm" color="info" onClick={() => moveUp(idx)}>
                    <i className="fa fa-arrow-up" />
                  </Button>
                  <Button className="btn-icon" size="sm" color="info" onClick={() => moveDown(idx)}>
                    <i className="fa fa-arrow-down" />
                  </Button>
                </Col>
              </Row>
            })
          }
        </Col>
      </Row>
      {
        errorMessage
          ? <div>
            <p className="text-danger">{errorMessage}</p>
          </div>
          : null
      }
      <Row>
        <Col className='text-right'>
          <Button color="primary" disabled={onSaving || !isModified} onClick={onSave}>
            {
              onSaving ?
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                : null
            }
            저장
            </Button>
          <Button color="warning" onClick={onCancel}>취소</Button>
        </Col>
      </Row>

    </div>
  )
}

export default QuestionForm
