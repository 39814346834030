import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux';
import { useFirestore, useFirebase, useFirestoreConnect } from 'react-redux-firebase';

import Tree from '@naisutech/react-tree'
import Select from "react-select";

import {
  Row,
  Col,
  FormGroup,
} from 'reactstrap';
import { get } from 'lodash';
import { useTranslation } from "react-i18next";

function CoursePicker({ curriculumVersion, course, cbSelected, checkDisableId}) {

  console.log('curriculumVersion = ', curriculumVersion);
  
  console.log('checkdisabled in course picker = ', checkDisableId);

  console.log('true or false in course picker = ', checkDisableId== 'L8fuNXDnH6tQjD0cspc6');
  const { t, i18n } = useTranslation();

  const [optionCourses, setOptionCourses] = useState([]);

  const [selectedCourse, setSelectedCourse] = useState(course || null);

  useFirestoreConnect([
    {
      collection: `curriculumVersions/${curriculumVersion}/courses`,
      orderBy: ['title', 'asc'],
      storeAs: 'courses'
    }
  ]);

  const firestore = useFirestore();

  const courses = useSelector(state => state.firestore.ordered.courses);

  useEffect(() => {
    console.log('selected course = ', selectedCourse);

    const initOptions = async () => {
      const forceUpdate = true;
      if (selectedCourse) {
        await selectCourse(selectedCourse, forceUpdate);
      }
    }
    initOptions();
    return () => {
      // cleanup
    }
  }, []);


  useEffect(() => {
    if (courses) {
      console.log('courses = ', courses);
      setOptionCourses(courses);
    }
    return () => {
      // cleanup
    }
  }, [courses])


  const selectCourse = async (course) => {
    if (selectedCourse?.id !== course.id) {
      setSelectedCourse(course);
      cbSelected({
        course
      })
    }
  }

  return (
    <div>
      <Row>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col><label>{t('커리큘럼 코스 선택')}</label><span className="ml-1" style={{ color: 'red', fontSize: 20 }}>*</span></Col>
          </Row>
          <FormGroup>
            <Select
              className="react-select"
              classNamePrefix="react-select"
              value={{ value: selectedCourse, label: selectedCourse?.title }}
              onChange={item => {
                if (item.value.id !== selectedCourse?.id) {
                  selectCourse(item.value);
                }
              }}
              isDisabled=
              {
                checkDisableId=== 'L8fuNXDnH6tQjD0cspc6'? true:false
              }
              options={[
                {
                  value: "",
                  label: "코스 선택",
                  isDisabled: true
                },
                ...optionCourses.map((item, idx) => ({ value: item, label: item.title }))
              ]}
              placeholder="커리큘럼 코스를 선택하세요."
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  )
}

export default CoursePicker
