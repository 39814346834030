import React from 'react'
import _ from 'lodash'
import { useTranslation } from "react-i18next";

import {
  Container,
  Row,
  Col,
  Input,
  Label,
} from 'reactstrap'

function DayAndTimePicker({ data, onChange }) {
  const { t, i18n } = useTranslation();
  return (
    <Container>
      <Row>
        <Col sm="12" md={{ size: 10, offset: 1 }}>
          {
            [t("일"), t("월"), t("화"), t("수"), t("목"), t("금"), t("토")].map((dayOfWeek, idx) => {
              const item = _.findLast(data, { dayOfWeek: idx });
              const start = `${item?.start}`;
              const end = `${item?.end}`;
              return <Row key={idx}>
                <Col md="1">
                  <Input type="checkbox"
                    checked={Boolean(item)}
                    type='checkbox'
                    locale='en'
                    onChange={e => {
                      let updatedValues;
                      if (e.target.checked) {
                        updatedValues = [...data, { dayOfWeek: idx }];
                      } else {
                        updatedValues = data?.filter(item => item.dayOfWeek !== idx);
                      }
                      return onChange(updatedValues);
                    }}
                  />{' '}{dayOfWeek}
                </Col>
                <Col className='text-right'>
                  <Input
                    type='time'
                    disabled={!Boolean(item)}
                    value={start}
                    onChange={e => {
                      return onChange([...(data.filter(item => item.dayOfWeek !== idx)), { ...item, start: e.target.value }]);
                    }}
                  />
                  <Label className='text-muted'>{t("부터")}</Label>
                </Col>
                <Col className='text-right'>
                  <Input
                    type='time'
                    disabled={!Boolean(item)}
                    value={end}
                    onChange={e => {
                      return onChange([...(data.filter(item => item.dayOfWeek !== idx)), { ...item, end: e.target.value }]);
                    }}
                  />
                  <Label className='text-muted'>{t("까지")}</Label>
                </Col>
              </Row>
            })
          }
        </Col>
      </Row>
    </Container>
  )
}

export default DayAndTimePicker
