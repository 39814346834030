import React from 'react'

import { Radar } from 'react-chartjs-2';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from "react-i18next";

const colorOptions = [
  {
    backgroundColor: 'rgba(35, 85, 164, 0.2)',
    borderColor: 'rgba(35, 85, 164,1.0)',
    pointBackgroundColor: 'rgba(0, 0, 0,0.0)',
    pointBorderColor: 'rgba(0, 0, 0,0.0)',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(35, 85, 164,1.0)',
  },
  {
    backgroundColor: 'rgba(98, 78, 157, 0.2)',
    borderColor: 'rgba(98, 78, 157,1.0)',
    pointBackgroundColor: 'rgba(0, 0, 0,0.0)',
    pointBorderColor: 'rgba(0, 0, 0,0.0)',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(98, 78, 157,1.0)',
  },
  {
    backgroundColor: 'rgba(231, 70, 146,0.2)',
    borderColor: 'rgba(231, 70, 146,1.0)',
    pointBackgroundColor: 'rgba(0, 0, 0,0.0)',
    pointBorderColor: 'rgba(0, 0, 0,0.0)',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(231, 70, 146,1.0)',
  },
]


const RadarChart = ({ physicalTests = [] }) => {
  const { t, i18n } = useTranslation();
  console.log('physicalTests = ', physicalTests);
  const data = {
    labels: ['MS', 'ME', 'CE', 'AG', 'PO', 'CO', 'BA'],
    datasets: physicalTests?.map((item, idx, array) => ({
      label: item?.sessionNo + '회차',
      ...colorOptions[idx],
      data: [item.ms, item.me, item.ce, item.ag, item.po, item.co, item.ba]
    }))
  }
  console.log('data = ', data);
  return (
    <div style={{padding:0, }} >
      <h5 className='text-muted'>{t("체력프로파일")}</h5>
      <Radar
        height={300}
        
        padding={0}
        data={data}
        options={{
          legend: {
            display: false
          }
        }}
      />
    </div>
  )
}


export default RadarChart
